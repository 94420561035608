import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  AD_RENTS_ATTEMPT,
  AD_RENTS_SUCCESS,
  AD_RENTS_FAILED,
  ////////////////////////////////
  AD_RENTS_MORE_ATTEMPT,
  AD_RENTS_MORE_SUCCESS,
  AD_RENTS_MORE_FAILED,
  ////////////////////////////////
  AD_RENTS_CHANGE_POSTSTATUS_SUCCESS,
  AD_RENTS_CHANGE_POSTSTATUS_FAILED,
  ///////////////////////////
  AD_RENTS_REMOVE_SUCCESS,
  AD_RENTS_REMOVE_FAILED,
  ////////////////////////////////
  AD_RENTS_NEW_SUCCESS,
  AD_RENTS_NEW_FAILED,
} from "../types/adRentsTypes";

import { AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_SUCCESS } from "../types/adRentsInactiveTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_RENTS_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: AD_RENTS_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: AD_RENTS_FAILED,
    error: error,
  };
}
/////////////////////////////////////////////
export function loadingMore() {
  return {
    type: AD_RENTS_MORE_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: AD_RENTS_MORE_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: AD_RENTS_MORE_FAILED,
    error: error,
  };
}

////////////////////////////////////////////////////
export function successRemoveAd(item) {
  return {
    type: AD_RENTS_REMOVE_SUCCESS,
    item: item,
  };
}
export function failedRemoveAd(error) {
  return {
    type: AD_RENTS_REMOVE_FAILED,
    error: error,
  };
}
////////////////////////////////////////////////////
export function successUpdatePostStatus(item) {
  return {
    type: AD_RENTS_CHANGE_POSTSTATUS_SUCCESS,
    item: item,
  };
}
export function failedUpdatePostStatus(error) {
  return {
    type: AD_RENTS_CHANGE_POSTSTATUS_FAILED,
    error: error,
  };
}
/////////////////////////////////////////
export function successUpdatePostStatusInactive(item) {
  return {
    type: AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_SUCCESS,
    item: item,
  };
}

/**
 *   export function successScroll(data){
    return{
    type:NOTIFICATION_LIST_SCROLL,
    data:data
    }
};
*/
/////////////////////////////////////////////
export function successAdNew(item) {
  return {
    type: AD_RENTS_NEW_SUCCESS,
    item: item,
  };
}
export function failedAdNew(error) {
  return {
    type: AD_RENTS_NEW_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "rent/active", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async (dispatch) => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "rent/active", {
      params: {
        index: data.page_count,
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "goder/poststatus", {
      goder_id: data.id,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success") {
          dispatch(successUpdatePostStatus(response.data.body));
          dispatch(successUpdatePostStatusInactive(response.data.body));
        }
        else dispatch(failedUpdatePostStatus(response.data.body));
      } else {
        dispatch(failedUpdatePostStatus("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdatePostStatus("try_later"));
      } else {
        dispatch(failedUpdatePostStatus("try_later"));
      }
      return;
    });
};

export const deleteItem = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "rent", {
      goder_id: data.id,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successRemoveAd(response.data.body));
        else dispatch(failedRemoveAd(response.data.body));
      } else {
        dispatch(failedRemoveAd("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedRemoveAd("try_later"));
      } else {
        dispatch(failedRemoveAd("try_later"));
      }
      return;
    });
};

export const newItem = (data: Object) => async (dispatch) => {
  // await axios({
  //   method: "post",
  //   url: url_api + "rent",
  //   data: data.params,
  // }, {
  //   headers: { Authorization: `Bearer ${data.token}` },
  // })

  await axios.post(url_api + "rent", data.params, {
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //  console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAdNew(response.data.body));
        else dispatch(failedAdNew(response.data.body));
      } else {
        dispatch(failedAdNew("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedAdNew("try_later"));
      } else {
        dispatch(failedAdNew("try_later"));
      }
      return;
    });
};



