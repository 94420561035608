import React from "react";
import "./styles/AdDescription.css";
import { connect } from "react-redux";

class AdDescription extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="goder-description">
          <div
            dangerouslySetInnerHTML={{ __html: this.props.ad.Description }}
          />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    ad: reducer.ad.item
  };
}

export default connect(mapStateToProps, null)(AdDescription);
