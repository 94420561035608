import React, { Component } from "react";
import "./styles/Home.css";
import PageLoading from "../components/PageLoading";
import AdList from "../components/AdList";
import AdMenu from "../components/AdMenu";
import PublishVertical from "../components/PublishVertical";
import { connect } from "react-redux";
// import * as actions from "../redux/actions/adRentsActions";
import * as actions from "../redux/actions/adListActions";
import SummaryByCountry from "../components/SummaryByCountry";
import Footer from "../components/Footer";
import { Translate } from "react-redux-i18n";
import MiniLoader from "../components/MiniLoader";

class HomeRents extends Component {

    componentDidMount() {
        this.fetchAdList();
    }



    onClickLoadingMore = () => {
        this.fetchMoreAdList();
    }

    fetchAdList = async () => {
        try {
            await this.props.LoadAdHome(this.props.user_id, this.props.token);
        } catch (error) {
            console.log(error);
        }
    };

    fetchMoreAdList = async () => {
        try {
            await this.props.LoadMoreAdHome(this.props.pageCount, this.props.user_id, this.props.token);
        } catch (error) {
            console.log(error);
        }
    };

    componentWillUnmount() { }

    render() {
        return (
            <React.Fragment>
                <div className="Home-in">
                    <div className="col-lg-3 d-none d-lg-block ">
                        <div className="Home-left">
                            <AdMenu />
                            <SummaryByCountry />
                            <PublishVertical />
                        </div>
                    </div>
                    <div className="col-lg-9 ">
                        {
                            this.props.loading ?
                                (
                                    <PageLoading />
                                ) :
                                (<React.Fragment>
                                    <AdList adList={this.props.adList} />
                                    {
                                        this.props.page_size >= 10 && (
                                            this.props.loadingMore ? (
                                                <div className="text-center">
                                                    <MiniLoader />
                                                </div>
                                            ) :
                                                (
                                                    <div className="text-center" onClick={this.onClickLoadingMore}>
                                                        <button className="btn btn-secondary" >
                                                            <Translate value="load_more" />
                                                        </button>
                                                    </div>
                                                )
                                        )
                                    }</React.Fragment>
                                )
                        }
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

function mapStateToProps(reducer) {
    //console.log(reducer);
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,

        ////////////////////////////
        adList: reducer.adList.list,
        error: reducer.adList.error,
        loading: reducer.adList.loading,
        ////////////////////////////
        loadingMore: reducer.adList.loadingMore,
        page_index: reducer.adList.page_index,
        page_size: reducer.adList.page_size,
        pageCount: reducer.adList.page_count,
        ///////////////////////////////
    };
}
const mapDispatchToProps = (dispatch) => ({
    LoadAdHome: (user_id, token) =>
        dispatch(actions.loadListRent({ user_id, token })),
    LoadMoreAdHome: (page_index, user_id, token) =>
        dispatch(actions.loadListScrollRent({ page_index, user_id, token })),
    /////////////////////////////////



});

export default connect(mapStateToProps, mapDispatchToProps)(HomeRents);