import {
  PUBLISH_LIST_ATTEMPT,
  PUBLISH_LIST_SUCCESS,
  PUBLISH_LIST_FAILED,
  //////////////////////////
  PUBLISH_NEW_SUCCESS,
  PUBLISH_NEW_FAILED
} from "../types/publishTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  success: "",
  item: {},
  error_update: ""
};

function publish(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PUBLISH_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case PUBLISH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.list,
        error: ""
      };

    case PUBLISH_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    case PUBLISH_NEW_SUCCESS:
      return {
        ...state,
        item: action.item,
        error_update: ""
      };

    case PUBLISH_NEW_FAILED:
      return {
        ...state,
        error_update: action.error
      };

    default:
      return state;
  }
}
export default publish;
