import React from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";//, I18n 
import { FaDownload, FaEye } from "react-icons/fa";//, FaFilePdf
//import ReactPDF from "@react-pdf/renderer";
//import { OrderPdf } from "../pages/OrderPdf";
import { withRouter } from "react-router";
import MiniLoader from "./MiniLoader";
import * as actions from "../redux/actions/ordersActions";
//import { PDFViewer } from '@react-pdf/renderer';

class PayListItem extends React.Component {

  onClick = (item) => {
    this.props.setItem(item);
  }
  render() {
    // var date_local = new Date(Date.parse(this.props.item.CreatedAt + " UTC"));
    return (
      <tr>
        <th>{this.props.item.Id}</th>
        <th>{this.props.item.Total / 100}{'USD'}</th>
        <th>{this.props.item.Kind}</th>
        <th>{this.props.item.Status}</th>
        <th>{this.props.item.CreatedAt}</th>
        <th colSpan="3">
          {/* <a
            className="btn btn-primary"
            href={`https://www.godiway.com/api/order/download/${this.props.item.Id}`}
          > */}
          <button class="btn btn-primary" onClick={this.props.onClick}>
            <FaDownload />
          </button>


          {/* <a
            className="btn btn-secondary"
            target="_blank"
            href={`https://www.godiway.com/api/order/view/${this.props.item.Id}`}
            rel="noopener noreferrer"
          > */}
          {/* <button class="btn btn-secondary" onClick={this.props.onClick}>
            <FaEye />
          </button>
          </a> */}
        </th>
      </tr>
    );
  }
}

class PayList extends React.Component {

  onClickLoadMore = (item) => {
    this.fetchOrdersList();
  };

  fetchOrdersList = async () => {
    try {
      await this.props
        .LoadOrders(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClick = (item) => {
    //this.props.history.push("/orderview");
    //this.props.setItem(item);
    const win = window.open("/orderview/" + item.Id, "_blank");
    win.focus();
  }

  render() {
    if (this.props.orderList.length === 0)
      return (
        <h3>
          <Translate value="order_not_list" />
        </h3>
      );

    return (
      <div className="col-12">
        <div className="row ">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>#</th>
                <th>Total</th>
                <th>
                  {" "}
                  <Translate value="order_kind" />
                </th>
                <th>
                  {" "}
                  <Translate value="order_status" />
                </th>
                <th>
                  <Translate value="order_date" />
                </th>
                <th colSpan="3">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.orderList.map((item) => {
                return (
                  <PayListItem
                    item={item}
                    key={item.Id}
                    onClick={() => {
                      this.onClick(item);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
        {
          this.props.pageSize >= 10 && (
            this.props.loadingMore ? (
              <div className="text-center ">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }

      </div>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    orderList: reducer.orders.list,

    pageCount: reducer.orders.page_count,
    pageSize: reducer.orders.page_size,
    loadingMore: reducer.orders.loadingMore,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadOrders: (page_index, user_id, token) =>
    dispatch(actions.loadListScroll({ page_index, user_id, token })),
  //setItem: (item) => dispatch(actions.setItem({ item })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayList)
);


//ReactPDF.render(<MyDocument />, `${__dirname}/example.pdf`);

//ReactPDF.renderToStream(<MyDocument />);