import {
    MARKETSTORE_EXTRA_ATTEMPT,
    MARKETSTORE_EXTRA_SUCCESS,
    MARKETSTORE_EXTRA_FAILED,
} from "../types/marketstoreExtraListTypes";

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: "",

    page_size: 0,
    page_count: 0,
    loadingMore: false
};

function marketstoreExtraList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case MARKETSTORE_EXTRA_ATTEMPT:
            return {
                ...state,
                loading: true,
            };
        case MARKETSTORE_EXTRA_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: "",
            };

        case MARKETSTORE_EXTRA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: [],
            };
        //////////////////////////

        default:
            return state;
    }
}
export default marketstoreExtraList;