import {
  MARKETSTORE_SEARCH_ATTEMPT,
  MARKETSTORE_SEARCH_SUCCESS,
  MARKETSTORE_SEARCH_FAILED,
} from "../types/marketstoreSearchTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  page_size: 0,
  page_count: 0
};
function marketstoreSearch(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKETSTORE_SEARCH_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case MARKETSTORE_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: "",
      };

    case MARKETSTORE_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };

    default:
      return state;
  }
}
export default marketstoreSearch;
