import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
  CARD_LIST_ATTEMPT,
  CARD_LIST_SUCCESS,
  CARD_LIST_FAILED
} from "../types/cardsTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: CARD_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: CARD_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: CARD_LIST_FAILED,
    error: error
  };
}

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "card", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
