
import {
    CITY_LIST_ATTEMP,
    CITY_LIST_SUCCESS,
    CITY_LIST_FAILED,
} from '../types/cityTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
};
function city(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CITY_LIST_ATTEMP:
            return {
                ...state,
                loading: true,
            }

        case CITY_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: ''
            }

        case CITY_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }

        default: return state;
    }
};
export default city;