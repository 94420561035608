import React from "react";
import "./styles/Profile.css";
import ProfileLayout from "../components/ProfileLayout";
import { connect } from "react-redux";
import * as actions from "../redux/actions/userActions";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { FaCamera } from "react-icons/fa";

class Profile extends React.Component {
  state = {
    avatar: ""
  };

  componentDidMount() {
    this.fetchProfile();
  }

  fetchProfile = async () => {
    try {
      await this.props
        .LoadProfile(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else this.setState({ avatar: this.props.profile.Avatar });
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeAvatar = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        //   file: file,
        avatar: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    return (
      <React.Fragment>
        <ProfileLayout>
          <div className="">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title text-center">
                  <b>@{this.props.profile.Username}</b>
                </h3>
                <hr className="dividerGray" />
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <figure>
                      <div className="form-group drop">
                        <label>
                          <img
                            id="image"
                            className="prev_thumb img-rounded"
                            src={this.state.avatar}
                            alt="Avatar"
                            width="260px"
                            height="260px"
                          />
                          <div></div>
                        </label>
                      </div>
                    </figure>
                  </div>
                  <div className=" col-lg-6 col-md-12 col-sm-12 col-xs-12  ">
                    <div className="prof-info mt10">
                      <div className="row">
                        <small className="col-12">
                          <Translate value="profile_name" />:
                        </small>
                        <span className="col-12">
                          {this.props.profile.Name} {this.props.profile.LastN}
                        </span>
                      </div>
                      <hr className="dividerGray" />
                      <div className="row">
                        <small className="col-12">
                          <Translate value="profile_email" />:{" "}
                        </small>
                        <span className="col-12">
                          {this.props.profile.Email}
                        </span>
                      </div>
                      <hr className="dividerGray" />
                      <div className="row">
                        <small className="col-12">
                          <Translate value="profile_phone" />:
                        </small>
                        <span className="col-12">
                          {this.props.profile.Country !== undefined && (
                            <React.Fragment>
                              <img
                                src={this.props.profile.Country.Flag}
                                className="profile-flag"
                                alt="Gw"
                              />
                              +{this.props.profile.Country.PhoneCode}
                            </React.Fragment>
                          )}{" "}
                          {this.props.profile.Phone}
                        </span>
                      </div>
                      <hr className="dividerGray" />
                      <div className="row">
                        <small className="col-12">
                          <Translate value="profile_city" />:
                        </small>
                        <span className="col-12">
                          {this.props.profile.State && this.props.profile.State.Name}
                        </span>
                      </div>
                      <hr className="dividerGray" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Link to="/" className="btn btn-secondary mb-4 mg-10">
                    <Translate value="back" />
                  </Link>
                  <Link
                    to="/profile/edit"
                    className="btn btn-primary mb-4 mg-10"
                  >
                    <Translate value="profile_edit" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </ProfileLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    profile: reducer.user.item,
    error: reducer.user.error,
    loading: reducer.user.loading
  };
}
const mapDispatchToProps = dispatch => ({
  LoadProfile: (user_id, token) =>
    dispatch(actions.loadItem({ user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
