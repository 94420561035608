import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";

import "./styles/AdMenu.css";

import sale from "../assets/138213.svg";
import rent from "../assets/998220.svg";
import exchange from "../assets/994393.svg";
import donation from "../assets/940817.svg";
import ventarenta from "../assets/venta-renta.svg";


class AdMenu extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="admenu-container">
          <br />
          <div className="col ">
            <Link to="/goder/new/sale" style={{ textDecoration: "none" }}>
              <div className="" align="center">
                <img src={sale} className="card" width="150" height="150" alt="sale" />
                <h4>
                  <Translate value="sale" />
                </h4>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/goder/new/rent" style={{ textDecoration: "none" }}>
              <div className="" align="center">
                <img src={rent} width="150" height="150" className="card" alt="rent" />
                <h4>
                  <Translate value="rent" />
                </h4>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/goder/new/exchange" style={{ textDecoration: "none" }}>
              <div className="" align="center">
                <img src={exchange} width="150" height="150" className="card" alt="exchange" />
                <h4>
                  <Translate value="exchange" />
                </h4>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/goder/new/donation" style={{ textDecoration: "none" }}>
              <div className="" align="center">
                <img src={donation} width="150" height="150" className="card" alt="donation" />
                <h4>
                  <Translate value="donation" />
                </h4>
              </div>
            </Link>
          </div>


        </div>
      </div>
    );
  }
}

export default AdMenu;

/*

 <div className="col">
            <Link to="/goder/new/auction" style={{ textDecoration: "none" }}>
              <div className="" align="center">
                <img src={ventarenta} width="150" height="150" className="card" alt="auction" />
                <h4>
                  <Translate value="auction" />
                </h4>
              </div>
            </Link>
          </div>

          
 <div className="col">
            <Link to="/ad/new/auction">
              <div className="" align="center">
                <img src={auction} width="150" height="150" className="card" />
                <h4>
                  <Translate value="express" />
                </h4>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/ad/new/auction">
              <div className="" align="center">
                <img src={auction} width="150" height="150" className="card" />
                <h4>
                  <Translate value="bidder" />
                </h4>
              </div>
            </Link>
          </div>*/
