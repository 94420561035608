import {
  VIDEO_LIST_ATTEMPT,
  VIDEO_LIST_SUCCESS,
  VIDEO_LIST_FAILED,
  ////////////////////////
  REMOVE_VIDEO_SUCCESS,
  REMOVE_VIDEO_FAILED,
  /////////////////////////
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_LOADING,
  ADD_VIDEO_FAILED,
} from "../types/videoTypes";

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: "",

  progress: 0,
  success: "",
  loadingFile: false,
  error_update: "",
};

function video(state = INITIAL_STATE, action) {
  switch (action.type) {
    case VIDEO_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case VIDEO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: "",
      };

    case VIDEO_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    /////////////////////////////
    case REMOVE_VIDEO_SUCCESS: {
      const newList = state.list.filter((item) => {
        return item.Id !== action.id; // return all the items not matching the action.id
      });
      return {
        ...state,
        list: newList,
        error_update: "",
      };
    }

    case REMOVE_VIDEO_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    /////////////////////////////////
    case ADD_VIDEO_SUCCESS: {
      return {
        ...state,
        loadingFile: false,
        progress: 0,
        error: "",
        list: [action.item, ...state.list],
      };
    }
    case ADD_VIDEO_LOADING:
      return {
        ...state,
        loadingFile: true,
        progress: action.progress,
        success: "",
        error: "",
      };
    case ADD_VIDEO_FAILED:
      return {
        ...state,
        loadingFile: false,
        progress: 0,
        error: action.error,
        success: "",
      };

    default:
      return state;
  }
}
export default video;
