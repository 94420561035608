import React from "react";
//import ReactDOM from 'react-dom';
import Modal from "./Modal";
import { Translate } from "react-redux-i18n";

function ModalHome(props) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} title="">
      <div className="modal-body">
        <img className="modal-img" src={props.image} alt="modal-img" />
      </div>
      <div className="modal-footer">
        <div className="col-12 text-center">
          <button className="btn btn-primary" onClick={props.onClose}>
            <i className="fa fa-close"></i> <Translate value="ok" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalHome;
