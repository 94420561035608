import React, { Component } from "react";

//import './styles/Home.css';

import WallMenu from "./WallMenu";
import SummaryByCountry from "../components/SummaryByCountry";
//import api from '../api';

class NotificationLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <WallMenu />
        <SummaryByCountry />
      </React.Fragment>
    );
  }
}

export default NotificationLeft;
