import React from "react";
//import ReactDOM from 'react-dom';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

function StepRegister(props) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 text-center">
          <text
            className=""
            style={{
              fontSize: "18px",
              fontWeight: "bold ",
              color: "gray",
            }}
          >
            <Translate value="step_account" />
          </text>
        </div>
      </div>

      <div className="form-group">
        <input
          type="email"
          name="email"
          placeholder="Email"
          className={
            props.error.email ? "form-control is-invalid" : "form-control"
          }
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.email}
        />

        <input
          type="password"
          name="password"
          placeholder="Confirm Password"
          className={
            props.error.password ? "form-control is-invalid" : "form-control"
          }
          onChange={props.onChange}
          value={props.form.password}
        />
        <input
          type="password"
          name="password2"
          placeholder="Password"
          className={
            props.error.password2 ? "form-control is-invalid" : "form-control"
          }
          onChange={props.onChange}
          value={props.form.password2}
        />
      </div>
      <div className="form-group">
        <select
          className={
            props.error.country ? "form-control is-invalid" : "form-control"
          }
          onChange={props.onChange}
          name="country"
          value={props.form.country}
        >
          <option key={0}>{I18n.t("choose_country")}</option>
          {props.countryList.map((country) => {
            return (
              <option key={country.Id} value={country.Id}>
                {country.Name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group row">
        <div className="col-2">
          <input
            type="checkbox"
            className="form-check "
            name="accept_tos"
            value={props.accept_tos}
            onChange={props.onChangeAcceptTos}
          />
        </div>{" "}
        {"  "}
        <div className="col-10 text-left">
          <Link className="" to="/home/terms_condition" target="_blank">
            {"  "} {<Translate value="footer_tos" />}
          </Link>
          {"  "}
          <Translate value="and" />
          {"  "}
          <Link className="" to="/home/privacy_politics" target="_blank">
            {<Translate value="footer_privacy" />}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StepRegister;
