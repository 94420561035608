import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  AD_LIST_ATTEMPT,
  AD_LIST_SUCCESS,
  AD_LIST_FAILED,
  ////////////////
  AD_MORELIST_ATTEMPT,
  AD_MORELIST_SUCCESS,
  AD_MORELIST_FAILED

} from "../types/adListTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: AD_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: AD_LIST_FAILED,
    error: error
  };
}

///////////////////////////////////////////

export function loadingMore() {
  return {
    type: AD_MORELIST_ATTEMPT
  };
}

export function successMore(data) {
  return {
    type: AD_MORELIST_SUCCESS,
    data: data
  };
}

export function failedMore(error) {
  return {
    type: AD_MORELIST_FAILED,
    error: error
  };
}


export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "goder", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
export const loadListScroll = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  //console.log(data);
  await axios
    .get(url_api + "goder", {
      params: {
        index: data.page_index,
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const loadListSlider = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "goder/slider", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      //headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadAdHomeOut = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "goder", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
////////////////
export const loadListSale = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "sale", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScrollSale = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  //console.log(data);
  await axios
    .get(url_api + "sale", {
      params: {
        index: data.page_index,
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const loadListRent = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "rent", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
export const loadListScrollRent = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  //console.log(data);
  await axios
    .get(url_api + "rent", {
      params: {
        index: data.page_index,
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};


export const loadListExchange = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "exchange", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
export const loadListScrollExchange = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  //console.log(data);
  await axios
    .get(url_api + "exchange", {
      params: {
        index: data.page_index,
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const loadListDonation = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "donation", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
export const loadListScrollDonation = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  //console.log(data);
  await axios
    .get(url_api + "donation", {
      params: {
        index: data.page_index,
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};


