import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as actions_category from "../redux/actions/adCategoryActions";
import * as actions_publish from "../redux/actions/publishActions";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import moment from "moment";
/*
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
//import Heading from "@ckeditor/ckeditor5-heading/src/heading";

const editorConfiguration = {
  plugins: [Essentials, Bold, Italic, Paragraph],
  toolbar: ["heading", "|", "bold", "italic"],
};
npm install --save \
    @ckeditor/ckeditor5-react \
    @ckeditor/ckeditor5-editor-classic \
    @ckeditor/ckeditor5-essentials \
    @ckeditor/ckeditor5-basic-styles \
    @ckeditor/ckeditor5-heading \
    @ckeditor/ckeditor5-paragraph

//plugins: [ Essentials, Paragraph, Bold, Italic, Heading ],
//toolbar: [ 'heading', '|', 'bold', 'italic', '|', 'undo', 'redo', ]
*/
class AdCategoryItem extends React.Component {
  render() {
    return <option value={this.props.item.key}>{this.props.item.label}</option>;
  }
}

class AdForm extends React.Component {
  state = {
    form: {
      price: "1.0",
      description: "",
      store_id: "",
      name: "",
    },
    touched: {
      name: false,
      categories: false,
      condition: false,
      frequency: false,
      request: false,
      expiration: false
    },
    kindComerce: "",
  };
  componentDidMount() {
    this.setState({ kindComerce: this.props.match.params.kindCommerce_id });
    this.fetchAdCategoryList();
  }

  handleBlur = (field) => (evt) => {
    this.setState({
      touched: { ...this.state.touched, [field]: true },
    });
  };

  fetchAdCategoryList = async () => {
    try {
      await this.props
        .LoadCategories(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeDescription = (evt, editor) => {
    this.setState({
      form: {
        ...this.state.form,
        description: editor.getData(),
      },
    });
  };

  onClickNext = async () => {
    var KindCommerceId = "0";
    switch (this.state.kindComerce) {
      case "donation":
        KindCommerceId = "1";
        break;

      case "sale":
        KindCommerceId = "2";
        break;

      case "exchange":
        KindCommerceId = "3";
        break;

      case "rent":
        KindCommerceId = "4";
        break;

      case "auction":
        KindCommerceId = "5";
        break;

      default: break;
    }
    //console.log(KindCommerceId);

    if (
      (KindCommerceId === "2" || KindCommerceId === "3") &&
      (this.errors("condition") ||
        this.errors("name") ||
        this.errors("categories"))
    ) {
      this.setState({
        ...this.state,
        touched: {
          ...this.state.touched,
          name: true,
          categories: true,
          condition: true,
        },
      });

      return;
    }

    if (
      (KindCommerceId === "4" || KindCommerceId === "5") &&
      (this.errors("frequency") ||
        this.errors("name") ||
        this.errors("categories"))
    ) {
      this.setState({
        ...this.state,
        touched: {
          ...this.state.touched,
          name: true,
          categories: true,
          frequency: true,
          expiration: true
        },
      });
      return;
    }

    if (
      KindCommerceId === "1" &&
      (this.errors("request") ||
        this.errors("name") ||
        this.errors("categories"))
    ) {
      this.setState({
        ...this.state,
        touched: {
          ...this.state.touched,
          request: true,
          name: true,
          categories: true,
        },
      });
      return;
    }

    let params = new FormData();

    params.append("goder_id", "");
    params.append("name", this.state.form.name);
    params.append("quantity", this.state.form.quantity || "1");
    params.append("price", this.state.form.price || "1");
    params.append("description", this.state.form.description);
    params.append("category_id", this.state.form.categories);
    params.append("kindcommerce_id", KindCommerceId);
    params.append("condition_id", this.state.form.condition);
    params.append("frequency_id", this.state.form.frequency || "");
    params.append("numRequest", this.state.form.request || "0");
    params.append("store_id", this.state.form.store_id);
    params.append("expiration", this.state.form.expiration);

    params.append("id", this.props.user_id);
    params.append("lang", I18n.t("locale"));
    try {
      await this.props.AdNew(this.props.token, params).then(() => {
        if (this.props.error_update) {
          alertify.warning(I18n.t(this.props.error_update));
        } else this.props.history.push("/goder_new_galery");
      });
    } catch (error) {
      console.log(error);
    }
  };

  errors = (field) => {
    if (
      this.state.form[field] === undefined ||
      this.state.form[field].length === 0
    ) {
      return true;
    }
    return false;
  };

  shouldMarkError = (field) => {
    const hasError = this.errors(field);
    const shouldShow = this.state.touched[field];
    return hasError ? shouldShow : false;
  };

  render() {
    // console.log(moment().add(3, 'M').format('YYYY-MM-DD'));
    return (
      <React.Fragment>
        <div className="card-body">
          <div className="col-12">
            <div className="form-group">
              <span className="required-field">*</span>
              <label htmlFor="name" className="control-label">
                <Translate value="ad_name" />
              </label>
              <input
                type="text"
                id="name"
                name="name"
                data-type="product_detail"
                maxLength="100"
                className={
                  this.shouldMarkError("name")
                    ? "form-control is-invalid"
                    : "form-control"
                }
                autoComplete={"off"}
                onBlur={this.handleBlur("name")}
                onChange={this.handleChange}
                value={this.state.form.name}
                required
              />
            </div>
          </div>
          <div className="col-12">
            <div className="row ">
              <div className="col-lg-4">
                <div className="form-group">
                  <span className="required-field">*</span>
                  <label htmlFor="quantity" className="control-label">
                    <Translate value="ad_quantity" />
                  </label>
                  <div className="input-group">
                    <span className="input-group-addon">#</span>
                    <input
                      type="number"
                      min="0"
                      id="quantity"
                      data-type="product"
                      name="quantity"
                      className={"form-control"}
                      value="1"
                      onChange={this.handleChange}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group ">
                  <span className="required-field">*</span>
                  <label htmlFor="price" className="control-label">
                    <Translate value="ad_price" />
                  </label>
                  <div className="input-group">
                    <span className="input-group-addon">$</span>
                    <input
                      type="number"
                      step="0.01"
                      required=""
                      min="0"
                      id="price"
                      data-type="product"
                      name="price"
                      className={
                        this.shouldMarkError("price")
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onBlur={this.handleBlur("price")}
                      value={this.state.form.price}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              {this.state.kindComerce === "auction" && (
                <div className="col-lg-4">
                  <div className="form-group ">
                    <div className="form-group">
                      <span className="required-field">*</span>
                      <label htmlFor="condition">
                        <Translate value="expire" />
                        <small>{" 3 month max"}</small>
                      </label>
                      <input
                        type="date"
                        required={true}
                        id="expiration"
                        min={moment().format('YYYY-MM-DD')}
                        max={moment().add(3, 'M').format('YYYY-MM-DD')}
                        data-date-format={'YYYY-MM-DD'}
                        name="expiration"
                        className={
                          this.shouldMarkError("expiration")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onBlur={this.handleBlur("expiration")}
                        value={this.state.form.expiration}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.kindComerce === "rent" ? (
                <div className="col-lg-4">
                  <div className="form-group ">
                    <div className="form-group">
                      <span className="required-field">*</span>
                      <label htmlFor="condition">
                        <Translate value="ad_frecuency" />
                      </label>
                      <select
                        className={
                          this.shouldMarkError("frequency")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onBlur={this.handleBlur("frequency")}
                        data-type="product"
                        name="frequency"
                        value={this.state.form.frequency}
                        onChange={this.handleChange}
                        defaultValue={""}
                      >
                        <option value="">{I18n.t("ad_select")}</option>
                        <option value="H">{I18n.t("ad_hour")}</option>
                        <option value="D">{I18n.t("ad_day")}</option>
                        <option value="M">{I18n.t("ad_month")}</option>
                        <option value="Y">{I18n.t("ad_year")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-4">
                  <div className="form-group ">
                    <div className="form-group">
                      <span className="required-field">*</span>
                      <label htmlFor="condition">
                        <Translate value="ad_condition" />
                      </label>
                      <select
                        className={
                          this.shouldMarkError("condition")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        onBlur={this.handleBlur("condition")}
                        data-type="product"
                        name="condition"
                        value={this.state.form.condition}
                        onChange={this.handleChange}
                        defaultValue={""}
                      >
                        {" "}
                        <option value="">{I18n.t("ad_select")}</option>
                        <option value="NEW">{I18n.t("ad_new")}</option>
                        <option value="USED">{I18n.t("ad_used")}</option>
                        <option value="SERVICE">{I18n.t("ad_service")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group row ">
              <div className="col-lg-6">
                <div className="form-group ">
                  <span className="required-field">*</span>
                  <label htmlFor="category">
                    <Translate value="ad_category" />
                  </label>
                  <select
                    className={
                      this.shouldMarkError("categories")
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onBlur={this.handleBlur("categories")}
                    required=""
                    data-type="categories"
                    rows="4"
                    name="categories"
                    value={this.state.form.categories}
                    onChange={this.handleChange}
                    defaultValue={0}
                  >
                    <option value={0}>{I18n.t("ad_category_select")}</option>
                    {this.props.adCategoryList.map((item) => {
                      return (
                        <AdCategoryItem
                          item={item}
                          key={item.key}
                          value={item.key}
                        />
                      );
                    })}
                  </select>
                </div>
              </div>
              {this.state.kindComerce === "donation" ? (
                <div className="col-lg-6">
                  <div className="form-group">
                    <span className="required-field">*</span>
                    <label htmlFor="request" className="control-label">
                      <Translate value="ad_request" />
                    </label>
                    <div className="input-group">
                      <span className="input-group-addon">#</span>
                      <input
                        type="number"
                        min="0"
                        id="request"
                        data-type="product"
                        name="request"
                        className={
                          this.shouldMarkError("request")
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={this.state.form.request}
                        onChange={this.handleChange}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-lg-6">
                  <div className="form-group ">
                    <label htmlFor="store_id">
                      <Translate value="ad_marketstore" />
                    </label>
                    <a
                      data-container="body"
                      title="Marketstore"
                      data-content="???"
                    >
                      <span className="glyphicon glyphicon-info-sign"></span>
                    </a>
                    <div className="input-group">
                      <div className="input-group-btn"></div>
                      <select
                        className="form-control "
                        data-type="product"
                        name="store_id"
                        value={this.props.hasStore ? this.props.store_id : "0"}
                        onChange={this.handleChange}
                        defaultValue={"0"}
                      >
                        {this.props.hasStore ? (
                          <option value={this.props.store_id}>
                            {this.props.name}
                          </option>
                        ) : (
                          <option value="0">
                            {I18n.t("ad_not_marketstore")}
                          </option>
                        )}
                      </select>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group ">
              <label htmlFor="description" className="control-label">
                <Translate value="ad_description" />
              </label>
              <CKEditor
                data={this.state.form.description}
                editor={ClassicEditor}
                //config={editorConfiguration}
                onChange={(e, editor) =>
                  this.handleChangeDescription(e, editor)
                }
                name="description"
              />
            </div>
          </div>
          <hr className="dividerGray" />
          <div className="form-group">
            <div className="col-lg-12 ">
              <div className="text-center">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    this.onClickNext();
                  }}
                >
                  <Translate value="ad_next" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    hasStore: reducer.session.auth.hasStore,
    store_id: reducer.session.auth.store_id,
    name: reducer.session.auth.name,

    adCategoryList: reducer.adCategory.list,
    error: reducer.adCategory.error,
    loading: reducer.adCategory.loading,

    error_update: reducer.publish.error_update,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCategories: (user_id, token) =>
    dispatch(actions_category.loadList({ user_id, token })),
  AdNew: (token, params) => dispatch(actions_publish.newItem({ token, params })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdForm));
/**
 *
 *
 * render() {
  let className = 'menu';
  if (this.props.isActive) {
    className += ' menu-active';
  }
  return <span className={className}>Menu</span>

   <textarea
                name="description"
                className="form-control ckeditor "
                cols="10"
                rows="5"
                value={this.state.form.description}
                onChange={this.handleChange}
              ></textarea>
} */
