import {
    TOS_ATTEMPT,
    TOS_SUCCESS,
    TOS_FAILED,
} from "../types/tosTypes";

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: ""
};

function tos(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TOS_ATTEMPT:
            return {
                ...state,
                loading: true
            };
        //  break;
        case TOS_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: ""
            };
        //break;
        case TOS_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            };
        default:
            return state;
    }
}
export default tos;
