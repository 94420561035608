export const AD_EXCHANGE_ATTEMPT = "AD_EXCHANGE_ATTEMPT";
export const AD_EXCHANGE_SUCCESS = "AD_EXCHANGE_SUCCESS";
export const AD_EXCHANGE_FAILED = "AD_EXCHANGE_FAILED";
////////////////////////////
export const AD_EXCHANGE_DELETE_SUCCESS = "AD_EXCHANGE_DELETE_SUCCESS";
export const AD_EXCHANGE_DELETE_FAILED = "AD_EXCHANGE_DELETE_FAILED";
////////////////////////////
export const AD_EXCHANGE_NEW_SUCCESS = "AD_EXCHANGE_NEW_SUCCESS";
export const AD_EXCHANGE_NEW_FAILED = "AD_EXCHANGE_NEW_FAILED";


export const AD_EXCHANGE_MORE_ATTEMPT = "AD_EXCHANGE_MORE_ATTEMPT";
export const AD_EXCHANGE_MORE_SUCCESS = "AD_EXCHANGE_MORE_SUCCESS";
export const AD_EXCHANGE_MORE_FAILED = "AD_EXCHANGE_MORE_FAILED";


export const LOGOUT = "LOGOUT";