import {

  TRANSACTIONF_REQUEST_LIST_ATTEMPT,
  TRANSACTIONF_REQUEST_LIST_SUCCESS,
  TRANSACTIONF_REQUEST_LIST_FAILED,
  ///////////////////
  TRANSACTIONF_REQUEST_MORELIST_ATTEMPT,
  TRANSACTIONF_REQUEST_MORELIST_SUCCESS,
  TRANSACTIONF_REQUEST_MORELIST_FAILED,

} from "../types/transactionRequestFinishTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  error_new: "",
  page_size: 0,
  page_count: 0,
  loadingMore: false
};

function transactionRequest(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRANSACTIONF_REQUEST_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case TRANSACTIONF_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: ""
      };

    case TRANSACTIONF_REQUEST_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    /////////////////////////////
    case TRANSACTIONF_REQUEST_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true
      };

    case TRANSACTIONF_REQUEST_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case TRANSACTIONF_REQUEST_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    /////////////////////////////

    default:
      return state;
  }
}
export default transactionRequest;
