export const AD_AUCTION_ATTEMPT = "AD_AUCTION_ATTEMPT";
export const AD_AUCTION_SUCCESS = "AD_AUCTION_SUCCESS";
export const AD_AUCTION_FAILED = "AD_AUCTION_FAILED";
////////////////////////////////
export const AD_AUCTION_MORE_ATTEMPT = "AD_AUCTION_MORE_ATTEMPT";
export const AD_AUCTION_MORE_SUCCESS = "AD_AUCTION_MORE_SUCCESS";
export const AD_AUCTION_MORE_FAILED = "AD_AUCTION_MORE_FAILED";
////////////////////////////////
export const AD_AUCTION_DELETE_SUCCESS = "AD_AUCTION_DELETE_SUCCESS";
export const AD_AUCTION_DELETE_FAILED = "AD_AUCTION_DELETE_FAILED";

export const AD_AUCTION_INACTIVE_DELETE_SUCCESS = "AD_AUCTION_INACTIVE_DELETE_SUCCESS";
export const AD_AUCTION_INACTIVE_DELETE_FAILED = "AD_AUCTION_INACTIVE_DELETE_FAILED";

///////////////////////////
export const AD_AUCTION_NEW_SUCCESS = "AD_AUCTION_NEW_SUCCESS";
export const AD_AUCTION_NEW_FAILED = "AD_AUCTION_NEW_FAILED";


export const AD_AUCTION_INACTIVE_ATTEMPT = "AD_AUCTION_INACTIVE_ATTEMPT";
export const AD_AUCTION_INACTIVE_SUCCESS = "AD_AUCTION_INACTIVE_SUCCESS";
export const AD_AUCTION_INACTIVE_FAILED = "AD_AUCTION_INACTIVE_FAILED";
////////////////////////////////
export const AD_AUCTION_INACTIVE_MORE_ATTEMPT = "AD_AUCTION_INACTIVE_MORE_ATTEMPT";
export const AD_AUCTION_INACTIVE_MORE_SUCCESS = "AD_AUCTION_INACTIVE_MORE_SUCCESS";
export const AD_AUCTION_INACTIVE_MORE_FAILED = "AD_AUCTION_INACTIVE_MORE_FAILED";
////////////////////////////////
export const AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_SUCCESS = "AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_SUCCESS";
export const AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_FAILED = "AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_FAILED";
////////////////////////////////
export const AD_AUCTION_CHANGE_POSTSTATUS_SUCCESS = "AD_AUCTION_CHANGE_POSTSTATUS_SUCCESS";
export const AD_AUCTION_CHANGE_POSTSTATUS_FAILED = "AD_AUCTION_CHANGE_POSTSTATUS_FAILED";




