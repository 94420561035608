import {
  MARKETSTORE_CATEGORIES_ATTEMPT,
  MARKETSTORE_CATEGORIES_SUCCESS,
  MARKETSTORE_CATEGORIES_FAILED,
} from "../types/marketstoreCategoriesTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
};

function marketstoreCategories(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKETSTORE_CATEGORIES_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case MARKETSTORE_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: "",
      };
    case MARKETSTORE_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    //////////////////////////

    default:
      return state;
  }
}
export default marketstoreCategories;
