import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as actions from "../redux/actions/contactsActions";
import { I18n } from "react-redux-i18n";//Translate,
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
////import * as actions from "../redux/actions/sessionActions";
//import StoreAdList from "../components/StoreAdList";

class TempRedirectChat extends React.Component {


    async componentDidMount() {
        await this.props
            .ChatUser(this.props.match.params.product_id, this.props.user_id, this.props.token)
            .then(() => {
                if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                }
                else
                    this.props.history.push(
                        `/chat/${this.props.contact.ConversationId}`
                    );
            });


    }

    render() {
        return <></>;
    }
}

function mapStateToProps(reducer) {
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,
        contact: reducer.contact.item,
    };
}

const mapDispatchToProps = (dispatch) => ({
    ChatUser: (id, user_id, token) =>
        dispatch(actions.loadItem({ id, user_id, token })),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TempRedirectChat));