import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/HowToWork.css";
import intercambios from "../assets/intercambios.svg";
import donaciones from "../assets/donaciones.svg";
import ventarenta from "../assets/venta-renta.svg";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";

class HowToWork extends React.Component {
  render() {
    return (
      <div className="container-howtowork">
        <div className="container-howtowork-title">
          <h1 className="">
            {" "}
            <Translate value="how_work" />{" "}
          </h1>
        </div>
        <div className="container-howtowork-body">
          <div className="howtowork-item">
            <div className="card goder-shadow">
              <div className="card-header">
                <img
                  src={intercambios}
                  alt="Intercambio tus productos"
                  height="50"
                />
                <h3>
                  <Translate value="exchanges" />
                </h3>
              </div>
              <div className="card-body ">
                <p>
                  <strong>
                    <Translate value="free" />
                  </strong>
                </p>

                <ul className="list-group ">
                  <li className="list-group-item">
                    <Translate value="exchange1" />
                  </li>
                  <li className="list-group-item">
                    <Translate value="exchange2" />
                  </li>
                  <li className="list-group-item">
                    <Translate value="exchange3" />
                  </li>
                </ul>
              </div>
              <div className="card-footer">
                <Link to="/login" className="btn btnBig btn-primary mt10s">
                  <Translate value="exchange4" />
                </Link>
              </div>
            </div>
          </div>

          <div className="howtowork-item">
            <div className="card goder-shadow">
              <div className="card-header">
                <img src={donaciones} alt="donation" height="50" />
                <h3>
                  {" "}
                  <Translate value="donations" />{" "}
                </h3>
              </div>
              <div className="card-body">
                <p>
                  <strong>
                    <Translate value="free" />
                  </strong>
                </p>

                <ul className="list-group ">
                  <li className="list-group-item">
                    <Translate value="donation1" />{" "}
                  </li>
                  <li className="list-group-item">
                    <Translate value="donation2" />
                  </li>
                  <li className="list-group-item">
                    <Translate value="exchange3" />
                  </li>
                </ul>
              </div>
              <div className="card-footer">
                <Link to="/login" className="btn btnBig btn-primary mt10">
                  <Translate value="donation3" />
                </Link>
              </div>
            </div>
          </div>

          <div className="howtowork-item">
            <div className="card goder-shadow">
              <div className="card-header">
                <img src={ventarenta} alt="sale" height="50" />
                <h3>
                  {" "}
                  <Translate value="sales" />{" "}
                </h3>
              </div>
              <div className="card-body">
                <p>
                  <strong>
                    {" "}
                    <Translate value="free" />
                  </strong>
                </p>

                <ul className="list-group ">
                  <li className="list-group-item">
                    <Translate value="sale1" />
                  </li>
                  <li className="list-group-item">
                    <Translate value="exchange2" />
                  </li>
                  <li className="list-group-item">
                    <Translate value="exchange3" />
                  </li>
                </ul>
              </div>
              <div className="card-footer">
                <Link
                  to="/login"
                  className="btn btnBig btn-primary mt10 disabledbutton"
                  disabled=""
                >
                  <Translate value="sale2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HowToWork;
