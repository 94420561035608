import React from "react";
import TransactionList from "../components/TransactionList";
//import api from '../api';
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import Tabs from "../components/Tabs";
import { connect } from "react-redux";
import * as actions_pending from "../redux/actions/transactionPendingActions";
import * as actions_finish from "../redux/actions/transactionFinishActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class TransactionSales extends React.Component {
  state = {
    // data_pending: [],
    // data_finish: []
  };
  /*constructor(props) {
    super(props);
  }
*/
  componentDidMount() {
    this.fetchTransactionList();
  }

  fetchTransactionList = async () => {
    try {
      this.props
        .LoadTransactionPending(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
      this.props
        .LoadTransactionFinish(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.errorf) {
            alert(this.props.errorf);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadMoreTransaction(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  onClickLoadMoref = async () => {
    try {
      await this.props
        .LoadMoreTransactionf(this.props.pageCountf, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            {" "}
            <Translate value="transaction_ad" />
          </h3>
          <Tabs>
            <div label="Pendientes" className="col-12">
              {this.props.loading ? (
                <PageLoading />
              ) : (
                <TransactionList transactionList={this.props.transactionList}
                  pageCount={this.props.pageCount}
                  pageSize={this.props.pageSize}
                  loadingMore={this.props.loadingMore}
                  onClickLoadMore={() => {
                    this.onClickLoadMore();
                  }}
                />
              )}
            </div>
            <div label="Finalizadas" className="col-12">
              {this.props.loadingf ? (
                <PageLoading />
              ) : (
                <TransactionList
                  transactionList={this.props.transactionfList}
                  pageCount={this.props.pageCountf}
                  pageSize={this.props.pageSizef}
                  loadingMore={this.props.loadingMoref}
                  onClickLoadMore={() => {
                    this.onClickLoadMoref();
                  }}
                />
              )}
            </div>
          </Tabs>
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    transactionList: reducer.transactionPending.list,
    error: reducer.transactionPending.error,
    loading: reducer.transactionPending.loading,

    pageCount: reducer.transactionPending.page_count,
    pageSize: reducer.transactionPending.page_size,
    loadingMore: reducer.transactionPending.loadingMore,

    transactionfList: reducer.transactionFinish.list,
    errorf: reducer.transactionFinish.error,
    loadingf: reducer.transactionFinish.loading,

    pageCountf: reducer.transactionFinish.page_count,
    pageSizef: reducer.transactionFinish.page_size,
    loadingMoref: reducer.transactionFinish.loadingMore,
  };
}
const mapDispatchToProps = dispatch => ({
  LoadTransactionPending: (user_id, token) =>
    dispatch(actions_pending.loadList({ user_id, token })),
  LoadTransactionFinish: (user_id, token) =>
    dispatch(actions_finish.loadList({ user_id, token })),
  LoadMoreTransaction: (page_count, user_id, token) =>
    dispatch(actions_pending.loadListScroll({ page_count, user_id, token })),
  LoadMoreTransactionf: (page_count, user_id, token) =>
    dispatch(actions_finish.loadListScroll({ page_count, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionSales);
