import React, { Component } from "react";
//import { Link } from 'react-router-dom';

import "./styles/MarketStore.css";

import StoreLeft from "../components/StoreLeft";
import MarketStoreSlider from "../components/MarketStoreSlider";
import StoreRight from "../components/StoreRight";
import StoreCenter from "../components/StoreCenter";
import Loader from "../components/Loader";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions_store from "../redux/actions/marketstoreActions";
//import * as actions_ad_store from "../redux/actions/marketstoreAdListActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class MarketStore extends Component {

  state = { marketstoreId: "" };

  async componentDidMount() {
    // await this.setState({ marketstoreId: this.props.match.params.store_id });
    this.fetchMarketStore(this.props.match.params.store_id);
  }

  fetchMarketStore = async (marketstoreId) => {
    try {
      await this.props
        .LoadMarketStore(this.props.token, {
          id: this.props.user_id,
          store_id: marketstoreId,
          lang: I18n.t("locale"),
        }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(this.error);
    }
  };

  componentWillUnmount() {
    this.props.ClearMarketstore();
  }

  render() {
    if (this.props.loading) return <Loader />;
    else
      return (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="store-slider">
                <MarketStoreSlider />
              </div>

              <div className="col-3 d-none d-lg-block">
                <div className="store-left">
                  <StoreLeft />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="store-center">
                  <StoreCenter />
                </div>
              </div>
              <div className="col-3 d-none d-lg-block">
                <div className="store-right">
                  <StoreRight />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
  }
}

function mapStateToProps(reducers) {
  //console.log(reducers);
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    error: reducers.marketstore.error,
    loading: reducers.marketstore.loading,
    marketstore: reducers.marketstore.item,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadMarketStore: (token, params) =>
    dispatch(actions_store.loadItem({ token, params })),
  ClearMarketstore: () => dispatch(actions_store.clearMarketStore({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStore);
