import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player/youtube";

import "./styles/AdGalery.css";
// import Zoom from 'react-medium-image-zoom'
// import 'react-medium-image-zoom/dist/styles.css'

import MiniLoader from "../components/MiniLoader";
import ModalZoom from "./ModalZoom";


class AdGaleryItem extends React.Component {
  render() {
    return this.props.item.Type === "video" ? (
      <Link className="thumbnails" to="#" onClick={this.props.onClick}>
        <img
          className="ad-image-thumbnails  img-responsive"
          src={this.props.item.YoutubeSrc}
          alt="goder-img"
        />
      </Link>
    ) : (
      <Link className="thumbnails" to="#" onClick={this.props.onClick}>
        <img
          className="goder-image-thumbnails  img-responsive"
          src={this.props.item.Url}
          alt="goder-img"
        />
      </Link>
    );
  }
}

class AdGalery extends React.Component {
  state = {
    Image: "",
    loading: false,
    type: "image",
    open: false
  };


  onClick = (item) => {
    if (item.Type === "image")
      this.setState({
        Image: item.Url,//Url_750_500
        loading: true,
        type: item.Type,
      });
    else
      this.setState({
        Image: item.Url,
        //         Image: "https://youtu.be/" + item.YoutubeId,
        //        Image: "https://www.youtube.com/watch?v=" + item.YoutubeId,
        //           Image: "https://www.youtube.com/embed/" + item.YoutubeId,
        //loading: true,
        type: item.Type,
      });
  };
  //<iframe width="640" height="360" src="https://www.youtube.com/embed/hqaodo5biso" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

  componentDidMount() {
    if (this.props.ad.Media && this.props.ad.Media.length > 1)
      this.setState({ Image: this.props.ad.Media[0].Url });//_750_500
    else this.setState({ Image: this.props.ad.Photo });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps && this.props.ad.Media.length > 0) {
      // console.log(this.props.ad);
      this.setState({ Image: this.props.ad.Media[0].Url });//_750_500
    }
  }
  //componentWillUpdate(){}
  //componentDidUpdate(){}
  //componentWillMount(){}

  componentWillReceiveProps(nextProps) { }
  // if (this.props.ad.Media.length > 0)
  //this.setState({ Image: this.props.ad.Media[0].Url_750_500 });
  //else
  //this.setState({ Image: this.props.ad.Photo });

  handleImageLoaded() {
    this.setState({ loading: false });
  }

  handleImageErrored() {
    //this.setState({ imageStatus: "failed to load" });
  }

  onClickZoom() {
    this.setState({ open: true });
  }

  onCloseZoom() {
    this.setState({ open: false });
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-galery">
          <div className="goder-image-prev">
            {
              this.props.ad.Media !== undefined && this.props.ad.Media.map((item) => {
                return (
                  <AdGaleryItem
                    item={item}
                    key={item.Id}
                    onClick={() => {
                      this.onClick(item);
                    }}
                  />
                );
              })
            }
          </div>
          <div className="goder-image-show">
            {
              this.state.type === "image" ? (
                <img
                  className="goder-image"
                  src={this.state.Image}
                  alt="img"
                  onLoad={this.handleImageLoaded.bind(this)}
                  onError={this.handleImageErrored.bind(this)}
                  onClick={this.onClickZoom.bind(this)}

                />
              ) : (
                <div>
                  <ReactPlayer
                    url={this.state.Image}
                    height="500px"
                    width="750px"
                    controls={true}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 0 },
                      },
                    }}
                  />
                </div>
              )}

            {
              this.state.loading && <MiniLoader />
            }
            <ModalZoom isOpen={this.state.open} src={this.state.Image} onClose={this.onCloseZoom.bind(this)} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer.ad.item);
  return {
    ad: reducer.ad.item,
  };
}

export default connect(mapStateToProps, null)(AdGalery);
