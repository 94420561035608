export const AD_LIST_ATTEMPT = 'AD_LIST_ATTEMPT';
export const AD_LIST_SUCCESS = 'AD_LIST_SUCCESS';
export const AD_LIST_FAILED = 'AD_LIST_FAILED';
/////////
export const AD_MORELIST_ATTEMPT = 'AD_MORELIST_ATTEMPT';
export const AD_MORELIST_SUCCESS = 'AD_MORELIST_SUCCESS';
export const AD_MORELIST_FAILED = 'AD_MORELIST_FAILED';

export const LOGOUT = 'LOGOUT';


