import React from 'react';

import NavbarOut from './NavbarOut';

//import Footer from './Footer';

function LayoutOut(props) {


  return (
    <React.Fragment>
      <NavbarOut />
      {props.children}
    </React.Fragment>
  );
}

export default LayoutOut;
