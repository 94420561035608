import {
    PRIPOL_ATTEMPT,
    PRIPOL_SUCCESS,
    PRIPOL_FAILED,
} from "../types/pripolTypes";

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: ""
};

function pripol(state = INITIAL_STATE, action) {
    switch (action.type) {
        case PRIPOL_ATTEMPT:
            return {
                ...state,
                loading: true
            };
        //  break;
        case PRIPOL_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: ""
            };
        //break;
        case PRIPOL_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            };
        default:
            return state;
    }
}
export default pripol;
