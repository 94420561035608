import axios from "axios";

import {
  FOLLOWER_LIST_ATTEMPT,
  FOLLOWER_LIST_SUCCESS,
  FOLLOWER_LIST_FAILED,
  ////////////////////////////
  FOLLOWER_UPDATE_SUCCESS,
  FOLLOWER_UPDATE_FAILED,
} from "../types/marketstoreFollowersTypes";
import {
  MARKETSTORE_ITEM_SUCCESS
} from '../types/marketstoreTypes';

import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: FOLLOWER_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: FOLLOWER_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: FOLLOWER_LIST_FAILED,
    error: error,
  };
}
////////////////////////////////////
/*export function successUpdate(data) {
  return {
    type: FOLLOWER_UPDATE_SUCCESS,
    data: data,
  };
}
*/

export function successUpdate(item) {
  return {
    type: MARKETSTORE_ITEM_SUCCESS,
    item: item
  }
};
export function failedUpdate(error) {
  return {
    type: FOLLOWER_UPDATE_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "marketstore/follow", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "marketstore/follow", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};
