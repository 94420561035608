import axios from "axios";

import {
  SUMMARY_COUNTRY_ATTEMPT,
  SUMMARY_COUNTRY_SUCCESS,
  SUMMARY_COUNTRY_FAILED,
} from "../types/summaryByCountryTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: SUMMARY_COUNTRY_ATTEMPT,
  };
}

export function success(item) {
  return {
    type: SUMMARY_COUNTRY_SUCCESS,
    item: item,
  };
}

export function failed(error) {
  return {
    type: SUMMARY_COUNTRY_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "summary/country", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
      //timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
