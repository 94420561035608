import {
  FOLLOWER_LIST_ATTEMPT,
  FOLLOWER_LIST_SUCCESS,
  FOLLOWER_LIST_FAILED,
  ////////////////////////////
  FOLLOWER_UPDATE_SUCCESS,
  FOLLOWER_UPDATE_FAILED
} from "../types/marketstoreFollowersTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  error_update: ""
};

function marketstoreFollowers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FOLLOWER_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };
    case FOLLOWER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: ""
      };
    case FOLLOWER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    //////////////////////////
    case FOLLOWER_UPDATE_SUCCESS: {
      //const newState = state.list.map(item => {
      //  if (item.StoreId === action.Id) return item;
      //});
      return {
        ...state,
        //list: newState,
        list: action.data.list,
        error_update: ""
      };
    }

    case FOLLOWER_UPDATE_FAILED:
      return {
        ...state,
        error_update: action.error
      };
    default:
      return state;
  }
}
export default marketstoreFollowers;
