import {
  SUMMARY_COUNTRY_ATTEMPT,
  SUMMARY_COUNTRY_SUCCESS,
  SUMMARY_COUNTRY_FAILED,
} from "../types/summaryByCountryTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: "",
};

function summaryByCountry(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUMMARY_COUNTRY_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case SUMMARY_COUNTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: "",
      };

    case SUMMARY_COUNTRY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {},
      };

    default:
      return state;
  }
}
export default summaryByCountry;
