import React from "react";
import "./styles/OnePay.css";
//import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/customerActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import PayPalSuscription from "../components/PaypalSuscription";

class SuscriptionPay extends React.Component {
  componentDidMount() {
    this.fetchCustomer();
  }

  fetchCustomer = async () => {
    try {
      await this.props
        .LoadCustomer(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-onepay">
            <div className="col-4">
              <section className="section-information-customer">
                <h4>
                  <span className="text-muted">
                    {" "}
                    <Translate value="billing" />
                  </span>
                </h4>
                <hr />
                <div className="">
                  <div className="col-12">
                    <div className="text-left">
                      <b>
                        <Translate value="billing_customer_data" />
                      </b>{" "}
                      <a href="#">
                        <small>
                          <b>
                            <Translate value="profile_edit" />
                          </b>
                        </small>
                      </a>
                    </div>
                    <div className="card ">
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_name" />:
                        </span>
                      </small>
                      <label>
                        {" "}
                        {this.props.customer.Name} {this.props.customer.LastN}
                      </label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_email" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Email}</label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_phone" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Phone}</label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_organization" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Organization}</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-left">
                      <b>
                        <Translate value="billing_customer_address" />
                      </b>{" "}
                      <a href="#">
                        <small>
                          <b>
                            {" "}
                            <Translate value="profile_edit" />
                          </b>
                        </small>
                      </a>
                    </div>
                    <div className="card">
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_address1" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressAddress}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_city" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressCity}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_state" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressState}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_zipcode" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressZipcode}</label>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-4  ">
              <h5 className="d-flex justify-content-between align-items-center">
                <span className="text-muted"> <Translate value="you_cart" /></span>
                <span className="badge badge-secondary badge-pill">1</span>
              </h5>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">
                      {" "}
                      <Translate value="one_pay" />
                    </h6>
                    <small className="text-muted">
                      <Translate value="one_pay2" />
                      <br />
                      <Translate value="one_pay4" />
                      <br /> <Translate value="one_pay4" /> <br />
                      <Translate value="one_pay5" />
                    </small>
                  </div>
                  <span className="text-muted">$15</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (USD)</span>
                  <strong>$15</strong>
                </li>
              </ul>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Codigo Promocional"
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-secondary">
                    <Translate value="password_forget_send" />
                  </button>
                </div>
              </div>
              <hr className="" />
              <PayPalSuscription />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    customer: reducer.customer.customerData,
    error: reducer.customer.error,
    loading: reducer.customer.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCustomer: (user_id, token) =>
    dispatch(actions.loadItem({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuscriptionPay);
