import {
  SESSION_ATTEMPT,
  SESSION_SUCCESSFULL,
  SESSION_FAILED,
  //////////////
  SESSION_FAILED_MESSAGE,
  SESSION_SUCCESS_MESSAGE,
  SESSION_FAILED_UPDATE,
  ////////////
  LOGOUT,
  //////////
  POPUP,
} from "../types/sessionType";
import {
  COUNTRY_UPDATE_SUCCESS,
  COUNTRY_UPDATE_FAILED,
} from '../types/countryTypes';

const INITIAL_STATE = {
  auth: {},
  error: "",
  success: "",
  loading: false,
  /////////////////////
  error_update: ""
};
function session(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION_ATTEMPT:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case SESSION_SUCCESSFULL:
      return {
        ...state,
        auth: action.item,
        loading: false,
        error: "",
      };
    case SESSION_FAILED:
      return {
        ...state,
        error: action.error,
        auth: {},
        loading: false,
      };
    ///////////////////
    case SESSION_SUCCESS_MESSAGE:
      return {
        ...state,
        success: action.success,
        error: "",
        loading: false,
      };
    case SESSION_FAILED_MESSAGE:
      return {
        ...state,
        error: action.error,
        succes: "",
        loading: false,
      };
    case SESSION_FAILED_UPDATE:
      return {
        ...state,
        error_update: action.error,
        succes: "",
        loading: false,
      }
    ////////////////////////

    case LOGOUT:
      return INITIAL_STATE;
    //////////////////////
    case COUNTRY_UPDATE_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          hasCountry: true,
        },
      };
    case COUNTRY_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
      };
    /////////////////////////
    case POPUP: {
      var tmp = state.auth;
      tmp.popup = false;
      return {
        ...state,
        auth: tmp,
      };
    }

    default:
      return state;
  }
}
export default session;
