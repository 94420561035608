import React from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/wallActions";
import {
  //FaPaperPlane,
  FaEllipsisH,
  // FaComments,
  //FaShareAlt,
  //FaComment,
} from "react-icons/fa";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";

class CommentBtnRight extends React.Component {
  state = {
    band: false,
    menu: null,
  };

  onClickReport = async (item) => {

    await this.props
      .ReportComment(item.Id, this.props.user_id, this.props.token)
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
        else
          alertify.success(I18n.t('success'));
      });
  };

  onClickRemove = async (item) => {
    //console.log("remove");
    await this.props
      .RemoveComment(item.Id, this.props.user_id, this.props.token)
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickMenu = () => {
    if (!this.state.band) {
      this.setState({
        menu: (
          <React.Fragment>
            <div className="" key={this.props.item.Id}>
              <ul className="menu-comments" style={{ left: "-150px" }}>
                {this.props.item.UserId.toString() ===
                  this.props.user_id ? (
                  <li
                    key={this.props.item.Id}
                    onClick={() => { this.onClickRemove(this.props.item) }}
                    onClickCapture={(e) => {
                      this.onClickHideMenu(e);
                    }}
                  >
                    <small className="mr-3">
                      <Translate value="comment_remove" />
                    </small>
                  </li>
                ) : (
                  <li
                    key={this.props.item.Id}
                    onClick={() => { this.onClickReport(this.props.item) }}
                    onClickCapture={(e) => {
                      this.onClickHideMenu(e);
                    }}
                  >
                    <small className="mr-3">
                      <Translate value="comment_report" />
                    </small>
                  </li>
                )}
              </ul>
            </div>
          </React.Fragment>
        ),
      });
    } else
      this.setState({
        menu: null,
      });

    this.setState((state) => {
      return { band: !state.band };
    });
  };

  onClickHideMenu = (e) => {
    this.setState((state) => {
      return { band: false, menu: null };
    });
  };
  render() {
    return (
      <div className="dropdown float-right">
        <button
          className="btn btn-flat btn-flat-icon"
          onClick={() => {
            this.onClickMenu();
          }}
          onBlur={(e) => {
            // this.onClickMenu();
            //this.onClickHideMenu(e);
          }}
        >
          <FaEllipsisH />
        </button>
        {this.state.menu}
      </div>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    user: reducer.session.auth,
    lang: reducer.i18n.locale,
  };
}

const mapDispatchToProps = (dispatch) => ({
  ReportComment: (id, user_id, token) =>
    dispatch(actions.reportComment({ id, user_id, token })),
  RemoveComment: (id, user_id, token) =>
    dispatch(actions.removeComment({ id, user_id, token })),


});
export default connect(mapStateToProps, mapDispatchToProps)(CommentBtnRight);

/***
 * <div
                className="dropdown-menu dropdown-scale dropdown-menu-right"
                role="menu"
              >
                <Link className="dropdown-item" to="#">
                  Ocultar publicación
                </Link>
                <Link className="dropdown-item" to="#">
                  Reportar
                </Link>
              </div>
              <li>
                  <Link to="" data-toggle="modal" data-target="#product_share">
                    <small>
                      <FaShareAlt />
                    </small>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <small>
                      <FaComments />
                    </small>
                  </Link>
                </li>
 */
