export const USER_ITEM_ATTEMP = "USER_ITEM_ATTEMP";
export const USER_ITEM_SUCCESS = "USER_ITEM_SUCCESS";
export const USER_ITEM_FAILED = "USER_ITEM_FAILED";

export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILED = "USER_UPDATE_FAILED";

export const USER_AVATAR_SUCCESS = "USER_AVATAR_SUCCESS";
export const USER_AVATAR_FAILED = "USER_AVATAR_FAILED";
export const USER_AVATAR_ATTEMP = "USER_AVATAR_ATTEMP";

export const LOGOUT = "LOGOUT";
