import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import {
  FaSearch,
  FaBars,
  //FaBell, 
  FaCartArrowDown
} from "react-icons/fa";

import "./styles/Navbar.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import logo_g from "../assets/logo_godiway_celeste_blanco_209_44.png";
import logo_p from "../assets/logo_godiway_celeste_blanco_51_44.png";

import * as actions_ad from "../redux/actions/adSearchActions";
import * as actions_wish from "../redux/actions/wishActions";
import * as actions_store from "../redux/actions/marketstoreSearchActions";
import * as actions_event from "../redux/actions/eventActions";

import MenuMobileIn from "./MenuMobileIn";
import DropDown from "./DropDown";
import DropDownLang from "./DropDownLang";
import DropDownNotification from "./DropDownNotification";
import DropDownChat from "./DropDownChat";


class NavbarIn extends React.Component {
  state = {
    search_text: "",
    mobile: false,
    marketstore: false,
  };

  componentDidMount() {
    this.props.SetWishCount(this.props.auth.myInterestCount);
    this.fetch();
  }

  async fetch() {
    await this.props.LoadBadget(this.props.token, this.props.user_id);
  }

  onSearch = async (event) => {
    event.preventDefault();
    try {
      if (this.props.history.location.pathname !== "/goder/search")
        this.props.history.replace("/goder/search");
      //if (this.state.search_text > 0)
      await this.props
        .AdSearch(this.props.token, {
          text: this.state.search_text,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  /*
  onSearchSubmit = async (event) => {
    event.preventDefault();

    // console.log("enra");
   
    this.props.history.push("ad/search");
  };
*/
  onChange = (event) => {
    this.setState({ search_text: event.target.value });
    if (event.target.value.startsWith("@"))
      this.setState({ marketstore: true });
    else this.setState({ marketstore: false });

    if (this.state.marketstore && event.target.value.length > 1)
      this.fecthSearchMarketStore();
  };

  async fecthSearchMarketStore() {
    try {
      await this.props
        .MarketstoreSearch(
          this.props.token, {
          text: this.state.search_text.replace("@", ""),
          id: this.props.user_id,
        }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  onClickMenuMobile = () => {
    this.setState({ mobile: !this.state.mobile });
  };

  onClickHome = () => {
    this.props.history.replace("/");
  };

  onClickGoMarketStore = (item, evt) => {
    evt.preventDefault();

    this.setState({ search_text: "" });
    // console.log(this.props.history.location.pathname);
    if (!this.props.history.location.pathname.startsWith("/marketstore/show/"))
      this.props.history.replace(`/marketstore/show/${item.Id}`);
    else this.props.history.replace(`/temp/marketstore/${item.Id}`);
    // window.location.reload();
  };
  render() {
    let suggestionsListComponent;

    if (this.state.search_text && this.state.marketstore) {
      if (this.props.marketStoreCount) {
        suggestionsListComponent = (
          <ul className="card suggestions">
            {this.props.marketStoreList.map((item, index) => {
              let className;

              // Flag the active suggestion with a class
              //  if (index === activeSuggestion) {
              //     className = "suggestion-active";
              //    }  
              // <Link refresh="true" to={`/marketstore/show/${item.Id}`}> </Link>
              return (
                <li
                  className={className}
                  key={item.Id}
                  onClick={(evt) => {
                    this.onClickGoMarketStore(item, evt);
                  }}
                >
                  <img
                    src={item.Logo}
                    className="rounded-circle"
                    style={{ height: "30px", width: "30px" }}
                    alt="GW"
                  />
                  &nbsp;
                  {item.Name}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="card no-suggestions">
            <em>No suggestions...!</em>
          </div>
        );
      }
    }
    return (
      <nav className="navbar" role="navigation">
        <div className="navbar-brand-big">
          <Link
            className=""
            to="#"
            onClick={() => {
              this.onClickHome();
            }}
          >
            <img src={logo_g} alt="Godiway" />
          </Link>
        </div>
        <div className="navbar-brand-small">
          <Link className="" to="/">
            <img src={logo_p} alt="Godiway" />
          </Link>
        </div>

        <div className="navbar-center">
          <div className="navbar-form ng-pristine ng-valid">
            <form
              onSubmit={(e) => {
                this.onSearch(e);
              }}
            >
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="searcher"
                    placeholder={I18n.t("search_what")}
                    maxLength="80"
                    value={this.state.search_text}
                    onChange={this.onChange}
                    autoComplete={"off"}
                  />

                  <div className="input-group-append">
                    <button
                      onClick={(e) => {
                        this.onSearch(e);
                      }}
                      className="btn btn-primary btn"
                    >
                      <FaSearch />
                    </button>
                  </div>
                </div>
                {suggestionsListComponent}
              </div>
            </form>
          </div>
        </div>
        <div className="navbar-mobile">
          <button
            className="btn btn-light"
            onClick={() => {
              this.onClickMenuMobile();
            }}
          >
            <FaBars />
          </button>
          {this.state.mobile && (
            <MenuMobileIn onClick={this.onClickMenuMobile} />
          )}
        </div>

        <div className="">
          <div className="navbar-items">
            <div className="nav-item">
              <Link
                to="/wish"
                className="nav-link"
                data-togle="tooltip"
                title="Lista de Deseos"
              >
                <FaCartArrowDown />
                {
                  this.props.pageCount > 0 && (
                    <span className="badge">{this.props.pageCount}</span>
                  )
                }
              </Link>
            </div>
            <div className="nav-item">
              <DropDownChat />
            </div>
            <div className="nav-item">
              <DropDownNotification />
            </div>
            <div className="nav-item">
              <DropDown
                title={`@${this.props.auth.username}`}
                list_items={[
                  {
                    id: 1,
                    route: "/profile/edit",//"/profile",
                    value: <Translate value="profile" />,
                  },
                  {
                    id: 2,
                    route: "/wish",
                    value: <Translate value="panel_control" />,
                  },
                  {
                    id: 3,
                    route: "/logout",
                    value: <Translate value="logout" />,
                  },
                ]}
              />
            </div>
            <div className="nav-item">
              <DropDownLang />
            </div>
            <div className="nav-item">
              <Link
                to="/goder/menu"
                className="btn btn-info"
                data-toggle="tooltip"
                title="Registrate en Godiway"
              >
                <b>
                  <strong>
                    <Translate value="ad" />
                  </strong>
                </b>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    auth: reducer.session.auth,

    wishCount: reducer.wish.page_count,
    lang: reducer.i18n.locale,

    marketStoreList: reducer.marketstoreSearch.list,
    marketStoreCount: reducer.marketstoreSearch.page_count,
    error: reducer.marketstoreSearch.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  AdSearch: (token, params) =>
    dispatch(actions_ad.loadListIn({ token, params })),//text, user_id,
  ///////////////////////////
  MarketstoreSearch: (token, params) =>
    dispatch(actions_store.marketstoreSearch({ token, params })),//text, user_id,
  //////////////////////////
  SetWishCount: (wishCount) =>
    dispatch(actions_wish.setWishCount({ wishCount })),
  //////////////
  LoadBadget: (token, user_id) => dispatch(actions_event.loadBadget({ token, user_id })),

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarIn)
);
