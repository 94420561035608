export const config = {
    production: process.env.PRODUCTION || true,
    jwt: {
        secret: process.env.JWT_SECRET || 'Godiway God is my way',
    },
    api: {
        facebook: process.env.API_KEY_FACEBOOK || '1796994160354148',
        google: process.env.API_KEY_GOOGLE || '763257742946-40dbaps2qjisf4mmfsevdkujsmc79g9f.apps.googleusercontent.com',
        godiway: 'https://www.godiway.com/api/'
    },
    socket: {
        url: process.env.ENDPOINT || 'wss://www.godiway.com',//"ws://localhost",//"wss://www.godiway.com"
        secure: true,
        pingInterval: process.env.PING_INTERVAL || 10000,
        pingTimeout: process.env.PING_TIMEOUT || 60000,
        withCredentials: false,
        rejectUnauthorized: false,
        transports: ["websocket", "polling"],
        reconnectionAttempts: "infinity",
        reconnectionDelay: process.env.RECONNECTION_DELAY || 1000,
        reconnectionDelayMax: process.env.RECONNECTION_DELAY_MAX || 5000,
        allowUpgrades: process.env.ALLOW_UPGRADES || false,
        reconnect: true,
        log: false
    },
    chatService: {
        path: process.env.CHAT_PATH_SOCKET || '/gw_chat',
        port: process.env.CHAT_SRV_PORT || 4000,
        channel: process.env.CHAT_SRV_CHANNEL || 'chat',//'chat',
    },
    notificationService: {
        path: process.env.NOTIFICATION_PATH_SOCKET || '/gw_notification',
        port: process.env.NOTIFICATION_SRV_PORT || 4001,
        channel: process.env.NOTIFICATION_SRV_CHANNEL || 'notification',//'notification',
    },
    paypal:
    {
        clientId: "ASg915W6oYD9CZgL9RwT2FRJM8otig3S-Fcbae0g_DVuYpx95X3PuzCuvCLoTbDu8QB0L_1uX4pnI9vv", //production
        //"AR2RI1AKO4mzp78KjJrcjwgi4goI3SUNCpknS2Ot2FzfQaVLfzxxe32cunOUoJIzM60e5Zz5SLCDx_Qm", //SadBox
    }

}

