import React from "react";
import { Link } from "react-router-dom";
import { FaLocationArrow, FaCamera, FaPaperclip } from "react-icons/fa";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/Chat.css";

import MessageList from "../components/MessageList";
import PanelLayout from "../components/PanelLayout";
import MiniLoader from "../components/MiniLoader";


import * as actions_message from "../redux/actions/messageActions";
import * as actions_event from "../redux/actions/eventActions";
import * as actions_contacts from "../redux/actions/contactsActions";
import * as actions_notification from "../redux/actions/notificationActions";
import Typping from '../components/Typping';
import ModalGodiwayer from "../components/ModalGodiwayer";

const messagesEndRef = React.createRef();

var timer;
class Chat extends React.Component {
  state = {
    message: "",
    open_modal: false,
    //   Socket: {},
  };



  componentDidMount() {
    this.fetchMessageList();
  }

  componentDidUpdate(prevProps) {
    this.scrollToBottom();
    //console.log(prevProps);
  }

  componentWillReceiveProps(nextProps) { // your code here
    /* console.log(nextProps);
     if (nextProps.onLine !== this.props.onLine) // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
     {
       this.setState({ ...this.state, });
       this.render();
     }*/
  }

  componentWillUnmount() {
    //this.props.ClearMessages();//al limpiar contacto se limpia mensajes
    this.props.ClearContactMessage();
    this.props.ClearContactEvent();
    this.props.ClearContact();
    clearInterval(timer);
  }

  fetchMessageList = async () => {
    try {
      await this.props
        .LoadMessages(
          this.props.token, {
          conversation_id: this.props.match.params.conversation_id, //this.props.contact.ConversationId,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        }
        )
        .then(() => {
          if (this.props.error) {
            console.log(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
    this.scrollToBottom();
  };

  scrollToBottom = () => {
    var element = document.getElementById("scroll_chat");
    if (element !== null) element.scrollIntoView({ behavior: "smooth" });
  };

  onClickSendMessage = async () => {
    var text = (this.state.message).trim();
    if (text.length === 0)
      return;

    const index = this.props.pageCount + 1;
    try {
      let params = {
        id: index,
        text: text,
        created_at: new Date(),
        conversation_id: this.props.contact.ConversationId,
        to_id: this.props.contact.UserId,
        is_server: 2,
        is_seen: 2,
        user: {
          id: this.props.auth.id,
          name: this.props.auth.name,
          lastN: this.props.auth.lastN,
          avatar: this.props.auth.avatar,
        },
        socket: this.props.contact.Socket,
        // id: this.props.user_id,
        lang: I18n.t("locale"),
      };
      this.props.AddMessage(this.props.token, params);//agrega el mensaje a la lista mensajes
      this.setState({ message: "" });
      params = {
        ...params,
        id: this.props.user_id,
        ////aqui me quede pias 
      };
      await this.props.StoreMessage(this.props.token, params, index).then(() => {//almacena en el servidor 
        if (!this.props.error) {
          // params.index = this.props.messageServer && this.props.messageServer.id;
          // params.is_server = this.props.messageServer && this.props.messageServer.is_server;
          // params = {
          //   ...params,
          //   index: this.props.messageServer && this.props.messageServer.id,
          //   is_server: this.props.messageServer && this.props.messageServer.is_server
          // };
          if (this.props.contact.Socket !== '') {
            this.props.SendMessage(this.props.messageServer);//Envia al socket al otro usuario
          }
          // else   // pendiente revisar la notificacion al cell                   
          //   this.props.SendNotification(this.props.token, params);
        }
      });//Envia al servidor si sale error avisar en la lista


      clearInterval(timer);
      timer = setTimeout(() => {
        this.scrollToBottom();
      }, 100);

    } catch (error) {
      console.log(error);
    }

  };

  onChange = (event) => {
    this.setState({ message: event.target.value });
    //console.log(this.props.contact);
    if (this.props.contact.Socket != undefined && this.props.contact.Socket !== '') {
      const paramsOn = {
        socket: this.props.contact.Socket,
        status: 'typingOn',
        id: this.props.user_id,
      };
      this.props.SendEvent(paramsOn);
    }
  };

  onFocus = (event) => {
    console.log("focus");
  };

  onLostFocus = (event) => {
    console.log("lost focus");
  };

  seeModal = (item) => {
    this.setState({ open_modal: true, contact: item });
  };

  onClose = () => {
    this.setState({ open_modal: false });
  };


  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <div className="container-chat">
            <div className="card_chat">
              <div className="card-header-chat">
                <div className="img_cont">
                  <img
                    src={this.props.contact.Avatar}
                    className="rounded-circle user_img"
                    alt="Gw"
                  />
                  {
                    this.props.onLine !== '' && (<i className="online_icon"></i>)
                  }
                </div>
                <div className="user_info">

                  <Link className="" to={'#'} onClick={() => { this.seeModal(this.props.contact); }} >{this.props.contact.Name + " " + this.props.contact.LastN} </Link>

                  <br />
                  <small>
                    <Translate value="message_count" />:
                    {this.props.messageList.length}
                  </small>

                  {
                    this.props.event.status === 'typingOn' && (<Typping background="light" kind='scaling' />)
                  }
                </div>
              </div>
              <div className="msg_card_body">

                {this.props.loading ? (
                  <MiniLoader />
                ) : (
                  <MessageList />
                )}
                <div className="msg_scroll" id={"scroll_chat"} />

              </div>
              <div className="card-footer-chat">
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text attach_btn">
                      <FaPaperclip />
                    </span>
                  </div>
                  <textarea
                    name="message"
                    className="form-control type_msg"
                    placeholder="Type your message..."
                    onChange={this.onChange}
                    value={this.state.message}
                  // onFocus={this.onFocus}
                  //onBlur={this.onLostFocus}
                  ></textarea>
                  <div className="input-group-append">
                    <button
                      className="input-group-text send_btn"
                      onClick={() => {
                        this.onClickSendMessage();
                      }}
                    >
                      <FaLocationArrow />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PanelLayout>
        <ModalGodiwayer
          isOpen={this.state.open_modal}
          onClose={() => {
            this.onClose();
          }}
          item={this.props.contact}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer.contacts.item);
  // console.log(reducer.contacts.list);
  return {
    auth: reducer.session.auth,

    token: reducer.session.auth.access_token,
    user_id: reducer.session.auth.id,
    ///////////
    messageList: reducer.message.list,
    pageCount: reducer.message.page_count,
    error: reducer.message.error,
    loading: reducer.message.loading,
    messageServer: reducer.message.item,
    ///////////////
    contact: reducer.contacts.item,

    onLine: reducer.contacts.online,
    ///////////////
    event: reducer.event.event,

  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadMessages: (token, params) =>
    dispatch(actions_message.loadList({ token, params })),
  AddMessage: (token, params) =>
    dispatch(
      actions_message.addMessage({ token, params })
    ),
  StoreMessage: (token, params, index) =>
    dispatch(
      actions_message.newItem({ token, params, index })
    ),
  ClearContactMessage: () =>
    dispatch(
      actions_message.clearContactMessage({})
    ),
  /////////////////////////
  SendMessage: (params) =>//message, id, to_id, status, user_id, token
    dispatch(
      actions_event.sendMessage(params)//{ message, id, to_id, status, user_id, token }
    ),
  SendEvent: (params) =>//message, id, to_id, status, user_id, token
    dispatch(
      actions_event.sendEvent(params)//{ message, id, to_id, status, user_id, token }
    ),
  ClearContactEvent: () =>
    dispatch(
      actions_event.clearContactEvent()
    ),
  ////////////////////////////
  ClearContact: () =>
    dispatch(
      actions_contacts.clearContact()
    ),
  ////////////////////////////
  SendNotification: (token, params) =>
    dispatch(
      actions_notification.sendNotification({ token, params })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);


/*
 <React.Fragment>
        <PanelLayout>
          <div className="msg_cotainer">
            <div className="card_chat">
              <div className="msg_head ">
                <div className=" card-header ">
                  <div className="d-flex bd-highlight">
                    <div className="img_cont">
                      <img
                        src={this.props.contact.Photo}
                        className="rounded-circle user_img"
                        alt="Gw"
                      />
                      {
                        this.props.contact.Socket && (<i className="online_icon"></i>)
                      }
                    </div>
                    <div className="user_info">
                      <span>
                        <Link to="#">{this.props.contact.Name} </Link>
                      </span>
                      <br />
                      <small>
                        <Translate value="message_count" />:
                        {this.props.messageList.length}
                      </small>

                      {
                        this.props.event.status === 'typingOn' && (<Typping background="light" kind='scaling' />)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="msg_card_body">
          
                  {this.props.loading ? (
                    <MiniLoader />
                  ) : (                 
                      <MessageList />                
                  )}
                  <div className="msg_scroll" id={"scroll_chat"} />
               
              </div>
             
            </div>
             <div className="card-footer">
                <div className="input-group">
                  <div className="input-group-append">
                    <span className="input-group-text attach_btn">
                      <FaPaperclip />
                    </span>
                  </div>
                  <textarea
                    name="message_chat"
                    className="form-control type_msg"
                    placeholder="Type your message..."
                    onChange={this.onChange}
                    value={this.state.message}
                  // onFocus={this.onFocus}
                  //onBlur={this.onLostFocus}
                  ></textarea>
                  <div className="input-group-append">
                    <button
                      className="input-group-text send_btn"
                      onClick={() => {
                        this.onClickSendMessage();
                      }}
                    >
                      <FaLocationArrow />
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </PanelLayout>
      </React.Fragment>
      // <ContactList /> style="height: 500px;background-color:rgba(0,0,0,0.4);"     style="display:flex;flex-wrap: nowrap;"


      
*/
