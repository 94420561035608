import {
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAILED,
  MESSAGE_LIST_ATTEMPT,
  //////////////////
  MESSAGE_MORELIST_SUCCESS,
  MESSAGE_MORELIST_FAILED,
  MESSAGE_MORELIST_ATTEMPT,
  ////////////////
  MESSAGE_STORE_SUCCESS,
  MESSAGE_STORE_FAILED,
  //////////////////////
  RECEIVE_MESSAGE,
  SEND_MESSAGE,
  CONFIRM_MESSAGE_READ,
  CONFIRM_MESSAGE_RECEIVE,
  /////////////////////////
  UPDATE_CONTACT_MESSAGE,
  CLEAR_CONTACT_MESSAGE
} from "../types/messageTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  ////////////////////
  page_size: 0,
  page_count: 0,
  loadingMore: false,
  ///////////////////////
  item: {},//mensaje ultimo enviado //para poder hacer el recibir con el id, original y no el temporal
  contact: {},
};
function message(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MESSAGE_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: ""
      };

    case MESSAGE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    ////////////////////////////////////////
    case MESSAGE_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true
      };

    case MESSAGE_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: action.data.list.concat(state.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case MESSAGE_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    ////////////////Agrega a la lista con id temporal 
    case SEND_MESSAGE: {
      return {
        ...state,
        list: [...state.list, action.item],
        page_count: state.page_count + 1
      };
    }
    ////////////////retorna del almacenar el msm del server y reemplaza
    case MESSAGE_STORE_SUCCESS: {
      const newList = state.list.map((item) => {
        if (item.id === action.index)
          return action.item;
        return item;
      });
      var new_contact = state.contact;
      new_contact.Message.Text = action.item.text;
      return {
        ...state,
        list: newList,
        item: action.item,
        contact: new_contact,
        error: '',
      };
    }
    case MESSAGE_STORE_FAILED: {
      const newList = state.list.map((item) => {
        if (item.id === action.index)
          item.is_server = 3;
        return item;
      });
      return {
        ...state,
        list: newList,
        error: action.error
      };
    }
    /////////////////////////Cuando recibo un nuevo mensaje lo actualiza en el servr y lo pongo en la ista
    case RECEIVE_MESSAGE: {
      if (state.contact && (state.contact.UserId === action.data.user.id))
        return {
          ...state,
          list: [...state.list, action.data],
          page_count: state.page_count + 1
        };
      else return state;
    }

    case CONFIRM_MESSAGE_RECEIVE: {

      if (state.contact && (state.contact.UserId === action.data.to_id)) {
        const newList = state.list.map((item) => {
          if (item.id === action.data.id && item.is_seen === 2)
            item.is_seen = 1;
          return item;
        });
        return {
          ...state,
          list: newList,
        };
      }

      else return state;
    }
    case CONFIRM_MESSAGE_READ: {

      if (state.contact && (state.contact.UserId === action.data.to_id)) {
        const newList = state.list.map((item) => {
          if (item.id === action.data.id && item.is_seen !== 0)
            item.is_seen = 0;
          return item;
        });
        return {
          ...state,
          list: newList,
        };
      }

      else return state;
    }
    ///////////////////////////////
    case UPDATE_CONTACT_MESSAGE:
      return {
        ...state,
        contact: action.item
      }
    case CLEAR_CONTACT_MESSAGE:
      return INITIAL_STATE;


    default:
      return state;
  }
}
export default message;
