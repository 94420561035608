export const NOTIFICATION_LIST_FAILED = "NOTIFICATION_LIST_FAILED";
export const NOTIFICATION_LIST_SUCCESS = "NOTIFICATION_LIST_SUCCESS";
export const NOTIFICATION_LIST_ATTEMPT = "NOTIFICATION_LIST_ATTEMPT";

export const NOTIFICATION_UPDATE_SUCCESS = "NOTIFICATION_UPDATE_SUCCESS";
export const NOTIFICATION_UPDATE_FAILED = "NOTIFICATION_UPDATE_FAILED";

export const NOTIFICATION_UPDATE_ALL_SUCCESS = "NOTIFICATION_UPDATE_ALL_SUCCESS";

export const NOTIFICATION_LIST_ALL_ATTEMPT = "NOTIFICATION_LIST_ALL_ATTEMPT";
export const NOTIFICATION_LIST_ALL_SUCCESS = "NOTIFICATION_LIST_ALL_SUCCESS";
export const NOTIFICATION_LIST_ALL_FAILED = "NOTIFICATION_LIST_ALL_FAILED";

export const RECEIVE_NOTIFICATION = "RECEIVE_NOTIFICATION";

export const LOGOUT = "LOGOUT";