import React from "react";
import "./styles/FeedBack.css";
//import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Translate, I18n } from "react-redux-i18n";
class GodiwayEmail extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-sucessfull">
            <section className="form">
              <h3 className="">
                <Translate value="feedback_subtitle" />
              </h3>
              <div className="rating-control">
                <div
                  className="rating-option"
                  rating="1"
                  selected-fill="#FFA98D"
                >
                  <div className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 50 50">
                      <path
                        d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
                        className="base"
                        fill="#C6CCD0"
                      ></path>
                      <path
                        d="M25,31.5 C21.3114356,31.5 17.7570324,32.4539319 14.6192568,34.2413572 C13.6622326,34.7865234 13.3246514,36.0093483 13.871382,36.9691187 C14.4181126,37.9288892 15.6393731,38.2637242 16.5991436,37.7169936 C19.1375516,36.2709964 22.0103269,35.5 25,35.5 C27.9896731,35.5 30.8610304,36.2701886 33.4008564,37.7169936 C34.3606269,38.2637242 35.5818874,37.9288892 36.128618,36.9691187 C36.6753486,36.0093483 36.3405137,34.7880878 35.3807432,34.2413572 C32.2429676,32.4539319 28.6885644,31.5 25,31.5 Z"
                        className="mouth"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M30.6486386,16.8148522 C31.1715727,16.7269287 31.2642212,16.6984863 31.7852173,16.6140137 C32.3062134,16.529541 33.6674194,16.3378906 34.5824585,16.1715729 C35.4974976,16.0052551 35.7145386,15.9660737 36.4964248,15.8741891 C36.6111841,15.9660737 36.7220558,16.0652016 36.8284271,16.1715729 C37.7752853,17.118431 38.1482096,18.4218859 37.9472002,19.6496386 C37.8165905,20.4473941 37.4436661,21.2131881 36.8284271,21.8284271 C35.26633,23.3905243 32.73367,23.3905243 31.1715729,21.8284271 C29.8093655,20.4662198 29.6350541,18.3659485 30.6486386,16.8148522 Z"
                        className="right-eye"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M18.8284271,21.8284271 C20.1906345,20.4662198 20.3649459,18.3659485 19.3513614,16.8148522 C18.8284273,16.7269287 18.7357788,16.6984863 18.2147827,16.6140137 C17.6937866,16.529541 16.3325806,16.3378906 15.4175415,16.1715729 C14.5025024,16.0052551 14.2854614,15.9660737 13.5035752,15.8741891 C13.3888159,15.9660737 13.2779442,16.0652016 13.1715729,16.1715729 C12.2247147,17.118431 11.8517904,18.4218859 12.0527998,19.6496386 C12.1834095,20.4473941 12.5563339,21.2131881 13.1715729,21.8284271 C14.73367,23.3905243 17.26633,23.3905243 18.8284271,21.8284271 Z"
                        className="left-eye"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </div>
                  <div class="label">
                    {" "}
                    <Translate value="feedback_terrible" />
                  </div>
                </div>
                <div
                  className="rating-option"
                  rating="2"
                  selected-fill="#FFC385"
                >
                  <div className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 50 50">
                      <path
                        d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
                        className="base"
                        fill="#C6CCD0"
                      ></path>
                      <path
                        d="M25,31.9996 C21.7296206,31.9996 18.6965022,32.5700242 15.3353795,33.7542598 C14.2935825,34.1213195 13.7466,35.2634236 14.1136598,36.3052205 C14.4807195,37.3470175 15.6228236,37.894 16.6646205,37.5269402 C19.617541,36.4865279 22.2066846,35.9996 25,35.9996 C28.1041177,35.9996 31.5196849,36.5918872 33.0654841,37.4088421 C34.0420572,37.924961 35.2521232,37.5516891 35.7682421,36.5751159 C36.284361,35.5985428 35.9110891,34.3884768 34.9345159,33.8723579 C32.7065288,32.6948667 28.6971052,31.9996 25,31.9996 Z"
                        className="mouth"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M30.7014384,16.8148522 C30.8501714,16.5872449 31.0244829,16.3714627 31.2243727,16.1715729 C32.054483,15.3414625 33.1586746,14.9524791 34.2456496,15.0046227 C34.8805585,15.7858887 34.945378,15.8599243 35.5310714,16.593811 C36.1167648,17.3276978 36.1439252,17.3549194 36.5988813,17.9093628 C37.0538374,18.4638062 37.2801558,18.7149658 38,19.6496386 C37.8693903,20.4473941 37.496466,21.2131881 36.8812269,21.8284271 C35.3191298,23.3905243 32.7864699,23.3905243 31.2243727,21.8284271 C29.8621654,20.4662198 29.6878539,18.3659485 30.7014384,16.8148522 Z"
                        className="right-eye"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M18.8284271,21.8284271 C20.1906345,20.4662198 20.3649459,18.3659485 19.3513614,16.8148522 C19.2026284,16.5872449 19.0283169,16.3714627 18.8284271,16.1715729 C17.9983168,15.3414625 16.8941253,14.9524791 15.8071502,15.0046227 C15.1722413,15.7858887 15.1074218,15.8599243 14.5217284,16.593811 C13.9360351,17.3276978 13.9088746,17.3549194 13.4539185,17.9093628 C12.9989624,18.4638062 12.772644,18.7149658 12.0527998,19.6496386 C12.1834095,20.4473941 12.5563339,21.2131881 13.1715729,21.8284271 C14.73367,23.3905243 17.26633,23.3905243 18.8284271,21.8284271 Z"
                        className="left-eye"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </div>
                  <div class="label">
                    <Translate value="feedback_bad" />
                  </div>
                </div>
                <div className="rating-option" rating="3">
                  <div className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 50 50">
                      <path
                        d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
                        className="base"
                        fill="#C6CCD0"
                      ></path>
                      <path
                        d="M25.0172185,33 C22.4651351,33 19.9789584,33 17.4783686,33 C16.4004906, 33.2250477 15.7222686,  34.2944568 15.9635531,  35.2723508 C16.2048377, 36.3502449 17.2738374,  37 17.8521373,37 C20.8031673,37 23.2056119,37 25.7416475,37 C28.2776831,37 30.8254642,37 33.3839467,37 C34.4772031, 36.7692059 35.235822,   35.7552362 35.0783131,  34.6619545 C34.9208042, 33.5686729 33.89778,    33 33.3135565,33 C30.2035476,33 27.6044107,33 17.4783686,33 Z"
                        className="mouth"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M30.6486386,16.8148522 C30.7973716,16.5872449 30.9716831,16.3714627 31.1715729,16.1715729 C32.0016832,15.3414625 33.1058747,14.9524791 34.1928498,15.0046227 C35.0120523,15.0439209 35.8214759,15.3337764 36.4964248,15.8741891 C36.6111841,15.9660737 36.7220558,16.0652016 36.8284271,16.1715729 C37.7752853,17.118431 38.1482096,18.4218859 37.9472002,19.6496386 C37.8165905,20.4473941 37.4436661,21.2131881 36.8284271,21.8284271 C35.26633,23.3905243 32.73367,23.3905243 31.1715729,21.8284271 C29.8093655,20.4662198 29.6350541,18.3659485 30.6486386,16.8148522 Z"
                        className="right-eye"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M18.8284271,21.8284271 C20.1906345,20.4662198 20.3649459,18.3659485 19.3513614,16.8148522 C19.2026284,16.5872449 19.0283169,16.3714627 18.8284271,16.1715729 C17.9983168,15.3414625 16.8941253,14.9524791 15.8071502,15.0046227 C14.9879477,15.0439209 14.1785241,15.3337764 13.5035752,15.8741891 C13.3888159,15.9660737 13.2779442,16.0652016 13.1715729,16.1715729 C12.2247147,17.118431 11.8517904,18.4218859 12.0527998,19.6496386 C12.1834095,20.4473941 12.5563339,21.2131881 13.1715729,21.8284271 C14.73367,23.3905243 17.26633,23.3905243 18.8284271,21.8284271 Z"
                        className="left-eye"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </div>
                  <div class="label">
                    <Translate value="feedback_good" />
                  </div>
                </div>
                <div className="rating-option" rating="4">
                  <div className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 50 50">
                      <path
                        d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
                        className="base"
                        fill="#C6CCD0"
                      ></path>
                      <path
                        d="M25,35 C21.9245658,35 18.973257,34.1840075 16.3838091,32.6582427 C15.4321543,32.0975048 14.2061178,32.4144057 13.64538,33.3660605 C13.0846422,34.3177153 13.401543,35.5437517 14.3531978,36.1044896 C17.5538147,37.9903698 21.2054786,39 25,39 C28.7945214,39 32.4461853,37.9903698 35.6468022,36.1044896 C36.598457,35.5437517 36.9153578,34.3177153 36.35462,33.3660605 C35.7938822,32.4144057 34.5678457,32.0975048 33.6161909,32.6582427 C31.026743,34.1840075 28.0754342,35 25,35 Z"
                        className="mouth"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M30.6486386,16.8148522 C30.7973716,16.5872449 30.9716831,16.3714627 31.1715729,16.1715729 C32.0016832,15.3414625 33.1058747,14.9524791 34.1928498,15.0046227 C35.0120523,15.0439209 35.8214759,15.3337764 36.4964248,15.8741891 C36.6111841,15.9660737 36.7220558,16.0652016 36.8284271,16.1715729 C37.7752853,17.118431 38.1482096,18.4218859 37.9472002,19.6496386 C37.8165905,20.4473941 37.4436661,21.2131881 36.8284271,21.8284271 C35.26633,23.3905243 32.73367,23.3905243 31.1715729,21.8284271 C29.8093655,20.4662198 29.6350541,18.3659485 30.6486386,16.8148522 Z"
                        className="right-eye"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M18.8284271,21.8284271 C20.1906345,20.4662198 20.3649459,18.3659485 19.3513614,16.8148522 C19.2026284,16.5872449 19.0283169,16.3714627 18.8284271,16.1715729 C17.9983168,15.3414625 16.8941253,14.9524791 15.8071502,15.0046227 C14.9879477,15.0439209 14.1785241,15.3337764 13.5035752,15.8741891 C13.3888159,15.9660737 13.2779442,16.0652016 13.1715729,16.1715729 C12.2247147,17.118431 11.8517904,18.4218859 12.0527998,19.6496386 C12.1834095,20.4473941 12.5563339,21.2131881 13.1715729,21.8284271 C14.73367,23.3905243 17.26633,23.3905243 18.8284271,21.8284271 Z"
                        className="left-eye"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </div>
                  <div className="label">
                    <Translate value="feedback_ok" />
                  </div>
                </div>
                <div className="rating-option" rating="5">
                  <div className="icon">
                    <svg width="100%" height="100%" viewBox="0 0 50 50">
                      <path
                        d="M50,25 C50,38.807 38.807,50 25,50 C11.193,50 0,38.807 0,25 C0,11.193 11.193,0 25,0 C38.807,0 50,11.193 50,25"
                        className="base"
                        fill="#C6CCD0"
                      ></path>
                      <path
                        d="M25.0931396,31 C22.3332651,31 16.6788329,31 13.0207,31 C12.1907788,31 11.6218259,31.4198568 11.2822542,32.0005432 C10.9061435,32.6437133 10.8807853,33.4841868 11.3937,34.17 C14.4907,38.314 19.4277,41 24.9997,41 C30.5727,41 35.5097,38.314 38.6067,34.17 C39.0848493,33.5300155 39.0947422,32.7553501 38.7884086,32.1320187 C38.4700938,31.4843077 37.8035583,31 36.9797,31 C34.3254388,31 28.6616205,31 25.0931396,31 Z"
                        className="mouth"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M30.6486386,16.8148522 C30.7973716,16.5872449 30.9716831,16.3714627 31.1715729,16.1715729 C32.0016832,15.3414625 33.1058747,14.9524791 34.1928498,15.0046227 C35.0120523,15.0439209 35.8214759,15.3337764 36.4964248,15.8741891 C36.6111841,15.9660737 36.7220558,16.0652016 36.8284271,16.1715729 C37.7752853,17.118431 38.1482096,18.4218859 37.9472002,19.6496386 C37.8165905,20.4473941 37.4436661,21.2131881 36.8284271,21.8284271 C35.26633,23.3905243 32.73367,23.3905243 31.1715729,21.8284271 C29.8093655,20.4662198 29.6350541,18.3659485 30.6486386,16.8148522 Z"
                        className="right-eye"
                        fill="#FFFFFF"
                      ></path>
                      <path
                        d="M18.8284271,21.8284271 C20.1906345,20.4662198 20.3649459,18.3659485 19.3513614,16.8148522 C19.2026284,16.5872449 19.0283169,16.3714627 18.8284271,16.1715729 C17.9983168,15.3414625 16.8941253,14.9524791 15.8071502,15.0046227 C14.9879477,15.0439209 14.1785241,15.3337764 13.5035752,15.8741891 C13.3888159,15.9660737 13.2779442,16.0652016 13.1715729,16.1715729 C12.2247147,17.118431 11.8517904,18.4218859 12.0527998,19.6496386 C12.1834095,20.4473941 12.5563339,21.2131881 13.1715729,21.8284271 C14.73367,23.3905243 17.26633,23.3905243 18.8284271,21.8284271 Z"
                        className="left-eye"
                        fill="#FFFFFF"
                      ></path>
                    </svg>
                  </div>
                  <div className="label">
                    ¡<Translate value="feedback_excellent" />!
                  </div>
                </div>
              </div>
              <h4 className="" animate-in-index="7">
                <Translate value="feedback_better" />
              </h4>
              <div className="form-container">
                <form method="POST">
                  <div className="form-controls">
                    <textarea
                      placeholder={I18n.t("comment_placeholder")}
                      className="form-control"
                      animate-in-index="8"
                    ></textarea>
                    <a className="btn btn-primary" animate-in-index="9" href="#">
                      <span className="label">
                        {" "}
                        <Translate value="password_forget_send" />
                      </span>
                    </a>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default GodiwayEmail;
