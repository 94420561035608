import React, { Component } from "react";
//import { Link } from "react-router-dom";

import StoreInformation from "./StoreInformation";
import StoreSuscripction from "./StoreSuscripction";
import StoreFollowers from "./StoreFollowers";

class StoreRight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <StoreInformation />
        <StoreFollowers />
        <StoreSuscripction />
      </React.Fragment>
    );
  }
}

export default StoreRight;
