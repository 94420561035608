import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
  TRANSACTION_LIST_ATTEMPT,
  TRANSACTION_LIST_SUCCESS,
  TRANSACTION_LIST_FAILED,
  ///////////////////////
  TRANSACTION_MORELIST_ATTEMPT,
  TRANSACTION_MORELIST_SUCCESS,
  TRANSACTION_MORELIST_FAILED,
  ///////////////////////
  TRANSACTION_NEW_SUCCESS,
  TRANSACTION_NEW_FAILED,
  ////////////////////////////////
  TRANSACTION_CALIFICATION_SUCCESS,
  TRANSACTION_CALIFICATION_FAILED,
  /////////////////////////
} from "../types/transactionPendingTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: TRANSACTION_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: TRANSACTION_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: TRANSACTION_LIST_FAILED,
    error: error,
  };
}
////////////////////////////////
export function loadingMore() {
  return {
    type: TRANSACTION_MORELIST_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: TRANSACTION_MORELIST_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: TRANSACTION_MORELIST_FAILED,
    error: error,
  };
}
////////////////////////////////
export function successNewRequest(seller) {
  return {
    type: TRANSACTION_NEW_SUCCESS,
    seller: seller,
  };
}

export function failedNewRequest(error) {
  return {
    type: TRANSACTION_NEW_FAILED,
    error: error,
  };
}
////////////////////////////////////////

export function successCalification(item) {
  return {
    type: TRANSACTION_CALIFICATION_SUCCESS,
    item: item,
  };
}

export function failedCalification(error) {
  return {
    type: TRANSACTION_CALIFICATION_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "transaction/pending", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};


export const loadListScroll = (data: Object) => async (dispatch) => {
  //console.log(data);
  dispatch(loadingMore());
  await axios
    .get(url_api + "transaction/pending", {
      params: {
        index: data.page_count,
        id: data.user_id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};


export const newItem = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "transaction", data.params /*{
      transaction_id: data.id,
      message: data.message,
      quantity: data.quantity,
      id: data.user_id,
    }*/
      , {
        headers: { Authorization: `Bearer ${data.token}` },
      })
    .then(function (response) {
      //console.log(response.data);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successNewRequest(response.data.body));
        else dispatch(failedNewRequest(response.data.body));
      } else {
        dispatch(failedNewRequest("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedNewRequest("try_later"));
      } else {
        dispatch(failedNewRequest("try_later"));
      }
      return;
    });
};

export const newItemCalification = (data: Object) => async (dispatch) => {
  // dispatch(loading());

  //  * Accept: "application/json, text/plain, */*"
  // Content-Type: "application/json;charset=utf-8"

  //console.log(data);
  await axios({
    method: "post",
    url: url_api + "calification",
    data: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successCalification(response.data.body));
        else dispatch(failedCalification(response.data.body));
      } else {
        dispatch(failedCalification("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failedCalification("try_later"));
      } else {
        dispatch(failedCalification("try_later"));
      }
      return;
    });
};
