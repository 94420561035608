import React from "react";
import { Link } from "react-router-dom";
import "./styles/Navbar.css";
import { FaSearch, FaSignInAlt, FaSignOutAlt, FaBars, FaQuestionCircle } from "react-icons/fa";
import DropDownLang from "./DropDownLang";
import logo_g from "../assets/logo_godiway_celeste_blanco_209_44.png";
import logo_p from "../assets/logo_godiway_celeste_blanco_51_44.png";
import * as actions from "../redux/actions/adSearchActions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import MenuMobileOut from "./MenuMobileOut";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
/*
accept-charset="UTF-8" autoComplete="off" role="search"  onKeyUp ="uniKeyCode(event)" disable
*/

class NavbarOut extends React.Component {
  state = {
    search_text: "",
    mobile: false,
  };

  onSearch = async (event) => {
    event.preventDefault();
    try {
      if (this.props.history.location.pathname != "/goder/search")
        this.props.history.push("goder/search");

      await this.props
        .AdSearch(this.state.search_text)//, this.props.user_id, this.props.token
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChange = (event) => {
    this.setState({
      search_text: event.target.value,
    });
  };

  onClickMenuMobile = () => {
    this.setState({ mobile: !this.state.mobile });
  };
  render() {
    return (
      <nav className="navbar" role="navigation">
        <div className="navbar-brand-big">
          <Link className="" to="/">
            <img src={logo_g} alt="Godiway" />
          </Link>
        </div>
        <div className="navbar-brand-small">
          <Link className="" to="/">
            <img src={logo_p} alt="Godiway" />
          </Link>
        </div>

        <div className="navbar-center">
          <div className="navbar-form ng-pristine ng-valid">
            <form
              onSubmit={(e) => {
                this.onSearch(e);
              }}
            >
              <div className="form-group">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    name="searcher"
                    placeholder={I18n.t("search_what")}
                    maxLength="80"
                    value={this.state.search_text}
                    onChange={this.onChange}
                    autoComplete={"off"}
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary btn"
                      onClick={(e) => {
                        this.onSearch(e);
                      }}
                    >
                      <FaSearch />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="navbar-mobile">
          <button
            className="btn btn-light"
            onClick={() => {
              this.onClickMenuMobile();
            }}
          >
            <FaBars />
          </button>
          {this.state.mobile && (
            <MenuMobileOut onClick={this.onClickMenuMobile} />
          )}
        </div>
        <div className="">
          <div className="navbar-items">
            <div className="nav-item ">
              <Link
                to="/faq_me"
                className="nav-link"
                data-toggle="tooltip"
                title="Faq"
              >
                <FaQuestionCircle />
              </Link>
            </div>
            &nbsp;
            <div className="nav-item">
              <Link className="nav-link" to="/login">
                {<Translate value="login" />}
              </Link>
            </div>

            <div className="nav-item">
              <DropDownLang />
            </div>

            <div className="nav-item ">
              <Link
                to="/register"
                className="btn btn-info"
                data-toggle="tooltip"
                title="Registrate en Godiway"
              >
                <b>
                  <strong>{<Translate value="godiway_join" />}</strong>
                </b>
              </Link>
            </div>

          </div>
        </div>
      </nav>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    // user_id: reducer.session.auth.id,
    // token: reducer.session.auth.access_token,

    // user: reducer.session.auth,
    lang: reducer.i18n.locale,
  };
}

const mapDispatchToProps = (dispatch) => ({
  AdSearch: (text) =>
    dispatch(actions.loadListOut({ text })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavbarOut)
);
/**
 * \
 *
 *
 */
