import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adSellerReputationActions";
import "./styles/SellerCalification.css";
import { Translate } from "react-redux-i18n";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
const icon_size = 30;
class SellerCalification extends React.Component {
  componentDidMount() {
    this.fetchLoadReputation();
  }

  fetchLoadReputation = async () => {
    try {
      await this.props
        .LoadSellerReputation(
          this.props.ad.Id,
          this.props.user_id,
          this.props.token
        )
        .then(() => {
          if (this.props.error) {
            console.log("Seller reputation");
            console.log(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    var porcent = 0;
    if (this.props.sellerReputation.TransactionTotal !== 0)
      porcent =
        (this.props.sellerReputation.TransactionConfirmed * 100) /
        this.props.sellerReputation.TransactionTotal;
    var link_accepted = "#",
      link_rejectd = "#";
    if (JSON.stringify(this.props.user) !== "{}") {
      link_accepted = "/commenttransaction/accepted";
      link_rejectd = "/commenttransaction/rejected";
    }
    return (
      <React.Fragment>
        <div className="cardBlue">
          <div className="text-center">
            <h4>
              <Translate value="calification_seller" />
            </h4>
          </div>
          <div className="row">
            <div className="col-12 text-center mb-2">
              {(porcent === 0 && (
                <FaRegStar color={"#f8b908"} size={icon_size} />
              )) ||
                (porcent > 0 && porcent < 10 && (
                  <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                )) ||
                (porcent >= 10 && (
                  <FaStar color={"#f8b908"} size={icon_size} />
                ))}
              {"   "}
              {(porcent < 20 && (
                <FaRegStar color={"#f8b908"} size={icon_size} />
              )) ||
                (porcent >= 20 && porcent < 30 && (
                  <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                )) ||
                (porcent >= 30 && (
                  <FaStar color={"#f8b908"} size={icon_size} />
                ))}
              {"   "}
              {(porcent < 40 && (
                <FaRegStar color={"#f8b908"} size={icon_size} />
              )) ||
                (porcent >= 40 && porcent < 50 && (
                  <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                )) ||
                (porcent >= 50 && (
                  <FaStar color={"#f8b908"} size={icon_size} />
                ))}
              {"   "}
              {(porcent < 60 && (
                <FaRegStar color={"#f8b908"} size={icon_size} />
              )) ||
                (porcent >= 60 && porcent < 70 && (
                  <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                )) ||
                (porcent >= 70 && (
                  <FaStar color={"#f8b908"} size={icon_size} />
                ))}
              {"   "}
              {(porcent < 80 && (
                <FaRegStar color={"#f8b908"} size={icon_size} />
              )) ||
                (porcent >= 80 && porcent < 90 && (
                  <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                )) ||
                (porcent >= 90 && (
                  <FaStar color={"#f8b908"} size={icon_size} />
                ))}
            </div>
            <div className="col-md-4 text-center">
              <h6>
                <Translate value="from" />
              </h6>
              <h6>{this.props.sellerReputation.TimeTransactionOld}</h6>
            </div>
            <div className="col-md-4 text-center none-decoration">
              <Link to={link_accepted}>
                <h6>
                  <Translate value="concret" />
                </h6>
              </Link>
              <div className="text-center">
                <h6>{this.props.sellerReputation.TransactionConfirmed}</h6>
              </div>
            </div>
            <div className="col-md-4 text-center none-decoration">
              <Link to={link_rejectd}>
                <h6>
                  <Translate value="canceled" />
                </h6>
              </Link>
              <div className="text-center">
                <h6> {this.props.sellerReputation.TransactionCanceled}</h6>
              </div>
            </div>
          </div>
        </div>
        <hr className="dividerGray" />
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    loading: reducer.adSellerReputation.loading,
    error: reducer.adSellerReputation.error,

    sellerReputation: reducer.adSellerReputation.item,
    ad: reducer.ad.item,
    user: reducer.session.auth,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadSellerReputation: (goder_id, user_id, token) =>
    dispatch(actions.loadItem({ goder_id, user_id, token })),
});
export default connect(mapStateToProps, mapDispatchToProps)(SellerCalification);
/**<div
                  className="progress-bar"
                  aria-valuenow={porcent}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <div className="filler" style={{ width: `${porcent}%` }} />
                  {porcent}%
                </div> */
