import axios from "axios";

import {
  CUSTOMER_ATTEMPT,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILED,
} from "../types/customerTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: CUSTOMER_ATTEMPT,
  };
}

export function success(item) {
  return {
    type: CUSTOMER_SUCCESS,
    item: item,
  };
}

export function failed(error) {
  return {
    type: CUSTOMER_FAILED,
    error: error,
  };
}
/////////////////////////////////
export const loadItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "customer", {
      params: {
        id: data.user_id,
      },
      headers: { authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const saveCustomer = (data: Object) => async (dispatch) => {
  await axios({
    method: "post",
    url: url_api + "customer",
    data: data.params,
    headers: { authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
