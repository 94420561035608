import React from "react";
import OrderList from "../components/OrderList";
//import api from '../api';
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/ordersActions";

class PanelOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    this.fetchOrdersList();
  }

  fetchOrdersList = async () => {
    try {
      await this.props
        .LoadOrders(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <div className="text-center">
            <h3>
              {" "}
              <Translate value="pay_orders" />
            </h3>
          </div>

          {this.props.loading ? (
            <PageLoading />
          ) : (
            <OrderList orderList={this.props.orderList} />
          )}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    orderList: reducer.orders.list,
    error: reducer.orders.error,
    loading: reducer.orders.loading
  };
}
const mapDispatchToProps = dispatch => ({
  LoadOrders: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelOrder);
