export const TRANSACTION_LIST_FAILED = "TRANSACTION_LIST_FAILED";
export const TRANSACTION_LIST_SUCCESS = "TRANSACTION_LIST_SUCCESS";
export const TRANSACTION_LIST_ATTEMPT = "TRANSACTION_LIST_ATTEMPT";


export const TRANSACTION_NEW_SUCCESS = "TRANSACTION_NEW_SUCCESS";
export const TRANSACTION_NEW_FAILED = "TRANSACTION_NEW_FAILED";

export const TRANSACTION_CALIFICATION_SUCCESS =
  "TRANSACTION_CALIFICATION_SUCCESS";
export const TRANSACTION_CALIFICATION_FAILED =
  "TRANSACTION_CALIFICATION_FAILED";

export const TRANSACTION_MORELIST_ATTEMPT = "TRANSACTION_MORELIST_ATTEMPT";
export const TRANSACTION_MORELIST_SUCCESS = "TRANSACTION_MORELIST_SUCCESS";
export const TRANSACTION_MORELIST_FAILED = "TRANSACTION_MORELIST_FAILED";

export const LOGOUT = "LOGOUT";