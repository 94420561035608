import React, { Component } from "react";
//import PublishVertical from './PublishVertical';
import StoreSuscripction from "./StoreSuscripction";
import WallStores from "./WallStores";

class WallRight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <WallStores />
        <StoreSuscripction />
      </React.Fragment>
    );
  }
}

export default WallRight;
