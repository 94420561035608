import {
  MARKETSTORE_LIST_ATTEMPT,
  MARKETSTORE_LIST_SUCCESS,
  MARKETSTORE_LIST_FAILED,
} from "../types/marketstoreListTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  page_count: 0,
  page_size: 0,
  loadingMore: false
};

function marketstoreList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKETSTORE_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    //  break;
    case MARKETSTORE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: "",
      };
    //break;
    case MARKETSTORE_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    default:
      return state;
  }
}
export default marketstoreList;
