import React from "react";
import PaypalOnePay from "../components/PaypalOnePay";
import PaypalSuscription from "../components/PaypalSuscription";

import PanelLayout from "../components/PanelLayout";
//import PageLoading from "../components/PageLoading";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/cardsActions";
import "./styles/Cards.css";

class SuscriptionPayPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: [],
    };
  }

  componentDidMount() { }

  render() {
    var text1, text2, text3, text4;
    switch (this.props.value) {
      case 100:
        text1 = <Translate value="suscription1" />;
        text2 = <Translate value="suscription2" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        break;

      case 200:
        text1 = <Translate value="suscription1" />;
        text2 = <Translate value="suscription7" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        break;

      case 10:
        text1 = <Translate value="suscription5" />;
        text2 = <Translate value="suscription2" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        break;

      case 20:
        text1 = <Translate value="suscription5" />;
        text2 = <Translate value="suscription7" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        break;

      default:
        break;
    }

    return (
      <React.Fragment>
        <PanelLayout>
          <h3 className="text-center">
            <Translate value="kind_pay" />
          </h3>
          <div className="col">
            <h5 className="d-flex justify-content-between align-items-center">
              <span className="text-muted"><Translate value="you_cart" /></span>
              <span className="badge badge-secondary badge-pill">1</span>
            </h5>
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">
                    {" "}
                    <Translate value="suscription_mine" />
                  </h6>
                  <small className="text-muted">
                    {text1}
                    <br /> {text2} <br />
                    {text3}
                    <br />
                    {text4}
                    <br />
                    <Translate value="suscription8" />
                  </small>
                </div>
                <span className="text-muted">${this.props.value}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Total (USD)</span>
                <strong>${this.props.value}</strong>
              </li>
            </ul>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Codigo Promocional"
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-secondary">
                  <Translate value="password_forget_send" />
                </button>
              </div>
            </div>
            <hr className="" />
          </div>
          {this.props.value === 15 ? <PaypalOnePay /> : <PaypalSuscription />}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    //cardList: reducer.cards.cardList,
    // error: reducer.cards.error,
    // loading: reducer.cards.loading,
    value: reducer.suscriptionPlan.plan_value,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCards: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuscriptionPayPanel);
