export const WALL_LIST_ATTEMPT = "WALL_LIST_ATTEMPT";
export const WALL_LIST_SUCCESS = "WALL_LIST_SUCCESS";
export const WALL_LIST_FAILED = "WALL_LIST_FAILED";
/////////////////////////////////
export const WALL_UPDATE_LIKE_SUCCESS = "WALL_UPDATE_LIKE_SUCCESS";
export const WALL_UPDATE_LIKE_FAILED = "WALL_UPDATE_LIKE_FAILED";
/////////////////////////////////
export const WALL_ADD_COMMENT_SUCCESS = "WALL_ADD_COMMENT_SUCCESS";
export const WALL_ADD_COMMENT_FAILED = "WALL_ADD_COMMENT_FAILED";

export const WALL_REMOVE_COMMENT_SUCCESS = "WALL_REMOVE_COMMENT_SUCCESS";
export const WALL_REMOVE_COMMENT_FAILED = "WALL_REMOVE_COMMENT_FAILED";

export const WALL_REPORT_COMMENT_SUCCESS = "WALL_REPORT_COMMENT_SUCCESS";
export const WALL_REPORT_COMMENT_FAILED = "WALL_REPORT_COMMENT_FAILED";

export const WALL_MORELIST_ATTEMPT = "WALL_MORELIST_ATTEMPT";
export const WALL_MORELIST_SUCCESS = "WALL_MORELIST_SUCCESS";
export const WALL_MORELIST_FAILED = "WALL_MORELIST_FAILED";


export const LOGOUT = "LOGOUT";