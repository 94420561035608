import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  AD_SALES_ATTEMPT,
  AD_SALES_SUCCESS,
  AD_SALES_FAILED,
  ////////////////////////////////
  AD_SALES_MORE_ATTEMPT,
  AD_SALES_MORE_SUCCESS,
  AD_SALES_MORE_FAILED,
  ////////////////////////////////
  AD_SALES_CHANGE_POSTSTATUS_SUCCESS,
  AD_SALES_CHANGE_POSTSTATUS_FAILED,
  ///////////////////////////
  AD_SALES_REMOVE_SUCCESS,
  AD_SALES_REMOVE_FAILED,
  ///////////////////////////
  AD_SALES_NEW_SUCCESS,
  AD_SALES_NEW_FAILED,
} from "../types/adSalesTypes";

import { AD_SALES_INACTIVE_CHANGE_POSTSTATUS_SUCCESS } from "../types/adSalesInactiveTypes";

import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_SALES_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: AD_SALES_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: AD_SALES_FAILED,
    error: error,
  };
}
/////////////////////////////////////////////
export function loadingMore() {
  return {
    type: AD_SALES_MORE_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: AD_SALES_MORE_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: AD_SALES_MORE_FAILED,
    error: error,
  };
}

////////////////////////////////////////////////////
export function successRemoveAd(item) {
  return {
    type: AD_SALES_REMOVE_SUCCESS,
    item: item,
  };
}
export function failedRemoveAd(error) {
  return {
    type: AD_SALES_REMOVE_FAILED,
    error: error,
  };
}
////////////////////////////////////////////////////
export function successUpdatePostStatus(item) {
  return {
    type: AD_SALES_CHANGE_POSTSTATUS_SUCCESS,
    item: item,
  };
}
export function failedUpdatePostStatus(error) {
  return {
    type: AD_SALES_CHANGE_POSTSTATUS_FAILED,
    error: error,
  };
}
/////////////////////////////////////////////
export function successUpdatePostStatusInactive(item) {
  return {
    type: AD_SALES_INACTIVE_CHANGE_POSTSTATUS_SUCCESS,
    item: item,
  };
}

/////////////////////////////////////////////
export function successAdNew(item) {
  return {
    type: AD_SALES_NEW_SUCCESS,
    item: item,
  };
}
export function failedAdNew(error) {
  return {
    type: AD_SALES_NEW_FAILED,
    error: error,
  };
}

/**
 *   export function successScroll(data){
    return{
    type:NOTIFICATION_LIST_SCROLL,
    data:data
    }
};
*/

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "sale/active", {
      params: {
        id: data.user_id,
        // token: data.token,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async (dispatch) => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "sale/active", {
      params: {
        index: data.page_count,
        id: data.user_id,
        //  token: data.token,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "goder/poststatus", {
      goder_id: data.id,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success") {
          dispatch(successUpdatePostStatus(response.data.body));
          dispatch(successUpdatePostStatusInactive(response.data.body));
        }
        else dispatch(failedUpdatePostStatus(response.data.body));
      } else {
        dispatch(failedUpdatePostStatus("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdatePostStatus("try_later"));
      } else {
        dispatch(failedUpdatePostStatus("try_later"));
      }
      return;
    });
};

export const deleteItem = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "sale", {
      goder_id: data.id,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successRemoveAd(response.data.body));
        else dispatch(failedRemoveAd(response.data.body));
      } else {
        dispatch(failedRemoveAd("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedRemoveAd("try_later"));
      } else {
        dispatch(failedRemoveAd("try_later"));
      }
      return;
    });
};

export const newItem = (data: Object) => async (dispatch) => {

  await axios.post(url_api + "sale", data.params, {
    headers: {
      Authorization: `Bearer ${data.token}`
    },
  })
    .then(function (response) {
      //  console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAdNew(response.data.body));
        else dispatch(failedAdNew(response.data.body));
      } else {
        dispatch(failedAdNew("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedAdNew("try_later"));
      } else {
        dispatch(failedAdNew("try_later"));
      }
      return;
    });
};


// export const loadAvatar = (data: Object) => async (dispatch) => {
//   try {
//     //loadingUpImage(0);
//     var request = new XMLHttpRequest();
//     request.open("post", url_api + "auth/avatar");
//     request.setRequestHeader('Authorization', `Bearer ${data.token}`);
//     request.setRequestHeader(
//       'Content-Type', 'multipart/form-data'
//     );
//     request.onload = () => {
//       if (request.status < 300) {
//         const response = JSON.parse(request.response);
//         if (response.status === "Success")
//           dispatch(success(response.body));
//         else {
//           dispatch(failedMessage("try_later"));
//         }
//         //console.log(data);
//       } else {
//         console.error(request.response);
//         dispatch(failedMessage("try_later"));
//       }
//     };
//     await request.send(data.params);
//     if (request.upload) {
//       request.upload.onprogress = ({ total, loaded }) => {
//         const uploadProgess = loaded / total;
//         console.log(uploadProgess);
//         //dispatch(loadingUpImage(uploadProgess));
//       };
//     }
//   } catch (error) {
//     console.error(error);
//     dispatch(failedMessage("try_later"));
//   }
// };
