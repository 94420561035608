import React from "react";

import "./styles/DownloadApp.css";
//import { Link } from "react-router-dom";
//import { Translate } from "react-redux-i18n";
import appstore from "../assets/download_appstore.png";
import playstore from "../assets/download_playstore.png";
class DonwloadApp extends React.Component {
  render() {
    return (
      <div className="container-download">
        <a
          href="https://apps.apple.com/us/app/godiway/id1488173670"
          target="_blank" rel="noopener noreferrer"
        >
          {" "}
          <img src={appstore} className=" image-app"  alt = "AppStore"/>
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.godiway"
          target="_blank" rel="noopener noreferrer"
        >
          {" "}
          <img src={playstore} className="image-app" alt = "PlayStore"/>
        </a>
      </div>
    );
  }
}

export default DonwloadApp;
