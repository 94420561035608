import React from "react";
//import ReactDOM from 'react-dom';
import Modal from "./Modal";
import { Translate } from "react-redux-i18n";

function ModalGalery(props) {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} title="">
      <div className="modal-body">
        <img
          src={
            "https://godiwayusev.s3.amazonaws.com/uploads/10/cffa85a9d6cb163638a96835138baa5c.jpeg"
          }
          className="img-fluid"
          alt="godiway-img"
        />
      </div>
      <div className="modal-footer">
        <div className="col-12 text-center">
          <button className="btn btn-primary" onClick={props.onClose}>
            <i className="fa fa-close"></i>
            <Translate value="ok" />
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalGalery;
