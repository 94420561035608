export const CONTACT_LIST_FAILED = 'CONTACT_LIST_FAILED';
export const CONTACT_LIST_SUCCESS = 'CONTACT_LIST_SUCCESS';
export const CONTACT_LIST_ATTEMPT = 'CONTACT_LIST_ATTEMPT';


export const SET_CONTACT = 'SET_CONTACT';
export const REMOVE_CONTACT = 'REMOVE_CONTACT';

export const CONTACT_MORELIST_ATTEMPT = 'CONTACT_MORELIST_ATTEMPT';
export const CONTACT_MORELIST_SUCCESS = 'CONTACT_MORELIST_SUCCESS';
export const CONTACT_MORELIST_FAILED = 'CONTACT_MORELIST_FAILED';

export const CONTACT_ONLINE = 'CONTACT_ONLINE';
export const CONTACT_OFFLINE = 'CONTACT_OFFLINE';
export const CONTACTS_ONLINE = 'CONTACTS_ONLINE';

export const UPDATE_BADGET_LIST_CONTACTS = 'UPDATE_BADGET_LIST_CONTACTS';
export const CLEAR_BADGET_LIST_CONTACTS = 'CLEAR_BADGET_LIST_CONTACTS';
export const SET_BADGET_TAB_CONTACTS = 'SET_BADGET_TAB_CONTACTS';

export const LOGOUT = "LOGOUT";



