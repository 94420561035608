import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";

import "./styles/RegisterSuccessfull.css";

import Footer from "../components/Footer";


class PasswordForgetSend extends React.Component {
    componentDidMount() { }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="container-sucessfull">
                        <section className="successfull">
                            <div className="icon">
                                <svg width="100%" height="100%">
                                    <g
                                        stroke-width="5"
                                        stroke="#3EA4EB"
                                        fill="none"
                                        fill-rule="evenodd"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            d="M24.7529297,41.4525637 34.2547154,50.9543494 55,30.2090647"
                                            stroke-dasharray="42.77581787109375"
                                            stroke-dashoffset="0"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <span className="h1">
                                <h1 className="initial">
                                    <Translate value="godiway_wellcome" />
                                </h1>
                            </span>
                            <span className="p">
                                <h3 className="initial">
                                    <Translate value="password_forget_email_send" />
                                </h3>
                                <p className="initial">
                                    <Translate value="godiway_inside" />
                                    <Link to="/login">
                                        {" "}
                                        <Translate value="login" />{" "}
                                    </Link>
                                    <Translate value="confirm_reset_password" />
                                </p>
                            </span>
                        </section>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

export default PasswordForgetSend;