import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
//import api from "../api";
import "./styles/AdList.css";
import * as actions_ad from "../redux/actions/adActions";
import * as actions_adList from "../redux/actions/adListActions";

class AdSliderItem extends React.Component {
  render() {
    var kindcommerce = "",
      condition = "";
    switch (this.props.ad.KindCommerceId) {
      case 1:
        kindcommerce = <Translate value="donation" />;
        break;
      case 2:
        kindcommerce = <Translate value="sale" />;
        break;
      case 3:
        kindcommerce = <Translate value="exchange" />;
        break;
      case 4:
        kindcommerce = <Translate value="rent" />;
        break;
      default:
        kindcommerce = <Translate value="sale" />;
        break;
    }
    switch (this.props.ad.ConditionId) {
      case "NEW":
        condition = <Translate value="ad_new" />;
        break;
      case "USED":
        condition = <Translate value="ad_used" />;
        break;
      case "SERVICE":
        condition = <Translate value="ad_service" />;
        break;
      default:
        condition = <Translate value="ad_new" />;
    }
    return (
      <div className="col-lg-3  mb-4">
        <div className="card  h-100 goder-shadow" style={{ padding: "0px" }}>
          <Link
            to={`/goder/show/${this.props.ad.Id}`}
            onClick={this.props.onClick}
          >
            <img
              className="card-img-top goder-img-list"
              src={this.props.ad.Photo}
              alt="Godiway_img"
            />
          </Link>
          <div className="goder-body">
            <div className="goder-title">
              <Link
                to={`/goder/show/${this.props.ad.Id}`}
                onClick={this.props.onClick}
                className="goder-link-list"
              >
                {this.props.ad.Name}
              </Link>
            </div>
            <div className="goder-row">
              <div className="">
                <small>{this.props.ad.Country.Name}</small>
                <span>&nbsp;</span>
              </div>
              <div className="">
                <i><strong>
                  {" "}

                  <small>{condition}</small>{" "}
                </strong>
                </i>

              </div>
            </div>
            <div className="goder-row">
              <div className="">
                {this.props.ad.Country.Flag !== undefined && (
                  <img
                    src={this.props.ad.Country.Flag}
                    className="profile-flag"
                    alt="Gw"
                  />
                )}
                <span>&nbsp;</span>
              </div>
              <div className="">
                <i> {kindcommerce} </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdSlider extends React.Component {
  state = {
    // adList: [],
    timer: null
  };

  componentDidMount() {
    this.fecthAdMarket();
    const timer = setInterval(() => {
      this.fecthAdMarket();
    }, 15000);
    this.setState({ timer: timer });
  }

  fecthAdMarket = async () => {
    try {
      await this.props.loadAdSlider();
      //this.setState({ adList: this.props.adList });
    } catch (error) {
      console.log(error);
    }
  };
  onClick = item => {
    //  console.log("entra");
    //  console.log(item);
    this.props.SetAd(item);
  };
  componentWillUnmount() {
    clearInterval(this.state.timer);
  }
  render() {
    return (
      <React.Fragment>
        <div style={{ padding: "50px" }}>
          <div className="row">
            {this.props.adList.map(ad => {
              return (
                <AdSliderItem
                  ad={ad}
                  key={ad.Id}
                  onClick={() => {
                    this.onClick(ad);
                  }}
                />
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}


function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    adList: reducer.adSlider.list
  };
}
const mapDispatchToProps = dispatch => ({
  SetAd: item => dispatch(actions_ad.setAd({ item })),
  loadAdSlider: () => dispatch(actions_adList.loadListSlider({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdSlider);
/**
 *  
 * 
 */