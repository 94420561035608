import React from "react";
//import api from "../api";
import PanelLayout from "../components/PanelLayout";
//import ModalOnePay from "../components/ModalOnePay";
import "./styles/MySuscription.css";
//import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import * as actions_suscription from "../redux/actions/suscriptionPlanActions";

class MySuscription extends React.Component {
  state = {
    open: false,
    close: false,
  };
  /*
  constructor(props) {
    super(props);
  }
*/
  componentDidMount() {
    this.fetchSuscriptionPlan();
  }

  fetchSuscriptionPlan = async () => {
    this.setState({ loading: true });
    try {
      await this.props
        .LoadSuscriptionPlan(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickOnePay = (value) => {
    //this.setState({ open: !this.state.open });
    this.props.SetPlanValue(value);
    this.props.history.push("/panel/onepay");
  };

  onClickSuscriptionPlan = (value) => {
    this.props.SetPlanValue(value);
    this.props.history.push("/panel/suscriptionpay");
  };

  onClickRemoveSuscription = async () => {
    try {
      await this.props
        .RemoveSuscription(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //style="background-image: url('https://godiwayusev.s3.amazonaws.com/stores/10/69608d5fdb27afa9881cdf0c3c36bd03.jpg');height:350px;"
  render() {
    if (this.props.suscription.Id !== 1)
      return (
        <React.Fragment>
          <PanelLayout>
            <div className="container-fluid">
              <div className="col-md-12">
                <h3 className="text-center">
                  {" "}
                  <Translate value="suscription_mine" />
                </h3>
                <div className="suscription_item">
                  <div className="col-md-2 col-xs-12 suscription_head_plan">
                    <b>{this.props.suscription.Name}</b>
                  </div>
                  <div className="col-md-6 suscription_body">
                    <ul>
                      <li>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.suscription.Description,
                          }}
                        />
                      </li>
                      <li>
                        {this.props.suscription.Id === 2 ? (
                          <Translate value="expire" />
                        ) : (
                          <Translate value="renewal" />
                        )}
                        : <b>{this.props.suscription.PlanEnd}</b>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4 suscription_footer">
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        this.onClickRemoveSuscription();
                      }}
                    >
                      <b>
                        {" "}
                        <Translate value="suscription_unsubscribe" />
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div
                dangerouslySetInnerHTML={{
                  __html: I18n.t("advice_plan"),
                }}
              />
            </div>
          </PanelLayout>
        </React.Fragment>
      );

    return (
      <React.Fragment>
        <PanelLayout>
          <div className="container-fluid">
            <div className="col-md-12">
              <h3 className="text-center">
                {" "}
                <Translate value="suscription_mine" />
              </h3>

              <div className="suscription_item">
                <div className="col-md-2 suscription_head_month">
                  <b>
                    USD $10 <Translate value="suscription_monthly" />
                  </b>
                </div>
                <div className="col-md-6 suscription_body">
                  <ul>
                    <li>
                      <Translate value="suscription5" />
                    </li>
                    <li>
                      <Translate value="suscription2" />
                    </li>
                    <li>  <Translate value="suscription8" /></li>
                    <li>
                      <Translate value="suscription3" />
                    </li>
                    <li>
                      <Translate value="suscription4" />
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 suscription_footer">
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      this.onClickSuscriptionPlan(10);
                    }}
                  >
                    <b>
                      <Translate value="suscription_subscribe" />
                    </b>
                  </button>
                </div>
              </div>


              <div className="suscription_item">
                <div className="col-md-2 col-xs-12 suscription_head_month">
                  <b>
                    USD $100 <Translate value="suscription_yearly" />
                  </b>
                </div>
                <div className="col-md-6 suscription_body">
                  <ul>
                    <li>
                      <Translate value="suscription1" />
                    </li>
                    <li>
                      <Translate value="suscription2" />
                    </li>
                    <li>  <Translate value="suscription8" /></li>
                    <li>
                      <Translate value="suscription3" />
                    </li>
                    <li>
                      <Translate value="suscription4" />
                    </li>
                  </ul>
                </div>

                <div className="col-md-4 suscription_footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.onClickSuscriptionPlan(100);
                    }}
                  >
                    <b>
                      {" "}
                      <Translate value="suscription_subscribe" />
                    </b>
                  </button>
                </div>
              </div>





              <div className="suscription_item">
                <div className="col-md-2 suscription_head_year">
                  <b>
                    USD $20 <Translate value="suscription_monthly" />
                  </b>
                </div>
                <div className="col-md-6 suscription_body">
                  <ul>
                    <li>
                      <Translate value="suscription5" />
                    </li>
                    <li>
                      <Translate value="suscription7" />
                    </li>
                    <li>  <Translate value="suscription8" /></li>
                    <li>
                      <Translate value="suscription3" />
                    </li>
                    <li>
                      <Translate value="suscription4" />
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 suscription_footer">
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      this.onClickSuscriptionPlan(20);
                    }}
                  >
                    <b>
                      <Translate value="suscription_subscribe" />
                    </b>
                  </button>
                </div>
              </div>


              <div className="suscription_item">
                <div className="col-md-2 suscription_head_year">
                  <b>
                    USD $200 <Translate value="suscription_yearly" />
                  </b>
                </div>
                <div className="col-md-6 suscription_body">
                  <ul>
                    <li>
                      <Translate value="suscription1" />
                    </li>
                    <li>
                      <Translate value="suscription7" />
                    </li>
                    <li>  <Translate value="suscription8" /></li>
                    <li>
                      <Translate value="suscription3" />
                    </li>
                    <li>
                      <Translate value="suscription4" />
                    </li>
                  </ul>
                </div>


                <div className="col-md-4 suscription_footer">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.onClickSuscriptionPlan(200);
                    }}
                  >
                    <b>
                      <Translate value="suscription_subscribe" />
                    </b>
                  </button>
                </div>
              </div>



              <div className="suscription_item">
                <div className="col-md-2 suscription_head_year">
                  <b>
                    USD $15 <Translate value="one_pay" />
                  </b>
                </div>
                <div className="col-md-6 suscription_body">
                  <ul>
                    <li>
                      <Translate value="one_pay1" />
                    </li>
                    <li>
                      <Translate value="one_pay2" />
                    </li>
                    <li>
                      <Translate value="one_pay3" />
                    </li>
                    <li>
                      <Translate value="one_pay4" />
                    </li>
                    <li>
                      <Translate value="one_pay5" />
                    </li>
                  </ul>
                </div>
                <div className="col-md-4 suscription_footer">
                  <button
                    className="btn btn-info"
                    onClick={() => {
                      this.onClickOnePay(15);
                    }}
                  >
                    <b>
                      {" "}
                      <Translate value="one_pay_pay" />
                    </b>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    suscription: reducer.suscriptionPlan.item,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SetPlanValue: (value) =>
    dispatch(actions_suscription.setPlanValue({ value })),
  LoadSuscriptionPlan: (user_id, token) =>
    dispatch(actions_suscription.loadItem({ user_id, token })),
  RemoveSuscription: (user_id, token) =>
    dispatch(actions_suscription.deleteItem({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySuscription);
