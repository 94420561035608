import axios from "axios";
import { I18n } from "react-redux-i18n";

import {
  AD_CATEGORY_ATTEMPT,
  AD_CATEGORY_SUCCESS,
  AD_CATEGORY_FAILED,
} from "../types/adCategoryTypes";
import { config } from "../../config";
const url_api = config.api.godiway;
export function loading() {
  return {
    type: AD_CATEGORY_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: AD_CATEGORY_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: AD_CATEGORY_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "category", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
