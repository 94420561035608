import React from "react";

import NavbarIn from "./NavbarIn";
import SessionTimer from "./SessionTimer";

function LayoutIn(props) {
  return (
    <React.Fragment>
      <SessionTimer />
      <NavbarIn />
      {props.children}
    </React.Fragment>
  );
}


export default (LayoutIn);
