import React from "react";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router";

class SessionTimer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: 1000 * 60 * 8,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  _onAction(e) {
    // console.log("user did something", e);
    this.setState({ isTimedOut: false });
  }

  _onActive(e) {
    // console.log("user is active", e);
    this.setState({ isTimedOut: false });
  }

  _onIdle(e) {
    // console.log("user is idle", e);
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.props.history.push("/logout");
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  }
  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={(e) => {
            this.onIdle(e);
          }}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SessionTimer);
