import {
  AD_SELLER_REPUTATION_ATTEMPT,
  AD_SELLER_REPUTATION_SUCCESS,
  AD_SELLER_REPUTATION_FAILED
} from "../types/adSellerReputationTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: ""
};
function adSellerReputation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_SELLER_REPUTATION_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_SELLER_REPUTATION_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: ""
      };

    case AD_SELLER_REPUTATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {}
      };

    default:
      return state;
  }
}
export default adSellerReputation;
