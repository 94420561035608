import React from "react";
import "./styles/TransactionStart.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import * as actions from "../redux/actions/wishActions";
import { withRouter } from "react-router";
import Footer from "../components/Footer";
import ModalWhatSappQr from "../components/ModalWhatSappQr";
import {
    FaComments,
    //FaComment 
} from "react-icons/fa";
import { Translate } from "react-redux-i18n";

class TransactionBid extends React.Component {
    state = {
        open_modal: false,
        number: "",
    };
    componentDidMount() { }

    seeModal = () => {
        const cell =
            this.props.ad.UserStore.CountryPhoneId +
            this.props.ad.UserStore.Phone.substring(
                this.props.ad.UserStore.Phone.length - 9,
                this.props.ad.UserStore.Phone.length
            );

        this.setState({ open_modal: true, number: cell });
    };

    onClose = () => {
        this.setState({ open_modal: false });
    };
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="container-transaction-start">
                        <section className="successfull">
                            <div className="icon">
                                <svg width="100%" height="100%">
                                    <g
                                        strokeWidth="5"
                                        stroke="#3EA4EB"
                                        fill="none"
                                        fillRule="evenodd"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path
                                            d="M24.7529297,41.4525637 34.2547154,50.9543494 55,30.2090647"
                                            strokeDasharray="42.77581787109375"
                                            strokeDashoffset="0"
                                        ></path>
                                    </g>
                                </svg>
                            </div>
                            <span className="h1">
                                <h1 className="initial">
                                    <Translate value="transaction_thanks" />
                                </h1>
                            </span>
                            <span className="p">
                                <h3 className="initial">
                                    <Translate value="transaction_bid_info" />
                                </h3>
                            </span>
                            <Link className="btn btn-primary" to="/home">
                                <Translate value="ok" />
                            </Link>
                        </section>

                    </div>
                </div>
                <ModalWhatSappQr
                    isOpen={this.state.open_modal}
                    onClose={() => {
                        this.onClose();
                    }}
                    item={this.state.number}
                />
                <Footer />
            </React.Fragment>
        );
    }
}
/**                          <div className="card-body row">
 <section className="card medium">

                            <div className="card-footer row">
                                <div className="col-12 text-center">
                                   
                                </div>
                            </div>
                        </section>
                            </div> */
function mapStateToProps(reducer) {
    //console.log(reducer);
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,

        ad: reducer.ad.item,
    };
}
const mapDispatchToProps = (dispatch) => ({
    // UpdateWishAd: (id, user_id, token) =>
    //  dispatch(actions.updateWishAd({ id, user_id, token }))
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(TransactionBid)
);
