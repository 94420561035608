import React from "react";
//import { Link } from 'react-router-dom';
import "./styles/MarketStoreBanner.css";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreActions";
import { Translate, I18n } from "react-redux-i18n";

class MarketStoreBanner extends React.Component {
  state = {
    timer: null,
  };
  componentDidMount() {
    // this.fetchMarketStore();
    // const timer = setInterval(() => {
    //   this.fetchMarketStore();
    // }, 15000);
    // this.setState({ timer: timer });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }
  fetchMarketStore = async () => {
    try {
      //const data = await api.MarketStore.list();
      //const data = await response.json();
      //[].concat(array1, array2),
      await this.props.LoadItemSlider();
      // this.setState({ marketstore: data });
    } catch (error) {
      console.log(error);
    }
  };
  //            className="img-responsive"
  //
  render() {
    return (
      <React.Fragment>

        <div className="" style={{ display: "flex", background: "white", }}>

          <div className="img_market_banner" style={{

            // paddingInline: "150px",
            // paddingTop: "70px",
            // paddingBottom: "70px"

          }} >
            <img alt="" src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/marketstore_banner/marketstore_banner.png"
              style={{ height: "450px" }} />
          </div>

          <div className="container_market_banner" style={{

          }}>
            <div className="" style={{}}>
              <h1>MarketStore</h1>
              <span style={{ fontSize: "larger" }}><Translate value="marketstore_login_detail" /></span>

            </div>
            <div className="" style={{ width: "100%" }}>
              <a class="btn btnBig btn-primary mt10" href="/login" style={{ marginTop: "40px" }}><Translate value="marketstore_login" /></a>
            </div>
          </div>

        </div>
      </React.Fragment >
    );
  }
}
function mapStateToProps(reducers) {
  // console.log(reducers);
  return {
    // user_id: reducers.session.auth.id,
    //  token: reducers.session.auth.access_token,

    error: reducers.marketstore.error,
    loading: reducers.marketstore.loading,
    marketstore: reducers.marketstore.item

  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadItemSlider: () =>
    dispatch(actions.loadItemSlider({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStoreBanner);

/**style="background-color:transparent;" style="color:#000000;" style="color:#000000;"


    
           
   */
