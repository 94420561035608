import React from "react";
//import ReactDOM from 'react-dom';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

function StepFinish(props) {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 text-center">
          <text
            className=""
            style={{
              fontSize: "18px",
              fontWeight: "bold ",
              color: "gray",
            }}
          ></text>
        </div>
      </div>
      <br />
      <br />

      <br />
      <div className="row justify-content-center">
        <div className="col-3">
          <img src="https://i.imgur.com/GwStPmg.png" className="fit-image" />
        </div>{" "}
        <br />
        <br />
        <div className="row justify-content-center">
          <div className="col-7 text-center">
            <h5 className="purple-text text-center">
              <Translate value="step_congratulations" />
            </h5>
          </div>
        </div>
        <Link className="" to="/home">
          {"  "} {<Translate value="ads" />}
        </Link>
      </div>
    </React.Fragment>
  );
}
/** <h2 className="purple-text text-center">
        <strong>
          {" "}
          <Translate value="Congratulations" />!
        </strong>
      </h2>{" "} */
export default StepFinish;
