import {
  TRANSACTION_REJECTED_ATTEMPT,
  TRANSACTION_REJECTED_SUCCESS,
  TRANSACTION_REJECTED_FAILED
} from "../types/transactionRejectedTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  page_size: 0,
  page_count: 0
};
function transactionRejected(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRANSACTION_REJECTED_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case TRANSACTION_REJECTED_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case TRANSACTION_REJECTED_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    default:
      return state;
  }
}
export default transactionRejected;
