import {
  //////////////////
  AD_UPDATE_WISH,
  /////////////////
  AD_ITEM_ATTEMPT,
  AD_ITEM_SUCCESS,
  AD_ITEM_FAILED,
  /////////////////
  ADLIKE_UPDATE_SUCCESS,
  ADLIKE_UPDATE_FAILED
} from "../types/adTypes";

const INITIAL_STATE = {
  loading: true,
  list: [],
  error: '',

  offset: 0,

  success: '',
  item: {}
};
function ad(state = INITIAL_STATE, action) {
  switch (action.type) {

    case AD_ITEM_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: ""
      };

    case AD_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {}
      };
    ///////////////////////////////////////////
    case ADLIKE_UPDATE_SUCCESS: {
      const newList = state.list.map((item) => {
        if (item.Id === action.item.Id)
          return action.item;
        return item;
      });

      return {
        ...state,
        item: action.item,
        list: newList,
        error: '',
      };
    }
    case ADLIKE_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        success: '',
      };
    ///////////////////////////////////////////
    case AD_UPDATE_WISH:
      return {
        ...state,
        item: {
          ...state.item,
          MyInterest: !state.item.MyInterest
        }
      };
    ///////////////////////////////////////////
    default:
      return state;
  }
}
export default ad;
