import React from "react";

import "./styles/Home.css";
import ProfileLayout from "../components/ProfileLayout";
import { Link } from "react-router-dom";
//import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "../redux/actions/sessionActions";
import * as actions_sesion from "../redux/actions/sessionActions";
import { FaKey } from "react-icons/fa";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { I18n, Translate } from "react-redux-i18n";

class ProfilePassword extends React.Component {
  state = {
    form: {},
    disabled: true
  };

  handleChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  onClickCheckPassword = async () => {
    await this.props
      .CheckPassword(
        this.state.form.password,
        this.props.user_id,
        this.props.token
      )
      .then(() => {
        if (this.props.error_update) {
          this.props.Logout();
          this.props.history.push("/login");
        } else {
          this.setState({ disabled: !this.state.disabled });
        }
      });
  };

  onClickUpdatePassword = async () => {
    if (
      this.state.form.password_confirmation1 === "" ||
      this.state.form.password_confirmation2 === ""
    ) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_password_confirm"),
        function () { }
      );
      return;
    }
    if (
      this.state.form.password_confirmation1 !==
      this.state.form.password_confirmation2
    ) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_password_similar"),
        function () { }
      );
      return;
    }
    await this.props
      .ResetPassword(
        this.state.form.password,
        this.state.form.password_confirmation2,
        this.props.user_id,
        this.props.token
      )
      .then(() => {
        if (this.props.error_update) {
          alertify.alert(this.props.error_update);
        } else {
          alertify.success("password actualizado");
          this.setState({ disabled: true, form: {} });
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <ProfileLayout>
          <div className="">
            <div className="col-12 ">
              <div className="card ">
                <div className="col-12">
                  <dl className="text-center">
                    <h3>
                      <b>
                        {" "}
                        <Translate value="password_change" />{" "}
                      </b>
                    </h3>
                  </dl>
                  <hr className="dividerGray" />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="col-md-12 ">
                      <div className="row">
                        <div className="col-12 text-left">
                          <Translate value="password_current" /> :
                        </div>
                        <div className="col-md-6">
                          <div className="input-group">
                            <span className="input-group-addon">
                              <FaKey />{" "}
                            </span>
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              maxLength="100"
                              value={this.state.form.password}
                              autoComplete="off"
                              placeholder={I18n.t("profile_password")}
                              name="password"
                              type="password"
                              disabled={!this.state.disabled}
                            />
                          </div>
                        </div>
                        <button
                          className="btn btn-success mt20"
                          onClick={() => {
                            this.onClickCheckPassword();
                          }}
                        >
                          <i
                            className="fa fa-pencil-square-o"
                            aria-hidden="true"
                          ></i>{" "}
                          <b> Check</b>
                        </button>
                      </div>
                      <br />
                      <div className="form-group row ">
                        <div className="col-md-6 text-left">
                          <Translate value="password_new" /> :
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="form-group col-6  ">
                          <label htmlFor="password" className="control-label">
                            {I18n.t("profile_password")}
                          </label>
                          <div className="controls">
                            <div className="input-group">
                              <span
                                className="input-group-addon"
                                id="basic-addon1"
                              >
                                <FaKey />{" "}
                              </span>
                              <input
                                className="form-control"
                                onChange={this.handleChange}
                                maxLength="100"
                                value={this.state.form.password_confirmation1}
                                autoComplete="off"
                                placeholder={I18n.t("profile_password")}
                                disabled={this.state.disabled}
                                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
                                title="Debe contener al menos un número, una letra mayúscula y minúscula, y mínimo 8 o más caracteres."
                                required={true}
                                name="password_confirmation1"
                                type="password"
                              />
                            </div>
                            <span className="text-danger"></span>
                          </div>
                        </div>
                        <div className="form-group col-6 ">
                          <label
                            htmlFor="password_confirmation2"
                            className="control-label"
                          >
                            <Translate value="password_confirm" />
                          </label>
                          <div className="controls">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <FaKey />{" "}
                              </span>
                              <input
                                className="form-control"
                                onChange={this.handleChange}
                                maxLength="100"
                                value={this.state.form.password_confirmation2}
                                autoComplete="off"
                                placeholder={I18n.t("profile_password")}
                                disabled={this.state.disabled}
                                required={true}
                                name="password_confirmation2"
                                type="password"
                              />
                            </div>
                            <span className="text-danger"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <small className="text-center">
                    <Translate value="password_tip" />
                  </small>
                </div>
                <div className="row">
                  <div className="col-6 text-center">
                    <Link to="/" className="btn btn-primary mt20">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                      <b>
                        {" "}
                        <Translate value="Home" />{" "}
                      </b>
                    </Link>
                  </div>
                  <div className="col-6 text-center">
                    <button
                      className="btn btn-success mt20"
                      onClick={() => {
                        this.onClickUpdatePassword();
                      }}
                      disabled={this.state.disabled}
                    >
                      <i
                        className="fa fa-pencil-square-o"
                        aria-hidden="true"
                      ></i>{" "}
                      <b>
                        {" "}
                        <Translate value="save" />
                      </b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ProfileLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    profile: reducer.session.auth,
    error_update: reducer.session.error_update
  };
}
const mapDispatchToProps = dispatch => ({
  ResetPassword: (password_old, password_new, user_id, token) =>
    dispatch(
      actions.resetPassword({ password_old, password_new, user_id, token })
    ),
  CheckPassword: (password, user_id, token) =>
    dispatch(actions.checkPassword({ password, user_id, token })),
  Logout: () => dispatch(actions_sesion.logout({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
