import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/PasswordForgetForm.css";
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

class PasswordForgetForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
  };

  render() {
    //console.log(this.props.touched);
    return (
      <div className="container-recovery-password">
        <form className="form-signin" onSubmit={this.handleSubmit}>
          <h3 className="title-recovery">
            {" "}
            <Translate value="password_forget_title" />
          </h3>
          <p className="text-center">
            <Translate value="password_forget_tip" />.
          </p>
          <div className="form-group">
            <input
              onChange={this.props.onChange}
              type="email"
              name="email"
              placeholder="Email"
              className={
                this.props.enable && this.props.touched
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              required
              //autoFocus
              autoComplete={"off"}
              onBlur={this.props.handleBlur}
              value={this.props.form.email}
            />
          </div>

          <button
            onClick={this.props.onClick}
            className="btn btn-primary"
            disabled={this.props.enable}
          >
            <Translate value="password_forget_send" />
          </button>

          <div className="invalid-feedback">{this.props.error}</div>
          <div className="addition-recovery">
            <p>
              <small>
                <Translate value="password_forget_login" />

                <Link to="/login">
                  {" "}
                  <Translate value="login" />
                </Link>
              </small>
            </p>
            <p>
              <small>
                <Translate value="password_forget_register" />

                <Link to="/register">
                  {" "}
                  <Translate value="register" />
                </Link>
              </small>
            </p>
            <small>
              <Translate value="password_forget_email" />

              <a
                href="mailto:team@godiway.com?subject=Godiwayer&body=Equipo%20GodiWay,"
                target="_blank"
              >
                {" "}
                team@godiway.com
              </a>
            </small>
          </div>
        </form>
      </div>
    );
  }
}

export default PasswordForgetForm;
