import React from "react";
import { Translate } from "react-redux-i18n";
//import "./styles/Screen.css";
import { Link } from "react-router-dom";

class AdMenu extends React.Component {
  render() {
    return (
      <div className="card">
        <nav className="nav flex-column">
          <Link className="nav-link disabled" to="#">
            <small>
              <Translate value="ads" />{" "}
            </small>
          </Link>
          <Link to="/home" className="nav-link">
            <li>
              <Translate value="ads_group" />
            </li>
          </Link>
          <Link to="/wall" className="nav-link">
            {" "}
            <li>
              <Translate value="ads_recently" />
            </li>
          </Link>
        </nav>
      </div>
    );
  }
}

export default AdMenu;
