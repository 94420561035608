import {
  SUSCRIPTION_ATTEMPT,
  SUSCRIPTION_SUCCESS,
  SUSCRIPTION_FAILED,
  ////////////////
  PLAN_UPDATE_VALUE,
  PLAN_UPDATE_NEXT
} from "../types/suscriptionPlanTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: "",
  plan_value: 0,
  plan_next: 1,
};
function suscriptionPlan(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SUSCRIPTION_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case SUSCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: "",
      };

    case SUSCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {},
      };
    ////////////////////////////
    case PLAN_UPDATE_VALUE:
      return {
        ...state,
        plan_value: action.value,
      };
    case PLAN_UPDATE_NEXT:
      return {
        ...state,
        plan_next: action.value,
      };

    default:
      return state;
  }
}
export default suscriptionPlan;
