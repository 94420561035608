import React from "react";
import idea from "../assets/idea.svg";
import { Translate, I18n } from "react-redux-i18n";

class AdTip extends React.Component {
  render() {
    return (
      <div className="col text-center">
        <img className="" src={idea} alt="Crea tu perfil profesional" />
        <h3>
          {" "}
          <Translate value="ad_tip" />
        </h3>
        <hr className="dividerGray" />
        <div className="col text-left">
          <p>
            1) <Translate value="ad_tip1" />.
          </p>

          <p>
            2) <Translate value="ad_tip2" />.
          </p>

          <p>
            3) <Translate value="ad_tip3" />.
          </p>

          <p>
            4)
            <Translate value="ad_tip4" /> .
          </p>

          <p>
            5) <Translate value="ad_tip5" /> .
          </p>

          <p>
            6) <Translate value="ad_tip6" />.
          </p>

          <p>
            7) <Translate value="ad_tip7" />.
          </p>
          <p>
            8) <Translate value="ad_tip8" />.
          </p>
        </div>
      </div>
    );
  }
}

export default AdTip;
