import React from "react";
import "./styles/KindCommerceHome.css";
//import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
/*
import sale from "../assets/138213.svg";
import rent from "../assets/998220.svg";
import exchange from "../assets/994393.svg";
import donation from "../assets/940817.svg";
*/

import sale from "../assets/ventas.png";
import rent from "../assets/rentas.png";
import exchange from "../assets/intercambio.png";
import donation from "../assets/donacion.png";

class KindCommerceHome extends React.Component {
  render() {
    return (
      <div className="container-kindcommerce">
        <div className="" align="center">
          <img src={sale} className="img-responsive" width="100" height="100" alt="sale"/>
          <h4>
            <Translate value="sale" />
          </h4>
        </div>

        <div className="" align="center">
          <img src={rent} className="img-responsive" width="100" height="100" alt = "rent"/>
          <h4>
            <Translate value="rent" />
          </h4>
        </div>

        <div className="" align="center">
          <img
            src={exchange}
            className="img-responsive"
            width="100"
            height="100"
            alt = "exchange"
          />
          <h4>
            <Translate value="exchange" />
          </h4>
        </div>

        <div className="" align="center">
          <img
            src={donation}
            className="img-responsive"
            width="100"
            height="100"
            alt = "donation"
          />
          <h4>
            <Translate value="donation" />
          </h4>
        </div>
      </div>
    );
  }
}

export default KindCommerceHome;
