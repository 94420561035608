import React from "react";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/sessionActions";
import PasswordForgetForm from "../components/PasswordForgetForm";


class PasswordForget extends React.Component {
  state = {
    form: {
      email: "",
    },
    enable: true,
    touched: false,
  };

  onClickForgetPassword = async (e) => {
    e.preventDefault();
    try {
      await this.props.ForgetPassword(this.state.form.email).then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        } else {
          //alertify.success(I18n.t(this.props.success));
          this.props.history.replace("/password_forget_send");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({
      form: {
        email: e.target.value,
      },
    });
    if (this.state.touched || e.target.value.length > 0) {
      if (this.validateEmail(e.target.value)) this.setState({ enable: false });
      else this.setState({ enable: true });
    }
    // console.log(this.state);
  };

  validateEmail = (email) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //console.log(reg.test(email));
    return reg.test(email) ? true : false;
  };

  handleBlur = () => {
    this.setState({
      ...this.state,
      touched: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <PasswordForgetForm
            onChange={(e) => {
              this.handleChange(e);
            }}
            form={this.state.form.email}
            onClick={(e) => {
              this.onClickForgetPassword(e);
            }}
            enable={this.state.enable}
            touched={this.state.touched}
            handleBlur={this.handleBlur}
            error_update={this.props.error_update}
            success_update={this.props.success_update}
          />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    error: reducer.session.error,
    success: reducer.session.success
  };
}

const mapDispatchToProps = (dispatch) => ({
  ForgetPassword: (email) => dispatch(actions.forgetPassword({ email })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PasswordForget);
