import React from "react";
import "./styles/NotFound.css";
import Ops from "../assets/ops.png";
import Footer from "../components/Footer";
import { Translate } from "react-redux-i18n";

const NotFound = () => (
  <React.Fragment>
    <div className="notfound_container">
      <h1>Ops</h1>
      <img className="img-fluid" src={Ops} alt="Ops" />
      <h1>
        <Translate value="not_found" />
      </h1>
      <h4>404: Not Found</h4>
    </div>
    <Footer />
  </React.Fragment>
);

export default NotFound;
