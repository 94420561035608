import {
  AD_CATEGORY_ATTEMPT,
  AD_CATEGORY_SUCCESS,
  AD_CATEGORY_FAILED
} from "../types/adCategoryTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  success: "",
  /////////////////
  page_index: 0,
  page_size: 0,
  page_count: 0,
};

function adCategory(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_CATEGORY_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case AD_CATEGORY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    default:
      return state;
  }
}
export default adCategory;
