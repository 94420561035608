import React from "react";
import AdPanelList from "../components/AdPanelList";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import { connect } from "react-redux";
import * as actions_active from "../redux/actions/adRentsActions";
import * as actions_inactive from "../redux/actions/adRentsInactiveActions";
import Tabs from "../components/Tabs";
import "./styles/AdPublish.css";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class AdRents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchAdRentsList();
  }

  fetchAdRentsList = async () => {
    try {
      await this.props
        .LoadPublish(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });

      await this.props
        .LoadPublishI(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error_inactive) {
            alert(this.props.error_inactive);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadPublishMore(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  onClickLoadMorei = async () => {
    try {
      await this.props
        .LoadPublishMorei(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <div className="container-btn-title">
            <h3 className="panel-title-left">
              <Translate value="ad_rent" />
            </h3>
            <div className="panel-button-right">
              <Link className="btn btn-primary" to="/goder/new/rent">
                <Translate value="new" />
              </Link>
            </div>
          </div>
          <Tabs>
            <div label={I18n.t("ad_active")} className="col-12">
              {this.props.loading ? (
                <PageLoading />
              ) : (
                <AdPanelList adPanelList={this.props.adListActive}
                  loadingMore={this.props.loadingMore}
                  pageCount={this.props.pageCount}
                  pageSize={this.props.pageSize}
                  onClickLoadMore={() => {
                    this.onClickLoadMore();
                  }} />
              )}
            </div>
            <div label={I18n.t("ad_inactive")} className="col-12">
              {this.props.loading_inactive ? (
                <PageLoading />
              ) : (
                <AdPanelList adPanelList={this.props.adListInactive}
                  loadingMore={this.props.loadingMorei}
                  pageCount={this.props.pageCounti}
                  pageSize={this.props.pageSizei}
                  onClickLoadMore={() => {
                    this.onClickLoadMorei();
                  }}
                />
              )}
            </div>
          </Tabs>
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    adListActive: reducer.adRents.list,
    error: reducer.adRents.error,
    loading: reducer.adRents.loading,

    loadingMore: reducer.adRents.loadingMore,
    pageCount: reducer.adRents.page_count,
    pageSize: reducer.adRents.page_size,

    adListInactive: reducer.adRentsInactive.list,
    error_inactive: reducer.adRentsInactive.error,
    loading_inactive: reducer.adRentsInactive.loading,

    loadingMorei: reducer.adRentsInactive.loadingMore,
    pageCounti: reducer.adRentsInactive.page_count,
    pageSizei: reducer.adRentsInactive.page_size,
  };
}
const mapDispatchToProps = dispatch => ({
  LoadPublish: (user_id, token) =>
    dispatch(actions_active.loadList({ user_id, token })),
  LoadPublishMore: (page_count, user_id, token) =>
    dispatch(actions_active.loadListScroll({ page_count, user_id, token })),
  LoadPublishI: (user_id, token) =>
    dispatch(actions_inactive.loadList({ user_id, token })),
  LoadPublishMorei: (page_count, user_id, token) =>
    dispatch(actions_inactive.loadListScroll({ page_count, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdRents);
