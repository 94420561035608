import React from "react";
import { Link } from "react-router-dom";
import { FaCartArrowDown } from "react-icons/fa";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

import './styles/AdList.css';
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions_wish from "../redux/actions/wishActions";
import * as actions_ad from "../redux/actions/adActions";

import MiniLoader from "../components/MiniLoader";

class AdWishItem extends React.Component {
  render() {
    var KindCommerce = "";
    switch (this.props.ad.KindCommerceId) {
      case 1:
        KindCommerce = <Translate value="donation" />;
        break;
      case 2:
        KindCommerce = <Translate value="sale" />;
        break;
      case 3:
        KindCommerce = <Translate value="exchange" />;
        break;
      case 4:
        KindCommerce = <Translate value="rent" />;
        break;

      default: break;
    }
    return (
      <div className="col-lg-4 ">
        <div className="card h-100  goder-shadow " style={{ padding: "0px" }}>
          <Link
            to={`/goder/show/${this.props.ad.Id}`}
            onClick={this.props.onClickAd}
          >
            <img
              className="card-img-top goder-img-list-panel"
              src={this.props.ad.Photo}
              alt="Gw"
            />
          </Link>
          <div className="goder-body">
            <div className="goder-title text-center">
              <Link
                to={`/goder/show/${this.props.ad.Id}`}
                onClick={this.props.onClickAd}
                className="goder-link-list"
              >
                {this.props.ad.Name}
              </Link>
            </div>
            <div className="row">
              <div className="col text-left">
                <small>{KindCommerce}</small>
                <span>&nbsp;</span>
              </div>
              <div className="col text-right">
                <i>
                  <strong> {this.props.ad.Price}
                    <small>{this.props.ad.Currency}</small>
                  </strong>
                </i>
              </div>

            </div>
            <div className="col text-center">
              <button
                data-toggle="tooltip"
                title="Wish/deseos"
                onClick={this.props.onClick}
                className="btn btn-danger"
              >
                <FaCartArrowDown />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class WishList extends React.Component {

  onClick = async (ad) => {
    await this.props
      .UpdateWish(this.props.token, {
        goder_id: ad.Id,
        id: this.props.user_id,
      })
      .then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        }
      });
  };

  onClickAd = (item) => {
    this.props.SetAd(item);
  };

  onClickLoadMore = () => {

  }

  render() {
    if (this.props.page_size === 0)
      return (
        <h3>
          <Translate value="wish_not_list" />
        </h3>
      );

    return (
      <React.Fragment>
        <div className="row">
          {this.props.wishList.map((ad) => {
            return (
              <AdWishItem
                ad={ad}
                key={ad.Id}
                onClick={() => {
                  this.onClick(ad);
                }}
                onClickAd={() => {
                  this.onClickAd(ad);
                }}
              />
            );
          })}
        </div>
        {
          this.props.page_size >= 10 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center" onClick={this.onClickLoadingMore}>
                  <button className="btn btn-secondary" >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    error: reducer.wish.error,
    wishList: reducer.wish.list,
    page_size: reducer.wish.page_size,
    page_count: reducer.wish.page_count,
    loadingMore: reducer.wish.loadingMore
  };
}
const mapDispatchToProps = (dispatch) => ({
  UpdateWish: (token, params) =>
    dispatch(actions_wish.updateItem({ token, params })),
  SetAd: (item) => dispatch(actions_ad.setAd({ item })),
});
export default connect(mapStateToProps, mapDispatchToProps)(WishList);
