import {
  MEDIA_LIST_ATTEMPT,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAILED,
  //////////////
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAILED,
  //////////////
  MEDIA_NEW_SUCCESS,
  MEDIA_NEW_LOADING,
  MEDIA_NEW_FAILED,
  //////////////
  MEDIA_RESET_LIST
} from "../types/mediaTypes";

const INITIAL_STATE = {
  list: [],
  loading: false,
  error: "",

  progress: 0,
  success: "",
  loadingFile: false,
  error_update: "",
};

function media(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MEDIA_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case MEDIA_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: "",
      };

    case MEDIA_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    /////////////////////////////
    case MEDIA_DELETE_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.Id !== action.item.Id; // return all the items not matching the action.id
      });
      return {
        ...state,
        list: newState,
        // list: action.data.list,
        error_update: "",
      };
    }

    case MEDIA_DELETE_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    /////////////////////////////////
    case MEDIA_NEW_LOADING:
      return {
        ...state,
        loadingFile: true,
        progress: action.progress,
        success: "",
        error: "",
      };
    case MEDIA_NEW_SUCCESS: {
      // if (action.item.Type === "image") {
      return {
        ...state,
        loadingFile: false,
        progress: 0,
        error: "",
        list: [action.item, ...state.list],
        //list: action.data.list,
      };
      //}
      // return state;
    }

    case MEDIA_NEW_FAILED:
      return {
        ...state,
        loadingFile: false,
        progress: 0,
        error: action.error,
        success: "",
      };
    ///////////////////////////////
    case MEDIA_RESET_LIST:
      return INITIAL_STATE;


    default:
      return state;
  }
}
export default media;
