import axios from "axios";
import {
  PUBLISH_LIST_ATTEMPT,
  PUBLISH_LIST_SUCCESS,
  PUBLISH_LIST_FAILED,
  //////////////////////////
  PUBLISH_NEW_SUCCESS,
  PUBLISH_NEW_FAILED
} from "../types/publishTypes";
import { config } from "../../config";
const url_api = config.api.godiway;


export function loading() {
  return {
    type: PUBLISH_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: PUBLISH_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: PUBLISH_LIST_FAILED,
    error: error
  };
}

export function successAdNew(item) {
  return {
    type: PUBLISH_NEW_SUCCESS,
    item: item
  };
}

export function failedAdNew(error) {
  return {
    type: PUBLISH_NEW_FAILED,
    error: error
  };
}

export const newItem = (data: Object) => async dispatch => {

  await axios.post(url_api + "goder", data.params, {
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //  console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAdNew(response.data.body));
        else dispatch(failedAdNew(response.data.body));
      } else {
        dispatch(failedAdNew("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedAdNew("try_later"));
      } else {
        dispatch(failedAdNew("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async dispatch => {

  // await axios.put(url_api + "goder", data.params, {
  //   headers: { Authorization: `Bearer ${data.token}` },
  // })

  await axios
    .put(url_api + "goder", data.params, { headers: { Authorization: `Bearer ${data.token}` }, })
    .then(function (response) {
      //  console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAdNew(response.data.body));
        else dispatch(failedAdNew(response.data.body));
      } else {
        dispatch(failedAdNew("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedAdNew("try_later"));
      } else {
        dispatch(failedAdNew("try_later"));
      }
      return;
    });
};
