import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import {
  // FaPaperPlane,
  FaComments,
  //  FaComment,
  FaRegThumbsUp,
  FaRegThumbsDown,
} from "react-icons/fa";

import "./styles/TransactionList.css";

import ModalGodiwayer from "../components/ModalGodiwayer";
import ModalCalificationRequest from "../components/ModalCalificationRequest";
import * as actions from "../redux/actions/contactsActions";
import MiniLoader from "./MiniLoader";


class TransactionItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <table className="table table-striped">
          <tbody>
            <tr>
              <td>
                <div className="row">
                  <div className="col-4">
                    <Link to={`/goder/show/${this.props.item.Ad.Id}`}>
                      <img
                        className="card-img-top transaction-img"
                        src={this.props.item.Ad.Photo}
                        alt="Goder"
                      />
                    </Link>
                  </div>
                  <div className="col-6">
                    <h5 className="row title">
                      <Link to={`/goder/show/${this.props.item.Ad.Id}`}>
                        {this.props.item.Ad.Name}
                      </Link>
                    </h5>

                    <div className="row">
                      <Translate value="transaction_date" />:
                      {this.props.item.CreatedAt}
                    </div>
                    <div className="row">
                      Godiwayer:
                      <Link to="#" onClick={this.props.onClick}>
                        {this.props.item.User.Name}
                      </Link>
                    </div>
                    <div className="row">
                      <Translate value="transaction_quantity" />:
                      {this.props.item.Quantity}
                    </div>
                  </div>

                  <div className="col-2">
                    <div className="row">{this.props.item.Status}</div>
                    {this.props.item.Status !== "FINISHED" &&
                      this.props.item.CalificationUser == null && (
                        <React.Fragment>
                          <button
                            className="btn btn-light"
                            onClick={this.props.onClickUp}
                          >
                            <FaRegThumbsUp />
                          </button>
                          <button
                            className="btn btn-light"
                            onClick={this.props.onClickDown}
                          >
                            <FaRegThumbsDown />
                          </button>
                        </React.Fragment>
                      )}
                    <Link
                      className="btn btn-light"
                      to={`/chat/${this.props.item.User.ConversationId}`}
                      onClick={this.props.onClickChat}
                    >
                      <FaComments />
                    </Link>
                    <div className="" style={{
                      position: "absolute",
                      bottom: "1px",
                      right: "10px",
                      color: "darkgray",
                      fontSize: "13px"
                    }}>{"GW-T" + this.props.item.Id}</div>

                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </React.Fragment >
    );
  }
}

class TransactionRequestList extends React.Component {
  state = {
    open_modal_calification: false,
    open_modal_profile: false,
    transaction: {},
    contact: {},
    confirmed: false,
  };

  onClickViewProfile = (item) => {
    this.setState({ open_modal_profile: true, contact: item.User });
  };

  onCloseModalProfile = () => {
    this.setState({ open_modal_profile: false });
  };

  onCloseModalCalification = () => {
    this.setState({ open_modal_calification: false });
  };

  onClickChat = (item) => {
    this.props.SetContact(item.User);
  };

  onClickDown = (item) => {
    this.setState({
      open_modal_calification: true,
      transaction: item,
      confirmed: false,
    });
  };

  onClickUp = (item) => {
    this.setState({
      open_modal_calification: true,
      transaction: item,
      confirmed: true,
    });
  };

  render() {
    if (this.props.pageCount === 0)
      return (
        <h3>
          <Translate value="transaction_not_list" />
        </h3>
      );

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            {
              this.props.transactionList.map((item) => {
                return (
                  <TransactionItem
                    item={item}
                    key={item.Id.toString()}
                    onClick={() => {
                      this.onClickViewProfile(item);
                    }}
                    onClickChat={() => {
                      this.onClickChat(item);
                    }}
                    onClickDown={() => {
                      this.onClickDown(item);
                    }}
                    onClickUp={() => {
                      this.onClickUp(item);
                    }}
                  />
                );
              })
            }
          </div>
        </div>
        {
          this.props.pageSize >= 4 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )

          )
        }
        <ModalGodiwayer
          isOpen={this.state.open_modal_profile}
          onClose={() => {
            this.onCloseModalProfile();
          }}
          item={this.state.contact}
        />
        <ModalCalificationRequest
          isOpen={this.state.open_modal_calification}
          onClose={() => {
            this.onCloseModalCalification();
          }}
          isConfirmed={this.state.confirmed}
          transaction={this.state.transaction}
        />
      </React.Fragment>
    );
  }
}

/**\
 * function mapStateToProps(reducer) {
  return {
    contactList: reducer.contact.contactList,
    lang: reducer.i18n.locale
  };
}
 */
const mapDispatchToProps = (dispatch) => ({
  SetContact: (item) => dispatch(actions.setContact(item)),
});

export default connect(null, mapDispatchToProps)(TransactionRequestList);
