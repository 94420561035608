export const AD_DONATION_ATTEMPT = "AD_DONATION_ATTEMPT";
export const AD_DONATION_SUCCESS = "AD_DONATION_SUCCESS";
export const AD_DONATION_FAILED = "AD_DONATION_FAILED";
////////////////////////////
export const AD_DONATION_DELETE_SUCCESS = "AD_DONATION_DELETE_SUCCESS";
export const AD_DONATION_DELETE_FAILED = "AD_DONATION_DELETE_FAILED";
////////////////////////////
export const AD_DONATION_NEW_SUCCESS = "AD_DONATION_NEW_SUCCESS";
export const AD_DONATION_NEW_FAILED = "AD_DONATION_NEW_FAILED";

export const AD_DONATION_MORE_ATTEMPT = "AD_DONATION_MORE_ATTEMPT";
export const AD_DONATION_MORE_SUCCESS = "AD_DONATION_MORE_SUCCESS";
export const AD_DONATION_MORE_FAILED = "AD_DONATION_MORE_FAILED";

export const LOGOUT = "LOGOUT";