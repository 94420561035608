import React from "react";
//import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "../redux/actions/adFaqActions";
import "./styles/AdFaq.css";
import { FaQuestion } from "react-icons/fa";
import { FaPaperPlane, } from "react-icons/fa";
import { withRouter } from "react-router";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";
import MiniLoader from "./MiniLoader";

class AdFaqListItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-12">
          <strong className="question">
            {this.props.item.Question}
            <small className="faq-date">{this.props.item.CreatedAt}</small>
          </strong>
          <div className="answer">{this.props.item.Answer}</div>
          <hr className="dividerGray" />
        </div>
      </React.Fragment>
    );
  }
}

class AdFaq extends React.Component {
  state = {
    question: ""
  };

  componentDidMount() {
    this.props.LoadAdFaq(this.props.ad.Id, this.props.user_id, this.props.token);
  }

  onChange = event => {
    this.setState({
      question: event.target.value
    });
  };

  handleSubmit = async () => {
    //console.log(this.props.ad);
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    } else
      await this.props
        .AddQuestion(
          this.state.question,
          this.props.ad.Id,
          this.props.user_id,
          this.props.token
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          } else
            this.setState({
              question: ""
            });
        });
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadAdFaqMore(this.props.pageCount, this.props.ad.Id, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="form-group row ">
              <div className="col-md-10 col-xs-12 mt10">
                <input
                  className="form-control"
                  type="text"
                  value={this.state.question}
                  onChange={this.onChange}
                  name="question"
                  placeholder={I18n.t("faq_placeholder")}
                />
              </div>
              <div className="col-xs-12 col-md-2 text-center mt10">
                <button
                  className="btn btn-primary"
                  disabled={this.state.question.length === 0}
                  onClick={this.handleSubmit.bind(this)}
                >
                  <FaPaperPlane />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="text-center">
              <h4>
                <Translate value="faq_title" />
              </h4>
            </div>
          </div>
          <div className="col-12">
            <div className="goder-faq">
              <div className="row">
                {this.props.adFaqList.map(item => {
                  return <AdFaqListItem item={item} key={item.Id} />;
                })}
              </div>
            </div>
          </div>
        </div>
        {
          this.props.pageSize > 7 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    user: reducer.session.auth,
    adFaqList: reducer.adFaq.list,
    ad: reducer.ad.item,
    error: reducer.adFaq.error,
    lang: reducer.i18n.locale,
    pageSize: reducer.adFaq.page_size,
    pageCount: reducer.adFaq.page_count,
    loadingMore: reducer.adFaq.loadingMore,

  };
}
const mapDispatchToProps = dispatch => ({
  LoadAdFaq: (id, user_id, token) => dispatch(actions.loadList({ id, user_id, token })),
  LoadAdFaqMore: (page_count, id, user_id, token) => dispatch(actions.loadListScroll({ page_count, id, user_id, token })),
  AddQuestion: (question, id, user_id, token) =>
    dispatch(actions.newItem({ question, id, user_id, token }))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdFaq));
