import React from "react";
import { Link } from "react-router-dom";
import {
  FaLocationArrow,
  FaEllipsisH,
  FaComments,
  FaCamera,
  FaShareAlt,
  FaComment,
  FaThumbsDown,
  FaThumbsUp,
  FaRegThumbsUp,
  FaRegThumbsDown
} from "react-icons/fa";
import CommentBtnRight from "./CommentBtnRight";
import YoutubeEmbed from "./YoutubeEmbed";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adCommentActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

function getEmbedId(url) {
  const array = url.split(' ');
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  for (let index = 0; index < array.length; index++) {
    var element = array[index];
    if (element.indexOf("https://") >= 0) {
      var match = element.match(regExp);
      if (match && match[7].length == 11) {
        return match[7];
      } else {
        return false;
      }
    }
  }
}

class HeadComment extends React.Component {

  onClickReportComment = async () => {

    await this.props
      .ReportComment(this.props.token, {
        comment_id: this.props.comment.Id,
        id: this.props.user_id,
        lang: I18n.t("locale"),
      })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
        else
          alertify.success(I18n.t('success'));
      });
  };

  onClickRemoveComment = async () => {
    await this.props
      .RemoveComment(this.props.token,
        {
          comment_id: this.props.comment.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickUp = async () => {
    if (this.props.comment.Like === 1)
      await this.props
        .RemoveLike(this.props.token, {
          comment_id: this.props.comment.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    else
      await this.props
        .UpdateLike(this.props.token, {
          comment_id: this.props.comment.Id,
          status: "1",
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
  };



  onClickDown = async () => {
    if (this.props.comment.Like === 0)
      await this.props
        .RemoveLike(this.props.token,
          {
            comment_id: this.props.comment.Id,
            id: this.props.user_id,
            lang: I18n.t("locale"),
          })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    else
      await this.props
        .UpdateLike(this.props.token, {
          comment_id: this.props.comment.Id,
          status: "'0'",
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
  };


  render() {
    return (
      <React.Fragment>
        <div className="cardbox shadow-lg bg-white">
          <div className="cardbox-heading">
            <CommentBtnRight
              item={this.props.comment}
              onClickRemove={this.onClickRemoveComment}
              onClickReport={this.onClickReportComment}
            />
            <div className="media m-0">
              <div className="d-flex mr-3">
                <Link to="#">
                  <img
                    className="img-fluid rounded-circle"
                    src={this.props.comment.Avatar}
                    alt="User"
                  />
                </Link>
              </div>
              <div className="media-body">
                {this.props.comment.HasStore ? (
                  <Link to={`/marketstore/show/${this.props.comment.Store.Id}`}>
                    <p className="m-0">{this.props.comment.Name}</p>
                  </Link>
                ) : (
                  <Link to="#">
                    <p className="m-0">{this.props.comment.Name}</p>
                  </Link>
                )}

                {
                  Object.keys(this.props.comment.Ad).length !== 0 && this.props.comment.Ad.State !== "" && (
                    <small>
                      <span>
                        <i className="icon ion-md-pin">
                          {
                            (this.props.comment.Ad.State,
                              this.props.comment.Ad.City)
                          }
                          <i className="icon ion-md-time"></i>{" "}
                        </i>
                      </span>
                    </small>
                  )
                }
                <small>
                  <span>
                    <i className="icon ion-md-time"></i>{" "}
                    {this.props.comment.CreatedAt}
                  </span>
                </small>
              </div>
            </div>
            {
              getEmbedId(this.props.comment.Text) && (<YoutubeEmbed embedId={getEmbedId(this.props.comment.Text)} />)
            }
            {this.props.comment.Text !== "" && (
              <span className="" style={{ "whiteSpace": "pre-wrap" }}>
                {this.props.comment.Text} </span>

            )}

            {
              Object.keys(this.props.comment.Ad).length !== 0 && this.props.comment.Ad.Name !== "" && (
                <span className="">
                  {this.props.comment.Ad.Name} </span>
              )
            }
          </div>
          <div className="cardbox-item">
            {this.props.comment.HasMedia && (
              <div className="text-center">
                <Link to={`/galery`}>
                  <img
                    src={this.props.comment.Media}
                    className="img-fluid"
                    style={{ "width": "100%" }}
                    alt="image"
                  />
                </Link>
              </div>
            )}
            {
              this.props.comment.Ad !== {} && this.props.comment.Ad.Id !== undefined && (
                <React.Fragment>
                  <div className="text-center">
                    <Link to={`/goder/show/${this.props.comment.Ad.Id}`}>
                      <img
                        src={this.props.comment.Ad.Photo}
                        className="img-fluid"
                        style={{ "width": "100%" }}
                        alt="image"
                      />
                    </Link>
                  </div>
                </React.Fragment>
              )}
            {/* {(this.props.comment.Kind === "COMMENT" ||
              this.props.comment.Kind === "NEWS" ||
              this.props.comment.Kind === "") && (
                <React.Fragment>
                  <small className="cardbox-comment">
                    <span> {this.props.comment.Text} </span>
                  </small>
                </React.Fragment>
              )} */}
          </div>
          <div className="cardbox-base">
            <ul>
              <li>
                <Link to="#">
                  <em className="mr-3">
                    <small>{this.props.comment.CountLike}</small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.onClickUp}
                >
                  <i>
                    {this.props.comment.Like === 1 ? (
                      <FaThumbsUp />
                    ) : (
                      <FaRegThumbsUp />
                    )}
                  </i>
                </button>
              </li>
              <li>
                <Link to="#">
                  <em className="mr-3">
                    <small>{this.props.comment.CountUnlike}</small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.onClickDown}
                >
                  <i>
                    {this.props.comment.Like === 0 ? (
                      <FaThumbsDown />
                    ) : (
                      <FaRegThumbsDown />
                    )}
                  </i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,


    comment: reducer.adComment.item
    // adList: reducer.adList.adList
  };
}

const mapDispatchToProps = (dispatch) => ({

  ReportComment: (token, params) =>
    dispatch(actions.reportComment({ token, params })),
  RemoveComment: (token, params) =>
    dispatch(actions.removeComment({ token, params })),
  RemoveLike: (token, params) =>
    dispatch(actions.removeLike({ token, params })),
  UpdateLike: (token, params) =>
    dispatch(actions.updateLike({ token, params })),


});
export default connect(mapStateToProps, mapDispatchToProps)(HeadComment);
/**
 * <ul className="float-right">
              <li>
                <a>
                  <em className="mr-3">
                    {" "}
                    {this.props.comment.CommentCountSubComment}
                  </em>
                </a>
              </li>
              <li>
                <Link to="/comment">
                  <i>
                    <FaComments />
                  </i>
                </Link>
              </li>
              <li>
                <Link to="#">
                  <em className="mr-3">
                    {this.props.comment.CommentCountShare}
                  </em>
                </Link>
              </li>
              <li>
                <Link to="#" data-toggle="modal" data-target="#product_share">
                  <i>
                    <FaShareAlt />
                  </i>
                </Link>
              </li>
            </ul>
 */
