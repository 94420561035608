import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/ProfileMenu.css";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
/*to="/profile"*/
class ProfileMenu extends React.Component {
  render() {
    return (
      <div className="container-profile-menu">
        <div className="card">
          <nav className="nav flex-column">
            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="profile" />
              </small>
            </Link>

            <Link to="/profile/edit" className="nav-link">
              <li className="nav-link">
                <Translate value="profile" />
              </li>
            </Link>

            <Link to="/profile/more_data" className="nav-link">
              <li className="nav-link">
                <Translate value="profile_data" />
              </li>
            </Link>

            <Link to="/profile/password" className="nav-link">
              <li className="nav-link">
                <Translate value="profile_change_password" />
              </li>
            </Link>

            <Link to="/profile/privacy" className="nav-link">
              <li className="nav-link">
                <Translate value="profile_privacy" />
              </li>
            </Link>
          </nav>
        </div>
      </div>
    );
  }
}

export default ProfileMenu;
