import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
//import scriptLoader from "react-async-script-loader";
import ReactDOM from "react-dom";
import { PayPalButton } from "react-paypal-button-v2";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { connect } from "react-redux";
//import './styles/Home.css';
import { withRouter } from "react-router";
import { config } from "../config";

class PaypalOnePay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  componentWillUnmount() { }

  render() {
    return (
      <div className="text-center">
        <PayPalButton
          amount={this.props.value.toString()}
          currency="USD"
          shippingPreference={"NO_SHIPPING"}
          onSuccess={(details, data) => {
            this.props.history.push("/panel/redirectmysuscription");
            return fetch("/api/paypal/onepay-godiway", {
              method: "post",
              body: JSON.stringify({
                order_id: data.orderID,
                id: this.props.user_id,
                details: details,
              }),
              headers: { Authorization: `Bearer ${this.props.token}` },
            });
          }}
          options={{
            clientId: config.paypal.clientId,
            vault: true
          }}
          catchError={(err) => {
            alertify.warning(I18n.t("try_later"));
          }}
          onError={(err) => {
            alertify.warning(I18n.t("try_later"));
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    value: reducer.suscriptionPlan.plan_value,
  };
}
export default withRouter(connect(mapStateToProps, null)(PaypalOnePay));

//scriptLoader(
//  "https://www.paypal.com/sdk/js?client-id=Adchgwaqx84H1AJdnCDWIkHVHiOjA5FEgekQNEyY16BWL1oIvG2LNIn7hcwLWpea7FtryEvC9h7IUo2p&currency=USD"
//   )
/**
 * <PayPalButton
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: "USD",
                value: "0.01"
              }
            }],
            // application_context: {
            //   shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
            // }
          });
        }}
        onApprove={(data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(function(details) {
            // Show a success message to your buyer
            alert("Transaction completed by " + details.payer.name.given_name);
 
            // OPTIONAL: Call your server to save the transaction
            return fetch("/paypal-transaction-complete", {
              method: "post",
              body: JSON.stringify({
                orderID: data.orderID
              })
            });
          });
        }}
      />

      data 
      orderID: "5KT64704VU541991P"
payerID: "TN8X5FTAUPXKJ"
facilitatorAccessToken: "A21AAHo3Z8kuIKMpsvaoGyzrzHJjQcNoUaTpVlINuZoSSTx1rmj6zBzjOG-zSVrcgZFq8H8IYNlMskU3DmeBANVKP3hidIJqQ"
__proto__: Object


detail 


create_time: "2020-03-20T21:25:58Z"
update_time: "2020-03-20T21:33:09Z"
id: "5KT64704VU541991P"
intent: "CAPTURE"
status: "COMPLETED"
payer:
email_address: "johnguzman85@gmail.com"
payer_id: "TN8X5FTAUPXKJ"
address: {country_code: "EC"}
name: {given_name: "Nombre", surname: "Apellido"}
__proto__: Object
purchase_units: Array(1)
0:
reference_id: "default"
soft_descriptor: "PAYPAL *JOHNDOESTES"
amount: {value: "0.01", currency_code: "USD"}
payee:
email_address: "sb-jmhq51183704@business.example.com"
merchant_id: "7GNV874EYL8SY"
__proto__: Object
payments:
captures: Array(1)
0:
status: "PENDING"
id: "625012373U376293N"
final_capture: true
create_time: "2020-03-20T21:33:09Z"
update_time: "2020-03-20T21:33:09Z"
amount: {value: "0.01", currency_code: "USD"}
seller_protection: {status: "NOT_ELIGIBLE"}
status_details: {reason: "PENDING_REVIEW"}



 details.payer.name.given_name
 */
