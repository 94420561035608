export const VIDEO_LIST_ATTEMPT = "VIDEO_LIST_ATTEMPT";
export const VIDEO_LIST_SUCCESS = "VIDEO_LIST_SUCCESS";
export const VIDEO_LIST_FAILED = "VIDEO_LIST_FAILED";

export const REMOVE_VIDEO_SUCCESS = "REMOVE_VIDEO_SUCCESS";
export const REMOVE_VIDEO_FAILED = "REMOVE_VIDEO_FAILED";

export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const ADD_VIDEO_LOADING = "ADD_VIDEO_LOADING";
export const ADD_VIDEO_FAILED = "ADD_VIDEO_FAILED";
