import React from "react";
import "./styles/Information.css";
//import api from "../api";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

class WhatIsExchnage extends React.Component {
  componentDidMount() {
    // this.fetchPrepol();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-information">
            <div className="card ">
              {this.props.lang === "es" ? (
                <React.Fragment>
                  <div className="card-heading text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/intercambios.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>¿Que es Intercambiar?</h3>
                  </div>

                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      El Intercambio es el traspaso de bienes materiales o de
                      servicios por otros objetos o servicios, y se diferencia
                      de la compraventa habitual en que no intermedia el dinero
                      como representante del valor en la transacción.
                      Antiguamente se lo llamaba trueque.
                    </p>
                    <p>
                      El intercambio solo se produce si ambas partes valoran más
                      subjetivamente aquello que tiene la otra, que lo que
                      esperan dar a cambio en el intercambio. Así, supongamos
                      que Juan tiene A, y Marcos tiene B. Solo si Juan valora
                      más B que A, y Marcos valora más A que B, se realizará el
                      intercambio (o esto es, por lo menos, una condición
                      necesaria para que se produzca).
                    </p>
                    <p>
                      El sitio provee la posibilidad de publicar anuncios de los
                      oferentes(Usuarios registrados que desean y publican
                      Intercambiar ) de algún intercambio que desee realizar,
                      asi el o los demandantes (Usuarios registrados que desean
                      Intercambiar) podrán negociar hasta llegar algún acuerdo.
                    </p>
                    <p>
                      Godiway solo realiza el vínculo entre ambas partes
                      oferentes y demandantes, la negociación que realicen y
                      acuerden queda entre ellos.{" "}
                    </p>
                    <p>
                      El precio al que llegarán ambas partes para efectuar el
                      intercambio depende de sus valoraciones subjetivas. En
                      efecto, el precio máximo al que está dispuesto a pagar el
                      comprador debe ser mayor que el precio mínimo al que está
                      dispuesto a vender el vendedor.
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="card-heading text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/intercambios.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>What is Exchange?</h3>
                  </div>

                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      The Exchange is the transfer of material goods or services
                      for other objects or services, and differs from the
                      habitual sale and purchase in which the money is not
                      representative of the value in the transaction. Formerly I
                      know it called barter.
                    </p>
                    <p>
                      The exchange only occurs if both parties value more
                      subjectively what the other has, what they expect give
                      back in exchange. So suppose that John has A, and Marcos
                      has B. Only if Juan values B more than A, and Marcos
                      values more A than B, the exchange will take place (or
                      that is, at least, a necessary condition for it to occur).
                    </p>
                    <p>
                      The site provides the possibility of publishing
                      announcements of the bidders (Registered users who want
                      and publish Exchange) of any exchange you want to make, so
                      the or the plaintiffs (Registered users who want Exchange)
                      may negotiate until an agreement is reached.
                    </p>
                    <p>
                      Godiway only makes the link between both offering parties
                      and claimants, the negotiation they carry out and agree
                      upon remains among them.{" "}
                    </p>
                    <p>
                      The price at which both parties will arrive to carry out
                      the Exchange depends on your subjective assessments.
                      Indeed, the maximum price at which the buyer is willing to
                      pay must be greater than the minimum price you are willing
                      to sell the seller.
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(WhatIsExchnage);
