import axios from "axios";
import { I18n } from "react-redux-i18n";

import {
  MARKETSTORE_ITEM_ATTEMPT,
  MARKETSTORE_ITEM_SUCCESS,
  MARKETSTORE_ITEM_FAILED,
  ////////////////////////////
  MARKETSTORE_UPDATE_ATTEMPT,
  MARKETSTORE_UPDATE_SUCCESS,
  MARKETSTORE_UPDATE_FAILED,
  CLEAR_MARKETSTORE,
  ////////////////////////////
  SET_SELECTED_MARKETSTORE,
  ////////////////////////
} from "../types/marketstoreTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: MARKETSTORE_ITEM_ATTEMPT,
  };
}

export function success(item) {
  return {
    type: MARKETSTORE_ITEM_SUCCESS,
    item: item,
  };
}

export function failed(error) {
  return {
    type: MARKETSTORE_ITEM_FAILED,
    error: error,
  };
}
////////////////////////////////////////

export function setSelectedStore(item) {
  return {
    type: SET_SELECTED_MARKETSTORE,
    item: item,
  };
}
///////////////////////////////
export function clearStore() {
  return {
    type: CLEAR_MARKETSTORE,
  };
}

/////////////////////////////////////////
////////////////////////////////////////

export function loadingUpdate() {
  return {
    type: MARKETSTORE_UPDATE_ATTEMPT,
  };
}
export function successUpdate(item) {
  return {
    type: MARKETSTORE_UPDATE_SUCCESS,
    item: item,
  };
}

export function failedUpdate(error) {
  return {
    type: MARKETSTORE_UPDATE_FAILED,
    error: error,
  };
}

export const setStore = (data: Object) => (dispatch) => {
  dispatch(setSelectedStore(data.item));
};

export const clearMarketStore = (data: Object) => (dispatch) => {
  dispatch(clearStore());
};

export const loadItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "marketstore/id", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadItemMe = (data: Object) => async (dispatch) => {
  dispatch(loading());
  //console.log(data);
  await axios
    .get(url_api + "marketstore/mine", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};



export const newItem = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "marketstore", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    }
    )
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};

export const uploadLogo = (data: Object) => async (dispatch) => {
  try {
    dispatch(loadingUpdate());
    var request = new XMLHttpRequest();
    await request.open("POST", url_api + "marketstore/logo");
    request.setRequestHeader("Authorization", `Bearer ${data.token}`);
    request.onload = () => {
      if (request.status === 200) {
        const response = JSON.parse(request.response);
        // console.log("Image Loaded", response.body);
        dispatch(successUpdate(response.body));
      } else {
        console.error(request.response);
        dispatch(failedUpdate("try_later"));
      }
    };
    await request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        //dispatch(loadingUpImage(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(failedUpdate("try_later"));
  }
};

export const uploadCover = (data: Object) => async (dispatch) => {
  try {
    dispatch(loadingUpdate());
    var request = new XMLHttpRequest();
    await request.open("POST", url_api + "marketstore/cover", true);
    request.setRequestHeader("Authorization", `Bearer ${data.token}`);
    request.onload = () => {
      if (request.status < 300) {
        const response = JSON.parse(request.response);
        //console.log("Image Loaded", response.body);
        dispatch(successUpdate(response.body));
      } else {
        console.error(request.response);
        dispatch(failedUpdate("try_later"));
      }
    };
    await request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        //dispatch(loadingUpImage(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(failedUpdate("try_later"));
  }
};

export const editMyMarketStore = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "marketstore", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //  console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};

export const removeStore = (data: Object) => async (dispatch) => {
  // await axios
  //   .delete(url_api + "marketstore", data.params, {
  //     headers: { Authorization: `Bearer ${data.token}` },
  //   })
  await axios({
    method: "delete",
    url: url_api + "marketstore",
    params: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};

export const loadItemSlider = (data: Object) => async (dispatch) => {
  dispatch(loading());
  //console.log(data);
  await axios
    .get(url_api + "marketstore/slider", {
      params: {
        //id: data.user_id,
        // token: data.token,
        lang: I18n.t("locale"),
      },
      // headers: { authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};