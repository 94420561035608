import React from "react";
import "./styles/Information.css";
//import api from "../api";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

class WhyDonationExchange extends React.Component {
  componentDidMount() {
    // this.fetchPrepol();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-information">
            <div className="card">
              {this.props.lang === "es" ? (
                <React.Fragment>
                  <div className="card-header text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>¿Por que Donar o Intercambiar?</h3>
                  </div>
                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      El sitio se creó con el fin de apoyar a la economía de los
                      usuarios, el cual últimamente ha venido reduciendo, con la
                      Ola de consumismo se adquieren artículos que ya no
                      necesitan y otros si, Así cuando dispongan de algún
                      producto, articulo o bien que actualmente ya no usen
                      puedan realizar intercambios con artículos que es este
                      necesitando, bienes que otros usuarios no estén usando,
                      Así mismo las donaciones cunado otras personas hayan
                      sufrido alguna eventualidad, necesidad y se desee
                      apoyarlos se podrá publicar donaciones y donarlos a
                      personas que necesiten directamente o a su vez
                      intermediarios como fundaciones, grupos, movimientos que
                      distribuyen directamente a personas que las necesitan.
                    </p>
                    <p>
                      Te recomendamos antes de cualquier transacción consultar
                      todos los datos tecnicos y caracteristicas a detalle que
                      requieres, para no tener sorpresas, ni incovenientes en el
                      momento de que recibas lo anunciado.
                    </p>
                    <p>
                      Para mas seguridad revisa la calificación del Anunciante,
                      y si ya has solicitado la transaccion en el sitio y no
                      estas seguro igual la puedes cancelar en cualquier
                      momento.
                    </p>
                    <p>
                      Si el Anunciante no dispone de lugar comercial, te
                      recomendamos que las compras, intercambios, donaciones lo
                      realices en lugares publicos.
                    </p>
                    <p>
                      La calificación del Anunciante se la realiza sumando las
                      ventas solicitadas en el sitio, se verifica cuantas han
                      sido completadas y cuantas han sido canceladas.
                    </p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="card-header text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>Why Donate or Exchange?</h3>
                  </div>
                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      The site was created in order to support the economy of
                      the users, which lately has been reducing, with the Wave
                      consumerism items that are no longer needed are purchased
                      and others yes, so when they have a product, article or
                      well that currently they no longer use can make exchanges
                      with items that is in need, goods that others users are
                      not using, also donations when other people have suffered
                      some eventuality, need and you want to support them you
                      can publish donations and donate them to people who
                      directly need or in turn intermediaries like foundations,
                      groups, movements that distribute directly to people who
                      need them.
                    </p>
                    <p>
                      We recommend you to consult all before any transaction the
                      technical data and characteristics in detail that you
                      require, so as not to have surprises or inconveniences
                      when receive what is announced.
                    </p>
                    <p>
                      For more security check the advertiser's rating, and if
                      you have already requested the transaction on the site and
                      not you are sure you can cancel it in any moment.
                    </p>
                    <p>
                      If the Advertiser does not have a commercial place, We
                      recommend that purchases, exchanges, donations you perform
                      in public places.
                    </p>
                    <p>
                      The Advertiser's qualification is done by adding the sales
                      requested on the site, it is verified how many have have
                      been completed and how many have been canceled.
                    </p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(WhyDonationExchange);
