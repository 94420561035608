import React from "react";
import { connect } from "react-redux";
import { FaTrash } from "react-icons/fa";
import { Translate, I18n } from "react-redux-i18n";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/mediaActions";
import MiniLoader from "../components/MiniLoader";
import Api from "../utils/api";

class GaleryItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-4 mb-4">
          <div className="card  h-100">
            <img src={this.props.item.Url} className="img-responsive" alt="Goder" />
            <button className="btn btn-light" onClick={this.props.onClick}>
              <FaTrash />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
class PanelEditGalery extends React.Component {
  state = {
    image: "",
    file: "",
  };

  componentDidMount() {
    this.fecthMedia();
  }

  fecthMedia = async () => {
    try {
      await this.props
        .LoadMedia(this.props.token, {
          goder_id: this.props.ad.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickRemove = async (item) => {
    try {
      await this.props
        .RemoveMedia(this.props.token, {
          media_id: item.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeImageAd = async (e) => {
    if (this.props.mediaList.length >= 5) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_max_img"),
        function () { }
      );
      return;
    }

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        image: reader.result,
      });
    };

    reader.readAsDataURL(file);
    let params = new FormData();


    params.append("goder_id", this.props.ad.Id);
    params.append("id", this.props.user_id);
    params.append("image", file);//file await Api.compressImage(file)


    await this.props.UpdateMedia(this.props.token, params).then(() => {
      if (this.props.error_update) {
        alertify.warning(I18n.t(this.props.error_update));
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header text-center">
            <h2>
              {" "}
              <Translate value="ad_galery" />
            </h2>
          </div>
          <div className="card-body row">
            {this.props.mediaList.map((item) => {
              if (item.Type === "image")
                return (
                  <GaleryItem
                    item={item}
                    key={item.Id}
                    onClick={() => {
                      this.onClickRemove(item);
                    }}
                  />
                );
              else return;
            })}
          </div>
          {this.props.loading_image && <MiniLoader />}
          <div className="card-footer">
            <div className="text-center">
              <Translate value="warning_max_img" />
            </div>
            <div className="text-center">
              <input
                className="upload"
                accept="image/*"
                type="file"
                name="image"
                id="image"
                onChange={(e) => {
                  this.onChangeImageAd(e);
                }}
              />
              <label htmlFor="image">
                <span className="btn btn-secondary">
                  <Translate value="ad_load_image" />
                </span>
              </label>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,

    error: reducer.media.error,
    loading: reducer.media.loading,
    mediaList: reducer.media.list,

    error_update: reducer.media.error_update,
    loading_image: reducer.media.loadingFile,
  };
}
const mapDispatchToProps = (dispatch) => ({
  RemoveMedia: (token, params) =>
    dispatch(actions.deleteItem({ token, params })),
  LoadMedia: (token, params) =>
    dispatch(actions.loadList({ token, params })),
  UpdateMedia: (token, params) => dispatch(actions.newItem({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelEditGalery);
