import React from "react";

import "./styles/Typping.css";

function Typping(props) {
    // console.log(props.background);
    return (

        props.background === "light" ? (
            <React.Fragment>
                <div className="half light">
                    {
                        props.kind === 'scaling' &&
                        (<div className="typing">
                            <span className="circle scaling"></span>
                            <span className="circle scaling"></span>
                            <span className="circle scaling"></span>
                        </div>) ||
                        props.kind === 'bouncing' &&
                        (<div className="typing">
                            <span className="circle bouncing"></span>
                            <span className="circle bouncing"></span>
                            <span className="circle bouncing"></span>
                        </div>)
                        ||
                        props.kind === 'mix' &&
                        (<div className="typing">
                            <span className="circle scaling"></span>
                            <span className="circle bouncing"></span>
                            <span className="circle scaling"></span>
                        </div>)
                    }
                </div>
            </React.Fragment>
        )
            :
            (<React.Fragment>
                <div className="half dark">
                    {
                        props.kind === 'scaling' &&
                        (<div className="typing">
                            <span className="circle scaling"></span>
                            <span className="circle scaling"></span>
                            <span className="circle scaling"></span>
                        </div>) ||
                        props.kind === 'bouncing' &&
                        (<div className="typing">
                            <span className="circle bouncing"></span>
                            <span className="circle bouncing"></span>
                            <span className="circle bouncing"></span>
                        </div>)
                        ||
                        props.kind === 'mix' &&
                        (<div className="typing">
                            <span className="circle scaling"></span>
                            <span className="circle bouncing"></span>
                            <span className="circle scaling"></span>
                        </div>)
                    }
                </div>
            </React.Fragment >
            )

    );
}
export default Typping;


