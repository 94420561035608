import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";
import {
  FaComments,
} from "react-icons/fa";

import Modal from "./Modal";
import * as actions_contact from "../redux/actions/contactsActions";

class ModalGodiwayer extends React.Component {

  onClickChat = (item) => {
    this.props.SetContact(item);
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={`@${this.props.item.Username}`}
      >
        <div className="row">
          <div className="col-6">
            <div className="img-center">
              <img className="img-respon" src={this.props.item.Avatar} alt="Avatar" />
            </div>
          </div>
          <div className="col-6 ">
            <div className="row">
              <small>
                <strong>Godiwayer:</strong>
              </small>
            </div>
            <div className="row">
              <small>{this.props.item.Name + " " + this.props.item.LastN}</small>
            </div>
            <div className="row">
              <small>
                <strong>
                  {" "}
                  <Translate value="profile_phone" />:
                </strong>
              </small>
            </div>
            <div className="row">
              <small>
                (+{this.props.country && this.props.country.Lada}){this.props.item.Phone}
              </small>
            </div>
            <div className="row ">
              <small>
                <strong>
                  <Translate value="profile_email" />:{" "}
                </strong>
              </small>
            </div>
            <div className="row">
              <small>
                <a href={`mailto:${this.props.item.Email}`} target="_blank" rel="noopener noreferrer">
                  {this.props.item.Email}
                </a>
              </small>
            </div>

            <div className="row ">
              <small>
                <strong>
                  <Translate value="profile_city" />:
                </strong>
              </small>
            </div>
            <div className="row">
              <small>{this.props.item.City}</small>
            </div>
          </div>
          <div id="whatsapp" className="collapse col-md-12  text-center"></div>
          <div className="row">
            <div className="text-center"></div>
          </div>
          <div className="col-md-12">
            <div className="text-center">..</div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="col-6 text-center">
            <Link
              className="btn btn-light"
              //to="#"
              to={`/chat/${this.props.item.ConversationId}`}
            // onClick={() => { this.props.onClickChat(this.props.item) }}
            >
              <FaComments />
            </Link>
          </div>
          <div className="col-6 text-center">
            <button className="btn btn-primary" onClick={this.props.onClose}>
              <i className="fa fa-close"></i>
              <Translate value="ok" />
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  SetContact: (item) => dispatch(actions_contact.setContact(item)),
  //LoadMoreTransaction: (page_count, user_id, token) =>
  //  dispatch(actions_transaction.loadMoreTransaction({ page_count, user_id, token })),
});
export default connect(null, mapDispatchToProps)(ModalGodiwayer);
