import {
    CONTACT_LIST_ATTEMPT,
    CONTACT_LIST_SUCCESS,
    CONTACT_LIST_FAILED,
    ///////////////////////////
    SET_CONTACT,
    REMOVE_CONTACT,
    ///////////////////////////
    CONTACT_MORELIST_ATTEMPT,
    CONTACT_MORELIST_SUCCESS,
    CONTACT_MORELIST_FAILED,
    /////////////////////
    CONTACT_ONLINE,
    CONTACT_OFFLINE,
    CONTACTS_ONLINE,
    //////////////////////
    UPDATE_BADGET_LIST_CONTACTS,
    CLEAR_BADGET_LIST_CONTACTS,
    ///////////////////
    SET_BADGET_TAB_CONTACTS,
    LOGOUT
} from '../types/contactsTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
    /////////////////////////////
    item: {},
    online: '',
    tab_badget_chat: 0,
    ////////////////////
    loadingMore: false,
    page_index: 0,
    page_size: 0,
    page_count: 0,
    ////////////////

};
function contact(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CONTACT_LIST_ATTEMPT:
            return {
                ...state,
                loading: true,
            }

        case CONTACT_LIST_SUCCESS: {
            const newList = action.data.list.filter(item => item.MessageCount > 0);

            return {
                ...state,
                loading: false,
                list: action.data.list,
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: state.page_count + action.data.size,
                error: '',
                tab_badget_chat: newList.length
            }
        }

        case CONTACT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }

        /////////////////////////////////////
        case SET_BADGET_TAB_CONTACTS:
            return {
                ...state,
                tab_badget_chat: action.badget_chat
            }
        /////////////////////////////////////
        case CONTACT_MORELIST_ATTEMPT:
            return {
                ...state,
                loadingMore: true,
            }

        case CONTACT_MORELIST_SUCCESS:
            return {
                ...state,
                loadingMore: false,
                list: state.list.concat(action.data.list),
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: state.page_count + action.data.size,
                error: ''
            }

        case CONTACT_MORELIST_FAILED:
            return {
                ...state,
                loadingMore: false,
            }
        //////////////////////////////////////
        case CONTACTS_ONLINE:
            {
                const newList = state.list.map((item) => {

                    action.list.find(contact_online => {

                        // console.log(item.UserId);
                        // console.log(contact_online.user.id);
                        // console.log(item.UserId === contact_online.user.id);

                        if (item.UserId === contact_online.user.id)
                            item.Socket = contact_online.socket;
                        //return item;
                    });
                    return item;
                });

                return {
                    ...state,
                    list: newList,
                };
            }
        case CONTACT_ONLINE:
            {
                const newList = state.list.map((item) => {
                    // console.log(item.UserId);
                    // console.log(action.data.user.id);
                    // console.log(item.UserId === action.data.user.id);
                    if (item.UserId === action.data.user.id)
                        item.Socket = action.data.socket;
                    return item;
                });

                var newItem = state.item;
                if (state.item && state.item.UserId === action.data.user.id)
                    newItem.Socket = action.data.socket;

                return {
                    ...state,
                    list: newList,
                    item: newItem,
                    online: newItem.Socket
                }
            }

        case CONTACT_OFFLINE:
            {
                const newList = state.list.map((item) => {
                    if (item.UserId === action.data.user.id)
                        item.Socket = "";
                    return item;
                });

                var newItem = state.item;

                if (newItem && newItem.UserId === action.data.user.id)
                    newItem.Socket = "";

                return {
                    ...state,
                    list: newList,
                    item: newItem,
                    online: newItem.Socket
                }
            }

        //////////////////////////////// 
        case UPDATE_BADGET_LIST_CONTACTS: { //funcionando
            // console.log(state.item);
            if (!state.item || (state.item && state.item.UserId !== action.data.user.id)) {
                var tab_chat = state.tab_badget_chat;
                const newList = state.list.map((item) => {
                    if (item.UserId === action.data.user.id) {
                        if (item.MessageCount === 0)
                            tab_chat++;
                        item.MessageCount++;
                        item.Message.Text = action.data.text;
                    }
                    return item;
                });

                return {
                    ...state,
                    list: newList,
                    tab_badget_chat: tab_chat
                };
            }
            else return state;
        }

        case CLEAR_BADGET_LIST_CONTACTS: {//funcionando
            var tab_chat = state.tab_badget_chat;
            const newList = state.list.map((item) => {
                if (item.UserId === action.data.UserId && item.MessageCount > 0) {
                    item.MessageCount = 0;
                    if (tab_chat > 0) tab_chat--;
                }
                return item;
            });

            return {
                ...state,
                list: newList,
                tab_badget_chat: tab_chat
            };
        }

        case SET_CONTACT: {
            return {
                ...state,
                item: action.item,
                online: action.item.Socket
            };
        }

        case REMOVE_CONTACT:
            return {
                ...state,
                item: {},
                online: ''
            };
        case LOGOUT:
            return INITIAL_STATE;
        default: return state;
    }
}
export default contact;