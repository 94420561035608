import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FaUserAlt, FaSearch, FaChevronRight } from "react-icons/fa";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/ContactList.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions_contacts from "../redux/actions/contactsActions";
import * as actions_message from "../redux/actions/messageActions";
import * as actions_event from "../redux/actions/eventActions";
import MiniLoader from "./MiniLoader";

function getInitial(name) {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}
class ContactItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Link
          to={`/chat/${this.props.item.ConversationId}`}
          onClick={this.props.onClick}
          style={{ textDecoration: "none" }}
        >
          <li className="contacts_card">
            <div className="img_cont">
              <img
                src={this.props.item.Avatar}
                className="rounded-circle user_img active"
                alt={getInitial(this.props.item.Name)}
              />
              {
                this.props.item.Socket && (<i className="online_icon"></i>)
              }

            </div>
            <div className="contact_name">
              <div className="rower">
                <span>{this.props.item.Name + " " + this.props.item.LastN}</span>
                <small> {this.props.item.Message.Text} <br />
                  {this.props.item.Message.CreatedAt}</small>
              </div>
            </div>

            {
              this.props.item.MessageCount > 0 &&
              (
                <div className="contact_badge">
                  <span>
                    {this.props.item.MessageCount}
                  </span>
                </div>
              )
            }

            <div className="contact_profile">
              <span>
                <FaChevronRight />
              </span>
            </div>
          </li>
        </Link>
      </React.Fragment>
    );
  }
}


class ContactList extends React.Component {
  state = {
    text_search: "",
    contactList: []
  };

  componentDidMount() {

  }

  componentDidUpdate() {
    this.render();
  }

  onChange = (event) => {
    this.setState({ text_search: event.target.value });
    var text_search = event.target.value;
    const newList = this.props.contactList.filter(function (item) {
      const itemData = item.Name ? item.Name.toUpperCase() : "".toUpperCase();
      const textData = text_search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    this.setState({ contactList: newList });
  };

  onClick = (item) => {
    this.props.SetContact(item);
    this.props.ClearBadgetContact(item);
    this.props.SetContactMessage(item);
    this.props.SetContactEvent(item);

  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadMoreContacts(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };


  render() {
    if (this.props.pageCount === 0)
      return (
        <h3>
          <Translate value="contact_not_list" />
        </h3>
      );

    return (
      <React.Fragment>
        <div className="card">
          <div className="input-group">
            <input
              type="text"
              placeholder={this.props.lang === "en" ? "Search" : "Buscar"}
              onChange={this.onChange}
              className="form-control"
              value={this.state.text_search}
            />
            <span className="input-group-text search_btn">
              <FaSearch />
            </span>
          </div>

          <div className="container-fluid">
            <div className="card-body contacts-body  mb-4">
              <ul className="contacts">
                {
                  this.props.contactList.map((item) => {
                    return (
                      <ContactItem
                        item={item}
                        key={item.Id}
                        onClick={() => {
                          this.onClick(item);
                        }}

                      />
                    );
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        {
          this.props.pageSize > 7 && (
            this.props.loadingMore ? (
              <div className="text-center ">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center mt-2">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    contactList: reducer.contacts.list,
    pageCount: reducer.contacts.page_count,
    pageSize: reducer.contacts.page_size,
    loadingMore: reducer.contacts.loadingMore,

    lang: reducer.i18n.locale,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SetContact: (item) => dispatch(actions_contacts.setContact(item)),
  ClearBadgetContact: item => dispatch(actions_contacts.clearBadgetContact({ item })),
  LoadMoreContacts: (page_count, user_id, token) =>
    dispatch(actions_contacts.loadListScroll({ page_count, user_id, token })),
  ///////////////
  SetContactMessage: (item) =>
    dispatch(actions_message.setContactMessage(item)),
  //////////////
  SetContactEvent: (item) =>
    dispatch(actions_event.setContactEvent(item)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);

/**
 *  
 */
/*
 <div className="contact_profile">
            <button
              className="btn btn-secondary"
              title={<Translate value="see_profile" />}
             
            >
              <FaUserAlt />
            </button>
          </div>
          
*/