import React from "react";
import PaypalOnePay from "../components/PaypalOnePay";
import PanelLayout from "../components/PanelLayout";
//import PageLoading from "../components/PageLoading";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/cardsActions";
import "./styles/Cards.css";

class OnePayPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: [],
    };
  }

  componentDidMount() { }

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3 className="text-center">
            <Translate value="kind_pay" />
          </h3>
          <div className="col">
            <h5 className="d-flex justify-content-between align-items-center">
              <span className="text-muted"><Translate value="you_cart" /></span>
              <span className="badge badge-secondary badge-pill">1</span>
            </h5>
            <ul className="list-group mb-3">
              <li className="list-group-item d-flex justify-content-between lh-condensed">
                <div>
                  <h6 className="my-0">
                    {" "}
                    <Translate value="one_pay" />
                  </h6>
                  <small className="text-muted">
                    <Translate value="one_pay2" />
                    <br />
                    <Translate value="one_pay3" />
                    <br /> <Translate value="one_pay4" /> <br />
                    <Translate value="one_pay5" />
                  </small>
                </div>
                <span className="text-muted">${this.props.value}</span>
              </li>
              <li className="list-group-item d-flex justify-content-between">
                <span>Total (USD)</span>
                <strong>${this.props.value}</strong>
              </li>
            </ul>

            <hr className="" />
          </div>
          <PaypalOnePay />
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    value: reducer.suscriptionPlan.plan_value,

    cardList: reducer.cards.cardList,
    error: reducer.cards.error,
    loading: reducer.cards.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCards: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnePayPanel);

/**  <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Codigo Promocional"
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-secondary">
                  <Translate value="password_forget_send" />
                </button>
              </div>
            </div> */