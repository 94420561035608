import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import "./styles/Countries.css";
import Footer from "../components/Footer";
import * as actions_country from "../redux/actions/countryActions";
import * as actions_session from "../redux/actions/sessionActions";


class Countries extends React.Component {
  state = {
    text: "",
    countries_list: []
  };

  componentDidMount() {
    this.fetchCountry();
  }

  fetchCountry = async () => {
    try {
      await this.props.LoadCountry().then(() => {
        if (this.props.error) {
          alert(this.props.error);
        } else this.setState({ countries_list: this.props.countryList });
      });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeTex = event => {
    this.setState({ text: event.target.value });
    var text_search = event.target.value;
    const newData = this.props.countryList.filter(function (item) {
      const itemData = item.Name ? item.Name.toUpperCase() : "";
      const textData = text_search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({ countries_list: newData });
  };

  onClickSelectCountry = async item => {
    //event.preventDefault();
    try {
      //   const data = await api.countries.update({
      //     user_id: this.props.user_id,
      //    token: this.props.token,
      //     id: item.key
      //   });
      //console.log(data);
      //    if (data.status === "Error") alertify.warning(I18n.t("try_later"));
      // else
      await this.props
        .UpdateCountry(this.props.token, {
          country_id: item.prefix,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error_update) alertify.warning(I18n.t("try_later"));
          else this.props.history.push("/home");
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-countrie">
            <section className="countrie-list">
              <h2 className="text-center">
                <Translate value="choose_country" />
              </h2>
              <input
                type="text"
                placeholder={I18n.t("country")}
                className="form-control"
                value={this.state.text}
                onChange={this.onChangeTex}
              />
              <ul className="countries-scroll">
                {this.state.countries_list.map(item => {
                  return (
                    <li className="nav-item" key={item.id}>
                      <Link
                        to="#"
                        className="nav-link"
                        onClick={() => {
                          this.onClickSelectCountry(item);
                        }}
                      >
                        <img
                          src={item.flag}
                          className="country-img"
                          alt={"godiway-img"}
                        />{" "}
                        {item.name} (+{item.lada}) {item.prefix}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    countryList: reducer.country.list,
    error: reducer.country.error,
    loading: reducer.country.loading,

    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    error_update: reducer.session.error_update
  };
}
const mapDispatchToProps = dispatch => ({
  LoadCountry: () => dispatch(actions_country.loadList({})),
  UpdateCountry: (token, params) =>
    dispatch(actions_session.updateItem({ token, params }))
});
export default connect(mapStateToProps, mapDispatchToProps)(Countries);
