import React, { Component } from "react";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import * as actions from "../redux/actions/marketstoreFollowersActions";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
function getInitial(name) {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

class FollowerItem extends React.Component {
  render() {
    return (

      <div className="col-md-12 media mb-1">
        <div className="media-left ">
          {this.props.item.Avatar ?
            (<img
              src={this.props.item.Avatar}
              className="img-circle"
              alt="img"
              width="50"
              height="50"
              style={{ borderRadius: "50%" }}
            />

            ) : getInitial(this.props.item.Name)
          }

        </div>
        <div className="media-body ">
          <div className="text-left">
            {this.props.item.Name}
            <Link to="#"></Link>
          </div>
        </div>
        <div className="media-footer "></div>
      </div>

    );
  }
}

class StoreFollowers extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fechFollowers();
  }
  fechFollowers = async () => {
    try {
      await this.props
        .LoadFollowers(
          this.props.token,
          {
            store_id: this.props.marketstore.Id,
            id: this.props.user_id,
          }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <small>
            {" "}
            <Translate value="marketstore_followers" />
          </small>
          <div className="card-body">

            {this.props.followerList.map((item) => {
              return <FollowerItem item={item} key={item.Id} />;
            })}

          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducers) {
  //console.log(reducers);
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    marketstore: reducers.marketstore.item,

    error: reducers.marketstoreFollowers.error,
    loading: reducers.marketstoreFollowers.loading,
    followerList: reducers.marketstoreFollowers.list,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadFollowers: (token, params) =>
    dispatch(actions.loadList({ token, params })),
  //  UpdateFollow: (id, user_id, token) =>
  //  dispatch(actions_marketstore.updateFollow({ id, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreFollowers);
