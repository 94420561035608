import React from "react";
//import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { Translate, I18n } from "react-redux-i18n";
import * as actions from "../redux/actions/marketstoreFollowersActions";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

class FollowerItem extends React.Component {
  render() {
    return (
      <div className="col-md-12 media mb-1">
        <div className="media-left ">
          <img
            src={this.props.item.Avatar}
            className="img-circle"
            alt="avatar"
            width="50"
            height="50"
            style={{ borderRadius: "50%" }}
          />
        </div>
        <div className="media-body ">
          <div className="text-left">
            <Link to="#" style={{ textDecoration: "none" }}>{this.props.item.Name}</Link>
          </div>
        </div>
        <div className="media-footer ">
          <div className="text-right">
            {this.props.item.HasStore &&
              (this.props.item.IsFollow ? (
                <button
                  className="btn btn-light"
                  onClick={this.props.onClickFollow}
                >
                  <Translate value="marketstore_unfollow" />
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={this.props.onClickUnFollow}
                >
                  <Translate value="marketstore_follow" />
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
//Description  HasStore: false  : false
class ModalStoreFollowers extends React.Component {
  onClickFollow = async (item) => {
    try {
      await this.props
        .UpdateFollow(item.StoreId, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickUnFollow = async (item) => {
    try {
      await this.props
        .UpdateFollow(item.StoreId, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={<Translate value="marketstore_followers" />}
      >
        <div className="">
          <div className="modal-scroll">

            {this.props.followerList.map((item) => {
              return (
                <FollowerItem
                  item={item}
                  key={item.Id}
                  onClickFollow={() => {
                    this.onClickFollow(item);
                  }}
                  onClickUnFollow={() => {
                    this.onClickUnFollow(item);
                  }}
                />
              );
            })}

          </div>
        </div>
        <div className="modal-footer">
          <div className="col-12 text-center">
            <button className="btn btn-secondary" onClick={this.props.onClose}>
              <i className="fa fa-close"></i> <Translate value="close" />
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(reducers) {
  // console.log(reducers);
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,
    //marketstore: reducers.marketstore.marketstoreItem,
    error: reducers.marketstoreFollowers.error,
    loading: reducers.marketstoreFollowers.loading,
    followerList: reducers.marketstoreFollowers.list,
  };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateFollow: (id, user_id, token) =>
    dispatch(actions.updateItem({ id, user_id, token })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalStoreFollowers);
