export const SESSION_ATTEMPT = "SESSION_ATTEMPT";
export const SESSION_SUCCESSFULL = "SESSION_SUCCESSFULL";
export const SESSION_FAILED = "SESSION_FAILED";
/////////////

export const SESSION_SUCCESS_MESSAGE = "SESSION_SUCCESS_MESSAGE";
export const SESSION_FAILED_MESSAGE = "SESSION_FAILED_MESSAGE";
export const SESSION_FAILED_UPDATE = "SESSION_FAILED_UPDATE";

//////////
export const LOGOUT = "LOGOUT";

export const POPUP = "POPUP";

export const DESTROY_SESSION = "DESTROY_SESSION";

