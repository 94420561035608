import React from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import ContactList from "../components/ContactList";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";

import * as actions_contact from "../redux/actions/contactsActions";
import * as actions_event from '../redux/actions/eventActions';




class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    //if (this.props.pageCount === 0)
    // this.fetchContactList();


  }

  fetchContactList = async () => {
    try {
      await this.props
        .LoadContacts(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
          else
            this.props.LoadContactsOnline(this.props.user_id, this.props.token);
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            <Translate value="contact_title" />
          </h3>
          {this.props.loading ? (
            <PageLoading />
          ) : (
            <ContactList contactList={this.props.contactList} />
          )}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    contactList: reducer.contacts.list,
    error: reducer.contacts.error,
    loading: reducer.contacts.loading,
    pageCount: reducer.contacts.page_count
  };
}
const mapDispatchToProps = dispatch => ({
  LoadContacts: (user_id, token) =>
    dispatch(actions_contact.loadList({ user_id, token })),

  LoadContactsOnline: (user_id, token) =>
    dispatch(actions_event.loadContactsOnline({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
