import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";

class PublishVertical extends Component {


  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="d-none d-lg-block col-md-12">
          <div className="col-md-12 text-center">
            <small>
              <Translate value="ad" />
            </small>
          </div>

          <Link to="http://www.azriel.com" target="_blank">
            <img
              src="https://godiwayusev.s3.amazonaws.com/markets/ce08e5460a8e5a1fa8c3fadf2f8def7d.jpg"
              alt="Publicidad"
              className="card-img-top img-responsive"
            />
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default PublishVertical;
