export const ADCOMMENT_LIST_ATTEMPT = "ADCOMMENT_LIST_ATTEMPT";
export const ADCOMMENT_LIST_SUCCESS = "ADCOMMENT_LIST_SUCCESS";
export const ADCOMMENT_LIST_FAILED = "ADCOMMENT_LIST_FAILED";

export const ADCOMMENT_NEW_ATTEMPT = "ADCOMMENT_NEW_ATTEMPT";
export const ADCOMMENT_NEW_SUCCESS = "ADCOMMENT_NEW_SUCCESS";
export const ADCOMMENT_NEW_FAILED = "ADCOMMENT_NEW_FAILED";

export const ADCOMMENT_LIKE_SUCCESS = "ADCOMMENT_LIKE_SUCCESS";
export const ADCOMMENT_LIKE_FAILED = "ADCOMMENT_LIKE_FAILED";
//////////////////////////

export const COMMENT_SET_ITEM = "COMMENT_SET_ITEM";

export const ADCOMMENT_DELETE_SUCCESS = "ADCOMMENT_DELETE_SUCCESS";
export const ADCOMMENT_DELETE_FAILED = "ADCOMMENT_DELETE_FAILED";

export const REPORT_COMMENT_SUCCESS = "REPORT_COMMENT_SUCCESS";
export const REPORT_COMMENT_FAILED = "REPORT_COMMENT_FAILED";

export const ADCOMMENT_MORE_ATTEMPT = "ADCOMMENT_MORE_ATTEMPT";
export const ADCOMMENT_MORE_SUCCESS = "ADCOMMENT_MORE_SUCCESS";
export const ADCOMMENT_MORE_FAILED = "ADCOMMENT_MORE_FAILED";


export const LOGOUT = "LOGOUT";
