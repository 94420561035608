
import {
    AD_LIST_ATTEMPT,
    AD_LIST_SUCCESS,
    AD_LIST_FAILED,
    ////////////
    AD_MORELIST_ATTEMPT,
    AD_MORELIST_SUCCESS,
    AD_MORELIST_FAILED,

    LOGOUT

} from '../types/adListTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
    ////////////////////
    item: {},
    ////////////////////
    loadingMore: false,
    page_index: 0,
    page_size: 0,
    page_count: 0,
};
function adList(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AD_LIST_ATTEMPT:
            return {
                ...state,
                loading: true,
            }

        case AD_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: action.data.size,
                error: ''
            }

        case AD_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }
        //////////////////////////////////////////////////
        case AD_MORELIST_ATTEMPT:
            return {
                ...state,
                loadingMore: true,
            }

        case AD_MORELIST_SUCCESS:
            return {
                ...state,
                loadingMore: false,
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: state.page_count + action.data.size,
                list: state.list.concat(action.data.list)
            }

        case AD_MORELIST_FAILED:
            return {
                ...state,
                loadingMore: false,
                //errorMore: action.error,
            }
        case LOGOUT:
            return INITIAL_STATE;
        default: return state;
    }
};
export default adList;