import React from "react";
import "./styles/DropDownNotification.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions_notification from "../redux/actions/notificationActions";
import * as actions_event from "../redux/actions/eventActions";
import { FaSearch, FaBell, FaCartArrowDown } from "react-icons/fa";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
/*
{
  Kind:'MESSAGE',
  TempId:41,
  Photo:'',
  Message:'',
  Detail:''
}
 */


class DropDownNotificationItem extends React.Component {
  render() {
    const kind = this.props.item.Kind;
    var route = "";
    switch (kind) {
      case "FAQ":
        route = "/faq";
        break;
      case "AD":
        route = `/goder/show/${this.props.item.TempId}`;
        break;
      case "MESSAGE":
        route = `/chat/${this.props.item.TempId}`;
        break;
      case "TRANSACTION":
        route = "/transaction";
        break;
      case "TRANSACTION_REQUEST":
        route = "/transaction/request";
        break;
      case "MARKETSTORE":
        route = `/marketstore/show/${this.props.item.TempId}`;
        break;
      case "COMMENT":
        route = "/panel";
        break;

      default:
        route = "/panel";
        break;
    }
    return (
      <Link className="nav-link" to={route} onClick={this.props.onClick}>
        <li className="nav-item">
          <div className="media">
            <div className="media-left">
              <div className="media-object">
                <img
                  src={this.props.item.Avatar}
                  className="notification-img-circle"
                  alt="50x50"
                />
              </div>
            </div>
            <div className="notification-media-body">
              <strong className="notification-title">

                <Translate value={this.props.item.Message} />
              </strong>
              <div className="notification-meta">
                <small className="timestamp">
                  {this.props.item.Detail}
                </small>
                <small className="timestamp">
                  {this.props.item.CreatedAt}
                </small>
              </div>
            </div>
          </div>
        </li>
      </Link>
    );
  }
}
//   
class DropdownNotification extends React.Component {
  state = {
    displayMenu: false
  };
  // constructor() {
  //   super();
  //   // this.showDropdownMenu = this.showDropdownMenu.bind(this);
  //   // this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  //   this.changeDropdownMenu = this.changeDropdownMenu.bind(this);
  //   this.fetchNotificationList();
  // }

  componentDidMount() {

    this.fetchNotificationList();
  }

  fetchNotificationList = async () => {
    // console.log("entra");
    try {
      await this.props
        .LoadNotifications(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // showDropdownMenu(event) {
  //   event.preventDefault();
  //   this.setState({ displayMenu: true }, () => {
  //     document.addEventListener("click", this.hideDropdownMenu);
  //   });
  // }

  // hideDropdownMenu() {
  //   this.setState({ displayMenu: false }, () => {
  //     document.removeEventListener("click", this.hideDropdownMenu);
  //   });
  // }

  changeDropdownMenu(event) {
    event.preventDefault();
    clearInterval(this.timer);
    this.setState({ displayMenu: !this.state.displayMenu });
  }

  onClickReadAll = () => {
    this.setState({ displayMenu: false });
    if (this.props.notificationCount > 0) {
      this.props.SetNotificationReadAll(this.props.user_id, this.props.token);

      this.props.LoadBadget(this.props.token, this.props.user_id);

    }
  };

  onClickViewNotification = item => {
    this.setState({ displayMenu: false });
    this.props.SetNotificationRead(
      item.Id,
      this.props.user_id,
      this.props.token
    );
    this.props.LoadBadget(this.props.token, this.props.user_id);
  };

  lostBlur = (event) => {
    // event.preventDefault();
    this.timer = setInterval(() => {
      this.setState({ displayMenu: false });
    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        <div className="dropdown_notification">
          <div className="button" tabIndex={0} onClick={this.changeDropdownMenu.bind(this)} onBlur={this.lostBlur}>
            <FaBell />
            {this.props.notificationCount > 0 && (
              <span className="badge">{this.props.notificationCount}</span>
            )}
          </div>

          {this.state.displayMenu ? (
            <ul>
              <div className="dropdown-container">
                <div className="dropdown-head">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      this.onClickReadAll();
                    }}
                  >
                    <small>
                      <Translate value="notification_mark_all" />{" "}
                    </small>
                  </button>
                  <div className="notification-count">
                    <small>
                      <Translate value="notifications" /> (
                      {this.props.notificationCount})
                    </small>
                  </div>
                </div>

                <div className="dropdown-divider"></div>
                {this.props.notificationList.map(item => {
                  return (
                    <DropDownNotificationItem
                      key={item.Id}
                      item={item}
                      onClick={() => {
                        this.onClickViewNotification(item);
                      }}
                    />
                  );
                })}

                <div className="dropdown-divider"></div>

                <div className="dropdown-footer text-center">
                  <Link className="dropdown-item" to="/notification/all" onClick={this.lostBlur} >
                    <Translate value="notification_see_all" />
                  </Link>
                </div>
              </div>
            </ul>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
//<hr className="dividerGray" />
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    user: reducer.session.auth,

    notificationList: reducer.notification.list,
    pageCount: reducer.notification.page_count,
    error: reducer.notification.error,
    loading: reducer.notification.loading,

    notificationCount: reducer.event.tab_badget_notification,
  };
}

const mapDispatchToProps = dispatch => ({
  LoadNotifications: (user_id, token) =>
    dispatch(actions_notification.loadList({ user_id, token })),
  SetNotificationRead: (id, user_id, token) =>
    dispatch(actions_notification.setNotificationRead({ id, user_id, token })),
  SetNotificationReadAll: (user_id, token) =>
    dispatch(actions_notification.setNotificationReadAll({ user_id, token })),
  ////////////////////
  LoadBadget: (token, user_id) => dispatch(actions_event.loadBadget({ token, user_id })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropdownNotification);
