import {
  AD_STORE_ATTEMPT,
  AD_STORE_SUCCESS,
  AD_STORE_FAILED,
  /////
  AD_STORE_MORE_ATTEMPT,
  AD_STORE_MORE_SUCCESS,
  AD_STORE_MORE_FAILED,
  ////////////////
  AD_STORE_CLEAR,
} from "../types/marketstoreAdListTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  loadingMore: false,
  page_count: 0,
  page_size: 0
};

function marketstoreAdList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_STORE_ATTEMPT:
      return {
        ...state,
        loading: true,
        list: [],
      };
    //  break;
    case AD_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: "",
        page_count: action.data.size,
        page_size: action.data.size,
      };
    //break;
    case AD_STORE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    ///////////////////////////////////////////
    case AD_STORE_MORE_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };
    //  break;
    case AD_STORE_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_count: state.page_count + action.data.size,
        page_size: action.data.size,
      };
    //break;
    case AD_STORE_MORE_FAILED:
      return {
        ...state,
        loadingMore: false,
      };//////////////////////////////
    case AD_STORE_CLEAR:
      return {
        ...state,
        list: [],
      };
    default:
      return state;
  }
}
export default marketstoreAdList;
