export const TRANSACTION_REQUEST_LIST_ATTEMPT =
  "TRANSACTION_REQUEST_LIST_ATTEMPT";
export const TRANSACTION_REQUEST_LIST_SUCCESS =
  "TRANSACTION_REQUEST_LIST_SUCCESS";
export const TRANSACTION_REQUEST_LIST_FAILED =
  "TRANSACTION_REQUEST_LIST_FAILED";


export const TRANSACTION_REQUEST_NEW_SUCCESS =
  "TRANSACTION_REQUEST_NEW_SUCCESS";
export const TRANSACTION_REQUEST_NEW_FAILED = "TRANSACTION_REQUEST_NEW_FAILED";

export const TRANSACTION_REQUEST_CALIFICATION_SUCCESS =
  "TRANSACTION_REQUEST_CALIFICATION_SUCCESS";
export const TRANSACTION_REQUEST_CALIFICATION_FAILED =
  "TRANSACTION_REQUEST_CALIFICATION_FAILED";


export const TRANSACTION_REQUEST_MORELIST_ATTEMPT =
  "TRANSACTION_REQUEST_MORELIST_ATTEMPT";
export const TRANSACTION_REQUEST_MORELIST_SUCCESS =
  "TRANSACTION_REQUEST_MORELIST_SUCCESS";
export const TRANSACTION_REQUEST_MORELIST_FAILED =
  "TRANSACTION_REQUEST_MORELIST_FAILED";


export const LOGOUT = "LOGOUT";