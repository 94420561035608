import {
    /*SEND_EVENT_SUCCESS,
    SEND_EVENT_FAILED,
    ////////
    RECEIVE_EVENT_CHAT,
    REMOVE_EVENT_CHAT,
    //////////
    CLEAR_CHAT_USERID,
    RECEIVE_EVENT_NOTIFICATION,
    REMOVE_EVENT_NOTIFICATION,
    SET_COUNT_NOTIFICATION,
    /////////
    UPDATE_STATE,
    ADD_MEMBER_ACTIVE,
    REMOVE_MEMBER_ACTIVE,

    /////////////////////*/

    /////////////////////
    UPDATE_BADGET_NAVBAR_SUCCESS,
    UPDATE_BADGET_NAVBAR_FAILED,
    ///////////////////
    EVENT_CHAT,
    CLEAR_CONTACT_EVENT,
    UPDATE_CONTACT_EVENT,

} from '../types/eventTypes';

const INITIAL_STATE = {
    error: '',
    success: '',

    tab_badget_chat: 0,
    tab_badget_notification: 0,
    ///////////////////////////////
    contact: {},
    event: {},
    loading: false
};
const event = (state = INITIAL_STATE, action) => {
    switch (action.type) {

        /* case RECEIVE_MEMBERS: {
             return {
                 ...state,
                 membersList: action.list,
             };
         }
         case RECEIVE_MEMBER: {
             return {
                 ...state,
                 membersList: [...state.membersList, action.data],
             };
         }
         case REMOVE_MEMBER: {
             // const index = state.membersList.findIndex(member => member.id === action.data.id);
             if (index !== -1) {
             const newState = state.membersList.splice(index, 1)[0];
             return {
                 ...state,
                 membersList: newState,
             };
         }
             const newState = state.membersList.filter(member => {
                 return member.id !== action.data.id;
             });
             return {
                 ...state,
                 membersList: newState,
             };
 
 
         }
 */
        case EVENT_CHAT:
            {
                if (state.contact.UserId === action.data.id)
                    return {
                        ...state,
                        event: action.data,
                    };
                else return state;
            }
        ///////////////////////////////////////
        case UPDATE_CONTACT_EVENT:
            return {
                ...state,
                contact: action.item
            }
        case CLEAR_CONTACT_EVENT:
            return {
                ...state,
                contact: {}
            }
        /////////////////////////////////
        case UPDATE_BADGET_NAVBAR_SUCCESS:
            return {
                ...state,
                tab_badget_notification: action.item.notificationBadget,
                error: ""
            };

        case UPDATE_BADGET_NAVBAR_FAILED:
            return {
                ...state,
                error: action.error,
            };
        /*
                case CLEAR_CHAT_USERID:
                    return {
                        ...state,
                        chat_user_id: '',
                    };
        
                case RECEIVE_EVENT_CHAT: { ///agraga al tab numero de conversacion
                    var newState = state.contacts_message_unready;
                    if (state.chat_user_id != action.data.from) {
                        if (newState[parseInt(action.data.from)]) {
                            newState[parseInt(action.data.from)] += 1;
                        } else {
                            newState[parseInt(action.data.from)] = 1;
                        }
                        var size = Object.keys(newState).length;
                        return {
                            ...state,
                            contacts_message_unready: newState,
                            tab_badget_chat: size,
                        };
                    } else return state;
                }
        
                case REMOVE_EVENT_CHAT: { ///quita del tab badget
                    const newState = state.contacts_message_unready; //.filter(item => {
        
                    if (newState[action.id]) newState.splice(action.id, 1);
                    let size = Object.keys(newState).length;
        
                    return {
                        ...state,
                        chat_user_id: action.id,
                        contacts_message_unready: newState,
                        tab_badget_chat: size,
                    };
                }
                ///////////////////////////
                case RECEIVE_EVENT_NOTIFICATION:
                    return {
                        ...state,
                        tab_badget_notification: state.tab_badget_notification + 1,
                    };
        
                case REMOVE_EVENT_NOTIFICATION:
                    return {
                        ...state,
                        tab_badget_notification: 0,
                    };
                case SET_COUNT_NOTIFICATION:
                    return {
                        ...state,
                        tab_badget_notification: action.notificationCount,
                    };
                //////////////////////////////
                case UPDATE_STATE:
                    return {
                        ...state,
                        state: action.state,
                    };
        
                case ADD_MEMBER_ACTIVE:
                    return {
                        ...state,
                        members_active: [...state.members_active, action.member],
                    };
                case REMOVE_MEMBER_ACTIVE: {
                    const newState = state.members_active.filter(member => {
                        return member.id !== action.member.id; // return all the items not matching the action.id
                    });
                    return {
                        ...state,
                        members_active: newState,
                    };
                }
        */
        default:
            return state;
    }
};
export default event;
