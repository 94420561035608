export const MEDIA_LIST_ATTEMPT = 'MEDIA_LIST_ATTEMPT';
export const MEDIA_LIST_SUCCESS = 'MEDIA_LIST_SUCCESS';
export const MEDIA_LIST_FAILED = 'MEDIA_LIST_FAILED';

export const MEDIA_DELETE_SUCCESS = 'MEDIA_DELETE_SUCCESS';
export const MEDIA_DELETE_FAILED = 'MEDIA_DELETE_FAILED';

export const MEDIA_NEW_SUCCESS = 'MEDIA_NEW_SUCCESS';
export const MEDIA_NEW_LOADING = 'MEDIA_NEW_LOADING';
export const MEDIA_NEW_FAILED = 'MEDIA_NEW_FAILED';

export const MEDIA_RESET_LIST = 'MEDIA_RESET_LIST';

export const LOGOUT = "LOGOUT";






