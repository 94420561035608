import {
  TRANSACTIONF_LIST_ATTEMPT,
  TRANSACTIONF_LIST_SUCCESS,
  TRANSACTIONF_LIST_FAILED,
  ////////////////////////
  TRANSACTIONF_MORELIST_ATTEMPT,
  TRANSACTIONF_MORELIST_SUCCESS,
  TRANSACTIONF_MORELIST_FAILED,
} from "../types/transactionFinishTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  page_size: 0,
  page_count: 0,
  loadingMore: false
};

function transactionFinish(state = INITIAL_STATE, action) {
  //console.log(action);
  switch (action.type) {

    case TRANSACTIONF_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    //  break;
    case TRANSACTIONF_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: "",
      };
    //break;
    case TRANSACTIONF_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    ///////////////////////////////////
    case TRANSACTIONF_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };
    //  break;
    case TRANSACTIONF_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: "",
      };
    //break;
    case TRANSACTIONF_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    ///////////////////////////////////

    default:
      return state;
  }
}

export default transactionFinish;
