import React from "react";
import AdPanelList from "../components/AdPanelList";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import { connect } from "react-redux";
import * as actions_active from "../redux/actions/adAuctionActions";
import * as actions_inactive from "../redux/actions/adAuctionInactiveActions";
import "./styles/AdPublish.css";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import Tabs from "../components/Tabs";
class AdAuction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.fetchAdAuctionList();
    }

    fetchAdAuctionList = async () => {
        try {
            await this.props
                .LoadPublish(this.props.user_id, this.props.token)
                .then(() => {
                    if (this.props.error) {
                        alertify.warning(this.props.error);
                    }
                });
            await this.props
                .LoadPublishI(this.props.user_id, this.props.token)
                .then(() => {
                    if (this.props.errori) {
                        alertify.warning(this.props.errori);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    onClickLoadMore = async () => {
        try {
            await this.props
                .LoadPublishMore(this.props.pageCount, this.props.user_id, this.props.token)
                .then(() => {
                    if (this.props.error) {
                        alertify.warning(this.props.error);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }

    onClickLoadMoreI = async () => {
        try {
            await this.props
                .LoadPublishMoreI(this.props.pageCounti, this.props.user_id, this.props.token)
                .then(() => {
                    if (this.props.errori) {
                        alertify.warning(this.props.errori);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    }


    render() {
        return (
            <React.Fragment>
                <PanelLayout>
                    <div className="container-btn-title">
                        <h3 className="panel-title-left">
                            <Translate value="auction" />
                        </h3>
                        <div className="panel-button-right">
                            <Link className="btn btn-primary" to="/goder/new/auction">
                                <Translate value="new" />
                            </Link>
                        </div>
                    </div>

                    <Tabs>
                        <div label={I18n.t("ad_active")} className="col-12">
                            {
                                this.props.loading ? (
                                    <PageLoading />
                                )
                                    :
                                    (
                                        <AdPanelList
                                            adPanelList={this.props.adListActive || []}
                                            pageSize={this.props.pageSize}
                                            loadingMore={this.props.loadingMore}
                                            pageCount={this.props.pageCount}
                                            onClickLoadMore={() => {
                                                this.onClickLoadMore();
                                            }} />
                                    )
                            }
                        </div>
                        <div label={I18n.t("ad_inactive")} className="col-12">
                            {this.props.loading_inactive ? (
                                <PageLoading />
                            )
                                : (
                                    <AdPanelList adPanelList={this.props.adListInactive || []}
                                        pageSize={this.props.pageSizei}
                                        loadingMore={this.props.loadingMorei}
                                        pageCount={this.props.pageCounti}
                                        onClickLoadMore={() => {
                                            this.onClickLoadMorei();
                                        }}
                                    />
                                )}

                        </div>
                    </Tabs>
                </PanelLayout>
            </React.Fragment>
        );
    }
}

function mapStateToProps(reducer) {
    //  console.log(reducer);
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,

        adListActive: reducer.adAuction.list,
        error: reducer.adAuction.error,
        loading: reducer.adAuction.loading,

        loadingMore: reducer.adAuction.loadingMore,
        pageCount: reducer.adAuction.page_count,
        pageSize: reducer.adAuction.page_size,

        adListInactive: reducer.adAuctionInactive.list,
        loading_inactive: reducer.adAuctionInactive.loading,
        errori: reducer.adAuctionInactive.error,

        loadingMorei: reducer.adAuctionInactive.loadingMore,
        pageCounti: reducer.adAuctionInactive.page_count,
        pageSizei: reducer.adAuctionInactive.page_size,


    };
}
const mapDispatchToProps = dispatch => ({
    LoadPublish: (user_id, token) =>
        dispatch(actions_active.loadList({ user_id, token })),
    LoadPublishMore: (page_count, user_id, token) =>
        dispatch(actions_active.loadListScroll({ page_count, user_id, token })),

    LoadPublishI: (user_id, token) =>
        dispatch(actions_inactive.loadList({ user_id, token })),
    LoadPublishMoreI: (page_count, user_id, token) =>
        dispatch(actions_inactive.loadListScroll({ page_count, user_id, token })),

});

export default connect(mapStateToProps, mapDispatchToProps)(AdAuction);

/**
 * 
 *  

 *   
 */