import React, { useState, useRef } from "react";
import "./styles/BlogCarousel.css";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
const icon_size = 30;
const porcent = 80;
function BlogCarouselItem(props) {

    function _getName(name) {
        // let { name } = this.props.bider.Name;
        return name
            .split(' ')
            .map(item => item.charAt(0))
            .slice(0, 2)
            .join('').toUpperCase();
    }
    return (

        <div className="card">
            <div className="row">
                <div className="col-12 text-center mt-2">
                    {(props.porcent === 0 && (
                        <FaRegStar color={"#f8b908"} size={icon_size} />
                    )) ||
                        (props.porcent > 0 && props.porcent < 10 && (
                            <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                        )) ||
                        (props.porcent >= 10 && (
                            <FaStar color={"#f8b908"} size={icon_size} />
                        ))}
                    {"   "}
                    {(props.porcent < 20 && (
                        <FaRegStar color={"#f8b908"} size={icon_size} />
                    )) ||
                        (props.porcent >= 20 && props.porcent < 30 && (
                            <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                        )) ||
                        (props.porcent >= 30 && (
                            <FaStar color={"#f8b908"} size={icon_size} />
                        ))}
                    {"   "}
                    {(props.porcent < 40 && (
                        <FaRegStar color={"#f8b908"} size={icon_size} />
                    )) ||
                        (props.porcent >= 40 && props.porcent < 50 && (
                            <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                        )) ||
                        (props.porcent >= 50 && (
                            <FaStar color={"#f8b908"} size={icon_size} />
                        ))}
                    {"   "}
                    {(props.porcent < 60 && (
                        <FaRegStar color={"#f8b908"} size={icon_size} />
                    )) ||
                        (props.porcent >= 60 && props.porcent < 70 && (
                            <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                        )) ||
                        (props.porcent >= 70 && (
                            <FaStar color={"#f8b908"} size={icon_size} />
                        ))}
                    {"   "}
                    {(props.porcent < 80 && (
                        <FaRegStar color={"#f8b908"} size={icon_size} />
                    )) ||
                        (props.porcent >= 80 && props.porcent < 90 && (
                            <FaStarHalfAlt color={"#f8b908"} size={icon_size} />
                        )) ||
                        (props.porcent >= 90 && (
                            <FaStar color={"#f8b908"} size={icon_size} />
                        ))}
                </div>
            </div>

            <div className="card-body">
                {props.content}
            </div>
            <div className={"avatar medium"}>
                <span>{_getName(props.name)}</span>
            </div>
            <div className="card-title">
                {props.name}
            </div>
        </div>
    );
}
/**<div className="blogcaroulsel-avatar">
                <img
                    src={props.photo}
                    className="rounded-circle"
                    alt="Gw"
                />
            </div> */
class BlogCarousel extends React.Component {
    state = {

        data: [
            {
                name: "Juan C. Rendon",
                content: "Realice la compra, contacte al vendedor, negociamos y recibi el articulo sin novedad.",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 85
            },
            {
                name: "John Guzman",
                content: "Buen vendedor, buena plataforma gracias",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 70
            },
            {
                name: "Maria Zapata",
                content: "me llego el producto bien, sin novedad",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 95
            },

            {
                name: "Carlos Guaman",
                content: "Vendedor muy muy confiable .",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 90
            },
            {
                name: "Mery Garcia",
                content: "Gracias a Dios, todo bien ",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 70
            },
            {
                name: "Zaila Merino F",
                content: "Me arrepenti de hacer la compra.",
                photo: "https://www.godiway.com/api/img/avatar-48.jpg",
                porcent: 60
            },
        ]

    }
    render() {
        var index1, index2, index3;
        index1 = Math.floor(Math.random() * (6 - 0)) + 0;
        index2 = index1 + 1;
        index2 = index2 > 5 ? 0 : index2;
        index3 = index2 + 1;
        index3 = index3 > 5 ? 0 : index3;
        return (
            <React.Fragment>
                <div className="container-blogcarousel">
                    <div className="container-blogcarousel-body ">
                        <div className="blogcarousel-item ">
                            <BlogCarouselItem
                                name={this.state.data[index1].name}
                                content={this.state.data[index1].content}
                                photo={this.state.data[index1].photo}
                                porcent={this.state.data[index1].porcent}
                            />
                        </div>
                        <div className="blogcarousel-item ">
                            <BlogCarouselItem
                                name={this.state.data[index2].name}
                                content={this.state.data[index2].content}
                                photo={this.state.data[index2].photo}
                                porcent={this.state.data[index2].porcent}
                            />
                        </div>
                        <div className="blogcarousel-item ">
                            <BlogCarouselItem
                                name={this.state.data[index3].name}
                                content={this.state.data[index3].content}
                                photo={this.state.data[index3].photo}
                                porcent={this.state.data[index3].porcent}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}
export default BlogCarousel;