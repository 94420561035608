import React from "react";
import ReactDOM from "react-dom";
//redux
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import redux from "./redux";
import thunk from "redux-thunk";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import detectBrowserLanguage from "detect-browser-language";
//redux
import "bootstrap/dist/css/bootstrap.css";
import "./global.css";
import App from "./routes/App";

import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";
import { initConextionSocket } from './redux/actions/eventActions';

const translations = {
  en: require("./assets/lang/en.json"),
  es: require("./assets/lang/es.json"),
  he: require("./assets/lang/he.json"),
};
const middleware = applyMiddleware(thunk);
const container = document.getElementById("app");
const store = createStore(redux, middleware);
let persistor = persistStore(store);
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
store.dispatch(setLocale(detectBrowserLanguage().substring(0, 2)));
store.dispatch(initConextionSocket());

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  container
);
