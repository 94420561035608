import React from "react";
import "./styles/Information.css";
//import api from "../api";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

class WhatIsDonation extends React.Component {
  componentDidMount() {
    // this.fetchPrepol();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-information">
            <div className="card">
              {this.props.lang === "es" ? (
                <React.Fragment>
                  <div className="card-header text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/donaciones.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>¿Que es Donar?</h3>
                  </div>
                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      Donación es el acto y el resultado de donar, regalar,
                      ceder de manera voluntaria, traspasar algo sin esperar
                      nada a cambio. El concepto proviene del vocablo latino
                      donatio dar.
                    </p>
                    <p>
                      {" "}
                      Por lo general una donación consiste en la entrega de
                      dinero o bienes como una acción caritativa. Quien realiza
                      la donación espera que su aporte ayude a mejorar la
                      calidad de vida de aquel que necesita asistencia para
                      subsistir o salir adelante.
                    </p>
                    <p>
                      Muchas organizaciones sin fines de lucro tienen como
                      principal fuente de financiamiento las donaciones de los
                      interesados.
                    </p>
                    <p>
                      Hay instituciones que reciben donaciones de manera
                      constante para asistir periódicamente a las personas más
                      pobres. En otros casos, se llevan a cabo campañas
                      especiales para ayudar a las víctimas de determinados
                      fenómenos o sucesos (inundaciones, terremotos, guerras
                      civiles, etc.).
                    </p>
                    <p>
                      Tener que en cuenta que en el proceso de Donación del
                      sitio es anunciar las donaciones del Donante (Usuarios
                      registrados que deseen donar) y vincularlo con el o los
                      posibles donatarios (Usuarios registrados que requieran de
                      donaciones). El Donante discriminara a los Donatarios y
                      proveerá al donatario que le parezca pertinente bajo sus
                      propios criterios.
                    </p>
                    <p>Godiway no solicita, ni provee donaciones.</p>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="card-header text-center">
                    <img
                      src="https://www.godiway.com/themes/frontend/img/donaciones.svg"
                      alt="Dona y recibe productos"
                      height="50"
                    />
                    <h3>What is Donate?</h3>
                  </div>
                  <div className="card-body text-justify">
                    <p>
                      {" "}
                      Donation is the act and the result of donating, giving
                      away, giving in voluntarily, transferring something
                      without expecting anything in return. The concept comes
                      from the Latin word donatio dar.
                    </p>
                    <p>
                      {" "}
                      Usually a donation consists of the delivery of money or
                      property as a charitable action. Who performs The donation
                      hopes that your contribution will help improve the quality
                      of life of those who need assistance to subsist or get
                      ahead.
                    </p>
                    <p>
                      Many non-profit organizations have The main source of
                      financing is donations from interested.
                    </p>
                    <p>
                      There are institutions that receive donations so constant
                      to periodically assist the most poor. In other cases,
                      campaigns are carried out to help victims of certain
                      phenomena or events (floods, earthquakes, wars civilians,
                      etc.).
                    </p>
                    <p>
                      Bear in mind that in the Donation process of the site is
                      to announce Donor donations (Users registered wishing to
                      donate) and link it with the potential donors (Registered
                      users who require donations). The Donor will discriminate
                      against the Donors and will provide the grantee as it
                      deems appropriate under its own criteria.
                    </p>
                    <p>Godiway does not solicit or provide donations.</p>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(WhatIsDonation);
