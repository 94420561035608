import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/PanelMenu.css";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";

class PanelMenu extends React.Component {
  render() {
    return (
      <div className="container-panel-menu ">
        <div className="card">
          <nav className="nav flex-column">
            <Link className="nav-link " to="/">

              <Translate value="ads" />

            </Link>
            <Link className="nav-link disabled" to="#">
              <small>
                {" "}
                <Translate value="wish_title" />
              </small>
            </Link>
            <Link className="nav-link" to="/wish">
              <li className="nav-link ">
                <Translate value="wish_title" />
              </li>
            </Link>

            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="iteraction" />
              </small>
            </Link>

            <Link className="nav-link" to="/faq">
              <li className="nav-link">
                <Translate value="faq_title" />
              </li>
            </Link>
            <Link className="nav-link" to="/contacts">
              <li className="nav-link">
                <Translate value="contact_title" />
              </li>
            </Link>

            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="my_ads" />
              </small>
            </Link>

            <Link className="nav-link" to="/panel/sales">
              <li className="nav-link">
                <Translate value="sales" />
              </li>
            </Link>
            <Link className="nav-link" to="/panel/rents">
              <li className="nav-link">
                <Translate value="rents" />
              </li>
            </Link>
            <Link className="nav-link" to="/panel/exchanges">
              <li className="nav-link">
                <Translate value="exchanges" />
              </li>
            </Link>
            <Link className="nav-link" to="/panel/donations">
              <li className="nav-link">
                <Translate value="donations" />
              </li>
            </Link>


            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="transaction" />
              </small>
            </Link>

            <Link className="nav-link" to="/transaction">
              <li className="nav-link">
                <Translate value="transaction_ad" />
              </li>
            </Link>
            <Link className="nav-link" to="/transaction/request">
              <li className="nav-link">
                <Translate value="transaction_request" />
              </li>
            </Link>

            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="marketstore_title" />
              </small>
            </Link>

            <Link className="nav-link" to="/panel/marketstore">
              <li className="nav-link">
                <Translate value="marketstores_mine" />
              </li>
            </Link>
            <Link className="nav-link" to="/marketstores">
              <li className="nav-link">
                <Translate value="marketstores_title" />
              </li>
            </Link>



            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="billing" />
              </small>
            </Link>

            <Link className="nav-link" to="/mysuscription">
              <li className="nav-link">
                <Translate value="suscription_mine" />
              </li>
            </Link>

            <Link className="nav-link" to="/customer">
              <li className="nav-link">
                <Translate value="order_data" />
              </li>
            </Link>
            <Link className="nav-link" to="/paylist">
              <li className="nav-link">
                <Translate value="pay_did" />
              </li>
            </Link>
            {/* <Link className="nav-link" to="#">
              <li className="nav-link">
                <Translate value="my_wallet" />
              </li>
            </Link> */}
          </nav>
        </div>
      </div>
    );
  }
}

export default PanelMenu;

/* 

 <Link className="nav-link" to="/panel/auction">
              <li className="nav-link">
                <Translate value="auction" />
              </li>
            </Link>
<Link className="nav-link" to="/goder/exchanges">
                            <li className="nav-link">
                               Intercambios
                            </li>
                        </Link>
                        <Link className="nav-link" to="/goder/donations">
                            <li className="nav-link">
                                Donaciones
                            </li>
                        </Link>
                        <Link className="nav-link" to="#">
                            <li className="nav-link">
                                Express
                            </li>
                        </Link>




                  



<Link className="nav-link" to="/contacts">
                            <li className="nav-link">
                                  Intercambios
                            </li>
                        </Link>
                        <Link className="nav-link" to="/contacts">
                            <li className="nav-link">
                                  Donacines
                            </li>
                        </Link>   

                         <Link className="nav-link" to="/transaction/exchanges">
                             <li className="nav-link">
                                  Intercambios
                            </li>
                        </Link>
                        <Link className="nav-link" to="/transaction/donations">
                            <li className="nav-link">
                                 Donaciones
                            </li>
                        </Link>    
<Link className="nav-link" to="/cardlist">
              <li className="nav-link">
                <Translate value="kind_pay" />
              </li>
            </Link>
                    */
