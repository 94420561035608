import {
  PRIVACY_ATTEMPT,
  PRIVACY_SUCCESS,
  PRIVACY_FAILED,
  ////////////////////
  PRIVACY_UPDATE_SUCCESS,
  PRIVACY_UPDATE_FAILED
} from "../types/privacyTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  ///////
  error_update: "",
  success: ""
};

function privacy(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRIVACY_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case PRIVACY_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: ""
      };

    case PRIVACY_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    ////////////////////////////
    case PRIVACY_UPDATE_SUCCESS:
      return {
        ...state,
        success: action.success,
        error_update: ""
      };

    case PRIVACY_UPDATE_FAILED:
      return {
        ...state,
        error_update: action.error
      };

    default:
      return state;
  }
}
export default privacy;
