import {
  SET_LANG_ENGLISH,
  SET_LANG_SPANISH
} from "../types/configurationTypes";

const INITIAL_STATE = {
  lang: "es",
  time_zone: ""
};

function configuration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LANG_ENGLISH:
      return {
        ...state,
        lang: "en"
      };

    case SET_LANG_SPANISH:
      return {
        ...state,
        lang: "es"
      };

    default:
      return state;
  }
}
export default configuration;
