import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adCommentActions";
import "./styles/AdComment.css";
import { Translate, I18n } from "react-redux-i18n";
import CommentBtnRight from "./CommentBtnRight";
import {
  FaPaperPlane,
  FaCamera,
  //FaComment,
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import MiniLoader from "./MiniLoader";

class AdCommentItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="cardbox shadow-lg bg-white">
          <div className="cardbox-heading">
            <CommentBtnRight
              item={this.props.comment}
              key={this.props.comment.Id}
              onClickRemove={this.props.onClickRemove}
              onClickReport={this.props.onClickReport}
            />
            <div className="media m-0">
              <div className="d-flex mr-3">
                <Link to="#">
                  <img
                    className="img-fluid rounded-circle"
                    src={this.props.comment.Avatar}
                    alt="User"
                  />
                </Link>
              </div>
              <div className="media-body">
                <Link to="#">
                  <p className="m-0">{this.props.comment.Name}</p>
                </Link>
                <small>
                  <span>{this.props.comment.CreatedAt}</span>
                </small>
              </div>
            </div>
          </div>
          <div className="cardbox-item">
            <small className="cardbox-comment">
              <span>{this.props.comment.Text}</span>
            </small>
            {this.props.comment.MediaCount > 0 && (
              <div className="text-center">
                <img
                  src={this.props.comment.Media}
                  className="img-fluid"
                  alt="godiway-img"
                />
              </div>
            )}
          </div>
          <div className="cardbox-base">
            <ul>
              <li>
                <Link to="">
                  <em className="mr-3">
                    <small id="up_count403">
                      {this.props.comment.CountLike}
                    </small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.props.onClickUp}
                >
                  <i>
                    {this.props.comment.Like === 1 ? (
                      <FaThumbsUp />
                    ) : (
                      <FaRegThumbsUp />
                    )}
                  </i>
                </button>
              </li>
              <li>
                <Link to="">
                  <em className="mr-3">
                    <small id="down_count403">
                      {this.props.comment.CountUnlike}
                    </small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.props.onClickDown}
                >
                  <i>
                    {this.props.comment.Like === 0 ? (
                      <FaThumbsDown />
                    ) : (
                      <FaRegThumbsDown />
                    )}
                  </i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
class AdComment extends React.Component {
  state = {
    comment_text: "",
    disabled: true,
    image: "",
    file: "",
    hasFile: false,
    loading: false,
  };

  async componentDidMount() {
    if (JSON.stringify(this.props.user) !== "{}")
      await this.props
        .LoadAdComment(
          this.props.token, {
          comment_id: this.props.ad.CommentId,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
  }

  onChangeImage = async (e) => {
    e.preventDefault();
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );

      return;
    }
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        image: reader.result,
        hasFile: true,
        disabled: false,
      });
    };
    reader.readAsDataURL(file);
  };

  onClickSendComment = async () => {
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }

    if (this.state.hasFile || this.state.comment_text !== "") {
      this.setState({ loading: true });
      let params = new FormData();
      if (this.state.hasFile) {
        params.append("image", this.state.file);
      }
      if (this.state.comment_text !== "") {
        params.append("comment", this.state.comment_text);
      }
      params.append("comment_id", this.props.ad.CommentId);
      params.append("id", this.props.user_id);

      await this.props.AddComment(this.props.token, params).then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        } else {
          this.setState({
            comment_text: "",
            image: "",
            file: "",
            hasFile: false,
            disabled: true,
            loading: false,
          });
        }
      });
    }
  };

  onChangeComment = (event) => {
    const comment = event.target.value;
    this.setState({ comment_text: comment });
    if (comment.lenght === 0) this.setState({ disabled: true });
    else this.setState({ disabled: false });
  };

  onClickUp = async (item) => {
    if (item.Like === 1)
      await this.props
        .RemoveLike(this.props.token,
          {
            comment_id: item.Id,
            id: this.props.user_id,
            lang: I18n.t("locale"),
          })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });

    await this.props
      .UpdateLike(this.props.token,
        {
          comment_id: item.Id,
          status: "1",
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickDown = async (item) => {
    if (item.CommentLike === 0)
      await this.props
        .RemoveLike(this.props.token, {
          comment_id: item.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    else
      await this.props
        .UpdateLike(this.props.token, {
          comment_id: item.Id,
          status: "'0'",
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
  };

  onClickReportComment = async (item) => {
    await this.props
      .ReportComment(this.props.token, {
        comment_id: item.Id,
        id: this.props.user_id,
        lang: I18n.t("locale"),
      })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickRemoveComment = async (item) => {
    await this.props
      .RemoveComment(this.props.token,
        {
          comment_id: item.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadAdCommentMore(
          this.props.token, {
          comment_id: this.props.ad.CommentId,
          id: this.props.user_id,
          page_count: this.props.pageCount,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    var comment_placeholder = "Escriba su comentario";
    if (this.props.lang === "en") {
      comment_placeholder = "Write your comment";
    }
    return (
      <React.Fragment>
        <div className="card-footer-comment">
          <div className="input-group">
            <div className="input-group-append">
              <input
                type="file"
                id="open_input_img"
                name="open_img"
                className="attach_file"
                accept="image/*"
                onChange={(e) => {
                  this.onChangeImage(e);
                }}
              />
              <label
                htmlFor="open_input_img"
                className="input-group-text attach_btn"
              >
                <span>
                  <FaCamera />
                </span>
              </label>
            </div>
            <textarea
              name="comment_msg"
              className="form-control type_msg"
              placeholder={comment_placeholder}
              value={this.state.comment_text}
              onChange={this.onChangeComment}
            ></textarea>
            <div className="input-group-append">
              <button
                className="input-group-text send_btn"
                disabled={this.state.disabled}
                onClick={() => {
                  this.onClickSendComment();
                }}
              >
                <FaPaperPlane />{" "}
              </button>
            </div>
          </div>
          {this.state.hasFile && (
            <div className="text-center">
              <img
                src={this.state.image}
                alt="img-coment"
                className="img-responsive"
              />
            </div>
          )}
        </div>
        {this.state.loading && <MiniLoader />}
        {this.props.loading ? (
          <MiniLoader />
        ) : (
          this.props.adCommentList.map((item) => {
            return (
              <AdCommentItem
                comment={item}
                key={item.Id}
                onClickUp={() => {
                  this.onClickUp(item);
                }}
                onClickDown={() => {
                  this.onClickDown(item);
                }}
                onClickRemove={() => {
                  this.onClickRemoveComment(item);
                }}
                onClickReport={() => {
                  this.onClickReportComment(item);
                }}
              />
            );
          })
        )}

        {
          this.props.pageSize >= 7 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}
//
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    user: reducer.session.auth,
    adCommentList: reducer.adComment.list,
    error: reducer.adComment.error,
    loading: reducer.adComment.loading,
    ad: reducer.ad.item,
    lang: reducer.i18n.locale,

    pageCount: reducer.adComment.page_count,
    pageSize: reducer.adComment.page_size,
    loadingMore: reducer.adComment.loadingMore,

    ////Revisado esta bien 
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadAdComment: (token, params) =>
    dispatch(actions.loadList({ token, params })),
  LoadAdCommentMore: (token, params) =>
    dispatch(actions.loadListScroll({ token, params })),
  AddComment: (token, params) => dispatch(actions.addComment({ token, params })),
  RemoveLike: (token, params) =>
    dispatch(actions.removeLike({ token, params })),
  UpdateLike: (token, params) =>
    dispatch(actions.updateLike({ token, params })),
  ReportComment: (token, params) =>
    dispatch(actions.reportComment({ token, params })),
  RemoveComment: (token, params) =>
    dispatch(actions.removeComment({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdComment);
