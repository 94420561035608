import React from "react";
import "./styles/DropDown.css";
import { Link } from "react-router-dom";

class Dropdown extends React.Component {

  state = {
    displayMenu: false
  };

  // constructor() {
  //   super();
  //   // this.showDropdownMenu = this.showDropdownMenu.bind(this);
  //   // this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  //   this.changeDropdownMenu = this.changeDropdownMenu.bind(this);
  // }

  // showDropdownMenu(event) {
  //   event.preventDefault();
  //   this.setState({ displayMenu: true }, () => {
  //     document.addEventListener("click", this.hideDropdownMenu);
  //   });
  // }

  // hideDropdownMenu() {
  //   this.setState({ displayMenu: false }, () => {
  //     document.removeEventListener("click", this.hideDropdownMenu);
  //   });
  // }

  changeDropdownMenu(event) {
    event.preventDefault();
    clearInterval(this.timer);
    this.setState({ displayMenu: !this.state.displayMenu });
  }

  lostBlur = (event) => {
    // event.preventDefault();
    // console.log("lostFocus");
    this.timer = setInterval(() => {
      this.setState({ displayMenu: false });
    }, 200);
  }

  onFocus = (event) => {
    // event.preventDefault();
    console.log("onFocus");
    //this.setState({ displayMenu: false });
  }

  onClick = (event) => {
    this.setState({ displayMenu: false });
  }

  render() {
    return (
      <div className="dropdown">
        <div className="button" tabIndex={0} onClick={this.changeDropdownMenu.bind(this)} onBlur={this.lostBlur}>
          {" "}
          {this.props.title}{" "}
        </div>

        {this.state.displayMenu ? (
          <ul>
            {this.props.list_items.map(item => {
              return (
                <Link className="nav-link" to={item.route} key={item.id} onClick={this.onClick}>
                  {" "}
                  <li className="nav-item">{item.value}</li>
                </Link>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}

export default Dropdown;
