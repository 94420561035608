import axios from "axios";
import { I18n } from "react-redux-i18n";

import {
  //////////////////////////////
  AD_ITEM_ATTEMPT,
  AD_ITEM_SUCCESS,
  AD_ITEM_FAILED,
  ///////////////////////////
  ADLIKE_UPDATE_SUCCESS,
  ADLIKE_UPDATE_FAILED
  ////////////////////////////
} from "../types/adTypes";
import { config } from "../../config";

const url_api = config.api.godiway;


////////////////////////////////////////////
export function loading() {
  return {
    type: AD_ITEM_ATTEMPT
  };
}

export function success(item) {
  return {
    type: AD_ITEM_SUCCESS,
    item: item
  };
}

export function failed(error) {
  return {
    type: AD_ITEM_FAILED,
    error: error
  };
}
////////////////////////////////////////////
///////////////////////////
export function successLike(item) {
  return {
    type: ADLIKE_UPDATE_SUCCESS,
    item: item,
  };
}

export function failedLike(error) {
  return {
    type: ADLIKE_UPDATE_FAILED,
    error: error,
  };
}

export const setAd = (data: Object) => dispatch => {
  dispatch(loading());
  dispatch(success(data.item));
};
export const setLoading = (data: Object) => dispatch => {
  dispatch(loading());
};

export const loadItem = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "goder/id", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
      //  timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};


export const loadItemOut = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "goder/id/out", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
      //  timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};


export const updateLike = (data: Object) => async dispatch => {
  await axios
    .post(url_api + "adlike", data.params,
      {
        headers: { Authorization: `Bearer ${data.token}` },
        // timeout: 5000,
      }
    )
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successLike(response.data.body));
        else dispatch(failedLike(response.data.body));
      } else {
        dispatch(failedLike("try_later"));
        console.log(response);
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedLike("try_later"));
      } else {
        dispatch(failedLike("try_later"));
      }
      return;
    });
};

export const removeLike = (data: Object) => async dispatch => {
  await axios
    .put(url_api + "adlike", {
    },
      {
        headers: { Authorization: `Bearer ${data.token}` },
        //  timeout: 5000,
      }
    )
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successLike(response.data.body));
        else dispatch(failedLike(response.data.body));
      } else {
        dispatch(failedLike("try_later"));
        console.log(response);
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedLike("try_later"));
      } else {
        dispatch(failedLike("try_later"));
      }
      return;
    });
};
//////////////////////////////////////////////////////////////
