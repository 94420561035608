import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
//import { Link } from 'react-router-dom';
import "./styles/AdSearch.css";

import PageLoading from "../components/PageLoading";
import AdSearchListOut from "../components/AdSearchListOut";
import AdSearchList from "../components/AdSearchList";
import AdMenu from "../components/AdMenu";
import Footer from "../components/Footer";
import PublishVertical from "../components/PublishVertical";

class AdSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: [],
    };
  }

  componentDidMount() {
    // this.fetchAdList();
  }

  fetchAdList = async () => {
    try {
      if (this.props.user_id && this.props.token)
        await this.props.LoadAdSearchIn(this.props.user_id, this.props.token);
      else await this.props.LoadAdSearchOut();
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    // if(this.state.isLogin) onClose={props.onCloseModal}
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <div className="search-left">
                <AdMenu />
                <PublishVertical />
              </div>
            </div>
            <div className="col-lg-9">
              <div className="search-right">
                {this.props.loading ? (
                  <PageLoading />
                ) : (
                  (this.props.user_id && this.props.token) ?
                    <AdSearchList adList={this.props.adList} />
                    : <AdSearchListOut adList={this.props.adList} />
                )}
              </div>
            </div>
            {!this.state.loading && (
              <button
                onClick={() => this.fetchAdList()}
                className="btn btn-secondary"
              >
                <Translate value="load_more" />
              </button>
            )}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer)
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    auth: reducer.session.auth,

    adList: reducer.adSearch.list,
    error: reducer.adSearch.error,
    loading: reducer.adSearch.loading,
  };
}
//const mapDispatchToProps = dispatch => ({
// LoadAdHomeIn:(user_id, token) => dispatch(actions.loadAdHomeIn({user_id, token})),
// LoadAdHomeOut:() => dispatch(actions.loadAdHomeOut({})),
//});

export default connect(mapStateToProps, null)(AdSearch);
