import React from "react";
import { I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import "./styles/Ad.css";

import AdSectionRight from "../components/AdSectionRight";
import AdSectionLeft from "../components/AdSectionLeft";
import * as actions_ad from "../redux/actions/adActions";
import * as actions_adrelation from "../redux/actions/adRelationActions";
import PageLoading from "../components/PageLoading";

class Ad extends React.Component {

  componentDidMount() {
    //console.log(Object.keys(this.props.auth).length);
    if (this.props.user_id && this.props.token)
      this.fetchaAd();
    else this.fetchaAdOut()
  }

  fetchaAd = async () => {
    //console.log(this.props.match.params.goder_id);
    try {
      await this.props
        .LoadAd(this.props.token, {
          goder_id: this.props.match.params.goder_id,
          id: this.props.user_id,
          lang: I18n.t("locale")
        }
        )
        .then(() => {
          if (this.props.error) {
            console.log("Ad Load");
            console.log(this.props.error);
          } else

            this.props
              .LoadAdRelation(
                this.props.token, {
                goder_id: this.props.ad.Id,
                id: this.props.user_id,
                lang: I18n.t("locale")
              }
              )
              .then(() => {
                if (this.props.error_adrelation) {
                  console.log("Ad Relation");
                  console.log(this.props.error_adrelation);
                }
              });
        });
    } catch (error) {
      console.log(error);
    }
  };

  fetchaAdOut = async () => {
    //console.log(this.props.match.params.goder_id);
    try {
      await this.props
        .LoadAdOut(this.props.token, {
          goder_id: this.props.match.params.goder_id,
          lang: I18n.t("locale")
        }
        )
        .then(() => {
          if (this.props.error) {
            console.log("Ad Load");
            console.log(this.props.error);
          }

        });
    } catch (error) {
      console.log(error);
    }
  };

  ////<AdSectionLeft/> AdSectionRight

  render() {
    return (
      <div className="container-fluid">
        <div className="container-goder">
          {
            !this.props.loading ? (
              <React.Fragment>
                {" "}
                <div className="col-md-8">
                  <div className="goder-left">
                    <AdSectionLeft />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="goder-right">
                    <AdSectionRight />
                  </div>
                </div>
              </React.Fragment>

            ) : (
              <PageLoading />
            )
          }
        </div>
      </div>
    );
  }
};//
function mapStateToProps(reducer) {
  // console.log(reducer); 
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    auth: reducer.session.auth,

    loading: reducer.ad.loading,
    ad: reducer.ad.item,
    error: reducer.ad.error,

    //error_adrelation:reducer.adRelation.error,
  };
}
const mapDispatchToProps = dispatch => ({
  LoadAd: (token, params) =>
    dispatch(actions_ad.loadItem({ token, params })),
  LoadAdOut: (token, params) =>
    dispatch(actions_ad.loadItemOut({ token, params })),

  SetLoading: () =>
    dispatch(actions_ad.setLoading({})),
  ////////////////////////////////////
  LoadAdRelation: (token, params) =>
    dispatch(actions_adrelation.loadList({ token, params }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
