import {
  MARKETSTORE_ITEM_ATTEMPT,
  MARKETSTORE_ITEM_SUCCESS,
  MARKETSTORE_ITEM_FAILED,
  ////////////////////////////
  MARKETSTORE_UPDATE_ATTEMPT,
  MARKETSTORE_UPDATE_SUCCESS,
  MARKETSTORE_UPDATE_FAILED,
  ///////////////////////////////////
  SET_SELECTED_MARKETSTORE,
  ///////////////////////
  CLEAR_MARKETSTORE,
  ////////////////////////////
} from "../types/marketstoreTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: "",

  error_update: "",
  loading_update: false,
};

function marketstore(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKETSTORE_ITEM_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case MARKETSTORE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: "",
      };

    case MARKETSTORE_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {},
      };
    /////////////////////////////////////////////////
    case SET_SELECTED_MARKETSTORE:
      return {
        ...state,
        item: action.item,
      };
    case CLEAR_MARKETSTORE:
      return {
        ...state,
        item: {},
      };
    ////////////////////////////////////////////
    //////////////////////////////////////
    case MARKETSTORE_UPDATE_ATTEMPT:
      return {
        ...state,
        loading_update: true,
      };
    case MARKETSTORE_UPDATE_SUCCESS:
      return {
        ...state,
        item: action.item,
        error_update: "",
        loading_update: false,
      };

    case MARKETSTORE_UPDATE_FAILED:
      return {
        ...state,
        error_update: action.error,
        loading_update: false,
      };
    default:
      return state;
  }
}
export default marketstore;
