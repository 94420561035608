export const AD_SALES_ATTEMPT = "AD_SALES_ATTEMPT";
export const AD_SALES_SUCCESS = "AD_SALES_SUCCESS";
export const AD_SALES_FAILED = "AD_SALES_FAILED";

export const AD_SALES_MORE_ATTEMPT = "AD_SALES_MORE_ATTEMPT";
export const AD_SALES_MORE_SUCCESS = "AD_SALES_MORE_SUCCESS";
export const AD_SALES_MORE_FAILED = "AD_SALES_MORE_FAILED";

export const AD_SALES_CHANGE_POSTSTATUS_SUCCESS =
  "AD_SALES_CHANGE_POSTSTATUS_SUCCESS";
export const AD_SALES_CHANGE_POSTSTATUS_FAILED =
  "AD_SALES_CHANGE_POSTSTATUS_FAILED";

export const AD_SALES_REMOVE_SUCCESS = "AD_SALES_REMOVE_SUCCESS";
export const AD_SALES_REMOVE_FAILED = "AD_SALES_REMOVE_FAILED";

export const AD_SALES_NEW_SUCCESS = "AD_SALES_NEW_SUCCESS";
export const AD_SALES_NEW_FAILED = "AD_SALES_NEW_FAILED";


export const LOGOUT = "LOGOUT";