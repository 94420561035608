import React from "react";
import { connect } from "react-redux";
import LoginForm from "../components/LoginForm";
import ModalLoading from "../components/ModalLoading";


//import alertify from "alertifyjs";
// import "../components/styles/Alertify.css";
// import "../components/styles/AlertifyTheme.css";
// import "../components/styles/Alertify.min.css";
// import "../components/styles/AlertifyTheme.min.css";
// import { Translate, I18n } from "react-redux-i18n";

//import * as actions from "../redux/actions/sessionActions";



class Login extends React.Component {
  render() {
    return (
      <div className="container-fluid">
        <LoginForm history={this.props.history} />
        <ModalLoading loading={this.props.loading} />
      </div>
    );
  }
}
//
const mapDispatchToProps = (dispatch) => ({
  //LoginRequest: (data) => dispatch(actions.loginRequest(data)),
});

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    loading: reducer.session.loading,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
