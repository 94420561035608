export const AD_RENTS_INACTIVE_MORE_ATTEMPT = "AD_RENTS_INACTIVE_MORE_ATTEMPT";
export const AD_RENTS_INACTIVE_MORE_SUCCESS = "AD_RENTS_INACTIVE_MORE_SUCCESS";
export const AD_RENTS_INACTIVE_MORE_FAILED = "AD_RENTS_INACTIVE_MORE_FAILED";

export const AD_RENTS_INACTIVE_ATTEMPT = "AD_RENTS_INACTIVE_ATTEMPT";
export const AD_RENTS_INACTIVE_SUCCESS = "AD_RENTS_INACTIVE_SUCCESS";
export const AD_RENTS_INACTIVE_FAILED = "AD_RENTS_INACTIVE_FAILED";

export const AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_SUCCESS =
  "AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_SUCCESS";
export const AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_FAILED =
  "AD_RENTS_INACTIVE_CHANGE_POSTSTATUS_FAILED";


export const AD_RENTS_REMOVE_SUCCESS = "AD_RENTS_REMOVE_SUCCESS";
export const AD_RENTS_REMOVE_FAILED = "AD_RENTS_REMOVE_FAILED";
export const AD_RENTS_NEW_SUCCESS = "AD_RENTS_NEW_SUCCESS";
export const AD_RENTS_NEW_FAILED = "AD_RENTS_NEW_FAILED";
export const LOGOUT = "LOGOUT";