import {
    ADFAQ_LIST_ATTEMPT,
    ADFAQ_LIST_SUCCESS,
    ADFAQ_LIST_FAILED,
    ////////////////////
    ADFAQ_MORE_ATTEMPT,
    ADFAQ_MORE_SUCCESS,
    ADFAQ_MORE_FAILED,
    ///////////////////////
    FAQ_NEW_SUCCESS,
    FAQ_NEW_FAILED
} from '../types/adFaqTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
    ////////////////////
    loadingMore: false,
    page_index: 0,
    page_size: 0,
    page_count: 0,
};

function adFaq(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADFAQ_LIST_ATTEMPT:
            return {
                ...state,
                loading: true,
            }

        case ADFAQ_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: action.data.size,
                error: ''
            }

        case ADFAQ_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }
        /////////////////////////////////////
        case ADFAQ_MORE_ATTEMPT:
            return {
                ...state,
                loadingMore: true,
            }

        case ADFAQ_MORE_SUCCESS:
            return {
                ...state,
                loadingMore: false,
                list: state.list.concat(action.data.list),
                page_index: action.data.page,
                page_size: action.data.size,
                page_count: state.page_count + action.data.size,
            }

        case ADFAQ_MORE_FAILED:
            return {
                ...state,
                loading: false,
            }
        ///////////////////////////
        case FAQ_NEW_FAILED:
            return {
                ...state,
                error: action.error,
                page_size: 0,
            }

        case FAQ_NEW_SUCCESS:
            return {
                ...state,
                list: [
                    action.item,
                    ...state.list
                ],
                page_size: 1,
                page_count: state.page_count + 1,

            }

        default: return state;
    }
};
export default adFaq;
