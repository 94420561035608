import axios from "axios";
import {
  SESSION_ATTEMPT,
  SESSION_SUCCESSFULL,
  SESSION_FAILED,
  /////////////
  SESSION_SUCCESS_MESSAGE,
  SESSION_FAILED_MESSAGE,
  SESSION_FAILED_UPDATE,
  //////////
  LOGOUT,
  DESTROY_SESSION,
  //////////////
  POPUP
} from "../types/sessionType";
import { config } from "../../config";


const url_api = config.api.godiway;
export function loading() {
  return {
    type: SESSION_ATTEMPT,
  };
}
export function success(item) {
  return {
    type: SESSION_SUCCESSFULL,
    item: item,
  };
}
export function failed(error) {
  return {
    type: SESSION_FAILED,
    error: error,
  };
}
///////////////
export function successMessage(success) {
  return {
    type: SESSION_SUCCESS_MESSAGE,
    success: success,
  };
}
export function failedMessage(error) {
  return {
    type: SESSION_FAILED_MESSAGE,
    error: error,
  };
}
export function failedUpdate(error) {
  return {
    type: SESSION_FAILED_UPDATE,
    error: error
  }
}
///////////////////////////
export function logout() {
  return {
    type: LOGOUT,
  };
}
/////////////////
export function popup() {
  return {
    type: POPUP,
  };
}
////////////////////////////////////

export const logoutRequest = (data: Object) => async (dispatch) => {
  dispatch(logout());
  //dispatch({ type: DESTROY_SESSION });
  //window.location.reload();
  //////////
};
export const setPopup = (data: Object) => async (dispatch) => {
  dispatch(popup());
};

export const loginRequest = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios({
    method: "post",
    url: url_api + "auth/login",
    data: { username: data.email, password: data.password },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`,
    }
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log("Error", error.message);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
    });
  return;
};

export const loginSocialRequest = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios({
    method: "post",
    url: url_api + "auth/social",
    data: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error.message);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
    });
  return;
};





export const loginTokenRequest = (data: Object) => async (dispatch) => {
  //dispatch(loginLoading());
  await axios({
    method: "post",
    url: url_api + "auth/token",
    data: { id: data.user_id, email: data.email },
    // headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error.message);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
    });
  return;
};

export const sendFeedBack = (data: Object) => async (dispatch) => {
  //dispatch(loginLoading());
  await axios({
    method: "post",
    url: url_api + "auth/feedback",
    data: { comment: data.comment },
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error.message);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
    });
  return;
};

////////////////////////////////////////////////////////

export const newItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .post(url_api + "auth/register", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        dispatch(failed("try_later"));
      }
    });
};

export const loadItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  //console.log(data.user_id);
  await axios
    .get(url_api + "auth", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadItemAll = (data: Object) => async (dispatch) => {
  dispatch(loading());
  //console.log(data.user_id);
  await axios
    .get(url_api + "auth/all", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async (dispatch) => {
  await axios({
    method: "post",
    url: url_api + "auth",
    data: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failedMessage(response.data.body));
      } else {
        dispatch(failedMessage("try_later"));
      }
    })
    .catch(function (error) {
      console.log("Error", error.message);
      if (error.response) {
        dispatch(failedMessage("try_later"));
      } else {
        dispatch(failedMessage("try_later"));
      }
    });
};

export const updatefield = (data: Object) => async (dispatch) => {
  // dispatch(Loading());
  // console.log(data);
  await axios
    .put(url_api + "auth", data.params, { headers: { Authorization: `Bearer ${data.token}` }, })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failedMessage(response.data.body));
      } else {
        dispatch(failedMessage("try_later"));
      }
    })
    .catch(function (error) {
      console.log("Error", error.message);
      if (error.response) {
        dispatch(failedMessage("try_later"));
      } else {
        dispatch(failedMessage("try_later"));
      }
    });
};

export const loadAvatar = (data: Object) => async (dispatch) => {
  try {
    //loadingUpImage(0);
    var request = new XMLHttpRequest();
    request.open("post", url_api + "auth/avatar");
    request.setRequestHeader('Authorization', `Bearer ${data.token}`);
    // request.setRequestHeader(
    //   'Content-Type', 'multipart/form-data'
    // );
    request.onload = () => {
      if (request.status < 300) {
        const response = JSON.parse(request.response);
        if (response.status === "Success")
          dispatch(success(response.body));
        else {
          dispatch(failedMessage("try_later"));
        }
        //console.log(data);
      } else {
        console.error(request.response);
        dispatch(failedMessage("try_later"));
      }
    };
    await request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        //dispatch(loadingUpImage(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(failedMessage("try_later"));
  }
};

export const forgetPassword = (data: Object) => async (dispatch) => {

  await axios
    .get(url_api + "auth/forget", {
      params: {
        email: data.email,
      },
      //headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMessage(response.data.body));
        else dispatch(failedMessage(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        dispatch(failed("try_later"));
      }
    });
};

export const changePassword = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "auth/reset", {
      password: data.password,
      token: data.token
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMessage(response.data.body));
        else dispatch(failedMessage(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      if (error.response) {
        dispatch(failed("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        dispatch(failed("try_later"));
      }
    });
};

export const resetPassword = (data: Object) => async (dispatch) => {
  //dispatch(Loading());
  await axios
    .put(url_api + "auth/pwd", {
      currentPassword: data.password_old,
      newPassword: data.password_new,
      id: data.user_id,
    }, { headers: { authorization: `Bearer ${data.token}` }, })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMessage(response.data.body));
        else dispatch(failedMessage(response.data.body));
      } else {
        dispatch(failedMessage("try_later"));
      }
    })
    .catch(function (error) {
      if (error.response) {
        dispatch(failedMessage("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        dispatch(failedMessage("try_later"));
      }
    });
};





export const checkPassword = (data: Object) => async (dispatch) => {
  //dispatch(Loading());
  await axios
    .post(url_api + "auth/check", {
      id: data.user_id,
      pwd: data.password,
    }, { headers: { Authorization: `Bearer ${data.token}` }, })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMessage(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.response) {
        dispatch(failedUpdate("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
        dispatch(failedUpdate("try_later"));
      }
    });
};

