import React from "react";
import { Link } from "react-router-dom";
import "./styles/ContactList.css";
import { FaUserAlt, FaSearch } from "react-icons/fa";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreActions";
import { Translate, I18n } from "react-redux-i18n";
import MiniLoader from "./MiniLoader";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

function getInitial(name) {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}

class MarketStoreItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Link
          to={`/marketstore/show/${this.props.item.Id}`}
          onClick={this.props.onClick}
          style={{ textDecoration: "none" }}
        >
          <li className="contacts_card">
            <div className="d-flex bd-highlight">
              <div className="img_cont">
                {
                  this.props.item.Logo ? (<img
                    src={this.props.item.Logo}
                    className="rounded-circle user_img"
                  />) : getInitial(this.props.item.Name)
                }

              </div>
              <div className="contact_name">
                <div className="rower">
                  <span>{this.props.item.Name}</span>
                  <small>{this.props.item.Description} <br></br>
                    {this.props.item.Home}
                  </small>
                </div>
              </div>
            </div>
          </li>
        </Link>
      </React.Fragment>
    );
  }
}

class MarketStoreList extends React.Component {
  state = {
    marketstoreList: [],
    text_search: "",
  };

  componentDidMount() {
    this.setState({ marketstoreList: this.props.marketstoreList });
  }

  onChange = (event) => {
    this.setState({ text_search: event.target.value });
    var text_search = event.target.value;
    const newData = this.props.marketstoreList.filter(function (item) {
      const itemData = item.Name ? item.Name.toUpperCase() : "".toUpperCase();
      const textData = text_search.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });

    this.setState({ marketstoreList: newData });
  };

  onClick = (store) => {
    this.props.SetSelectedStore(store, this.props.user_id, this.props.token);
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadMoreContacts(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    var search_placeholder = "Buscar";
    if (this.props.lang == "en") {
      search_placeholder = "Search";
    }
    if (this.props.marketstoreList.length == 0)
      return (
        <h3>
          <Translate value="marketstore_not_list" />
        </h3>
      );

    return (
      <React.Fragment>
        <div className="chat">
          <div className="input-group">
            <input
              type="text"
              placeholder={search_placeholder}
              onChange={this.onChange}
              className="form-control"
              value={this.state.text_search}
            />
            <span className="input-group-text search_btn">
              <FaSearch />
            </span>
          </div>

          <div className="container">
            <div className="card-body contacts-body  mb-4">
              <ul className="contacts">
                {this.state.marketstoreList.map((item) => {
                  return (
                    <MarketStoreItem
                      item={item}
                      key={item.Id}
                      onClick={() => {
                        this.onClick(item);
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {
          this.props.pageSize > 7 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducers) {
  //console.log(reducers);
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    error: reducers.marketstore.error,
    loading: reducers.marketstore.loading,
    marketstore: reducers.marketstore.item,

    lang: reducers.i18n.locale,
    marketstoreList: reducers.marketstoreList.list,

    pageCount: reducers.marketstoreList.page_count,
    pageSize: reducers.marketstoreList.page_size,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SetSelectedStore: (item, user_id, token) =>
    dispatch(actions.setStore({ item, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStoreList);
