import {
  WISH_LIST_ATTEMPT,
  WISH_LIST_SUCCESS,
  WISH_LIST_FAILED,
  //////////////////////////
  WISH_UPDATE_SUCCESS,
  WISH_UPDATE_FAILED,
  /////////////////////
  WISH_SET_COUNT,

  LOGOUT
} from "../types/wishTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  //////////    ////////////////////
  loadingMore: false,
  page_index: 0,
  page_size: 0,
  page_count: 0,
};
function wish(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WISH_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case WISH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        // page_count: state.page_count + action.data.size,
        error: ''
      };

    case WISH_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data.error,
        list: []
      };
    //////////////////////////////////////////////
    case WISH_UPDATE_SUCCESS: {
      if (action.item.MyInterest)
        return {
          ...state,
          list: [action.item, ...state.list],
          page_count: state.page_count + 1
        };
      else {
        const newState = state.list.filter(item => {
          return item.Id !== action.item.Id;
        });
        return {
          ...state,
          list: newState,
          page_count: state.page_count - 1
        };
      }
    }

    case WISH_UPDATE_FAILED: {
      return {
        ...state,
        error: action.error
      };
    }
    //////////////////////////////////
    case WISH_SET_COUNT: {
      return {
        ...state,
        page_count: action.wishCount
      };
    }
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
export default wish;
