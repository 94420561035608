import React from 'react';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import * as actions from "../redux/actions/ordersActions";
//import { withRouter } from "react-router";

import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({

    document: {
        //overflowY: "scroll",
        //  padding: theme.spacing.unit * 2
        width: window.innerWidth,
        height: window.innerHeight,
        //paddingHorizontal: "40px"
    },
    page: {
        flexDirection: 'column',
        marginTop: "40px",
        marginLeft: "40px",
        // marginRight: "80px",

        // fontSize: "14px",
        // flexDirection: 'row',
        // backgroundColor: '#E4E4E4'
    },
    logo: {
        height: "40px",
        width: "120px"
    },
    section1: {
        flexDirection: 'row',
        margin: "40px",
        justifyContent: "space-between",
        width: "88%",
        // padding: 10,
        // flexGrow: 1
    },
    section1_right: {
        fontSize: "11px",
        lineHeight: "1.5px",
        color: '#b5b5b5',
        whiteSpace: "nowrap"

    },
    section2: {
        // flexDirection: 'row',
        marginBottom: "20px",
        // padding: 10,
        // flexGrow: 1
    },
    section3: {
        flexDirection: 'row',
        //margin: "40px",
        // padding: 10,
        // flexGrow: 1
    },
    section3_left: {

        fontSize: "9.5px",
        //margin: "40px",
        // padding: 10,
        // flexGrow: 1
    },
    section3_right: {

        fontSize: "10px",
        left: "170px"
        //margin: "40px",
        // padding: 10,
        // flexGrow: 1
    },
    section3_right1: {

        fontSize: "10px",
        left: "200px"
        //margin: "40px",
        // padding: 10,
        // flexGrow: 1
    },
    section4: {
        width: "88%",
        flexDirection: 'row',
        fontSize: "10px",
        marginTop: "20px",
        backgroundColor: "black",
        paddingTop: "7px",
        paddingBottom: "7px",
        color: "white"
        //left: "200px"
        //margin: "40px",
        // padding: 10,
        // flexGrow: 1
    },
    section4_right1: {
        position: "absolute",
        left: "300px",
        top: "7px",
        width: "100px"
    },
    section4_right2: {
        position: "absolute",
        left: "400px",
        top: "7px",
        width: "100px"
    },
    section5: {
        fontSize: "10px",
        margin: "5px",
        flexDirection: 'row',
    },

    section6: {
        flexDirection: 'row',
        fontSize: "10px",
        margin: "5px"
    },
    section6_left: {
        // fontSize: "10px",
        // margin: "5px"
    },
    section6_right1: {
        position: "absolute",
        left: "300px",
        flexDirection: "column",
        width: "100px"
    },
    section6_right2: {
        position: "absolute",
        left: "400px",
        flexDirection: "column",
        width: "100px"
    },
    bold: {
        fontWeight: "bold"
    }
});
/**
 * 
 * @returns <Text>$10</Text>
                    <Text>$10</Text>
                    <Text>__</Text>
                    <Text>__</Text>
 */

// Create Document Component
const MyDocument = (props) => (
    <Document file="order.pdf"
        author="Godiway"
        title="Order" >
        <Page size="A4" style={styles.page} pageNumber={1}>
            <View style={styles.section1}>
                <View>
                    <Image src="https://www.godiway.com/themes/email/img/logo.png" style={styles.logo} />
                </View>
                <View style={styles.section1_right}>
                    <Text>GODIWAY, LLC</Text>
                    <Text>2815 DIRECTORS ROW</Text>
                    <Text>STE 100</Text>
                    <Text>ORLANDO, FL 32809</Text>
                </View>
            </View>
            <View style={styles.section2}>
                <Text>INVOICE</Text>
            </View>
            <View style={styles.section3}>
                <View style={styles.section3_left}>
                    <Text>{props.orderItem.Name}</Text>
                    <Text>{props.orderItem.Organization}</Text>
                    <Text>{props.orderItem.Address}</Text>
                    <Text>{props.orderItem.Location}</Text>
                </View>
                <View style={styles.section3_right}>
                    <Text>Invoice Date:</Text>
                    <Text>Order Number:</Text>
                    <Text>Order Date:</Text>
                    <Text>
                        Paymenth Method:
                    </Text>
                </View>
                <View style={styles.section3_right1}>
                    <Text>{props.orderItem.CreatedAt}</Text>
                    <Text>{props.orderItem.Id}</Text>
                    <Text>{props.orderItem.CreatedAt}</Text>
                    <Text>
                        Credit or Debit Card
                    </Text>
                </View>
            </View>
            <View style={styles.section4}>
                <Text>Detail</Text>
                <Text style={styles.section4_right1}>Quantity</Text>
                <Text style={styles.section4_right2}>Price</Text>
            </View>
            <View style={styles.section5}>
                <Text>{props.orderItem.Plan}</Text>
                <Text style={styles.section4_right1}>1 </Text>
                <Text style={styles.section4_right2}>${props.orderItem.Total} </Text>
            </View>

            <View style={styles.section6}>
                <View style={styles.section6_left}>
                    <Text style={styles.bold}>Customer Notes</Text>
                    <Text>Grettingd from Godiway, </Text>
                    <Text>we're writting to provide you</Text>
                    <Text>with an electronic invoice</Text>
                    <Text>for your use of Godiway services</Text>
                    <Text>All prices are in US Dollars,</Text>
                    <Text>Payment processed in the US.</Text>
                </View>
                <View style={styles.section6_right1}>
                    <Text>SubTotal</Text>
                    <Text>Total </Text>
                </View>
                <View style={styles.section6_right2}>
                    <Text>${props.orderItem.Total}</Text>
                    <Text>${props.orderItem.Total}</Text>
                </View>
            </View>
        </Page>
    </Document>
);
class OrderPdf extends React.Component {
    componentDidMount() {
        //console.log(this.props.match.params.order_id);
        this.fetchOrder(this.props.match.params.order_id);
    }

    fetchOrder = async (order_id) => {
        try {
            await this.props
                .LoadItem(this.props.token, {
                    id: this.props.user_id,
                    order_id: order_id,
                    lang: I18n.t("locale"),
                }
                )
                .then(() => {
                    if (this.props.error) {
                        alertify.warning(this.props.error);
                    }
                });
        } catch (error) {
            console.log(this.error);
        }
    };

    render() {
        return (
            <div >
                < PDFViewer style={styles.document} >
                    <MyDocument orderItem={this.props.orderItem} />
                </PDFViewer >
            </div>
        )
    }
}

function mapStateToProps(reducer) {
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,

        orderItem: reducer.orders.item,
    };
}

const mapDispatchToProps = (dispatch) => ({
    LoadItem: (token, params) =>
        dispatch(actions.loadItem({ token, params })),
});

export default
    connect(mapStateToProps, mapDispatchToProps)(OrderPdf);