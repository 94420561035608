import {
  USER_ITEM_ATTEMP,
  USER_ITEM_SUCCESS,
  USER_ITEM_FAILED,
  //////////////////
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  ////////////////////////
  USER_AVATAR_SUCCESS,
  USER_AVATAR_FAILED,
  USER_AVATAR_ATTEMP,
  /////////////////
  PROFILE_UPDATE_SUCCESS,
  PASSWORD_UPDATE_SUCCESS,
  REGISTER_SUCCESS,
  UPDATE_FAILED,
  PASSWORD_FORGET_SUCCESS,
  ///////////////
} from "../types/userTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: "",
  ////////////////
  error_update: "",
  success_update: "",
  loadingFile: false,
  progress: 0,
};

function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_ITEM_ATTEMP:
      return {
        ...state,
        loading: true,
      };

    case USER_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: "",
      };

    case USER_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: {},
      };
    /////////////////////////////////////////////
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        success_update: action.success,
        error: "",
      };

    case USER_UPDATE_FAILED:
      return {
        ...state,
        success_update: "",
        error_update: action.error,
      };

    ///////////////////////////////////////
    case USER_AVATAR_ATTEMP: {
      return {
        ...state,
        loadingFile: true,
        progress: action.progress,
        success: '',
        error: '',
      };
    }
    case USER_AVATAR_SUCCESS: {
      return {
        ...state,
        item: action.item,
        error: '',
        loadingFile: false,
        progress: 0,
      };
    }
    case USER_AVATAR_FAILED: {
      return {
        ...state,
        error: action.error,
        success: '',
        loadingFile: false,
        progress: 0,
      };
    }



    default:
      return state;
  }
}
export default user;
