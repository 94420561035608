import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
  MARKETSTORE_CATEGORIES_ATTEMPT,
  MARKETSTORE_CATEGORIES_SUCCESS,
  MARKETSTORE_CATEGORIES_FAILED,
  ////////////////////////////
} from "../types/marketstoreCategoriesTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: MARKETSTORE_CATEGORIES_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: MARKETSTORE_CATEGORIES_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: MARKETSTORE_CATEGORIES_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "marketstore/categories", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
