import axios from "axios";

import {
  AD_BIDDER_ATTEMPT,
  AD_BIDDER_SUCCESS,
  AD_BIDDER_FAILED,
  ////////////////////////
  AD_MOREBIDDER_ATTEMPT,
  AD_MOREBIDDER_SUCCESS,
  AD_MOREBIDDER_FAILED
} from "../types/adBidderTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_BIDDER_ATTEMPT
  };
}

export function success(data) {
  return {
    type: AD_BIDDER_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: AD_BIDDER_FAILED,
    error: error
  };
}
///////////////////////////////////////////////////////
export function loadingMore() {
  return {
    type: AD_MOREBIDDER_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: AD_MOREBIDDER_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: AD_MOREBIDDER_FAILED,
    error: error,
  };
}
export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "adbidder", { // pendiente crear que traiga    "ad/bidder"
      params: {
        id: data.user_id,
        goder_id: data.id
        //lang   :translate("lang")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //    console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "adbidder", { // pendiente crear que traiga    "ad/bidder"
      params: {
        id: data.user_id,
        goder_id: data.id,
        index: data.page_index,
        //lang   :translate("lang")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};