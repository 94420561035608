import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
//import scriptLoader from "react-async-script-loader";
import ReactDOM from "react-dom";
import { PayPalButton } from "react-paypal-button-v2";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { config } from "../config";
//import './styles/Home.css';

class PaypalSuscriptionMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  componentWillUnmount() { }
  // currency="USD"
  /**
   * // plan_id: "P-9A297909YS720713AL3MZDYI", //100 x anio
              // plan_id:"P-8G874870RB654210JL3MZEKA"//10 x month

   */
  render() {
    var plan_id = "";
    const user_id = this.props.user_id;
    const token = this.props.token;
    var plangw_id = "";
    switch (this.props.value) {
      case 10:
        plan_id = "P-92D36671GN7871549L3VDKTA"; // production
        //        plan_id = "P-8G874870RB654210JL3MZEKA"; //sandbox pay3r//10 x month
        plangw_id = "GWMB1";
        break; //10 x month
      case 20:
        plan_id = "P-8A0497736X240415VL3VDMBI"; //production
        //plan_id = "P-8G874870RB654210JL3MZEKA"; //sandbox pay3r//10 x month
        plangw_id = "GWMA1";
        break; //20 x month
      case 100:
        plan_id = "P-3AW38896WA450370WL3VDM5Q"; //production
        // plan_id = "P-9A297909YS720713AL3MZDYI"; //sandbox pay3r //100 x anio
        plangw_id = "GWAB1";
        break; //100 x anio
      case 200:
        plan_id = "P-5XD034121H339400HL3VDNMA"; //production
        //plan_id = "P-9A297909YS720713AL3MZDYI"; //sandbox pay3r //100 x anio
        plangw_id = "GWAA1";
        break; //200 x anio
    }
    return (
      <div className="text-center">
        <PayPalButton
          shippingPreference={"NO_SHIPPING"}
          options={{
            vault: true,
            clientId: config.paypal.clientId
          }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: plan_id,
            });
          }}
          onApprove={(data, actions) => {
            // Capture the funds from the transaction
            return actions.subscription.get().then(function (details) {
              this.props.history.push("/mobile/paypal/success");
              return fetch("/api/paypal/subscription-godiway", {
                method: "post",
                body: JSON.stringify({
                  order_id: data.orderID,
                  subscription_id: data.subscriptionID,
                  id: user_id,
                  //token: token,
                  details: details,
                  plan_id: plangw_id,
                }),
                headers: { Authorization: `Bearer ${token}` },
              });
            });
          }}
        />
      </div>
    );
  }
}
/**
 * 
 * 
          catchError={(err) => {
            alertify.warning(I18n.t("try_later"));
          }}
          onError={(err) => {
            alertify.warning(I18n.t("try_later"));
          }}
           
 */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    value: reducer.suscriptionPlan.plan_value,
  };
}
export default withRouter(connect(mapStateToProps, null)(PaypalSuscriptionMobile));
