import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MiniLoader from "./MiniLoader";
import * as actions_relation from "../redux/actions/adRelationActions";
import * as actions_ad from "../redux/actions/adActions";
import "./styles/AdRelation.css";
import { Translate } from "react-redux-i18n";

class AdRelationItem extends React.Component {
  render() {
    var KindCommerce = "";
    switch (this.props.item.KindCommerceId) {
      case 1:
        KindCommerce = <Translate value="donation" />;
        break;
      case 2:
        KindCommerce = <Translate value="sale" />;
        break;
      case 3:
        KindCommerce = <Translate value="exchange" />;
        break;
      case 4:
        KindCommerce = <Translate value="rent" />;
        break;
      default:
        KindCommerce = <Translate value="sale" />;
        break;
    }
    return (
      <div className="col-6 goder-relation-item">
        <Link to={`/goder/show/${this.props.item.Id}`} onClick={this.props.onClick} style={{ textDecoration: "none" }}>
          <img
            className="img-fluid goder-img-relation"
            src={this.props.item.Photo}
            alt="Img"
          />
          <div className="title-goder-relation">{this.props.item.Name}</div>
        </Link>
        <div className="goder-row">
          <div className="">
            <small>{KindCommerce}</small>
            <span>&nbsp;</span>
          </div>
          <div className="">
            <i><strong>
              {" "}
              {this.props.item.Price}&nbsp;
              <small>{this.props.item.Currency}</small>{" "}
            </strong>
            </i>
          </div>
        </div>

      </div>
    );
  }
}

class AdRelation extends React.Component {
  componentDidMount() {
    //setTime(this.fetchaAdRelation(),2000);
    // setTimeout(() => , 3000);
    //
    //setTimeout(() => {}, 5000);
    //console.log(this.props.match.params.goder_id);
  }

  fetchaAdRelation = async () => {
    try {
      await this.props
        .LoadAdRelation(this.props.ad.Id, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            console.log("Ad Relation");
            console.log(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClick = item => {
    this.props.SetAd(item);
  };

  render() {
    return (
      <React.Fragment>
        <h4 className="my-4 text-center">
          <Translate value="ad_relation" />
        </h4>
        <div className="row">
          {this.props.loading ? (
            <div className="col-12">
              <div className="text-center">
                <MiniLoader />
              </div>
            </div>
          ) : (
            this.props.adRelationList.map(item => {
              return (
                <AdRelationItem
                  item={item}
                  key={item.Id}
                  onClick={() => {
                    this.onClick(item);
                  }}
                />
              );
            })
          )}
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    //hasUser: reducer.session.hasUser,

    ad: reducer.ad.item,
    error: reducer.adRelation.error,
    loading: reducer.adRelation.loading,
    adRelationList: reducer.adRelation.list
  };
}
const mapDispatchToProps = dispatch => ({
  SetAd: item => dispatch(actions_ad.setAd({ item })),
  LoadAdRelation: (id, user_id, token) =>
    dispatch(actions_relation.loadList({ id, user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdRelation);
