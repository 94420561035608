function formatUser(props) {
    return {
        id: props.id,
        name: props.name,
        lastN: props.lastN,
        username: props.username,
        avatar: props.avatar,
        access_token: props.access_token,
    };
}

export default formatUser;