import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
    MARKETSTORE_EXTRA_ATTEMPT,
    MARKETSTORE_EXTRA_SUCCESS,
    MARKETSTORE_EXTRA_FAILED,
    ////////////////////////////
    // CATEGORIES_UPDATE_SUCCESS,
    // CATEGORIES_UPDATE_FAILED
} from "../types/marketstoreExtraListTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
    return {
        type: MARKETSTORE_EXTRA_ATTEMPT,
    };
}

export function success(data) {
    return {
        type: MARKETSTORE_EXTRA_SUCCESS,
        data: data,
    };
}

export function failed(error) {
    return {
        type: MARKETSTORE_EXTRA_FAILED,
        error: error,
    };
}

export const loadList = (data: Object) => async (dispatch) => {
    dispatch(loading());
    await axios
        .get(url_api + "marketstore/extra", {
            params: data.params,
            headers: { Authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            // console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else dispatch(failed(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            console.log(error);
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};
export const loadListScroll = (data: Object) => async (dispatch) => {
    dispatch(loading());
    await axios
        .get(url_api + "marketstore/extra", {
            params: data.params,
            headers: { Authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            // console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else dispatch(failed(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            console.log(error);
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};

export const clearExtraStore = (data: Object) => async (dispatch) => {
    // dispatch(loading());
}