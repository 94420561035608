import {
    AD_AUCTION_INACTIVE_ATTEMPT,
    AD_AUCTION_INACTIVE_SUCCESS,
    AD_AUCTION_INACTIVE_FAILED,
    ////////////////////////////////
    AD_AUCTION_INACTIVE_MORE_ATTEMPT,
    AD_AUCTION_INACTIVE_MORE_SUCCESS,
    AD_AUCTION_INACTIVE_MORE_FAILED,
    ///////////////////////////
    AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_SUCCESS,
    AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_FAILED,
    ////////////////////////////////
    AD_AUCTION_INACTIVE_DELETE_SUCCESS,
    AD_AUCTION_INACTIVE_DELETE_FAILED,
    ///////////////////////////
    AD_AUCTION_NEW_SUCCESS,
    AD_AUCTION_NEW_FAILED,
} from "../types/adAuctionTypes";

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: "",
    loadingMore: false,
    page_count: 0,
    page_size: 0
};
function adAuctionInactive(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AD_AUCTION_INACTIVE_ATTEMPT:
            return {
                ...state,
                loading: true
            };

        case AD_AUCTION_INACTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: "",
                page_count: action.data.size,
                page_size: action.data.size,
            };

        case AD_AUCTION_INACTIVE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            };

        /////////////////////////////////////////////
        case AD_AUCTION_INACTIVE_MORE_ATTEMPT:
            return {
                ...state,
                loadingMore: true
            };

        case AD_AUCTION_INACTIVE_MORE_SUCCESS:
            return {
                ...state,
                loadingMore: false,
                list: state.list.concat(action.data.list),
                page_count: state.page_count + action.data.size,
                page_size: action.data.size,
            };

        case AD_AUCTION_INACTIVE_MORE_FAILED:
            return {
                ...state,
                loadingMore: false,
            };
        /////////////////////////////////////////////
        case AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_SUCCESS: {
            if (action.item.PostStatus === 2) {
                const newState = state.list.filter((item) => {
                    return item.Id !== action.item.Id;
                });
                return {
                    ...state,
                    list: newState,
                    page_count: state.page_count - 1,
                };
            } else {
                return {
                    ...state,
                    list: [action.item, ...state.list],
                    page_count: state.page_count + 1,
                };
            }
        }

        case AD_AUCTION_INACTIVE_CHANGE_POSTSTATUS_FAILED:
            return {
                ...state,
                error_update: action.error,
            };
        /////////////////////////////////////////////
        case AD_AUCTION_INACTIVE_DELETE_SUCCESS: {
            const newState = state.list.filter(item => {
                return item.Id !== action.item.Id;
            });
            return {
                ...state,
                list: newState,
                page_count: state.page_count - 1,
            };
        }

        case AD_AUCTION_INACTIVE_DELETE_FAILED:
            return {
                ...state,
                error: action.error
            };
        ////////////////////////////////////////////
        case AD_AUCTION_NEW_SUCCESS: {
            return {
                ...state,
                list: [action.item, ...state.list],
                page_count: state.page_count + 1,
                error_update: ""
            };
        }

        case AD_AUCTION_NEW_FAILED:
            return {
                ...state,
                error_update: action.error
            };
        default:
            return state;
    }
}
export default adAuctionInactive;
