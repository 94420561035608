export const supportedLocales = {
  en: "English",
  es: "Español",
  he: "עִברִית"
};

export const fallbackLocale = "es";

/**
 * Language 	ISO Code
Abkhazian 	ab
Afar 	aa
Afrikaans 	af
Akan 	ak
Albanian 	sq
Amharic 	am
Arabic 	ar
Aragonese 	an
Armenian 	hy
Assamese 	as
Avaric 	av
Avestan 	ae
Aymara 	ay
Azerbaijani 	az
Bambara 	bm
Bashkir 	ba
Basque 	eu
Belarusian 	be
Bengali (Bangla) 	bn
Bihari 	bh
Bislama 	bi
Bosnian 	bs
Breton 	br
Bulgarian 	bg
Burmese 	my
Catalan 	ca
Chamorro 	ch
Chechen 	ce
Chichewa, Chewa, Nyanja 	ny
Chinese 	zh
Chinese (Simplified) 	zh-Hans
Chinese (Traditional) 	zh-Hant
Chuvash 	cv
Cornish 	kw
Corsican 	co
Cree 	cr
Croatian 	hr
Czech 	cs
Danish 	da
Divehi, Dhivehi, Maldivian 	dv
Dutch 	nl
Dzongkha 	dz
English 	en
Esperanto 	eo
Estonian 	et
Ewe 	ee
Faroese 	fo
Fijian 	fj
Finnish 	fi
French 	fr
Fula, Fulah, Pulaar, Pular 	ff
Galician 	gl
Gaelic (Scottish) 	gd
Gaelic (Manx) 	gv
Georgian 	ka
German 	de
Greek 	el
Greenlandic 	kl
Guarani 	gn
Gujarati 	gu
Haitian Creole 	ht
Hausa 	ha
Hebrew 	he
Herero 	hz
Hindi 	hi
Hiri Motu 	ho
Hungarian 	hu
Icelandic 	is
Ido 	io
Igbo 	ig
Indonesian 	id, in
Interlingua 	ia
Interlingue 	ie
Inuktitut 	iu
Inupiak 	ik
Irish 	ga
Italian 	it
Japanese 	ja
Javanese 	jv
Kalaallisut, Greenlandic 	kl
Kannada 	kn
Kanuri 	kr
Kashmiri 	ks
Kazakh 	kk
Khmer 	km
Kikuyu 	ki
Kinyarwanda (Rwanda) 	rw
Kirundi 	rn
Kyrgyz 	ky
Komi 	kv
Kongo 	kg
Korean 	ko
Kurdish 	ku
Kwanyama 	kj
Lao 	lo
Latin 	la
Latvian (Lettish) 	lv
Limburgish ( Limburger) 	li
Lingala 	ln
Lithuanian 	lt
Luga-Katanga 	lu
Luganda, Ganda 	lg
Luxembourgish 	lb
Manx 	gv
Macedonian 	mk
Malagasy 	mg
Malay 	ms
Malayalam 	ml
Maltese 	mt
Maori 	mi
Marathi 	mr
Marshallese 	mh
Moldavian 	mo
Mongolian 	mn
Nauru 	na
Navajo 	nv
Ndonga 	ng
Northern Ndebele 	nd
Nepali 	ne
Norwegian 	no
Norwegian bokmål 	nb
Norwegian nynorsk 	nn
Nuosu 	ii
Occitan 	oc
Ojibwe 	oj
Old Church Slavonic, Old Bulgarian 	cu
Oriya 	or
Oromo (Afaan Oromo) 	om
Ossetian 	os
Pāli 	pi
Pashto, Pushto 	ps
Persian (Farsi) 	fa
Polish 	pl
Portuguese 	pt
Punjabi (Eastern) 	pa
Quechua 	qu
Romansh 	rm
Romanian 	ro
Russian 	ru
Sami 	se
Samoan 	sm
Sango 	sg
Sanskrit 	sa
Serbian 	sr
Serbo-Croatian 	sh
Sesotho 	st
Setswana 	tn
Shona 	sn
Sichuan Yi 	ii
Sindhi 	sd
Sinhalese 	si
Siswati 	ss
Slovak 	sk
Slovenian 	sl
Somali 	so
Southern Ndebele 	nr
Spanish 	es
Sundanese 	su
Swahili (Kiswahili) 	sw
Swati 	ss
Swedish 	sv
Tagalog 	tl
Tahitian 	ty
Tajik 	tg
Tamil 	ta
Tatar 	tt
Telugu 	te
Thai 	th
Tibetan 	bo
Tigrinya 	ti
Tonga 	to
Tsonga 	ts
Turkish 	tr
Turkmen 	tk
Twi 	tw
Uyghur 	ug
Ukrainian 	uk
Urdu 	ur
Uzbek 	uz
Venda 	ve
Vietnamese 	vi
Volapük 	vo
Wallon 	wa
Welsh 	cy
Wolof 	wo
Western Frisian 	fy
Xhosa 	xh
Yiddish 	yi, ji
Yoruba 	yo
Zhuang, Chuang 	za
Zulu 	zu
 */

 /**
  * Country 	ISO Code
AFGHANISTAN 	AF
ALBANIA 	AL
ALGERIA 	DZ
AMERICAN SAMOA 	AS
ANDORRA 	AD
ANGOLA 	AO
ANTARCTICA 	AQ
ANTIGUA AND BARBUDA 	AG
ARGENTINA 	AR
ARMENIA 	AM
ARUBA 	AW
AUSTRALIA 	AU
AUSTRIA 	AT
AZERBAIJAN 	AZ
BAHAMAS 	BS
BAHRAIN 	BH
BANGLADESH 	BD
BARBADOS 	BB
BELARUS 	BY
BELGIUM 	BE
BELIZE 	BZ
BENIN 	BJ
BERMUDA 	BM
BHUTAN 	BT
BOLIVIA 	BO
BOSNIA AND HERZEGOVINA 	BA
BOTSWANA 	BW
BOUVET ISLAND 	BV
BRAZIL 	BR
BRITISH INDIAN OCEAN TERRITORY 	IO
BRUNEI DARUSSALAM 	BN
BULGARIA 	BG
BURKINA FASO 	BF
BURUNDI 	BI
CAMBODIA 	KH
CAMEROON 	CM
CANADA 	CA
CAPE VERDE 	CV
CAYMAN ISLANDS 	KY
CENTRAL AFRICAN REPUBLIC 	CF
CHAD 	TD
CHILE 	CL
CHINA 	CN
CHRISTMAS ISLAND 	CX
COCOS (KEELING) ISLANDS 	CC
COLOMBIA 	CO
COMOROS 	KM
CONGO 	CG
CONGO, THE DEMOCRATIC REPUBLIC OF THE 	CD
COOK ISLANDS 	CK
COSTA RICA 	CR
CÔTE D'IVOIRE 	CI
CROATIA 	HR
CUBA 	CU
CYPRUS 	CY
CZECH REPUBLIC 	CZ
DENMARK 	DK
DJIBOUTI 	DJ
DOMINICA 	DM
DOMINICAN REPUBLIC 	DO
ECUADOR 	EC
EGYPT 	EG
EL SALVADOR 	SV
EQUATORIAL GUINEA 	GQ
ERITREA 	ER
ESTONIA 	EE
ETHIOPIA 	ET
FALKLAND ISLANDS (MALVINAS) 	FK
FAROE ISLANDS 	FO
FIJI 	FJ
FINLAND 	FI
FRANCE 	FR
FRENCH GUIANA 	GF
FRENCH POLYNESIA 	PF
FRENCH SOUTHERN TERRITORIES 	TF
GABON 	GA
GAMBIA 	GM
GEORGIA 	GE
GERMANY 	DE
GHANA 	GH
GIBRALTAR 	GI
GREECE 	GR
GREENLAND 	GL
GRENADA 	GD
GUADELOUPE 	GP
GUAM 	GU
GUATEMALA 	GT
GUINEA 	GN
GUINEA-BISSAU 	GW
GUYANA 	GY
HAITI 	HT
HEARD ISLAND AND MCDONALD ISLANDS 	HM
HONDURAS 	HN
HONG KONG 	HK
HUNGARY 	HU
ICELAND 	IS
INDIA 	IN
INDONESIA 	ID
IRAN, ISLAMIC REPUBLIC OF 	IR
IRAQ 	IQ
IRELAND 	IE
ISRAEL 	IL
ITALY 	IT
JAMAICA 	JM
JAPAN 	JP
JORDAN 	JO
KAZAKHSTAN 	KZ
KENYA 	KE
KIRIBATI 	KI
KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF 	KP
KOREA, REPUBLIC OF 	KR
KUWAIT 	KW
KYRGYZSTAN 	KG
LAO PEOPLE'S DEMOCRATIC REPUBLIC (LAOS) 	LA
LATVIA 	LV
LEBANON 	LB
LESOTHO 	LS
LIBERIA 	LR
LIBYA, STATE OF 	LY
LIECHTENSTEIN 	LI
LITHUANIA 	LT
LUXEMBOURG 	LU
MACAO 	MO
MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF 	MK
MADAGASCAR 	MG
MALAWI 	MW
MALAYSIA 	MY
MALDIVES 	MV
MALI 	ML
MALTA 	MT
MARSHALL ISLANDS 	MH
MARTINIQUE 	MQ
MAURITANIA 	MR
MAURITIUS 	MU
MAYOTTE 	YT
MEXICO 	MX
MICRONESIA, FEDERATED STATES OF 	FM
MOLDOVA, REPUBLIC OF 	MD
MONACO 	MC
MONGOLIA 	MN
MONTENEGRO 	ME
MONTSERRAT 	MS
MOROCCO 	MA
MOZAMBIQUE 	MZ
MYANMAR 	MM
NAMIBIA 	NA
NAURU 	NR
NEPAL, FEDERAL DEMOCRATIC REPUBLIC OF 	NP
NETHERLANDS 	NL
NETHERLANDS ANTILLES 	AN
NEW CALEDONIA 	NC
NEW ZEALAND 	NZ
NICARAGUA 	NI
NIGER 	NE
NIGERIA 	NG
NIUE 	NU
NORFOLK ISLAND 	NF
NORTHERN MARIANA ISLANDS 	MP
NORWAY 	NO
OMAN 	OM
PAKISTAN 	PK
PALAU 	PW
PALESTINE, STATE OF 	PS
PANAMA 	PA
PAPUA NEW GUINEA 	PG
PARAGUAY 	PY
PERU 	PE
PHILIPPINES 	PH
PITCAIRN 	PN
POLAND 	PL
PORTUGAL 	PT
PUERTO RICO 	PR
QATAR 	QA
RÉUNION 	RE
ROMANIA 	RO
RUSSIAN FEDERATION 	RU
RWANDA 	RW
SAINT HELENA 	SH
SAINT KITTS AND NEVIS 	KN
SAINT LUCIA 	LC
SAINT PIERRE AND MIQUELON 	PM
SAINT VINCENT AND THE GRENADINES 	VC
SAMOA 	WS
SAN MARINO 	SM
SAO TOME AND PRINCIPE 	ST
SAUDI ARABIA 	SA
SENEGAL 	SN
SERBIA 	RS
SEYCHELLES 	SC
SIERRA LEONE 	SL
SINGAPORE 	SG
SLOVAKIA 	SK
SLOVENIA 	SI
SOLOMON ISLANDS 	SB
SOMALIA 	SO
SOUTH AFRICA 	ZA
SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS 	GS
SOUTH SUDAN 	SS
SPAIN 	ES
SRI LANKA 	LK
SUDAN 	SD
SURINAME 	SR
SVALBARD AND JAN MAYEN 	SJ
SWAZILAND 	SZ
SWEDEN 	SE
SWITZERLAND 	CH
SYRIAN ARAB REPUBLIC 	SY
TAIWAN 	TW
TAJIKISTAN 	TJ
TANZANIA, UNITED REPUBLIC OF 	TZ
THAILAND 	TH
TIMOR-LESTE 	TL
TOGO 	TG
TOKELAU 	TK
TONGA 	TO
TRINIDAD AND TOBAGO 	TT
TUNISIA 	TN
TURKEY 	TR
TURKMENISTAN 	TM
TURKS AND CAICOS ISLANDS 	TC
TUVALU 	TV
UGANDA 	UG
UKRAINE 	UA
UNITED ARAB EMIRATES 	AE
UNITED KINGDOM 	GB
UNITED STATES 	US
UNITED STATES MINOR OUTLYING ISLANDS 	UM
URUGUAY 	UY
UZBEKISTAN 	UZ
VANUATU 	VU
VENEZUELA 	VE
VIET NAM 	VN
VIRGIN ISLANDS, BRITISH 	VG
VIRGIN ISLANDS, U.S. 	VI
WALLIS AND FUTUNA 	WF
WESTERN SAHARA 	EH
YEMEN 	YE
ZAMBIA 	ZM
ZIMBABWE 	ZW 
  */
