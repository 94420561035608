import React from "react";
//import StepProgressBar from "react-step-progress";
// import the stylesheet
//import { Link } from "react-router-dom";
//import { FaUserPlus, FaCartArrowDown, FaDollarSign } from "react-icons/fa";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import "react-step-progress/dist/index.css";
import "./styles/PayStepProgress.css";
import { Translate, I18n } from "react-redux-i18n";
import * as actions from "../redux/actions/countryActions";
import * as actions_session from "../redux/actions/sessionActions";
import * as actions_address from "../redux/actions/customerActions";
import * as actions_suscription from "../redux/actions/suscriptionPlanActions";
import ModalLoading from "../components/ModalLoading";
import { connect } from "react-redux";
import PayPalOnePay from "../components/PaypalOnePayTemp";
import PayPalSuscription from "../components/PaypalSuscriptionTemp";
import StepRegister from "../components/StepRegister";
import StepAddress from "../components/StepAddress";
import StepFinish from "../components/StepFinish";
//import { withRouter } from "react-router";
class PayStepProgress extends React.Component {
  state = {
    formRegister: {},
    formAddress: { user_id: "", token: "" },
    accept_tos: false,
    next: 1,
    steps: 4,
    loading: false,
    errorRegister: {
      email: false,
      password: false,
      password2: false,
      country: false,
    },
  };

  componentDidMount() {
    if (this.props.value === 0) this.props.history.push("/pricing");
    else this.fetchCountry();
    this.props.SetPlanNext(1);
  }

  onChangeRegister = (e) => {
    this.setState({
      formRegister: {
        ...this.state.formRegister,
        [e.target.name]: e.target.value,
      },
      errorRegister: {
        ...this.state.errorRegister,
        [e.target.name]: false,
      },
    });
    if (e.target.name === "email") {
      this.setState({
        errorRegister: {
          ...this.state.errorRegister,
          email: this.validateEmail(e.target.value),
        },
      });
    }
  };

  validateEmail = (email) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email) ? false : true;
  };

  onChangeAddress = (e) => {
    this.setState({
      formAddress: {
        ...this.state.formAddress,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeAcceptTos = (e) => {
    this.setState({
      accept_tos: !this.state.accept_tos,
    });
  };

  fetchCountry = async () => {
    try {
      await this.props.LoadCountry().then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  onClickRegister = async () => {
    // console.log(this.state);
    if (!this.state.accept_tos) {
      alertify.alert(I18n.t("warning"), I18n.t("warning_tos"), function () { });

      return false;
    }
    if (
      this.state.formRegister.country === undefined ||
      this.state.formRegister.country === 0
    ) {
      this.setState({
        ...this.state,
        errorRegister: { ...this.state.errorRegister, country: true },
      });
      return false;
    }

    if (
      this.state.formRegister.email === undefined ||
      this.state.formRegister.email === "" ||
      this.state.errorRegister.email
    ) {
      this.setState({
        ...this.state,
        errorRegister: { ...this.state.errorRegister, email: true },
      });
      return false;
    }
    if (
      this.state.formRegister.password === undefined ||
      this.state.formRegister.password === ""
    ) {
      this.setState({
        ...this.state,
        errorRegister: { ...this.state.errorRegister, password: true },
      });
      return false;
    }

    if (
      this.state.formRegister.password2 === undefined ||
      this.state.formRegister.password2 === ""
    ) {
      this.setState({
        ...this.state,
        errorRegister: { ...this.state.errorRegister, password2: true },
      });
      return false;
    }

    if (this.state.formRegister.password2 !== this.state.formRegister.password) {
      this.setState({
        ...this.state,
        errorRegister: {
          ...this.state.errorRegister,
          password: true,
          password2: true,
        },
      });
      return false;
    }

    try {
      this.setState({ loading: true });
      await this.props
        .RegisterUser(
          this.state.formRegister.email,
          this.state.formRegister.password,
          this.state.formRegister.country
        )
        .then(() => {
          if (this.props.error_register) {
            this.setState({ loading: false });
            console.log("error");
            alertify.warning(this.props.error_register);
          } else {
            this.props.SetPlanNext(2);
            this.setState({
              ...this.state,
              loading: false,
              next: 2,
            });
          }
        });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
      //return false;
    }
  };

  onClickAddress = async () => {
    try {
      await this.setState({
        ...this.state,
        formAddress: {
          ...this.state.formAddress,
          user_id: this.props.user_id,
          token: this.props.token,
          email: "",
          organization: "",
        },
      });
      // console.log(this.state.formAddress);
      await this.props.SaveCustomer(this.props.token, this.state.formAddress).then(() => {
        if (this.props.error_address) {
          this.setState({ loading: false });
          alertify.warning(this.props.error_address);
        } else {
          this.props.SetPlanNext(3);
          this.setState({ loading: false, next: 3 });
        }
      });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
  };

  onClickNextStep1 = async (e) => {
    e.preventDefault();
    await this.onClickRegister();
    //console.log(this.state);
    //this.setState({ next: 2 });
  };
  onClickNextStep2 = async (e) => {
    e.preventDefault();

    await this.onClickAddress();
    //this.setState({ next: 3 });
  };

  onClickNextStep3 = (e) => {
    e.preventDefault();
    this.setState({ next: 4 });
    this.props.SetPlanNext(4);
  };

  onClickPrevStep2 = (e) => {
    e.preventDefault();
    this.setState({ next: 1 });
  };
  onClickPrevStep3 = (e) => {
    e.preventDefault();
    this.setState({ next: 2 });
  };

  render() {
    var percent = parseFloat(100 / this.state.steps) * this.state.next;
    percent = percent.toFixed();
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div
              className="col-10 col-sm-9 col-md-7 col-lg-6 col-xl-4 text-center "
              style={{ marginTop: "100px" }}
            >
              <div className="card ">
                <div className="paystepprogress">
                  <form id="msform" className="col-12">
                    <ul id="progressbar">
                      <li
                        id="step1"
                        className={this.props.next === 1 && "active"}
                      >
                        <strong>
                          {" "}
                          <Translate value="step_account" />
                        </strong>
                      </li>
                      <li
                        className={this.props.next === 2 && "active"}
                        id="step2"
                      >
                        <strong>
                          <Translate value="step_order" />
                        </strong>
                      </li>
                      <li
                        className={this.props.next === 3 && "active"}
                        id="step3"
                      >
                        <strong>
                          <Translate value="step_pay" />
                        </strong>
                      </li>
                      <li
                        className={this.props.next === 4 && "active"}
                        id="step4"
                      >
                        <strong>
                          <Translate value="step_finish" />
                        </strong>
                      </li>
                    </ul>
                    <div className="progress">
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated"
                        role="progressbar"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        style={{ width: percent + "%" }}
                      ></div>
                    </div>{" "}
                    <div
                      className="row"
                      style={{ marginTop: "15px", marginBottom: "10px" }}
                    >
                      {(this.props.value === 15 && (
                        <React.Fragment>
                          {" "}
                          <div className="col-7">
                            <h2 className="fs-title">Plan OnePay</h2>
                          </div>
                          <div className="col-5">
                            <h2 className="steps">15.00 USD</h2>
                          </div>
                        </React.Fragment>
                      )) ||
                        (this.props.value === 10 && (
                          <React.Fragment>
                            {" "}
                            <div className="col-7">
                              <h2 className="fs-title">
                                {" "}
                                <Translate value="suscription_mine" />{" "}
                                <Translate value="ad_month" />
                              </h2>
                            </div>
                            <div className="col-5">
                              <h2 className="steps">10.00 USD</h2>
                            </div>
                          </React.Fragment>
                        )) ||
                        (this.props.value === 20 && (
                          <React.Fragment>
                            {" "}
                            <div className="col-7">
                              <h2 className="fs-title">
                                <Translate value="suscription_mine" />{" "}
                                <Translate value="ad_month" />
                              </h2>
                            </div>
                            <div className="col-5">
                              <h2 className="steps">20.00 USD</h2>
                            </div>
                          </React.Fragment>
                        )) ||
                        (this.props.value === 100 && (
                          <React.Fragment>
                            {" "}
                            <div className="col-7">
                              <h2 className="fs-title">
                                <Translate value="suscription_mine" />{" "}
                                <Translate value="ad_year" />
                              </h2>
                            </div>
                            <div className="col-5">
                              <h2 className="steps">100.00 USD</h2>
                            </div>
                          </React.Fragment>
                        )) ||
                        (this.props.value === 200 && (
                          <React.Fragment>
                            {" "}
                            <div className="col-7">
                              <h2 className="fs-title">
                                <Translate value="suscription_mine" />{" "}
                                <Translate value="ad_year" />
                              </h2>
                            </div>
                            <div className="col-5">
                              <h2 className="steps">200.00 USD</h2>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                    {(this.props.next === 1 && ( //form-card  <span className="steps">Step 1 - 4</span>  <div className="col-5"></div>
                      <fieldset>
                        <StepRegister
                          countryList={this.props.countryList}
                          form={this.state.formRegister}
                          error={this.state.errorRegister}
                          onChange={this.onChangeRegister}
                          accept_tos={this.state.accept_tos}
                          onChangeAcceptTos={this.onChangeAcceptTos}
                        />
                        <button
                          name="next"
                          className="btn btn-primary"
                          onClick={this.onClickNextStep1}
                        >
                          <Translate value="ad_next" />
                        </button>
                      </fieldset>
                    )) ||
                      (this.props.next === 2 && ( //form-card
                        <fieldset>
                          <StepAddress
                            form={this.state.formAddress}
                            onChange={this.onChangeAddress}
                          />

                          <button
                            className="btn btn-primary"
                            onClick={this.onClickNextStep2}
                          >
                            <Translate value="ad_next" />
                          </button>
                        </fieldset>
                      )) ||
                      (this.props.next === 3 && ( //form-card
                        <fieldset>
                          <div className="row">
                            <div className="col-12 text-center">
                              <text
                                className=""
                                style={{
                                  fontSize: "18px",
                                  fontWeight: "bold ",
                                  color: "gray",
                                }}
                              >
                                <Translate value="card_credit_or_debit" />
                              </text>
                            </div>
                          </div>

                          {this.props.value === 15 ? (
                            <PayPalOnePay />
                          ) : (
                            <PayPalSuscription />
                          )}
                        </fieldset>
                      )) ||
                      (this.props.next === 4 && ( //form-card
                        <fieldset>
                          <StepFinish />
                        </fieldset>
                      ))}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoading loading={this.state.loading} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    countryList: reducer.country.list,
    error: reducer.country.error,
    loading: reducer.country.loading,

    lang: reducer.i18n.locale,

    error_register: reducer.session.error,
    loading: reducer.session.loading,
    user: reducer.session.auth,

    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    error_address: reducer.customer.error,

    value: reducer.suscriptionPlan.plan_value,
    next: reducer.suscriptionPlan.plan_next,

  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCountry: () => dispatch(actions.loadList({})),
  RegisterUser: (email, password, countryId) =>
    dispatch(actions_session.newItem({ email, password, countryId })),
  SaveCustomer: (token, params) => dispatch(actions_address.saveCustomer(token, params)),
  SetPlanNext: (value) => dispatch(actions_suscription.setPlanNext({ value })),
});
export default //withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayStepProgress);
//);
/**
 *  <button
                            className="btn btn-secondary"
                            onClick={this.onClickPrevStep3}
                          >
                            Prev
                          </button>
                          {"     "}
                           <button
                            className="btn btn-primary"
                            onClick={this.onClickNextStep3}
                          >
                            <Translate value="ad_next" />
                          </button>
                            <button
                            className="btn btn-secondary"
                            onClick={this.onClickPrevStep2}
                          >
                            Prev
                          </button>
                          {"     "}
 */
