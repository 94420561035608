import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";

class TipsGoodTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: []
    };
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (


      this.props.lang === "es" ? (
        <React.Fragment>
          <div className="card mt40">
            <div className="card-header">
              <h5>Tips para su Transacción</h5>
            </div>
            <div className="card-body">
              <ul className="">
                <li className="mt20">
                  Revisar la antiguedad del anunciante, que lleva en el sitio.
                </li>
                <li className="mt20">
                  Revisa las transacciones concretadas vs las canceladas y por
                  que.
                </li>
                <li className="mt20">
                  Revisa los comentarios de las transacciones concretadas.
                </li>
                <li className="mt20">
                  Revisa los comentarios de las transacciones sin concretar, para
                  conocer los motivos.<p></p>
                </li>
                <li className="mt20">
                  Revisa % de Efectividad.<p></p>
                </li>
                <li className="mt20">
                  Si no tiene calificación, has tu transacción en lugares
                  públicos.
                </li>
                <li className="mt20">
                  Si no tiene calificación y estas en otra ciudad, no arriesgues
                  mucho dinero.
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      )
        : (
          <React.Fragment>
            <div className="card mt40">
              <div className="card-header">
                <h5>Tips for your Transaction </h5>
              </div>
              <div className="card-body">
                <ul className="">
                  <li className="mt20">
                    Check the seniority of the advertiser, who has been on the site.
                  </li>
                  <li className="mt20">
                    Check the transactions completed vs those canceled and why
                    that.
                  </li>
                  <li className="mt20">
                    Review the comments of the completed transactions.
                  </li>
                  <li className="mt20">
                    Check the comments of the unspecified transactions, to
                    know the reasons. <p></p>
                  </li>
                  <li className="mt20">
                    Check % of Effectiveness. <p></p>
                  </li>
                  <li className="mt20">
                    If you do not have a rating, make your transaction in places
                    public.
                  </li>
                  <li className="mt20">
                    If you do not have a qualification and you are in another city, do not risk
                    a lot of money.
                  </li>
                </ul>
              </div>
            </div>
          </React.Fragment>
        )

    );
  }
}
function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(TipsGoodTransaction);
