import axios from "axios";

// import { I18n } from "react-redux-i18n";
import {
  WALL_LIST_ATTEMPT,
  WALL_LIST_SUCCESS,
  WALL_LIST_FAILED,
  ///////////////////////////
  WALL_MORELIST_ATTEMPT,
  WALL_MORELIST_SUCCESS,
  WALL_MORELIST_FAILED,
  ///////////////////////////
  WALL_UPDATE_LIKE_SUCCESS,
  WALL_UPDATE_LIKE_FAILED,
  //////////////////////////
  WALL_ADD_COMMENT_SUCCESS,
  WALL_ADD_COMMENT_FAILED,
  /////////////////////////
  WALL_REMOVE_COMMENT_SUCCESS,
  WALL_REMOVE_COMMENT_FAILED,
  ///////////////////////////
  WALL_REPORT_COMMENT_SUCCESS,
  WALL_REPORT_COMMENT_FAILED,
  ///////////////////////////

} from "../types/wallTypes";
import { config } from "../../config";

const url_api = config.api.godiway;

export function loading() {
  return {
    type: WALL_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: WALL_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: WALL_LIST_FAILED,
    error: error,
  };
}

////////////////////////////////////////
export function loadingMore() {
  return {
    type: WALL_MORELIST_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: WALL_MORELIST_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: WALL_MORELIST_FAILED,
    error: error,
  };
}
////////////////////////////////////////
export function successRemoveComment(item) {
  return {
    type: WALL_REMOVE_COMMENT_SUCCESS,
    item: item,
  };
}

export function failedRemoveComment(error) {
  return {
    type: WALL_REMOVE_COMMENT_FAILED,
    error: error,
  };
}
///////////////////////////////////////
export function successReportComment(item) {
  return {
    type: WALL_REPORT_COMMENT_SUCCESS,
  };
}

export function failedReportComment(error) {
  return {
    type: WALL_REPORT_COMMENT_FAILED,
    error: error,
  };
}
///////////////////////////////////////
export function updateWallLikeSuccess(item) {
  return {
    type: WALL_UPDATE_LIKE_SUCCESS,
    item: item,
  };
}

export function updateWallLikeFailed(error) {
  return {
    type: WALL_UPDATE_LIKE_FAILED,
    error: error,
  };
}
//////////////////////////////////////

export function updateWallCommentSuccess(item) {
  return {
    type: WALL_ADD_COMMENT_SUCCESS,
    item: item,
  };
}

export function updateWallCommentFailed(error) {
  return {
    type: WALL_ADD_COMMENT_FAILED,
    error: error,
  };
}
/////////////////////////////////////

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "comment", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async (dispatch) => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "comment", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const updateLike = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "comment/like", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateWallLikeSuccess(response.data.body));
        else dispatch(updateWallLikeFailed(response.data.body));
      } else {
        dispatch(updateWallLikeFailed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(updateWallLikeFailed("try_later"));
      } else {
        dispatch(updateWallLikeFailed("try_later"));
      }
      return;
    });
};

export const removeLike = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment/like", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateWallLikeSuccess(response.data.body));
        else dispatch(updateWallLikeFailed(response.data.body));
      } else {
        dispatch(updateWallLikeFailed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(updateWallLikeFailed("try_later"));
      } else {
        dispatch(updateWallLikeFailed("try_later"));
      }
      return;
    });
};

export const addCommentTmp = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "comment", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateWallCommentSuccess(response.data.body));
        else dispatch(updateWallCommentFailed(response.data.body));
      } else {
        dispatch(updateWallCommentFailed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(updateWallCommentFailed("try_later"));
      } else {
        dispatch(updateWallCommentFailed("try_later"));
      }
      return;
    });
};

export const addComment = (data: Object) => async (dispatch) => {
  try {
    var request = new XMLHttpRequest();
    await request.open("POST", url_api + "comment", true);
    request.setRequestHeader('Authorization', `Bearer ${data.token}`);
    // request.setRequestHeader(
    //   'Content-Type', 'multipart/form-data'
    // );
    request.onload = () => {
      if (request.status < 300) {
        const response = JSON.parse(request.response);
        //console.log("Image Loaded", data.item);
        dispatch(updateWallCommentSuccess(response.body));
      } else {
        console.error(request.response);
        dispatch(updateWallCommentFailed("try_later"));
      }
    };
    await request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        //dispatch(loadingUpImage(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(updateWallCommentFailed("try_later"));
  }
};

export const removeComment = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment/remove", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successRemoveComment(response.data.body));
        else dispatch(failedRemoveComment(response.data.body));
      } else {
        dispatch(failedRemoveComment("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedRemoveComment("try_later"));
      } else {
        dispatch(failedRemoveComment("try_later"));
      }
      return;
    });
};

export const reportComment = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment/report", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successReportComment(response.data.body));
        else dispatch(failedReportComment(response.data.body));
      } else {
        dispatch(failedReportComment("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedReportComment("try_later"));
      } else {
        dispatch(failedReportComment("try_later"));
      }
      return;
    });
};
