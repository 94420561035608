import axios from "axios";

import {
  AD_STORE_ATTEMPT,
  AD_STORE_SUCCESS,
  AD_STORE_FAILED,
  //////////
  AD_STORE_MORE_ATTEMPT,
  AD_STORE_MORE_SUCCESS,
  AD_STORE_MORE_FAILED,
  //////////
  AD_STORE_CLEAR,
} from "../types/marketstoreAdListTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_STORE_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: AD_STORE_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: AD_STORE_FAILED,
    error: error,
  };
}
//////////////////////////////////
export function loadingMore() {
  return {
    type: AD_STORE_MORE_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: AD_STORE_MORE_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: AD_STORE_MORE_FAILED,
    error: error,
  };
}
//////////////////////////////////
export function clearAds() {
  return {
    type: AD_STORE_CLEAR,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "marketstore/goder", {
      params: {
        id: data.user_id,
        store_id: data.id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success") {
          //  console.log(response);
          dispatch(success(response.data.body));
        } else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async (dispatch) => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "marketstore/goder", {
      params: {
        index: data.page_count,
        id: data.user_id,
        store_id: data.id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success") {
          //console.log(response);
          dispatch(successMore(response.data.body));
        } else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const clearAdStore = (data: Object) => async (dispatch) => {
  dispatch(clearAds());
};
