
export const FAQ_LIST_ANSWER_ATTEMPT = "FAQ_LIST_ANSWER_ATTEMPT";
export const FAQ_LIST_ANSWER_SUCCESS = "FAQ_LIST_ANSWER_SUCCESS";
export const FAQ_LIST_ANSWER_FAILED = "FAQ_LIST_ANSWER_FAILED";
/////////////////////////////
export const FAQ_SET_ANSWER_ATTEMPT = "FAQ_SET_ANSWER_ATTEMPT";
export const FAQ_SET_ANSWER_SUCCESS = "FAQ_SET_ANSWER_SUCCESS";
export const FAQ_SET_ANSWER_FAILED = "FAQ_SET_ANSWER_FAILED";



export const FAQ_MORELIST_ANSWER_ATTEMPT = "FAQ_MORELIST_ANSWER_ATTEMPT";
export const FAQ_MORELIST_ANSWER_SUCCESS = "FAQ_MORELIST_ANSWER_SUCCESS";
export const FAQ_MORELIST_ANSWER_FAILED = "FAQ_MORELIST_ANSWER_FAILED";

export const LOGOUT = "LOGOUT";