import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/Screen.css";
import mejoratueconomia from "../assets/mejora-tu-economia.svg";
import ayudaaotros from "../assets/ayuda-a-otros.svg";
import productosventas from "../assets/productos-ventas.svg";
import { Translate } from "react-redux-i18n";
import { Link } from "react-router-dom";

class JoinWinAll extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row text-center">
          <div className="col-md-12   mt60">
            <h2>
              {" "}
              <Translate value="joinwinall1" />
            </h2>
            <h3 className="mt40">
              <Translate value="joinwinall2" />
            </h3>
          </div>
        </div>
        <div className="row text-center mt40">
          <div className=" col-md-4">
            <img src={mejoratueconomia} width="60" height="55" alt="economy" />
            <h4 className="mt20">
              {" "}
              <Translate value="joinwinall3" />.
            </h4>
            <p className="mt20">
              <Translate value="joinwinall4" />
            </p>
          </div>
          <div className="col-md-4">
            <img src={ayudaaotros} width="60" height="55" alt="Help" />
            <h4 className="mt20">
              {" "}
              <Translate value="joinwinall5" />.
            </h4>
            <p className="mt20">
              <Translate value="joinwinall6" /> .
            </p>
          </div>
          <div className="col-md-4">
            <img src={productosventas} width="60" height="55" alt="Products" />
            <h4 className="mt20">
              <Translate value="joinwinall7" />.
            </h4>
            <p className="mt20">
              <Translate value="joinwinall8" />.
            </p>
          </div>
        </div>
        <div className="row text-center mt40 mb60">
          <div className="col-md-12">
            <Link to="/register" className="btn btnBig btn-primary">
              <Translate value="joinwinall9" />
            </Link>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default JoinWinAll;
