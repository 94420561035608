import {
  AD_BIDDER_ATTEMPT,
  AD_BIDDER_SUCCESS,
  AD_BIDDER_FAILED,
  ///////////////////////
  AD_MOREBIDDER_ATTEMPT,
  AD_MOREBIDDER_SUCCESS,
  AD_MOREBIDDER_FAILED
} from "../types/adBidderTypes";

const INITIAL_STATE = {
  loading: true,
  list: [],
  error: "",
  success: "",

  loadingMore: false,
  page_index: 0,
  page_size: 0,
  page_count: 0,
};

function adBidder(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_BIDDER_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_BIDDER_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case AD_BIDDER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    ///////////////////////////
    case AD_MOREBIDDER_ATTEMPT:
      return {
        ...state,
        loadingMore: true
      };

    case AD_MOREBIDDER_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case AD_MOREBIDDER_FAILED:
      return {
        ...state,
        loadingMore: false,
        list: []
      };

    default:
      return state;
  }
};

export default adBidder;