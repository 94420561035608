import React from "react";
import {
  FaRegThumbsUp,
  FaRegThumbsDown,
  FaThumbsDown,
  FaThumbsUp,
} from "react-icons/fa";
import "./styles/AdLike.css";
import { connect } from "react-redux";
import * as actions_ad from "../redux/actions/adActions";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import { I18n } from "react-redux-i18n";
//import { Link } from 'react-router-dom';
//Translate,
class AdLike extends React.Component {

  state = {
    like: this.props.ad && this.props.ad.Like.Status,
    count_up: this.props.ad && this.props.ad.Like.Up,
    count_down: this.props.ad && this.props.ad.Like.Down,
  };

  componentDidMount() {

  }

  handleLike = async () => {
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }
    if (this.state.like === 1) {
      this.setState({
        like: null,
        count_up: this.state.count_up - 1,
      });
      await this.props
        .RemoveLike(this.props.token,
          {
            goder_id: this.props.ad.Id,
            id: this.props.user_id,
          })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } else {
      if (this.state.like === 0) {
        this.setState({ count_down: this.state.count_down - 1 });
      }
      this.setState({
        like: 1,
        count_up: this.state.count_up + 1,
      });
      await this.props
        .UpdateLike(this.props.token,
          {
            goder_id: this.props.ad.Id,
            status: "1",
            id: this.props.user_id,
          })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    }
  };

  handleUnlike = async () => {
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }
    if (this.state.like === 0) {
      this.setState({ like: null, count_down: this.state.count_down - 1 });
      await this.props
        .RemoveLike(this.props.token, {
          goder_id: this.props.ad.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } else {
      if (this.state.like === 1) {
        this.setState({ count_up: this.state.count_up - 1 });
      }
      this.setState({ like: 0, count_down: this.state.count_down + 1 });
      await this.props
        .UpdateLike(
          this.props.token,
          {
            goder_id: this.props.ad.Id,
            status: "'0'",
            id: this.props.user_id,
          }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    }
  };

  render() {//.bind(this)
    //console.log(this.props.ad.Like);
    return (
      <React.Fragment>
        <div className="container-goder-like">
          <small id="up_count">{this.props.ad.Like.Up}</small>
          <button
            className="btn btn-light"
            disabled={false}
            data-toggle="tooltip"
            title="Me gusta"
            onClick={this.handleLike}
          >
            {this.state.like === 1 ? <FaThumbsUp /> : <FaRegThumbsUp />}
          </button>
          <small id="down_count">{this.props.ad.Like.Down}</small>
          <button
            className="btn btn-light"
            disabled={false}
            data-toggle="tooltip"
            onClick={this.handleUnlike}
            title="No me gusta"
          >
            {this.state.like === 0 ? <FaThumbsDown /> : <FaRegThumbsDown />}
          </button>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer.ad.item);
  return {
    token: reducer.session.auth.access_token,
    user_id: reducer.session.auth.id,
    user: reducer.session.auth,
    ///////////////////////////
    ad: reducer.ad.item,


    //error:
  };
}

const mapDispatchToProps = (dispatch) => ({
  UpdateLike: (token, params) =>
    dispatch(actions_ad.updateLike({ token, params })),
  RemoveLike: (token, params) =>
    dispatch(actions_ad.removeLike({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdLike);
