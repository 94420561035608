import React from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./styles/MarketStoreSlider.css";
import ModalStoreFollowers from "./ModalStoreFollowers";
import ModalStoreHome from "./Modal";
import ModalStoreLocation from "./Modal";
import ModalStoreContacts from "./Modal";
import * as actions from "../redux/actions/marketstoreFollowersActions";
import * as actions_marketstore from "../redux/actions/marketstoreActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
//  //
class MarketStoreSlider extends React.Component {
  state = {
    open_modal_followers: false,
    open_modal_home: false,
    open_modal_location: false,
    open_modal_contact: false,
    open_modal_message: false,
    information: "",
  };

  componentDidMount() { }

  fechFollowers = async () => {
    try {
      await this.props
        .LoadFollowers(this.props.token,
          {
            store_id: this.props.marketstore.Id,
            id: this.props.user_id,
          }

        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickFollow = async () => {
    try {
      await this.props
        .UpdateFollow(this.props.token, {
          store_id: this.props.marketstore.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickUnFollow = async () => {
    try {
      await this.props
        .UpdateFollow(
          this.props.token, {
          store_id: this.props.marketstore.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickFollowers = () => {
    this.setState({ open_modal_followers: true });
    this.fechFollowers();
  };

  onClose = () => {
    this.setState({ open_modal_followers: false });
  };

  onClickHome = () => {
    this.setState({ open_modal_home: !this.state.open_modal_home });
  };

  onClickLocation = () => {
    this.setState({ open_modal_location: !this.state.open_modal_location });
  };

  onClickContact = () => {
    this.setState({ open_modal_contact: !this.state.open_modal_contact });
  };

  onClickMessage = () => {
    this.setState({ open_modal_message: !this.state.open_modal_message });
  };
  /**
   * 
   * @returns   <button
              className="btn btn-link"
              onClick={() => {
                this.onClickFollowers();
              }}
            >
              <small> {this.props.marketstore.MarketStoreFollowCount}</small>{" "}
              <small>
                <Translate value="marketstore_followers" />
              </small>
            </button>
   */
  render() {
    return (
      <div className="card hovercard">
        <div className="slideHome">
          <img
            alt=""
            className="slideHome"
            src={this.props.marketstore.BackGroundPicturePage}
          />
          <div className="title-store">
            <h1 style={{ color: this.props.marketstore.TextColor }}>
              {this.props.marketstore.Name}
            </h1>
          </div>
          <div className="description-store">
            <h3 style={{ color: this.props.marketstore.BackGroundColor }}>
              {this.props.marketstore.Description}
            </h3>
          </div>
        </div>
        <div className="avatar_store">
          <img alt="" src={this.props.marketstore.Logo} />
        </div>
        <div className="bottom social-networks social-networks-color row">
          <div className="col-md-4 text-center">

            {this.props.marketstore.IsFollow ? (
              <button
                className="btn btn-light"
                onClick={() => {
                  this.onClickUnFollow();
                }}
                style={{ "width": "130px" }}
              >
                <small>
                  <Translate value="marketstore_unfollow" />
                </small>
              </button>
            ) : (
              this.props.marketstore.IsFollow === false && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.onClickFollow();
                  }}
                  style={{ "width": "130px" }}
                >
                  <span>
                    <Translate value="marketstore_follow" />
                  </span>
                </button>
              )
            )}
          </div>
          <div className="col-md-4 text-center"></div>
          <div className="col-md-4 text-center">
            <li className="list-inline-item text-capitalize">
              <button
                className="btn btn-link"
                onClick={() => {
                  this.onClickHome();
                }}
              >
                <b>
                  {" "}
                  <Translate value="marketstore_about_us" />{" "}
                </b>
              </button>
            </li>

            <li className=" list-inline-item text-capitalize">
              <button
                className="btn btn-link"
                onClick={() => {
                  this.onClickLocation();
                }}
              >
                <b>
                  <Translate value="marketstore_location" />{" "}
                </b>
              </button>
            </li>

            <li className=" list-inline-item text-capitalize">
              <button
                className="btn btn-link"
                onClick={() => {
                  this.onClickContact();
                }}
              >
                <b>
                  {" "}
                  <Translate value="footer_contacts" />{" "}
                </b>
              </button>
            </li>

          </div>
        </div>
        <ModalStoreFollowers
          isOpen={this.state.open_modal_followers}
          followerList={this.props.followerList}
          onClose={() => {
            this.onClose();
          }}
        />
        <ModalStoreHome
          isOpen={this.state.open_modal_home}
          onClose={() => {
            this.onClickHome();
          }}
          title={<Translate value="marketstore_about_us" />}
        >
          {" "}
          {this.props.marketstore.Home}
          <div className="modal-footer">
            <div className="col text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickHome();
                }}
              >
                <i className="fa fa-close"></i>
                <Translate value="ok" />
              </button>
            </div>
          </div>
        </ModalStoreHome>
        <ModalStoreLocation
          isOpen={this.state.open_modal_location}
          onClose={() => {
            this.onClickLocation();
          }}
          title={<Translate value="marketstore_location" />}
        >
          <div className="col">
            <div className="row">
              <small>
                <Translate value="marketstore_state" />
              </small>
            </div>
            <div className="row">{this.props.marketstore.State}</div>
            <div className="row">
              <small>
                <Translate value="marketstore_city" />
              </small>
            </div>

            <div className="row">{this.props.marketstore.City}</div>
            <div className="row">
              <small>
                <Translate value="marketstore_address" />
              </small>
            </div>

            <div className="row">{this.props.marketstore.Address}</div>
          </div>
          <div className="modal-footer">
            <div className="col text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickLocation();
                }}
              >
                <i className="fa fa-close"></i>
                <Translate value="ok" />
              </button>
            </div>
          </div>
        </ModalStoreLocation>
        <ModalStoreContacts
          isOpen={this.state.open_modal_contact}
          onClose={() => {
            this.onClickContact();
          }}
          title={<Translate value="footer_contacts" />}
        >
          <div className="col">
            <div className="row">
              <small>
                <Translate value="profile_email" />
              </small>
            </div>
            <div className="row">{this.props.marketstore.Email}</div>
            <div className="row">
              <small>
                <Translate value="profile_phone" />
              </small>
            </div>

            <div className="row">{this.props.marketstore.Phone}</div>
          </div>
          <div className="modal-footer">
            <div className="col text-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickContact();
                }}
              >
                <i className="fa fa-close"></i>
                <Translate value="ok" />
              </button>
            </div>
          </div>
        </ModalStoreContacts>
      </div>
    );
  }
}

/**
 * 
 <li className=" list-inline-item text-capitalize">
              <button
                className="btn btn-link"
                onClick={() => {
                  this.onClickMessage();
                }}
              >
                <b>
                  {" "}
                  <Translate value="message" />{" "}
                </b>
              </button>
            </li>
 */
function mapStateToProps(reducers) {
  //console.log(reducers);
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    marketstore: reducers.marketstore.item,

    error: reducers.marketstoreFollowers.error,
    loading: reducers.marketstoreFollowers.loading,
    followerList: reducers.marketstoreFollowers.list,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadFollowers: (token, params) =>
    dispatch(actions.loadList({ token, params })),
  UpdateFollow: (token, params) =>
    dispatch(actions.updateItem({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStoreSlider);
