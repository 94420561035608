import React from "react";

import "./styles/ProfileLayout.css";
//import ProfileData from '../components/ProfileData';
import ProfileMenu from "./ProfileMenu";
import ProfileSummary from "./ProfileSummary";
import PlanAdCallAction from "./PlanAdCallAction";

function ProfileLayout(props) {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 d-none d-lg-block">
            <div className="profile_left">
              <ProfileMenu onChange={props.handleChange} />
              <PlanAdCallAction />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="profile_center">{props.children}</div>
          </div>
          <div className="col-3 d-none d-lg-block">
            <div className="profile_right">
              <ProfileSummary />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProfileLayout;
