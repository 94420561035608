import axios from "axios";
import {
  CITY_LIST_ATTEMP,
  CITY_LIST_SUCCESS,
  CITY_LIST_FAILED,
} from "../types/cityTypes";
import { config } from "../../config";
const url_api = config.api.godiway;
export function loading() {
  return {
    type: CITY_LIST_ATTEMP
  };
}

export function success(data) {
  return {
    type: CITY_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: CITY_LIST_FAILED,
    error: error
  };
}

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "city", {
      params: {
        state_id: data.state_id,
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
