import React, { Component } from "react";
import WallMenu from "./WallMenu";
import PublishVertical from "./PublishVertical";
//import StoreSuscripction from './StoreSuscripction';
import SummaryByCountry from "../components/SummaryByCountry";
class WallLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <WallMenu />
        <SummaryByCountry />
        <PublishVertical />
      </React.Fragment>
    );
  }
}

export default WallLeft;
