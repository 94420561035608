
import {
    CHAT_ATTEMPT,
    CHAT_SUCCESS,
    CHAT_FAILED
} from '../types/chatTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
};

function chat(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CHAT_ATTEMPT:
            return {
                ...state,
                loading: true,
            }

        case CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                //offset:action.data.notification_count,
                list: action.data.list,
                error: ''
            }

        case CHAT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }



        default: return state;
    }
};
export default chat;