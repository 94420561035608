import React from "react";
import MarketStoreList from "../components/MarketStoreList";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";

import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import * as actions from "../redux/actions/marketstoreListActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
//import api from "../api";

class MarketStores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //if (this.props.pageCount === 0)
    this.fetchMarketStoreList();
  }

  fetchMarketStoreList = async () => {
    try {
      await this.props
        .LoadMarketStores(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            <Translate value="marketstore_title" />
          </h3>
          {this.props.loading ? (
            <PageLoading />
          ) : (
            <MarketStoreList marketstoreList={this.props.marketstoreList} />
          )}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducers) {
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    error: reducers.marketstoreList.error,
    loading: reducers.marketstoreList.loading,
    marketstoreList: reducers.marketstoreList.list,

    pageCount: reducers.marketstoreList.page_count,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadMarketStores: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketStores);
