import {
  CUSTOMER_ATTEMPT,
  CUSTOMER_SUCCESS,
  CUSTOMER_FAILED
} from "../types/customerTypes";

const INITIAL_STATE = {
  loading: false,
  item: {},
  error: ""
};

function customer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CUSTOMER_ATTEMPT:
      return {
        ...state,
        loading: true
      };
    //  break;
    case CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        item: action.item,
        error: ""
      };
    //break;
    case CUSTOMER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        item: []
      };
    default:
      return state;
  }
}
export default customer;
