import React from "react";

//import { connect } from "react-redux";
////import * as actions from "../redux/actions/sessionActions";
import { withRouter } from "react-router";
//import StoreAdList from "../components/StoreAdList";

class TempRedirectMarketStore extends React.Component {
  componentDidMount() {
    this.props.history.push(
      `/marketstore/show/${this.props.match.params.store_id}`
    );
  }

  render() {
    return <></>;
  }
}

export default withRouter(TempRedirectMarketStore);
