import React from "react";
//import { Link } from 'react-router-dom';
//import MiniLoader from "../components/MiniLoader";
//import './styles/Home.css';
//import { connect } from "react-redux";
import Tabs from "../components/Tabs";
//import PageLoading from "../components/PageLoading";
import StoreCommentList from "../components/StoreCommentList";
import StoreAdList from "./StoreAdList";
import StoreExtra from "./StoreExtra";
import { I18n } from "react-redux-i18n";//Translate,

function StoreCenter(props) {
  return (
    <Tabs>
      <div label={I18n.t("store_ads")} className="col-12">
        <StoreAdList />
      </div>
      <div label={I18n.t("store_wall")} className="col-12">
        <StoreCommentList />
      </div>
      <div label={I18n.t("store_extra")} className="col-12">
        <StoreExtra />
      </div>
    </Tabs>
  );
}

export default StoreCenter;
//this.props.loading ? <PageLoading /> :this.props.loading_inactive ? <PageLoading /> :
