import React from "react";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/ContactList.css";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import * as actions_message from "../redux/actions/messageActions";
import * as actions_event from "../redux/actions/eventActions";

import MiniLoader from "./MiniLoader";
import ServerSeen from './Serverseen';
import formatDate from '../utils/Date';
import formatHour from '../utils/Hour';

var date = (new Date(1900, 1, 1)).toDateString('en-US', { day: '2-digit', month: '2-digit', year: '2-digit' });
function setDate(props) {
  date = props.item.created_at;
  return true;
}
function isLess(date1, date2) {
  var fecha1 = new Date(date1);//.toLocaleDateString('en-US');
  var fecha2 = new Date(date2);//.toLocaleDateString('en-US');

  if (fecha1.getFullYear() < fecha2.getFullYear())
    return true;
  if (fecha1.getMonth() < fecha2.getMonth())
    return true;
  if (fecha1.getDate() < fecha2.getDate())
    return true;

  return false;
}
function MessageItemContact(props) {
  return (
    <React.Fragment>
      {
        isLess(date, props.item.created_at) && setDate(props) && (<span className="msg_date">{formatDate(props.item)}</span>)
      }

      <div className="d-flex justify-content-start mb-4">
        <div className="msg_head">
          <img
            src={props.item.user.avatar}
            className="rounded-circle user_img_msg"
            alt="Gw"
          />
        </div>
        <div className="msg_cotainer">
          {props.item.text}
          <span className="msg_time">{formatHour(props.item)}</span>
        </div>
      </div>

    </React.Fragment>
  );
}


function MessageItemMe(props) {
  return (
    <React.Fragment>
      {
        isLess(date, props.item.created_at) && setDate(props) && (<span className="msg_date">{formatDate(props.item)}</span>)
      }

      <div className="d-flex justify-content-end mb-4">
        <div className="msg_cotainer_send">
          {props.item.text}
          <span className="msg_time_send">{formatHour(props.item)}</span>
        </div>

        <div className="msg_head">
          <img
            src={props.item.user.avatar}
            className="rounded-circle user_img_msg"
            alt="Gw"
          />
        </div>
        <ServerSeen seen={props.item.is_seen} server={props.item.is_server} />
      </div>
    </React.Fragment>
  );
}
//

class MessageList extends React.Component {
  componentDidMount() { }

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadMoreMessages(this.props.token, {
          index: this.props.pageCount,
          conversation_id: this.props.contact.ConversationId,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };


  render() {
    //if (this.props.pageCount === 0)
    if (this.props.messageList.lentgh === 0)
      return (
        <h3>
          {" "}
          <Translate value="message_not_list" />
        </h3>
      );
    date = new Date(1900, 1, 1);
    return (
      <React.Fragment>
        {
          this.props.pageSize > 7 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
        {
          this.props.messageList.map((item) => {
            if (item.user.id.toString() !== this.props.user_id && item.is_seen !== 0) {
              item.is_seen = 0;
              // item.index = item.id;
              // item.id = item.user.id;
              //item.to_id = this.props.user_id;
              this.props.contact.Socket !== '' && this.props.UpdateMessageReadEvent(item);
              this.props.UpdateMessageReadMessage(this.props.token, {
                message_id: item.id,
                id: this.props.user_id,
                lang: I18n.t("locale"),
              });
            }

            return item.user.id.toString() === this.props.user_id ? (
              <MessageItemMe item={item} key={item.id} />
            ) :
              (
                <MessageItemContact item={item} key={item.id} />
              );
          })
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    messageList: reducer.message.list,
    pageCount: reducer.message.page_count,
    pageSize: reducer.message.page_size,
    loadingMore: reducer.message.loadingMore,

    contact: reducer.contacts.item,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadMoreMessages: (token, params) => dispatch(actions_message.loadListScroll({ token, params })),//page_count, id, user_id,
  UpdateMessageReadMessage: (token, params) => dispatch(actions_message.updateMessageRead({ token, params })),//id, user_id,
  ////////////////////
  UpdateMessageReadEvent: (item) => dispatch(actions_event.updateMessageRead(item)),

});

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
