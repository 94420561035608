import axios from "axios";
import { I18n } from "react-redux-i18n";

import {
  ADFAQ_LIST_ATTEMPT,
  ADFAQ_LIST_SUCCESS,
  ADFAQ_LIST_FAILED,
  //////////////////
  ADFAQ_MORE_ATTEMPT,
  ADFAQ_MORE_SUCCESS,
  ADFAQ_MORE_FAILED,
  //////////////////
  FAQ_NEW_SUCCESS,
  FAQ_NEW_FAILED
} from "../types/adFaqTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: ADFAQ_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: ADFAQ_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: ADFAQ_LIST_FAILED,
    error: error,
  };
}

export function loadingMore() {
  return {
    type: ADFAQ_MORE_ATTEMPT,
  };
}

export function successMore(data) {
  return {
    type: ADFAQ_MORE_SUCCESS,
    data: data,
  };
}

export function failedMore(error) {
  return {
    type: ADFAQ_MORE_FAILED,
    error: error,
  };
}
//////////////////
export function successPost(item) {
  return {
    type: FAQ_NEW_SUCCESS,
    item: item,
  };
}

export function failedPost(error) {
  return {
    type: FAQ_NEW_FAILED,
    error: error,
  };
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "faq", {
      params: {
        id: data.user_id,
        goder_id: data.id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async (dispatch) => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "faq", {
      params: {
        index: data.page_count,
        id: data.user_id,
        goder_id: data.id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const newItem = (data: Object) => async (dispatch) => {
  //dispatch(Loading());
  await axios
    .post(url_api + "faq", {
      question: data.question,
      goder_id: data.id,
      id: data.user_id,
      lang: I18n.t("locale"),
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successPost(response.data.body));
        else dispatch(failedPost(response.data.body));
      } else {
        console.log(response);
        dispatch(failedPost("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedPost("try_later"));
      } else {
        dispatch(failedPost("try_later"));
      }
      return;
    });
};
