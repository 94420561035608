import axios from "axios";

import {
    PRIPOL_ATTEMPT,
    PRIPOL_SUCCESS,
    PRIPOL_FAILED,
} from "../types/pripolTypes";
import { config } from "../../config";
const url_api = config.api.godiway;
export function loading() {
    return {
        type: PRIPOL_ATTEMPT
    };
}

export function success(data) {
    return {
        type: PRIPOL_SUCCESS,
        data: data
    };
}

export function failed(error) {
    return {
        type: PRIPOL_FAILED,
        error: error
    };
}
///////////////////////////////////

export const loadList = (data: Object) => async dispatch => {
    dispatch(loading());
    await axios
        .get(url_api + "legal/pripol", {
            params: {
                id: data.user_id,
            },
            headers: { Authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            //   console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else dispatch(failed(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            //console.log(error);
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};

