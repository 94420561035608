import React, { Component } from "react";
//import { Link } from 'react-router-dom';

import "./styles/Notification.css";
import PageLoading from "../components/PageLoading";
import { connect } from "react-redux";
import * as actions from "../redux/actions/notificationActions";

import NotificationLeft from "../components/NotificationLeft";
import NotificationCenter from "../components/NotificationCenter";
import NotificationRight from "../components/NotificationRight";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchNotificatioList();
  }

  fetchNotificatioList = async () => {
    try {
      await this.props
        .LoadNotificationsAll(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <div className="notification-left">
                <NotificationLeft />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="notification-center">
                {this.props.loading ? (
                  <PageLoading />
                ) : (
                  <NotificationCenter
                    notiticationList={this.props.notificationList}
                  />
                )}
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">
              <div className="notification-right">
                <NotificationRight />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    notificationList: reducer.notification.listAll,
    error: reducer.notification.error,
    loading: reducer.notification.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadNotificationsAll: (user_id, token) =>
    dispatch(actions.loadNotificationsAll({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
