
import {
    AD_RELATION_ATTEMPT,
    AD_RELATION_SUCCESS,
    AD_RELATION_FAILED,
} from '../types/adRelationTypes';

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: '',
};
function adRelation(state = INITIAL_STATE, action) {
    switch (action.type) {
        case AD_RELATION_ATTEMPT:
            return {
                ...state,
                loading: true,
            }

        case AD_RELATION_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: ''
            }

        case AD_RELATION_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            }

        default: return state;
    }
};
export default adRelation;