import React from "react";

import AdPanelList from "../components/AdPanelList";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adExchangeActions";
import "./styles/AdPublish.css";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class AdExchanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchAdExchangeList();
  }

  fetchAdExchangeList = async () => {
    try {
      await this.props
        .LoadPublish(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadPublishMore(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <div className="container-btn-title">
            <h3 className="panel-title-left">
              {" "}
              <Translate value="ad_exchange" />
            </h3>
            <div className="panel-button-right">
              <Link className="btn btn-primary" to="/goder/new/exchange">
                <Translate value="new" />
              </Link>
            </div>
          </div>
          {this.state.loading ? (
            <PageLoading />
          ) : (
            <AdPanelList adPanelList={this.props.adList}
              loadingMore={this.props.loadingMore}
              pageCount={this.props.pageCount}
              pageSize={this.props.pageSize}
              onClickLoadMore={() => {
                this.onClickLoadMore();
              }}
            />
          )}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    adList: reducer.adExchange.list,
    error: reducer.adExchange.error,
    loading: reducer.adExchange.loading,

    loadingMore: reducer.adExchange.loadingMore,
    pageCount: reducer.adExchange.page_count,
    pageSize: reducer.adExchange.page_size
  };
}
const mapDispatchToProps = dispatch => ({
  LoadPublish: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
  LoadPublishMore: (page_count, user_id, token) =>
    dispatch(actions.loadListScroll({ page_count, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdExchanges);
