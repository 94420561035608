import axios from "axios";

import {
  SUSCRIPTION_ATTEMPT,
  SUSCRIPTION_SUCCESS,
  SUSCRIPTION_FAILED,
  ////////////////////
  PLAN_UPDATE_VALUE,
  PLAN_UPDATE_NEXT,
} from "../types/suscriptionPlanTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: SUSCRIPTION_ATTEMPT,
  };
}

export function success(item) {
  return {
    type: SUSCRIPTION_SUCCESS,
    item: item,
  };
}

export function failed(error) {
  return {
    type: SUSCRIPTION_FAILED,
    error: error,
  };
}

export function setValuePlan(value) {
  return {
    type: PLAN_UPDATE_VALUE,
    value: value,
  };
}

export function setNextPlan(value) {
  return {
    type: PLAN_UPDATE_NEXT,
    value: value,
  };
}

export const setPlanValue = (data: Object) => async (dispatch) => {
  dispatch(setValuePlan(data.value));
};

export const setPlanNext = (data: Object) => async (dispatch) => {
  dispatch(setNextPlan(data.value));
};

export const loadItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "suscription", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(failed("try_later"));
      return;
    });
};

export const deleteItem = (data: Object) => async (dispatch) => {
  //dispatch(loading());

  await axios({
    method: "put",
    url: url_api + "suscription",
    data: data,
    headers: { Authorization: `Bearer ${data.token}` },
  }
  )
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(failed("try_later"));
      return;
    });
};
