import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
//import './styles/Home.css';

//import PublishVertical from "./PublishVertical";

class RegisterTips extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  //
  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="register card ">
          <div className="column    ">
            <h1 className="h3  text-center">
              <span>
                <Translate value="godiway_wellcome" />
              </span>
            </h1>
            <div
              className=""
              style={{ paddingRight: "100px", paddingLeft: "100px" }}
            >
              <Translate value="joinwinall1" />
              <br />
              <Translate value="joinwinall2" />
              <br />
              <br />
              <li>
                <Translate value="register_tip1" />
              </li>
              <li>
                <Translate value="register_tip2" />
              </li>
              <li>
                <Translate value="register_tip3" />
              </li>
              <li>
                <Translate value="register_tip4" />
              </li>
              <li>
                <Translate value="register_tip5" />
              </li>
              <li>
                <Translate value="register_tip6" />
              </li>

            </div>
            <br />
            <h5 className=" text-center">
              <Translate value="register_tip7" />{" "}
              <Link to="/pricing">
                <Translate value="register_tip8" />
              </Link>
            </h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 *  <li>
                <Translate value="register_tip7" />
              </li>
              <li>
                <Translate value="register_tip8" />
              </li>
              <li>
                <Translate value="register_tip9" />
              </li>
 */

export default RegisterTips;
