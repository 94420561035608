import React, { Component, } from 'react';
//import Api from '../../../../utils/api';
import "./styles/server&seen.css";

//const width = Api.width;
//const icon_size = 45;

class ServerSeen extends Component {
    render() {
        return (
            <div className="serverseen_line" style={{ flexDirection: 'row', paddingHorizontal: 5, alignItems: 'center', display: 'flex' }}>
                <div style={{
                    backgroundColor: this.props.seen === 2 ? "#F5F5F5" : (this.props.seen === 1 ? "#6b6b6b" : "#55a1e1"),
                    height: 10,
                    width: 10,
                    borderRadius: 50,
                    border: 1.5,
                    borderColor: "#FFFFFF",
                    margin: 1

                }} />
                <div style={{
                    backgroundColor: this.props.server === 2 ? "#F5F5F5" : (this.props.server === 1 ? "#6b6b6b" : "#55a1e1"),
                    height: 10,
                    width: 10,
                    borderRadius: 50,
                    border: 1.5,
                    borderColor: "#FFFFFF",
                    margin: 1
                }} />
            </div>
        )
    }
}
export default (ServerSeen);