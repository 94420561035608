import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Translate, I18n } from "react-redux-i18n";
import { FaCamera } from "react-icons/fa";

import "./styles/Profile.css";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import ProfileLayout from "../components/ProfileLayout";
import PageLoading from "../components/PageLoading";
import Api from "../utils/api";
import * as actions_session from "../redux/actions/sessionActions";
import * as actions_country from "../redux/actions/countryActions";




class ProfileEdit extends React.Component {
  state = {
    form: {
      whatsapp: false
    },
    avatar: ""
    //  file: ''
  };

  async componentDidMount() {
    if (!!this.props.listCountry) await this.fetchCountry();
    this.setState({
      form: {
        id: this.props.user_id,
        name: this.props.profile.name,
        lastN: this.props.profile.lastN,
        email: this.props.profile.email,
        username: this.props.profile.username,
        phone: this.props.profile.phone,
        country_id: this.props.profile.country_id,
        whatsapp: this.props.profile.whatsapp === "on" ? true : false
      },
      avatar: this.props.profile.avatar
    });
  }

  fetchCountry = async () => {
    try {
      await this.props
        .LoadCountry(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  /*   Ya no uso este metodo 
    fetchProfile = async () => {
      try {
        await this.props
          .LoadProfile(this.props.user_id, this.props.token)
          .then(() => {
            if (this.props.error) {
              alertify.warning(this.props.error);
            } else this.setState({ avatar: this.props.profile.Avatar });
          });
      } catch (error) {
        console.log(error);
      }
    };
  */

  onChange = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });

  };
  onChangeWhatsapp = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        whatsapp: !this.state.form.whatsapp
      }
    });
    // console.log(this.state.form);
  };

  onChangeAvatar = async e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        //   file: file,
        avatar: reader.result
      });
    };

    reader.readAsDataURL(file);
    let params = new FormData();

    params.append("id", this.props.user_id);
    params.append("avatar", file);//Api.compressImage(file)


    await this.props.LoadAvatar(this.props.token, params).then(() => {
      if (this.props.error) {
        alertify.warning(this.props.error);
      }
    });
  };

  onClick = async () => {
    try {
      /*   this.setState({
           form: {
             ...this.state.form,
             whatsapp: this.state.form.whatsapp ? 'on' : 'off'
           }
         });*/
      await this.props.UpdateUser(this.props.token, this.state.form).then(() => {
        if (this.props.error) {
          alertify.warning(this.props.error);
        } else {
          alertify.success("saved succesfull");
          this.props.history.push("/profile/edit"); //profile
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  //       <hr className="dividerGray" />
  render() {

    return (
      this.props.loadingCountry ? <PageLoading />
        : (
          <React.Fragment>
            <ProfileLayout>
              <div>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title text-center">
                      <b>@{this.props.profile.username}</b>
                    </h3>
                    <hr className="dividerGray" />
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        <figure>
                          <div className="form-group drop">
                            <label>
                              <img
                                id="image"
                                className="prev_thumb img-rounded"
                                src={this.state.avatar}
                                alt="Avatar"
                                width="260px"
                                height="260px"
                              />
                              <div>
                                <input
                                  className="upload"
                                  accept="image/*"
                                  type="file"
                                  name="avatar"
                                  id="avatar"
                                  onChange={e => {
                                    this.onChangeAvatar(e);
                                  }}
                                />
                                <span htmlFor="avatar">
                                  <FaCamera />
                                </span>
                              </div>
                            </label>
                          </div>
                        </figure>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <small>
                          <Translate value="profile_name" />:
                        </small>
                        <div className="form-group">
                          <input
                            onChange={this.onChange}
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder={I18n.t("profile_name")}
                            required={true}
                            autoFocus
                            value={this.state.form.name}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <small>
                          <Translate value="profile_last_name" />:
                        </small>
                        <div className="form-group">
                          <input
                            onChange={this.onChange}
                            className="form-control"
                            type="text"
                            name="lastN"
                            placeholder={I18n.t("profile_last_name")}
                            required
                            autoFocus
                            value={this.state.form.lastN}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <small>
                          <Translate value="profile_username" />:
                        </small>
                        <div className="form-group">
                          <input
                            onChange={this.onChange}
                            className="form-control"
                            type="text"
                            name="username"
                            placeholder={I18n.t("profile_username")}
                            required
                            autoFocus
                            value={this.state.form.username}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <small>
                          <Translate value="email" />:
                        </small>
                        <div className="form-group">
                          <input
                            onChange={this.onChange}
                            className="form-control"
                            type="text"
                            name="email"
                            placeholder={I18n.t("email")}
                            required
                            autoFocus
                            value={this.state.form.email}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2">
                        <small>{I18n.t("country")}</small>
                        <select
                          className="form-control"
                          onChange={this.onChange}
                          name="country_id"
                          value={this.state.form.country_id}
                        >
                          <option value="" key="0" >{I18n.t("profile_select")}</option>
                          {
                            this.props.listCountry.map((item) => {
                              return (
                                <option key={item.prefix} value={item.prefix}>
                                  {item.name}{" (+" + item.lada + ")"}
                                </option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
                        <div className="info">
                          <small>
                            <Translate value="profile_phone" />:
                          </small>
                          <div className="form-group">
                            <input
                              onChange={this.onChange}
                              className="form-control"
                              type="text"
                              name="phone"
                              placeholder={I18n.t("profile_phone")}
                              required
                              autoFocus
                              value={this.state.form.phone}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="profile-row form-check">
                      <strong> {"Whatsapp"}</strong>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="whatsapp"
                        checked={this.state.form.whatsapp}
                        onChange={this.onChangeWhatsapp}
                      />

                    </div>
                    <hr className="dividerGray" />
                    <div className="text-center">

                      <Link
                        to="#"
                        onClick={this.onClick}
                        className="btn btn-primary mb-4 mg-10"
                      >
                        <Translate value="save" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </ProfileLayout>
          </React.Fragment>
        )
    )


  }
}
/**
 * 
 * @param {                  <Link to="/profile" className="btn btn-secondary mb-4 mg-10">
                    <Translate value="back" />
                  </Link>} reducer 
 * @returns 
 */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    profile: reducer.session.auth,
    error: reducer.session.error,
    loading: reducer.session.loading,

    listCountry: reducer.country.list,
    errorCountry: reducer.country.error,
    loadingCountry: reducer.country.loading,
  };
}

const mapDispatchToProps = dispatch => ({
  UpdateUser: (token, params) => dispatch(actions_session.updateItem({ token, params })),
  LoadAvatar: (token, params) => dispatch(actions_session.loadAvatar({ token, params })),
  LoadProfile: (user_id, token) =>
    dispatch(actions_session.loadItem({ user_id, token })),
  ////////////
  LoadCountry: (user_id, token) => dispatch(actions_country.loadList({ user_id, token })),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileEdit)
);

/**
 *  <div className="profile-between form-group">
                      <div className="">

                        {this.props.profile.CountryFlag !== undefined && (
                          <img
                            src={this.props.profile.CountryFlag}
                            className="profile-flag"
                            alt="flag"
                          />

                        )}
                        + ({this.props.profile.CountryPhoneId})
                      </div>

                    </div>
 */