import React, { Component } from "react";
//import { Link } from 'react-router-dom';

import "./styles/Wall.css";
import PageLoading from "../components/PageLoading";
import WallLeft from "../components/WallLeft";
import WallRight from "../components/WallRight";
import WallComments from "../components/WallComments";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import * as actions from "../redux/actions/wallActions";
import { connect } from "react-redux";
//import AdFiltros from '../components/AdFiltros';
//import api from '../api';

class Wall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    if (this.props.pageCount === 0)
      this.fetchCommentList();
  }

  fetchCommentList = async () => {
    try {
      await this.props
        .LoadWall(this.props.token, {
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <div className="wall-left">
                <WallLeft />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wall-center">
                {this.props.loading ? (
                  <PageLoading />
                ) : (
                  <WallComments /*commentList={this.props.wallList}*/ />
                )}
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">
              <div className="wall-right">
                <WallRight />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    wallList: reducer.wall.list,
    loading: reducer.wall.loading,
    error: reducer.wall.error,
    pageCount: reducer.wall.page_count
  };
}

const mapDispatchToProps = dispatch => ({
  LoadWall: (token, params) => dispatch(actions.loadList({ token, params, }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Wall);
