import { config } from "../config";
// import imageCompression from 'browser-image-compression';
import { Translate } from "react-redux-i18n";
// import Compressor from 'compressorjs';

class Api {

  //   async compressImage(imageFile) {

  //     //const imageFile = event.target.files[0];
  //     // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  //     // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

  //     // const options = {
  //     //   maxSizeMB: 5,
  //     //   maxWidthOrHeight: 1080,
  //     //   useWebWorker: true
  //     // }
  //     // try {
  //     //   const compressedFile = await imageCompression(imageFile, options);
  //     //   console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
  //     //   console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

  //     //   return compressedFile;

  //     // } catch (error) {
  //     //   console.log(error);
  //     // }
  //     let compressedFile;

  //     new Compressor(imageFile, {
  //       quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
  //       success: (compressedResult) => {
  //         // compressedResult has the compressed file.
  //         // Use the compressed file to upload the images to your server.        
  //         compressedFile = compressedResult.res;
  //         console.log(compressedResult);
  //       },
  //     });
  //     return compressedFile;
  //   }

  // }
  /*
  async function callApi(endpoint, options = {}) {
    options.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
  
      //    'Access-Control-Allow-Origin': "*",
      //mode: 'no-cors',
      // Accept: 'application/json',
      //cache: 'default'
    };
    const url = config.api.godiway + endpoint;
    const response = await fetch(url, options);
    const data = await response.json();
  
    return data;
  }
  
  async function callApiExternal(endpoint, options = {}) {
    options.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      //"Access-Control-Allow-Origin": "*"
      // mode: "no-cors"
  
      // Accept: 'application/json',
      //cache: 'default'
    };
  
    const url = config.api.godiway;
    const response = await fetch(url, options);
    const data = await response.json();
  
    return data;
  }
  
  const api = {
   
  };
  */
}
export default new Api();
