import React from "react";
//import "./styles/FeedBack.css";
//import { Link } from "react-router-dom";
//import Footer from "../components/Footer";
import { Translate, I18n } from "react-redux-i18n";
import Accordion from "../components/Accordion";
import Footer from "../components/Footer";
import { connect } from "react-redux";

class FaqMe extends React.Component {

    state = {
        data_es: [
            {
                title: "¿Qué es Godiway?",
                content: "Godiway es una plataforma de E-commerce que busca ir más allá de los ecommerce comunes, dando un plus más colaborativo en tus transacciones."
            },
            {
                title: "¿Puedo registrame?",
                content: "Toda persona que tenga criterio formado y desee emprender anunciando con <b>Godiway</b> puede registrase o cualquier persona que este pensando en <br/> adquirir bienes o servicios podrá registrarse. Más información<a href = 'https://www.godiway.com/home/terms_condition'>Términos y Condiciones de Uso.</a> "
            },
            {
                title: "¿Cómo registrarme?",
                content: "En la parte superior izquierda en <b>Únete a Godiway</b> te llevará a la página de registro donde te pedirá tu email, contraseña y el país, a continuación deberás leer y aceptar los términos y condiciones de Uso, Políticas de Privacidad."
            },
            {
                title: "¿Es gratis anunciar?",
                content: "Es gratis anunciar hasta 4 bienes o servicios diferentes, a partir del 5 deberá adquirir un plan de anuncios.<a href='https://www.godiway.com/pricing'>Suscripciones.</a>"
            },
            {
                title: "¿Qué idioma soporta?",
                content: "Actualmente la plataforma soporta 3 idiomas: Ingles, Español y Hebreo."
            },
            {
                title: "¿Si requiero mayor información de los servicios de Godiway?",
                content: "Puedes comunicarte con nosotros al siguiente correo: team@godiway.com o al número 1 (786) 477-7340"
            },
            {
                title: "¿Cómo puedo publicar un anuncio?",
                content: "En el modo rápido, en la parte superior derecha se encuentra un botón de 'Publicar Anuncio', le das clic, luego seleccionas el tipo de anuncio, a continuación llenas todos los datos del producto o servicio y, en la última pantalla subes hasta 5 imágenes."
            },
            {
                title: "¿Hay aplicaciones móbiles?",
                content: "Tenemos disponibles en PlayStore para Android y en AppStore para Iphone la app Godiway, la puedes buscar, descargar e instalar. "
            },
        ],
        data_en: [
            {
                title: "What is Godiway?",
                content: "Godiway is an E-commerce platform that seeks to go beyond common E-commerce giving a more collaborative plus."
            },
            {
                title: "Can I register?",
                content: "Any person who has formed criteria and wants to start advertising with <b>Godiway</b> can register or anyone who is thinking of <br/> acquiring a good or service can register. More information <a href = 'https ://www.godiway.com/home/terms_condition'>Terms and Conditions of Use.</a> "
            },
            {
                title: "How to register?",
                content: "In the upper left part in <b>Join Godiway</b> it will take you to the registration page where it will ask you for your email, password, enter twice and the country, then you must read and accept the terms and conditions of Use, privacy policies."
            },
            {
                title: "Is it Free to Advertise?",
                content: "It's free to advertise up to 4 different goods or services. After 5 you must purchase an ad plan <a href='https://www.godiway.com/pricing'>Subscriptions.</a>"
            },
            {
                title: "What language does it support?",
                content: "Currently it is for 3 languages English, Spanish, Hebrew."
            },
            {
                title: "If I need more information, where can you help me?",
                content: "You could contact the email team@godiway.com or the number 1 (786) 477-7340"
            },
            {
                title: "How can I post an ad?",
                content: "In quick mode, at the top right there is a 'Publish Ad' button, you click on it, then you select the type of ad, then you fill in all the data of the product or service and on the last screen you upload the images you want up to 5."
            },
            {
                title: "Are there mobile apps?",
                content: "We have the Godiway app available in the PlayStore for Android and in the AppStore for Iphone, you can search, download and install it."
            },
        ]
    }
    componentDidMount() { }

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="container-sucessfull">
                        <hr className="dividerGray" />
                        <hr className="dividerGray" />
                        {
                            this.props.lang === "es" ? this.state.data_es.map(item => {
                                return (
                                    <React.Fragment>
                                        <Accordion
                                            title={item.title}
                                            content={item.content}
                                        />

                                    </React.Fragment>
                                );
                            }) : this.state.data_en.map(item => {
                                return (
                                    <React.Fragment>
                                        <Accordion
                                            title={item.title}
                                            content={item.content}
                                        />

                                    </React.Fragment>
                                );
                            })

                        }


                    </div>

                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
function mapStateToProps(reducer) {
    return {
        lang: reducer.i18n.locale
    };
}
export default connect(mapStateToProps, null)(FaqMe);