import axios from "axios";
import {
  WISH_LIST_ATTEMPT,
  WISH_LIST_SUCCESS,
  WISH_LIST_FAILED,
  ///////////////////////
  WISH_UPDATE_SUCCESS,
  WISH_UPDATE_FAILED,
  /////////////////////////
  WISH_SET_COUNT
} from "../types/wishTypes";

import {
  AD_ITEM_SUCCESS,
} from "../types/adTypes";

import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: WISH_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: WISH_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: WISH_LIST_FAILED,
    error: error
  };
}
////////////////////////////
export function successUpdateWish(item) {//Actualiza la lista de Wish
  return {
    type: WISH_UPDATE_SUCCESS,
    item: item
  };
}

export function failedUpdate(error) {
  return {
    type: WISH_UPDATE_FAILED,
    error: error
  };
}

export function successUpdateAd(item) {//Actualiza Ad Actual
  return {
    type: AD_ITEM_SUCCESS,
    item: item
  };
}
/**
 * Este Afecta el Reducer de Ad
 */
/*
export function update_adItem(item) {
  return {
    type: ADWISH_UPDATE_SUCCESS,
    item: item
  };
}

*/
/**end */

/**
 *   export function successScroll(data){
    return{
    type:NOTIFICATION_LIST_SCROLL,
    data:data
    }
};
*/
export function setCount(wishCount) {
  return {
    type: WISH_SET_COUNT,
    wishCount: wishCount
  };
}
export const setWishCount = (data: Object) => async dispatch => {
  dispatch(setCount(data.wishCount));
};

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "wish", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
    });
};


/*
export const updateWishAd = (data: Object) => async dispatch => {
  await axios
    .post(url_api + "wish/update", {
      id: data.id,
      user_id: data.user_id,
      token: data.token
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success") {
          dispatch(successUpdate(response.data.item));
          dispatch(update_adItem(response.data.item));
        } else dispatch(failedUpdate(response.data.description));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};
*/

export const updateItem = (data: Object) => async dispatch => {
  await axios
    .put(url_api + "wish", data.params,
      {
        headers: { Authorization: `Bearer ${data.token}` },
      })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success") {
          dispatch(successUpdateWish(response.data.body));
          dispatch(successUpdateAd(response.data.body));
        } else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};
