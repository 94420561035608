import React from "react";
//import './styles/Home.css';
import PanelSummary from "./PanelSummary";
import PanelMenu from "./PanelMenu";
import "./styles/PanelLayout.css";

export default function PanelLayout(props) {
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-3 d-none d-lg-block">
            <div className="panel-left">
              <PanelMenu />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="panel-center">{props.children}</div>
          </div>
          <div className="col-3 d-none d-lg-block">
            <div className="panel-right">
              <PanelSummary />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
