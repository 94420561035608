function formatNotification(props) {
    return {
        Kind: 'MESSAGE',
        TempId: 41,
        Photo: props.avatar,
        Message: 'Hola que tal ',
        Detail: 'Mensaje enviado',
        CreatedAt: new Date(),
        ToUserId: props.to_user_id,
        user: {
            avatar: props.avatar,
            id: props.user_id,
            name: props.name,
        }
    };
}

export default formatNotification;