import React, { Component } from "react";
import { connect } from "react-redux";

import "./styles/Wall.css";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import PageLoading from "../components/PageLoading";
import WallLeft from "../components/WallLeft";
import WallRight from "../components/WallRight";
import CommentCenter from "../components/CommentCenter";
import * as actions from "../redux/actions/adCommentActions";

//import AdFiltros from '../components/AdFiltros';
//import api from '../api';

class Comment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    this.fetchCommentList();
  }

  fetchCommentList = async () => {
    try {
      await this.props
        .LoadComment(
          this.props.token, {
          comment_id: this.props.match.params.comment_id,
          id: this.props.user_id,
        }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <div className="wall-left">
                <WallLeft />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="wall-center">
                {this.props.loading ? (
                  <PageLoading />
                ) : (
                  <CommentCenter commentList={this.props.commentList} />
                )}
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">
              <div className="wall-right">
                <WallRight />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    error: reducer.adComment.error,
    commentList: reducer.adComment.list,
    loading: reducer.adComment.loading
  };
}

const mapDispatchToProps = dispatch => ({
  LoadComment: (token, params) =>
    dispatch(actions.loadList({ token, params }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
//////////////////////////////////
