import axios from "axios";

import {
    CHAT_ATTEMPT,
    CHAT_SUCCESS,
    CHAT_FAILED
} from "../types/chatTypes";

import { SET_BADGET_TAB_CONTACTS } from "../types/contactsTypes";
import { config } from "../../config";

const url_api = config.api.godiway;

export function loading() {
    return {
        type: CHAT_ATTEMPT
    };
}

export function success(data) {
    return {
        type: CHAT_SUCCESS,
        data: data
    };
}

export function failed(error) {
    return {
        type: CHAT_FAILED,
        error: error
    };
}
/////////////////////////////
export function setBadgetChat(badget_chat) {
    return {
        type: SET_BADGET_TAB_CONTACTS,
        badget_chat: badget_chat
    }
}
export const loadList = (data: Object) => async dispatch => {
    dispatch(loading());
    await axios
        .get(url_api + "contact/unread", {
            params: {
                message_id: data.id,
                id: data.user_id,
            },
            headers: { Authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else {
                    dispatch(failed(response.data.body));
                    //dispatch(setBadgetChat(response.data.data.size));

                }
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};
