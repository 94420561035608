

export const TRANSACTIONF_REQUEST_LIST_ATTEMPT =
  "TRANSACTIONF_REQUEST_LIST_ATTEMPT";
export const TRANSACTIONF_REQUEST_LIST_SUCCESS =
  "TRANSACTIONF_REQUEST_LIST_SUCCESS";
export const TRANSACTIONF_REQUEST_LIST_FAILED =
  "TRANSACTIONF_REQUEST_LIST_FAILED";


export const TRANSACTIONF_REQUEST_MORELIST_ATTEMPT =
  "TRANSACTIONF_REQUEST_MORELIST_ATTEMPT";
export const TRANSACTIONF_REQUEST_MORELIST_SUCCESS =
  "TRANSACTIONF_REQUEST_MORELIST_SUCCESS";
export const TRANSACTIONF_REQUEST_MORELIST_FAILED =
  "TRANSACTIONF_REQUEST_MORELIST_FAILED";
export const LOGOUT = "LOGOUT";