import React from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";//, I18n 
import { FaTrash } from "react-icons/fa";

class CardItem extends React.Component {
  render() {
    var date_local = new Date(Date.parse(this.props.item.CreatedAt + " UTC"));
    return (
      <tr>
        <td>{this.props.item.Name}</td>
        <td>{this.props.item.BrandCard}</td>
        <td>{this.props.item.NumberCard}</td>
        <td>{this.props.item.KindCard}</td>
        <td>{this.props.item.Predetermine === 1 && "Yes"}</td>
        <td>{date_local.toDateString()}</td>
        <td colspan="3">
          <button className="btn btn-danger ">
            <FaTrash />
          </button>
        </td>
      </tr>
    );
  }
}

class CardList extends React.Component {
  onClick = item => {
    this.props.SetAd(item);
  };

  render() {
    if (this.props.cardList.length === 0)
      return (
        <h3>
          <Translate value="card_not_list" />
        </h3>
      );

    return (
      <div class="col-12">
        <div class="row ">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>
                  <Translate value="profile_name" />
                </th>
                <th>
                  <Translate value="card" />
                </th>
                <th>
                  <Translate value="card_number" />
                </th>
                <th>
                  <Translate value="card_kind" />
                </th>
                <th>
                  <Translate value="card_predetermined" />
                </th>
                <th>
                  <Translate value="order_date" />
                </th>
                <th colSpan="3">Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.cardList.map(item => {
                return (
                  <CardItem
                    item={item}
                    key={item.Id}
                    onClick={() => {
                      this.onClick(item);
                    }}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    cardList: reducer.cards.cardList
  };
}
const mapDispatchToProps = dispatch => ({
  //   SetAd:(ad) => dispatch(actions.setAd(ad)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardList);
