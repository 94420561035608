import {
  AD_REPORT_ATTEMPT,
  AD_REPORT_SUCCESS,
  AD_REPORT_FAILED,
  //////////////////
  ADD_REPORT_FAILED,
  ADD_REPORT_SUCCESS
} from "../types/adReportTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  success: ""
};
function adReport(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_REPORT_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: ""
      };

    case AD_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    case ADD_REPORT_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case ADD_REPORT_SUCCESS:
      return {
        ...state,
        success: action.success
      };

    default:
      return state;
  }
}
export default adReport;
