import React from "react";
//import api from "../api";
import PanelLayout from "../components/PanelLayout";
import "./styles/MarketStore.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreActions";
import MiniLoader from "../components/MiniLoader";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";

class PanelMarketStore extends React.Component {
  state = {
    cover: "",
    logo: ""
  };


  componentDidMount() {
    this.fetchMarketStoreData();
  }

  fetchMarketStoreData = async () => {
    try {
      await this.props
        .LoadMyMarketStore(this.props.token, {
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else {
            if (this.props.marketstore !== null)
              this.setState({
                cover: this.props.marketstore.BackGroundPicturePage,
                logo: this.props.marketstore.Logo
              });
          }
        });
    } catch (error) {
      console.log(error);
      alertify.warning(I18n.t(this.props.errorUpdate));
    }
  };

  onClickCreateStore = async () => {
    try {
      await this.props
        .CreateMyMarketStore(this.props.token, {
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.errorUpdate) {
            alertify.warning(I18n.t(this.props.errorUpdate));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeCover = async event => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        cover: reader.result
      });
    };
    reader.readAsDataURL(file);

    let params = new FormData();

    params.append("image", file);
    params.append("id", this.props.user_id);

    await this.props.UploadCover(this.props.token, params).then(() => {
      if (this.props.errorUpdate) {
        alertify.warning(I18n.t(this.props.errorUpdate));
      } else {
        alertify.success(I18n.t("image_loaded"));
      }
    });
  };

  onChangeLogo = async event => {
    event.preventDefault();

    let reader = new FileReader();
    let file = event.target.files[0];

    reader.onloadend = () => {
      this.setState({
        logo: reader.result
      });
    };
    reader.readAsDataURL(file);

    let params = new FormData();

    params.append("image", file);
    params.append("id", this.props.user_id);

    await this.props.UploadLogo(this.props.token, params).then(() => {
      if (this.props.errorUpdate) {
        alertify.warning(I18n.t(this.props.errorUpdate));
      } else {
        alertify.success(I18n.t("image_loaded"));
      }
    });
  };

  onClickRemove = async () => {
    await this.props
      .RemoveStore(
        this.props.token, {
        store_id: this.props.marketstore.Id,
        id: this.props.user_id,
      }
      )
      .then(() => {
        if (this.props.errorUpdate) {
          alertify.warning(I18n.t(this.props.errorUpdate));
        } else {
          alertify.success(I18n.t("deleted"));
        }
      });
  };
  render() {
    return (
      <PanelLayout>
        <h3>
          <Translate value="marketstore_title" />
        </h3>
        <div className="col-xl-12 ">
          <div className="text-center">
            {this.props.loading ? (
              <MiniLoader />
            ) : this.props.marketstore === null ? (
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickCreateStore();
                }}
              >
                <Translate value="marketstore_activate" />
              </button>
            ) : (
              <React.Fragment>
                <div className="marketstore-right">
                  <button
                    className="btn btn-link"
                    onClick={() => {
                      this.onClickRemove();
                    }}
                  >
                    <Translate value="marketstore_delete" />
                  </button>
                </div>
                <div className="col-md-12 store">
                  <div className="card hovercard">
                    <div className="slideHome">
                      <img
                        alt=""
                        className="slideHome"
                        src={this.state.cover}
                      />
                      <label
                        className="title-store"
                        style={{ color: this.props.marketstore.TextColor }}
                      >
                        {this.props.marketstore.Name}
                      </label>
                      <label
                        className="description-store"
                        style={{
                          color: this.props.marketstore.BackGroundColor
                        }}
                      >
                        {this.props.marketstore.Description}
                      </label>
                      <div className="container-fluid clrwhite">
                        <div className="row">
                          <div className="col-md-12 text-center info"></div>
                        </div>
                      </div>
                    </div>
                    <div className="avatar_store">
                      <img alt="" src={this.state.logo} />
                    </div>
                    <div className="row">
                      <div className="col-md-6 text-center">
                        <input
                          accept="image/*"
                          id="file_input_background"
                          type="file"
                          name="open_img"
                          className="attach_file"
                          onChange={e => {
                            this.onChangeCover(e);
                          }}
                        />
                        <label
                          htmlFor="file_input_background"
                          className="text-info"
                        >
                          <Translate value="marketstore_change_background" />
                        </label>
                      </div>
                      <div className="col-md-6 text-center">
                        <input
                          accept="image/*"
                          id="file-input_logo"
                          type="file"
                          name="open_img"
                          className="attach_file"
                          onChange={e => {
                            this.onChangeLogo(e);
                          }}
                        />
                        <label htmlFor="file-input_logo" className="text-info">
                          <Translate value="marketstore_change_logo" />
                        </label>
                      </div>
                    </div>
                  </div>
                  {this.props.loadingUpdate && <MiniLoader />}
                  <div className="thumbnail">
                    <div className="card">
                      <div className="row">
                        <div className="col-12 ">
                          <div className="row">
                            <div className="col-6 text-center">
                              <Link
                                to="/panel/marketstore/edit"
                                className="btn btn-primary"
                              >
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                                <span>
                                  {" "}
                                  <Translate value="marketstore_edit" />
                                </span>
                              </Link>
                            </div>
                            <div className="col-6 text-center">
                              <Link
                                className="btn btn-success"
                                to={`/marketstore/show/${this.props.marketstore.Id}`}
                              >
                                <i className="fa fa-institution"></i>
                                <span>
                                  {" "}
                                  <Translate value="marketstore_see" />
                                </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </PanelLayout>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    error: reducer.marketstore.error,
    loading: reducer.marketstore.loading,
    marketstore: reducer.marketstore.item,

    errorUpdate: reducer.marketstore.error_update,
    loadingUpdate: reducer.marketstore.loading_update
  };
}
const mapDispatchToProps = dispatch => ({
  LoadMyMarketStore: (token, params) =>
    dispatch(actions.loadItemMe({ token, params })),
  CreateMyMarketStore: (token, params) =>
    dispatch(actions.newItem({ token, params })),
  UploadCover: (token, params) => dispatch(actions.uploadCover({ token, params })),
  UploadLogo: (token, params) => dispatch(actions.uploadLogo({ token, params })),
  RemoveStore: (token, params) =>
    dispatch(actions.removeStore({ token, params }))
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelMarketStore);
//withRouter
