import {
  WALL_LIST_ATTEMPT,
  WALL_LIST_SUCCESS,
  WALL_LIST_FAILED,
  /////////////////////////
  WALL_MORELIST_ATTEMPT,
  WALL_MORELIST_SUCCESS,
  WALL_MORELIST_FAILED,
  ////////////////////////
  WALL_UPDATE_LIKE_SUCCESS,
  WALL_UPDATE_LIKE_FAILED,
  //////////////////////////
  WALL_ADD_COMMENT_SUCCESS,
  WALL_ADD_COMMENT_FAILED,
  /////////////////////////
  WALL_REMOVE_COMMENT_SUCCESS,
  WALL_REMOVE_COMMENT_FAILED,
  ///////////////////////////
  WALL_REPORT_COMMENT_SUCCESS,
  WALL_REPORT_COMMENT_FAILED,

  LOGOUT
} from "../types/wallTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  page_size: 0,
  page_count: 0,
  loadingMore: false,
  error: "",
  success: "",

  error_up: "",
  error_report: "",
  error_remove: "",
};

function wall(state = INITIAL_STATE, action) {
  switch (action.type) {
    case WALL_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case WALL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        page_size: action.data.size,
        page_count: action.data.size,
        list: action.data.list,
        error: "",
      };

    case WALL_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    //////////////////////////////////////////
    case WALL_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };

    case WALL_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        list: state.list.concat(action.data.list),
        error: "",
      };

    case WALL_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    //////////////////////////////////////////
    case WALL_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        list: [action.item, ...state.list],
        error_up: "",
      };

    case WALL_ADD_COMMENT_FAILED:
      return {
        ...state,
        error_up: action.error,
      };
    //////////////////////////////////////////
    case WALL_UPDATE_LIKE_SUCCESS: {
      const newState = state.list.map((item) => {
        if (item.Id === action.item.Id)
          return action.item;
        else return item;
      });
      return {
        ...state,
        list: newState,
        error: "",
      };
    }

    case WALL_UPDATE_LIKE_FAILED:
      return {
        ...state,
        error_up: action.error,
      };
    //////////////////////////
    case WALL_REMOVE_COMMENT_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.Id !== action.item.Id;
      });
      return {
        ...state,
        list: newState,
      };
    }

    case WALL_REMOVE_COMMENT_FAILED:
      return {
        ...state,
        error_remove: action.error,
      };
    //////////////////////////
    case WALL_REPORT_COMMENT_SUCCESS:
      return {
        ...state,
      };

    case WALL_REPORT_COMMENT_FAILED:
      return {
        ...state,
        error_report: action.error,
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
export default wall;
