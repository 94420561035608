import React from "react";
//import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { FaEyeSlash, FaEye } from "react-icons/fa";

import "./styles/LoginForm.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

//import GoogleLogin from "react-google-login";

import * as actions from "../redux/actions/sessionActions";

import { config } from "../config";

import MiniLoader from "../components/MiniLoader";

//import TipsGoodTransaction from "./TipsGoodTransaction";
const API_KEY_FACEBOOK = config.api.facebook;
class LoginForm extends React.Component {

  state = {
    show: false,
    form: { email: "", password: "" },
    touched: {
      email: false,
      password: false,
    },
  };

  handleChange = (e) => {
    this.setState({
      ...this.state,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
    this.validateField();
  };

  handleBlur = (field) => (evt) => {
    this.setState({
      ...this.state,
      touched: { ...this.state.touched, [field]: true },
    });
  };

  responseFacebook = async (response) => {
    if (response.status === "unknown") return;
    let params = new FormData();

    params.append("provider", "facebook");
    params.append("email", response.email);
    params.append("id", response.id);
    params.append("name", response.name);
    params.append("photo", response.picture.data.url);

    await this.props.LoginSocialRequest(params).then(() => {
      if (this.props.error) {
        alertify.warning(I18n.t(this.props.error));
      } else {
        //if (JSON.stringify(this.props.user) !== "{}") {
        this.props.history.push("/home");
        // }
      }
    });
  };

  responseGoogle = async (response) => {
    let params = new FormData();

    params.append("provider", "google");
    params.append("email", response.profileObj.email);
    params.append("id", response.profileObj.googleId);
    params.append("name", response.profileObj.name);
    params.append("photo", response.profileObj.imageUrl);

    await this.props.LoginSocialRequest(params).then(() => {
      if (this.props.error) {
        alertify.warning(I18n.t(this.props.error));
      } else {
        // if (JSON.stringify(this.props.user) !== "{}" && this.props.hasCountry)
        this.props.history.push("/home");
        //  else this.props.history.push("/countries");
      }
    });
  };

  responseGoogleFailure = async (response) => {
    // alertify.warning(response.error);
    console.log(response.error);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.validateField("email") ||
      this.validateField("password") ||
      this.validateEmail()
    ) {
      this.setState({
        ...this.state,
        touched: { email: true, password: true },
      });
      return;
    }
    this.fecthLogin();
  };


  fecthLogin = async () => {
    await this.props.LoginRequest(this.state.form).then(() => {
      if (JSON.stringify(this.props.user) === "{}" && this.props.error) {
        alertify.warning(I18n.t(this.props.error));
      } else {
        this.props.history.push("/home");
        /*   const timer = setTimeout(() => {
            console.log(props.user);
            props.LoginSocketRequest(props.user);
          }, 1000);
          //clearInterval(timer);
           const timer = setInterval(() => {
   
             if (JSON.stringify(this.props.user) !== "{}") {*/
        /*   
         }
       }, 500);*/
      }

    });
  };

  changePassword = () => {
    this.setState({ show: !this.state.show });
  };

  validateField = (field) => {
    if (
      this.state.form[field] === undefined ||
      this.state.form[field].length === 0
    ) {
      return true;
    }
    return false;
  };

  shouldMarkError = (field) => {
    const hasError = this.validateField(field);
    const shouldShow = this.state.touched[field];
    return hasError ? shouldShow : false;
  };

  validateEmail = () => {
    if (this.state.touched.email) {
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return reg.test(this.state.form.email) ? false : true;
    }
    return false;
  };

  render() {
    return (
      <div className="Login">
        <form className="form-signin" onSubmit={this.props.handleSubmit}>
          <h1 className="h3 mb-3 text-center">
            <Translate value="wellcome" />
          </h1>

          <div className="form-group">
            <input
              onChange={this.handleChange}
              className={
                this.shouldMarkError("email") || this.validateEmail()
                  ? "form-control  is-invalid"
                  : "form-control"
              }
              type="email"
              name="email"
              placeholder={"Email"}
              onBlur={this.handleBlur("email")}
              required
              autoComplete={"off"}
              //autoFocus
              value={this.state.form.email || ""}
            />
          </div>
          <div className="form-group">
            <input
              onChange={this.handleChange}
              className={
                this.shouldMarkError("password")
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              type={this.state.show ? "text" : "password"}
              name="password"
              placeholder={I18n.t("password")}
              onBlur={this.handleBlur("password")}
              required
              autoComplete={"off"}
              value={this.state.form.password || ""}
            />
            {this.state.form.password.length > 0 ? (
              <span
                style={{ position: "relative", right: "-290px", top: "-32px" }}
                onClick={this.changePassword}
              >
                {this.state.show ? <FaEyeSlash /> : <FaEye />}
              </span>
            ) : (
              <span></span>
            )}
          </div>

          <button onClick={this.handleSubmit} className="btn btn-primary">
            <Translate value="login" />
          </button>
          <div className="text-center mt-3  mb-3">
            <Link className="" to="/password_forget">
              <Translate value="password_forget" />
            </Link>
          </div>
          <div className="text-center mb-3">
            <Translate value="password_forget_register" /> {"  "}
            <Link className="" to="/register">
              <Translate value="register" />
            </Link>
          </div>
          {this.props.loading && <MiniLoader />}
          <FacebookLogin
            appId={API_KEY_FACEBOOK}
            fields="name,email,picture"
            autoLoad={false}
            size="small"
            textButton="Facebook"
            icon="fa-facebook"
            callback={(response) => {
              this.responseFacebook(response);
            }}
          />
          <br />
          <br />
          <div className="text-center">
            <Translate value="register_tos" /><br />
            <Link className="" to="/home/terms_condition" target="_blank">
              {<Translate value="footer_tos" />}
            </Link>{" "}
            <Translate value="and" />{" "} <br />
            <Link className="" to="/home/privacy_politics" target="_blank">
              {<Translate value="footer_privacy" />}
            </Link>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    //lang: reducer.i18n.locale,
    user: reducer.session.auth,
    error: reducer.session.error,

    // hasCountry: reducer.session.auth.hasCountry,
    loading: reducer.session.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoginSocialRequest: (params) =>
    dispatch(actions.loginSocialRequest({ params })),

  LoginRequest: (data) => dispatch(actions.loginRequest(data)),
});

export default
  connect(mapStateToProps, mapDispatchToProps)(LoginForm);
//withRouter();

/** <br />
          <GoogleLogin
            clientId={API_KEY_GOOGLE}
            buttonText="LOGIN WITH GOOGLE"
            onSuccess={(response) => {
              this.responseGoogle(response);
            }}
            onFailure={(response) => {
              this.responseGoogleFailure(response);
            }}
          />
           */