import React, { Component } from "react";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

import "./styles/Home.css";

import * as actions from "../redux/actions/adListActions";
import * as actions_sesion from "../redux/actions/sessionActions";
import * as actions_event from "../redux/actions/eventActions";
import * as actions_contact from "../redux/actions/contactsActions";

import SummaryByCountry from "../components/SummaryByCountry";
import Footer from "../components/Footer";
import MiniLoader from "../components/MiniLoader";
import PageLoading from "../components/PageLoading";
import AdList from "../components/AdList";
import AdMenu from "../components/AdMenu";
import PublishVertical from "../components/PublishVertical";
import ModalHome from "../components/ModalHome";

class HomeIn extends Component {
  state = {
    open_modal: false,
  };


  componentDidMount() {
    if (!this.props.hasCountry) this.props.history.push("/country");
    this.props.UserOnline(this.props.auth);
    //if (this.props.pageCount === 0)
    this.fetchAdList();
    if (this.props.popup)
      this.setState({ open_modal: false }, () =>
        setTimeout(() => this.setState({ open_modal: true }), 5000)
      );
    this.props.SetPopup();
    if (this.props.pageContactCount === 0)
      this.fetchContactList();

  }

  fetchContactList = async () => {
    try {
      await this.props
        .LoadContacts(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
    this.props.LoadContactsOnline(this.props.user_id, this.props.token);
  };

  onClose = () => {
    this.setState({ open_modal: false });
  };

  onClickLoadingMore = () => {
    this.fetchMoreAdList();
  }

  fetchAdList = async () => {
    try {
      await this.props.LoadAdHomeIn(this.props.user_id, this.props.token);
    } catch (error) {
      console.log(error);
    }
  };

  fetchMoreAdList = async () => {
    try {
      await this.props.LoadMoreAdHomeIn(this.props.pageCount, this.props.user_id, this.props.token);
    } catch (error) {
      console.log(error);
    }
  };

  /* 
  <AdFilter />
  */
  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="Home-in">
          <div className="col-lg-3 d-none d-lg-block ">
            <div className="Home-left">
              <AdMenu />
              <SummaryByCountry />
              <PublishVertical />
            </div>
          </div>
          <div className="col-lg-9 ">
            {
              this.props.loading ?
                (
                  <PageLoading />
                ) :
                (
                  <React.Fragment>
                    <AdList adList={this.props.adList} />
                    {
                      this.props.page_size >= 10 && (
                        this.props.loadingMore ?
                          (
                            <div className="text-center">
                              <MiniLoader />
                            </div>
                          )
                          :
                          (
                            <div className="text-center" onClick={this.onClickLoadingMore}>
                              <button className="btn btn-secondary" >
                                <Translate value="load_more" />
                              </button>
                            </div>
                          )
                      )
                    }
                  </React.Fragment>
                )
            }
          </div>
        </div>

        <ModalHome
          isOpen={this.state.open_modal}
          onClose={() => {
            this.onClose();
          }}
          image={this.props.popup_img}
        />
        <Footer />
      </React.Fragment>
    );
  }
}
/***
 * {!this.state.loading && (
              <div className="text-center">
                <button
                  onClick={() => this.fetchAdList()}
                  className="btn btn-secondary"
                >
                  <Translate value="load_more" />
                </button>
              </div>
            )}
 */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    auth: reducer.session.auth,
    popup: reducer.session.auth.popup,

    popup_img: reducer.session.auth.popup_img,
    hasCountry: reducer.session.auth.hasCountry,
    ////////////////////////////
    adList: reducer.adList.list,
    error: reducer.adList.error,
    loading: reducer.adList.loading,
    ////////////////////////////
    loadingMore: reducer.adList.loadingMore,
    page_index: reducer.adList.page_index,
    page_size: reducer.adList.page_size,
    pageCount: reducer.adList.page_count,
    ///////////////////////////////
    pageContactCount: reducer.contacts.page_count
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadAdHomeIn: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
  LoadMoreAdHomeIn: (page_index, user_id, token) =>
    dispatch(actions.loadListScroll({ page_index, user_id, token })),
  /////////////////////////////////
  SetPopup: () => dispatch(actions_sesion.setPopup({})),
  /////////////////////////////////
  UserOnline: (data) => dispatch(actions_event.userOnline(data)),
  LoadContactsOnline: (user_id, token) =>
    dispatch(actions_event.loadContactsOnline({ user_id, token })),
  ////////////////////////////
  LoadContacts: (user_id, token) =>
    dispatch(actions_contact.loadList({ user_id, token })),


});

export default connect(mapStateToProps, mapDispatchToProps)(HomeIn);
