import React from "react";
import "./styles/Legal.css";
//import api from "../api";
import Footer from "../components/Footer";
import { connect } from "react-redux";
import * as actions from "../redux/actions/tosActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import PageLoading from "../components/PageLoading";

function ListItem(props) {
  return <div dangerouslySetInnerHTML={{ __html: props.item.text }} />;
}

class TermOfCondition extends React.Component {
  componentDidMount() {
    this.fetchPripol();
  }


  fetchPripol = async () => {
    await this.props.loadListTos().then(() => {
      if (this.props.error) {
        alertify.warning(this.props.error);
      }
    });
  }

  render() {
    return (
      this.props.loading ?
        (
          <PageLoading />
        ) :
        (
          <React.Fragment>
            <div className="container-fluid">
              <div className="container-legal">
                {this.props.list.map(item => {
                  return <ListItem item={item} key={item.key} />;
                })}
              </div>
            </div>
            <Footer />
          </React.Fragment>)
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    //  user_id: reducer.session.auth.id,
    //  token: reducer.session.auth.access_token,

    list: reducer.tos.list,
    error: reducer.tos.error,
    loading: reducer.tos.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  loadListTos: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermOfCondition);
