import React from "react";
import ReactDOM from "react-dom";
import "./styles/ModalZoom.css";

function ModalZoom(props) {
    if (!props.isOpen) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="modal">
            <div className="">
                <div className="">
                    <button
                        type="button"
                        onClick={props.onClose}
                        className="modal_close_zoom-button"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">×</span>
                    </button>
                    <div className="img-center">
                        <img
                            className="modal-img"
                            src={props.src}
                            alt="img"
                            onClick={props.onClose}
                        />
                    </div>
                </div>
            </div>
        </div>,
        document.getElementById("modal")
    );
}

export default ModalZoom;