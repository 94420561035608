var options = { day: '2-digit', month: 'long', year: 'numeric', };//toUTCString
function formatDate(props) {
    // console.log(new Date(props.createdAt).toLocaleString('en-US', options), 'funcion ');
    return new Date(props.created_at).toLocaleString('en-US', options);

}

export default formatDate;

/*
Options key examples:

weekday: 'long',
day:
    The representation of the day.
    Possible values are "numeric", "2-digit".
    weekday:
    The representation of the weekday.
    Possible values are "narrow", "short", "long".
    year:
    The representation of the year.
    Possible values are "numeric", "2-digit".
    month:
    The representation of the month.
    Possible values are "numeric", "2-digit", "narrow", "short", "long".
    hour:
    The representation of the hour.
    Possible values are "numeric", "2-digit".
    minute: The representation of the minute.
    Possible values are "numeric", "2-digit".
    second:
    The representation of the second.
    Possible values are "numeric", 2 - digit".
*/