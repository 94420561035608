import React, { Component } from "react";
import ReactDOM from "react-dom";
import { PayPalButton } from "react-paypal-button-v2";
//import './styles/Home.css';

class PayBankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  componentWillUnmount() { }

  /*render() {
    return (
      <React.Fragment>
        <div className="card " style={{ paddingTop: "15px" }}>
          Pagar por PayPal muy pronto.
        </div>
        <div className="card " style={{ paddingTop: "15px" }}>
          Si deseas pagar vía transferencia ( solo Estados Unidos) a la cuenta
          de la empresa debes enviar un email a team@godiway.com para más
          información.
        </div>
      </React.Fragment>
    );
  }*/
  render() {
    return (
      <PayPalButton
        amount="0.01"
        // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={(details, data) => {
          alert("Transaction completed by " + details.payer.name.given_name);

          // OPTIONAL: Call your server to save the transaction
          return fetch("/paypal-transaction-complete", {
            method: "post",
            body: JSON.stringify({
              orderID: data.orderID
            })
          });
        }}
      />
    );
  }
}

export default PayBankAccount;
//scriptLoader(
//  "https://www.paypal.com/sdk/js?client-id=Adchgwaqx84H1AJdnCDWIkHVHiOjA5FEgekQNEyY16BWL1oIvG2LNIn7hcwLWpea7FtryEvC9h7IUo2p&currency=USD"
//   )
