import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  CONTACT_LIST_ATTEMPT,
  CONTACT_LIST_SUCCESS,
  CONTACT_LIST_FAILED,
  //////////////////
  CONTACT_MORELIST_ATTEMPT,
  CONTACT_MORELIST_SUCCESS,
  CONTACT_MORELIST_FAILED,
  ///////////////////
  ///////////////////
  SET_CONTACT,
  REMOVE_CONTACT,
  /////////////////////
  CLEAR_BADGET_LIST_CONTACTS,
} from "../types/contactsTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: CONTACT_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: CONTACT_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: CONTACT_LIST_FAILED,
    error: error
  };
}
///////////////////////////////
export function setContact(item) {
  return {
    type: SET_CONTACT,
    item: item
  }
};

export function removeContact() {
  return {
    type: REMOVE_CONTACT
  }
}
///////////////////////////////
export function loadingMore() {
  return {
    type: CONTACT_MORELIST_ATTEMPT
  };
}

export function successMore(data) {
  return {
    type: CONTACT_MORELIST_SUCCESS,
    data: data
  };
}

export function failedMore(error) {
  return {
    type: CONTACT_MORELIST_FAILED,
    error: error
  };
}
/////////////////////////////////
export function removeBadgetContact(data) {
  return {
    type: CLEAR_BADGET_LIST_CONTACTS,
    data: data
  }
}
/////////////////////////////////


////////////////////////////

export const updateContact = (data: Object) => async (dispatch) => {
  dispatch(setContact(data));
};

export const clearContact = () => async (dispatch) => {
  dispatch(removeContact());
};
export const clearBadgetContact = (data: Object) => async (dispatch) => {
  dispatch(removeBadgetContact(data.item));
};

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "contact", {
      params: {
        id: data.user_id,
        // token: data.token,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
      //timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "contact", {
      params: {
        index: data.page_count,
        id: data.user_id,
        // token: data.token,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};


export const loadItem = (data: Object) => async dispatch => {
  //dispatch(loading());
  await axios
    .get(url_api + "contact/id", {
      params: {
        contact_id: data.contact_id,
        id: data.user_id,
        //lang   :translate("lang")
      },
      headers: { authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateContact(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};