import React from "react";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/sessionActions";
import * as actions_suscription from "../redux/actions/suscriptionPlanActions";
import PaypalOnePayMobile from "../components/PaypalOnePayMobile";
import PaypalSuscriptionMobile from "../components/PaypalSuscriptionMobile";
//import ModalLoading from "../components/ModalLoading";
class PaypalMobile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //value:0
    };
  }

  componentDidMount() {
    var str = this.props.match.params.key;
    var res = str.split("*");
    // //9HsWbMn9p1zYFGiVZdeVSAT4cdeyArqtJ4D9NkomQ0PwWhPstSvY6Gl6klLO    2  *
    if (res) {
      this.props.LoginTokenRequest(res[1], res[2]).then(() => {
        if (JSON.stringify(this.props.auth) === "{}" && this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        } else {
          this.props.SetPlanValue(parseInt(res[0]));
        };
      });
    }
  }

  onClickSucess = () => {
    this.props.history.push('/mobile/paypal/success');
  }

  onClickWrong = () => {
    this.props.history.push('/mobile/paypal/wrong');
  }

  render() {
    var text1, text2, text3, text4, text5;
    switch (this.props.value) {
      case 100:
        text1 = <Translate value="suscription1" />;
        text2 = <Translate value="suscription2" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        text5 = "MarketStore";
        break;

      case 200:
        text1 = <Translate value="suscription1" />;
        text2 = <Translate value="suscription7" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        text5 = "MarketStore";
        break;

      case 10:
        text1 = <Translate value="suscription5" />;
        text2 = <Translate value="suscription2" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        text5 = "MarketStore";
        break;

      case 20:
        text1 = <Translate value="suscription5" />;
        text2 = <Translate value="suscription7" />;
        text3 = <Translate value="suscription3" />;
        text4 = <Translate value="suscription4" />;
        text5 = "MarketStore";
        break;

      case 15:
        text1 = <Translate value="one_pay" />;
        text2 = <Translate value="one_pay2" />;
        text3 = <Translate value="one_pay3" />;
        text4 = <Translate value="one_pay4" />;
        text5 = "";
        break;

      default:
        break;
    }

    return (
      <React.Fragment>

        <h3 className="text-center">
          <Translate value="kind_pay" />
        </h3>
        <div className="col">
          <h5 className="d-flex justify-content-between align-items-center">
            <span className="text-muted"><Translate value="you_cart" /></span>
            <span className="badge badge-secondary badge-pill">1</span>
          </h5>
          <ul className="list-group mb-3">
            <li className="list-group-item d-flex justify-content-between lh-condensed">
              <div>
                <h6 className="my-0">
                  {" "}
                  <Translate value="suscription_mine" />
                </h6>
                <small className="text-muted">
                  {text1}
                  <br />
                  {text2}
                  <br />
                  {text3}
                  <br />
                  {text4}
                  <br />
                  {text5}
                </small>
              </div>
              <span className="text-muted">${this.props.value}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (USD)</span>
              <strong>${this.props.value}</strong>
            </li>
          </ul>

          <hr className="" />

        </div>
        <div style={{ margin: 10 }}>
          {this.props.value === 15 ? <PaypalOnePayMobile /> : <PaypalSuscriptionMobile />}

        </div>


      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    lang: reducer.i18n.locale,

    error: reducer.session.error,

    auth: reducer.session.auth,

    value: reducer.suscriptionPlan.plan_value,
  };
}

const mapDispatchToProps = (dispatch) => ({
  SetPlanValue: (value) =>
    dispatch(actions_suscription.setPlanValue({ value })),
  //////////////////////
  LoginTokenRequest: (user_id, email) => dispatch(actions.loginTokenRequest({ user_id, email })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PaypalMobile);
/**  <button onClick={()=>{this.onClickSucess()}}>sucesss</button>
                <button onClick={()=>{this.onClickWrong()}}>cancel</button> 
        <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Codigo Promocional"
                  />
                  <div className="input-group-append">
                    <button type="submit" className="btn btn-secondary">
                      <Translate value="password_forget_send" />
                    </button>
                  </div>
                </div>           
                */