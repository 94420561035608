import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
//import './styles/Home.css';

import PublishVertical from "./PublishVertical";

class StoreLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <nav className="nav flex-column">
            <Link className="nav-link disabled" to="#">
              <small>
                <Translate value="ads" />{" "}
              </small>
            </Link>
            <Link to="/home" className="nav-link">
              <li>
                <Translate value="ads_group" />
              </li>
            </Link>
            <Link to="/wall" className="nav-link">
              {" "}
              <li>
                <Translate value="ads_recently" />
              </li>
            </Link>
          </nav>
        </div>
        <PublishVertical />
      </React.Fragment>
    );
  }
}

export default StoreLeft;
