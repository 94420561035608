import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate } from "react-redux-i18n";
//import './styles/Home.css';
//import api from '../api';

class PlanCallAction extends Component {


  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="card text-center">
          <div className="card-header">
            <h4 className="text-center">
              {" "}
              ¡<Translate value="suggestion" />!
            </h4>
          </div>
          <div className="card-body ">
            <div className="row">
              <Translate value="plan_suggestion" />
            </div>
            <h5 className="text-center">
              <Link to="/mysuscription">
                <Translate value="register_tip11" />
              </Link>
            </h5>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PlanCallAction;
