import React from "react";
import PanelLayout from "../components/PanelLayout";
import "./styles/MarketStore.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreActions";
import { FaSave } from "react-icons/fa";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class PanelEditMarketStore extends React.Component {
  state = {
    form: {
      name: "",
      text_color: "",
      description: "",
      backgroundColor: "",
      link_facebook: "",
      link_twitter: "",
      email: "",
      phone: "",
      home: "",
      address: "",
      city: "",
      state: "",
    },
  };



  componentDidMount() {
    this.setState({
      form: {
        name: this.props.marketstore.Name,
        text_color: this.props.marketstore.TextColor,
        description: this.props.marketstore.Description,
        backgroundColor: this.props.marketstore.BackGroundColor,
        link_facebook: this.props.marketstore.LinkFacebook,
        link_twitter: this.props.marketstore.LinkTwitter,
        email: this.props.marketstore.Email,
        phone: this.props.marketstore.Phone,
        address: this.props.marketstore.Address,
        home: this.props.marketstore.Home,
        address: this.props.marketstore.Address,
        city: this.props.marketstore.City,
        state: this.props.marketstore.State,
      },
    });
  }

  onChangeForm = (event) => {
    this.setState({
      form: {
        [event.target.name]: event.target.value,
      },
    });
  };

  onClickSaveStore = async (field_name, field_value) => {
    try {
      await this.props
        .EditMyMarketStore(
          this.props.token, {
          field_name: field_name,
          field_value: field_value,
          id: this.props.user_id,
        }
        )
        .then(() => {
          if (this.props.error_update) {
            alertify.warning(this.props.error_update);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
    } catch (error) {
      console.log(error);
    }
  };
  //
  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            <Translate value="ad_marketstore" />
          </h3>
          <div className="col-xl-12 ">
            <div className="col-12 card bg-light">
              <div className="row">
                <div className="col-md-12 text-center mt10">
                  <h2>
                    <Translate value="marketstore_edit" />
                  </h2>
                  <hr className="dividerGray" />
                  <br />
                </div>
              </div>
              <div className="row ">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <Translate value="marketstore_name" />
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.form.name}
                            name="name"
                            required={true}
                            onChange={this.onChangeForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 text-center">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore("name", this.state.form.name);
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                      <div className="col-md-10">
                        <Translate value="marketstore_title_textcolor" />
                        <input
                          name="text_color"
                          type="color"
                          value={this.state.form.text_color}
                          title="store.color_picker"
                          onChange={this.onChangeForm}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "text_color",
                              this.state.form.text_color
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          Slogan
                          <input
                            type="text"
                            className="form-control"
                            name="description"
                            value={this.state.form.description}
                            required=""
                            onChange={this.onChangeForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "description",
                              this.state.form.description
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group">
                          <Translate value="marketstore_decription_textcolor" />
                          <input
                            name="backgroundColor"
                            type="color"
                            className="form-control"
                            value={this.state.form.backgroundColor}
                            onChange={this.onChangeForm}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "backgroundColor",
                              this.state.form.backgroundColor
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <Translate value="marketstore_facebook_address" />
                          <div className="input-group">
                            <span
                              className="input-group-addon"
                              id="basic-addon1"
                            >
                              Http://
                            </span>
                            <input
                              className="form-control"
                              name="link_facebook"
                              type="text"
                              value={this.state.form.link_facebook}
                              onChange={this.onChangeForm}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "link_facebook",
                              this.state.form.link_facebook
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                      <div className="col-md-10">
                        <div className="form-group">
                          <Translate value="marketstore_twitter_address" />
                          <div className="input-group">
                            <span
                              className="input-group-addon"
                              id="basic-addon1"
                            >
                              Http://
                            </span>
                            <input
                              className="form-control"
                              name="link_twitter"
                              type="text"
                              value={this.state.form.link_twitter}
                              onChange={this.onChangeForm}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "link_twitter",
                              this.state.form.link_twitter
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <Translate value="marketstore_email" />
                        <input
                          type="text"
                          value={this.state.form.email}
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          onChange={this.onChangeForm}
                        />
                        <span className="help-block"></span>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "email",
                              this.state.form.email
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <Translate value="marketstore_phone" />
                        <input
                          type="text"
                          value={this.state.form.phone}
                          className="form-control"
                          placeholder="Teléfono"
                          name="phone"
                          onChange={this.onChangeForm}
                        />
                        <span className="help-block"></span>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "phone",
                              this.state.form.phone
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label htmlFor="direccion" className="control-label">
                            <Translate value="marketstore_about_us" />
                          </label>
                          <div className="input-group">
                            <textarea
                              name="home"
                              className="form-control  "
                              cols="10"
                              rows="5"
                              value={this.state.form.home}
                              onChange={this.onChangeForm}
                            >
                              { }
                            </textarea>
                          </div>
                          <span className="help-block"></span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore("home", this.state.form.home);
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label htmlFor="direccion" className="control-label">
                            <Translate value="marketstore_address" />
                          </label>
                          <div className="input-group">
                            <textarea
                              data-type="product_detail"
                              required=""
                              name="address"
                              className="form-control"
                              cols="10"
                              rows="5"
                              value={this.state.form.address}
                              onChange={this.onChangeForm}
                            ></textarea>
                          </div>
                          <span className="help-block"></span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "address",
                              this.state.form.address
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label htmlFor="direccion" className="control-label">
                            <Translate value="marketstore_city" />
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              placeholder={I18n.t("marketstore_city")}
                              value={this.state.form.city}
                              onChange={this.onChangeForm}
                            />
                          </div>
                          <span className="help-block"></span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore("city", this.state.form.city);
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-10">
                        <div className="form-group">
                          <label htmlFor="direccion" className="control-label">
                            <Translate value="marketstore_state" />
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              name="state"
                              placeholder={I18n.t("marketstore_state")}
                              className="form-control"
                              value={this.state.form.state}
                              onChange={this.onChangeForm}
                            />
                          </div>
                          <span className="help-block"></span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            this.onClickSaveStore(
                              "state",
                              this.state.form.state
                            );
                          }}
                        >
                          <FaSave />
                        </button>
                      </div>
                    </div>

                    <div className="form-group" align="center">
                      <Link className="btn btn-primary" to="/panel/marketstore/">
                        <i className="fa fa-sign-out " aria-hidden="true"></i>
                        <Translate value="back" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PanelLayout>
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    marketstore: reducer.marketstore.item,
    error_update: reducer.marketstore.error_update,
  };
}
const mapDispatchToProps = (dispatch) => ({
  EditMyMarketStore: (token, params) =>
    dispatch(
      actions.editMyMarketStore({ token, params })
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelEditMarketStore);

/**
 * MarketStoreFollowCount: 6
IsFollow: true
City: ""
State: "
 */
