import React from "react";

//import { connect } from "react-redux";
////import * as actions from "../redux/actions/sessionActions";
import { withRouter } from "react-router";
//import StoreAdList from "../components/StoreAdList";

class TempRedirectMySuscription extends React.Component {
  componentDidMount() {
    this.props.history.push("/mysuscription");
  }

  render() {
    return <></>;
  }
}

export default withRouter(TempRedirectMySuscription); //`/marketstore/show/${this.props.match.params.store_id}`
