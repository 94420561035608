import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
  SEARCH_LIST_ATTEMPT,
  SEARCH_LIST_SUCCESS,
  SEARCH_LIST_FAILED,
} from "../types/adSearchTypes";

import { config } from "../../config";

const url_api = config.api.godiway;

export function loading() {
  return {
    type: SEARCH_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: SEARCH_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: SEARCH_LIST_FAILED,
    error: error,
  };
}

/**
 *   export function successScroll(data){
    return{
    type:NOTIFICATION_LIST_SCROLL,
    data:data
    }
};
*/

export const loadListIn = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "search/in", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListOut = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "search/out", {
      params: {
        text: data.text,
        lang: I18n.t("locale"),
      },
      // headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
