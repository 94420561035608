import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { I18n } from "react-redux-i18n";


import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import ModalLoading from "../components/ModalLoading";
import RegisterTips from "../components/RegisterTips";
import RegisterForm from "../components/RegisterForm";
import * as actions from "../redux/actions/sessionActions";
//Translate,
class Register extends React.Component {
  state = {
    form: {},
    accept_tos: false,
    error: {
      email: false,
      password: false,
      password2: false,
      country_id: false,
    },
    loading: false,
  };

  validateEmail = (email) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return reg.test(email) ? false : true;
  };

  onChangeForm = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
      error: {
        ...this.state.error,
        [e.target.name]: false,
      },
    });
    if (e.target.name === "email") {
      this.setState({
        error: {
          ...this.state.error,
          email: this.validateEmail(e.target.value),
        },
      });
    }
  };

  onChangeAcceptTos = (e) => {
    this.setState({
      accept_tos: !this.state.accept_tos,
    });
  };

  onClickRegister = async (e) => {
    e.preventDefault();
    // console.log(this.state);
    //  console.log(this.state.form);
    if (!this.state.accept_tos) {
      alertify.alert(I18n.t("warning"), I18n.t("warning_tos"), function () { });
      return;
    }
    if (this.state.form.country_id === undefined || this.state.form.country_id === "") {
      /* alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_select_country"),
        function () {}
      );*/
      this.setState({
        error: {
          ...this.state.error,
          country_id: true,
        },
      });
      return;
    }

    if (
      this.state.form.email === undefined ||
      this.state.form.email === "" ||
      this.state.error.email
    ) {
      /*alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_email_valid"),
        function () {}
      );*/
      this.setState({
        error: {
          ...this.state.error,
          email: true,
        },
      });
      return;
    }
    if (
      this.state.form.password === undefined ||
      this.state.form.password === ""
    ) {
      /*
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_password"),
        function () {}
      );
      */
      this.setState({
        error: {
          ...this.state.error,
          password: true,
        },
      });
      return;
    }

    if (
      this.state.form.password2 === undefined ||
      this.state.form.password2 === ""
    ) {
      /*
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_password_confirm"),
        function () {}
      );*/
      this.setState({
        error: {
          ...this.state.error,
          password2: true,
        },
      });
      return;
    }

    if (this.state.form.password2 !== this.state.form.password) {
      /*alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_password_similar"),
        function () {}
      );*/
      this.setState({
        error: {
          ...this.state.error,
          password2: true,
          password: true,
        },
      });
      return;
    }

    try {
      await this.props
        .RegisterUser(
          this.state.form
        )
        .then(() => {
          if (this.props.error_update) {
            alertify.warning(this.props.error_update);
          } else this.props.history.replace("/home"); //this.props.history.push("/register_successfull");
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6  d-lg-block  d-none ">
              <RegisterTips />
            </div>
            <div className="col-md-6 col-xs-12 col-sm-12 ">
              <RegisterForm
                onChange={this.onChangeForm}
                form={this.state.form}
                error={this.state.error}
                accept_tos={this.state.accept_tos}
                onChangeAcceptTos={this.onChangeAcceptTos}
                onClickRegister={(e) => {
                  this.onClickRegister(e);
                }}
                history={this.props.history}
              />
            </div>
          </div>
        </div>
        <ModalLoading loading={this.props.loading} />
      </React.Fragment>
    );
  }
}
//
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    error_update: reducer.session.error,
    loading: reducer.session.loading,
    //success: reducer.user.success,
  };
}
const mapDispatchToProps = (dispatch) => ({
  RegisterUser: (params) =>
    dispatch(actions.newItem({ params })),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Register)
);
