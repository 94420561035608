import React from "react";

import WishList from "../components/WishList";
//import api from "../api";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";

import { connect } from "react-redux";
import * as actions_wish from "../redux/actions/wishActions";
import { Translate } from "react-redux-i18n";

class Wish extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    //if (this.props.wishList.length === 0) 
    this.fetchWishList();
  }

  fetchWishList = async () => {
    try {
      await this.props
        .LoadWish(this.props.token, { id: this.props.user_id, })
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            {" "}
            <Translate value="wish_title" />
          </h3>
          {this.props.loading ? (
            <PageLoading />
          ) : (
            <WishList wishList={this.props.wishList} />
          )}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    wishList: reducer.wish.list,
    error: reducer.wish.error,
    loading: reducer.wish.loading
  };
}
const mapDispatchToProps = dispatch => ({
  LoadWish: (token, params) => dispatch(actions_wish.loadList({ token, params }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Wish);
