import React from "react";
import AdDescription from "./AdDescription";
import AdFaq from "./AdFaq";
import AdComment from "./AdComment";
import Tabs from "./Tabs";
//import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
//Translate, Localize  <Localize value="2015-09-03" dateFormat="date.long"/>
// => returns '<span>3 september 2015</span> for locale 'nl'
//        <Localize value={10/3} options={{style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 2}}/>
//(${this.props.ad.FaqCount}) (${this.props.ad.CommentCount})`}
class AdTab extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Tabs>
          <div label={I18n.t("ad_description")} className="col-12">
            <AdDescription />
          </div>
          <div label={I18n.t("faq_questions")} className="col-12">
            <AdFaq />
          </div>
          <div label={I18n.t("comments")} className="col-12">
            <AdComment />
          </div>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default AdTab;
