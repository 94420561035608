import axios from "axios";

import { I18n } from "react-redux-i18n";
import {
  NOTIFICATION_LIST_ATTEMPT,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAILED,
  //////////////////////////
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_ALL_SUCCESS,
  NOTIFICATION_UPDATE_FAILED,
  /////////////////////////
  NOTIFICATION_LIST_ALL_ATTEMPT,
  NOTIFICATION_LIST_ALL_SUCCESS,
  NOTIFICATION_LIST_ALL_FAILED,
  /////////////////////////
  //NOTIFICATION_SEND,
  //NOTIFICATION_FAILED
} from "../types/notificationTypes";
import {
  UPDATE_BADGET_NAVBAR_SUCCESS,
} from '../types/eventTypes';

import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: NOTIFICATION_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: NOTIFICATION_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: NOTIFICATION_LIST_FAILED,
    error: error,
  };
}
/////////////////////////////////////
/**
     *   export function successScroll(data){
        return{
        type:NOTIFICATION_LIST_SCROLL,
        data:data
        }
    };
    */
export function successUpdate(item) {
  return {
    type: NOTIFICATION_UPDATE_SUCCESS,
    item: item,
  };
}
export function successUpdateAll(success) {
  return {
    type: NOTIFICATION_UPDATE_ALL_SUCCESS,
    success: success,
  };
}


export function failedUpdate(error) {
  return {
    type: NOTIFICATION_UPDATE_FAILED,
    error: error,
  };
}
/////////////////////////////////////
export function loadingAll() {
  return {
    type: NOTIFICATION_LIST_ALL_ATTEMPT,
  };
}

export function successAll(data) {
  return {
    type: NOTIFICATION_LIST_ALL_SUCCESS,
    data: data,
  };
}

export function failedAll(error) {
  return {
    type: NOTIFICATION_LIST_ALL_FAILED,
    error: error,
  };
}

export function successUpdateNavBar(item) {//Actualiza badget navbar
  return {
    type: UPDATE_BADGET_NAVBAR_SUCCESS,
    item: item
  }
};
//////////////////////////////
export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "notification/drowndown", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
      //  timeout: 5000,
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadNotificationsAll = (data: Object) => async (dispatch) => {
  dispatch(loadingAll());
  await axios
    .get(url_api + "notification", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale"),
      },
      headers: { Authorization: `Bearer ${data.token}` },
      // timeout: 5000,
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAll(response.data.body));
        else dispatch(failedAll(response.data.body));
      } else {
        dispatch(failedAll("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failedAll("try_later"));
      } else {
        dispatch(failedAll("try_later"));
      }
      return;
    });
};

export const setNotificationRead = (data: Object) => async (dispatch) => {
  //dispatch(setNotification(data.item));
  await axios
    .put(url_api + "notification/read", {
      notification_id: data.id,
      id: data.user_id,
      lang: I18n.t("locale"),
    },
      {
        headers: { Authorization: `Bearer ${data.token}` },
        // timeout: 5000,
      }
    )
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};

export const setNotificationReadAll = (data: Object) => async (dispatch) => {
  //dispatch(setNotification(data.item));
  await axios
    .put(url_api + "notification/readall", {
      id: data.user_id,
      lang: I18n.t("locale"),
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdateNavBar(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }
      return;
    });
};



export const sendNotification = (data: Object) => async (dispatch) => {
  /*await axios
    .post(url_api + "notification/send", {
      id: data.id,
      user_id: data.user_id,
      lang: I18n.t("locale"),
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
*/
  await axios({
    method: "post",
    url: url_api + "notification/send",
    data: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  })
    .then(function (response) {
      //console.log(response);
      /* if (response.status < 300) {
         if (response.data.status === "Success")
           dispatch(successUpdate(response.data.item));
         else dispatch(failedUpdate(response.data.description));
       } else {
         dispatch(failedUpdate("try_later"));
       }*/
    })
    .catch(function (error) {
      console.log(error);
      /*if (error.Error) {
        dispatch(failedUpdate("try_later"));
      } else {
        dispatch(failedUpdate("try_later"));
      }*/
      return;
    });
};