import React from "react";
import "./styles/DropDown.css";
import { Link } from "react-router-dom";
import { supportedLocales, fallbackLocale } from "../assets/lang/supportedLang";
import { setLocaleWithFallback } from "../redux/actions/i18n";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";

class DropDownLang extends React.Component {
  state = {
    displayMenu: false
  };
  // constructor() {
  //   // super();
  //   // // this.showDropdownMenu = this.showDropdownMenu.bind(this);
  //   // //this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  //   // this.changeDropdownMenu = this.changeDropdownMenu.bind(this);
  //   // this.lostBlur = this.lostBlur.bind(this);
  // }

  // showDropdownMenu(event) {
  //   event.preventDefault();

  //   this.setState({ displayMenu: true});
  //   //, () => {
  //   // document.addEventListener("click", this.hideDropdownMenu);
  //   //}
  // }

  // hideDropdownMenu() {
  //   this.setState({ displayMenu: false });
  //   //, () => {
  //   //document.removeEventListener("click", this.hideDropdownMenu);
  //   //}
  // }

  changeDropdownMenu(event) {
    event.preventDefault();
    clearInterval(this.timer);
    this.setState({ displayMenu: !this.state.displayMenu });
  }

  handleLanguageLinkClick = (e, code) => {
    e.preventDefault();
    this.props.setLocaleWithFallback(code);
    this.setState({ displayMenu: false });
  };

  lostBlur = (event) => {
    // event.preventDefault();
    // this.setState({ displayMenu: false });
    this.timer = setInterval(() => {
      this.setState({ displayMenu: false });
    }, 200);

    //
  }


  // focusOut = { this.lostBlur } focusIn = { this.lostBlur }
  render() {
    return (
      <div className="dropdown">
        <div className="button" tabIndex={0} onClick={this.changeDropdownMenu.bind(this)} onBlur={this.lostBlur}  >
          {" "}
          <Translate value="lang" />{" "}
        </div>

        {this.state.displayMenu ? (
          <ul>
            {Object.keys(supportedLocales).map(code => {
              return (
                <Link
                  className="nav-link"
                  to="#"
                  key={code}
                  active={code === this.props.locale ? "true" : "false"}
                  onClick={e => this.handleLanguageLinkClick(e, code)}
                >
                  <li className="nav-item">{supportedLocales[code]}</li>
                </Link>
              );
            })}
          </ul>
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = state => ({ locale: state.i18n.locale });

const mapDispatchToProps = { setLocaleWithFallback };

export default connect(mapStateToProps, mapDispatchToProps)(DropDownLang);

/** 
 *  {this.props.list_items.map(item => {
 * <Link
                 
                  to={item.route}
                  key={item.id}
                  onClick={item.onClick}
                >
                  {" "}
                  <li className="nav-item">{item.value}</li>
                </Link> */
