import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MiniLoader from "./MiniLoader";
import * as actions from "../redux/actions/adBidderActions";
import "./styles/AdBidder.css";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import ModalGodiwayer from "./ModalGodiwayer";

class AdBiderItem extends Component {
  state = {
    open_modal_profile: false,
  };

  _getName(name) {
    return name
      .split(' ')
      .map(item => item.charAt(0))
      .slice(0, 2)
      .join('').toUpperCase();
  }

  onClickViewProfile = (item) => {
    this.setState({ open_modal_profile: true });
  };

  onCloseModalProfile = () => {
    this.setState({ open_modal_profile: false });
  };

  render() {
    return (////this.props.biitemder.User.Avatar   <span>{this._getName(this.props.item.User.Name)}</span>
      <div className="bidder">
        <div className="avatar-head">
          <img
            src={this.props.item.User.Avatar}
            className="avatar"
            alt="Gw"
          />
          <div className="avatar-containt">
            {
              (this.props.ad.User.Id).toString() === this.props.user_id
                ?
                <Link className="avatar-name" to="#" onClick={() => { this.onClickViewProfile(this.props.item) }} >{this.props.item.User.Name} </Link>
                :
                <div className="avatar-name">{this.props.item.User.Name} </div>
            }
            <div className="avatar-date">{this.props.item.CreatedAt}</div>
          </div>
        </div>
        <div className="avatar-detail">
          <div className="avatar-text"><Translate value="ad_offered" /> </div>
          {this.props.item.Price}USD
        </div>


        <ModalGodiwayer
          isOpen={this.state.open_modal_profile}
          onClose={() => {
            this.onCloseModalProfile();
          }}
          item={this.props.item.User}
        />

      </div>

    );
  }
};

class AdBider extends Component {

  componentDidMount() {
    this.fetchaAdBidder();
  }

  fetchaAdBidder = async () => {
    try {
      await this.props
        .LoadAdBidder(this.props.ad.Id, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            console.log(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  _getName(name) {
    return name
      .split(' ')
      .map(item => item.charAt(0))
      .slice(0, 2)
      .join('').toUpperCase();
  }

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadAdBidderScroll(this.props.ad.Id, this.props.pageSize, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <h4 className="my-3 text-center">
          <Translate value="ad_bider" />
        </h4>
        <div className="col" style={{
          "backgroundColor": "white",
          "padding": "7px",
          "borderRadius": "12px"
        }}
        >
          {
            this.props.loading ?
              (
                <div className="col-12">
                  <div className="text-center">
                    <MiniLoader />
                  </div>
                </div>
              ) :
              (
                this.props.list.length === 0 ?
                  (
                    <div className="col-12 text-center">
                      <h3 className="my-3">
                        <Translate value="ad_notbider" />!
                      </h3>
                    </div>
                  ) :
                  (
                    this.props.list.map((item) => {
                      return (
                        <AdBiderItem
                          item={item}
                          key={item.Id}
                          ad={this.props.ad}
                          user_id={this.props.user_id}
                        />
                      );
                    }
                    )
                  )
              )
          }
          {

            this.props.pageSize >= 5 && (
              this.props.loadingMore ? (
                <div className="text-center ">
                  <MiniLoader />
                </div>
              ) :
                (
                  <div className="text-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        this.onClickLoadMore();
                      }}
                    >
                      <Translate value="load_more" />
                    </button>
                  </div>
                )
            )

          }
        </div>
        <hr className="dividerGray" />
      </React.Fragment>
    );
  }
}
/**
 * 
 *  reducer 
 *  (
                        
                      )
 */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    ad: reducer.ad.item,

    list: reducer.adBidder.list,
    loading: reducer.adBidder.loading,
    error: reducer.adBidder.error,

    pageSize: reducer.adBidder.page_size
  };
}
const mapDispatchToProps = dispatch => ({
  LoadAdBidder: (id, user_id, token) =>
    dispatch(actions.loadList({ id, user_id, token })),
  LoadAdBidderScroll: (id, page_index, user_id, token) =>
    dispatch(actions.loadListScroll({ id, page_index, user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdBider);

/**
 *     
 */