import React, { Component } from "react";
import { withRouter } from "react-router";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";

import "./styles/Wall.css";

import PageLoading from "../components/PageLoading";
import TipsGoodTransaction from "../components/TipsGoodTransaction";
import TransactionComments from "../components/TransactionComments";
import PublishVertical from "../components/PublishVertical";
import * as actions from "../redux/actions/transactionRejectedActions";


class CommentTransactionRejected extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchCommentList();
  }

  fetchCommentList = async () => {
    try {
      await this.props
        .LoadTransactionRejected(
          this.props.ad.UserStore.Id,
          this.props.user_id,
          this.props.token
        )
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-3 d-none d-lg-block">
              <div className="wall-left">
                <PublishVertical />
              </div>
            </div>
            <div className="col-6">
              <div className="wall-center">
                <h3 className="text-center">
                  {" "}
                  <Translate value="transaction_comment" />
                </h3>
                {this.state.loading ? (
                  <PageLoading />
                ) : (
                  <TransactionComments commentList={this.props.commentList} />
                )}
              </div>
            </div>
            <div className="col-3 d-none d-lg-block">
              <div className="wall-right">
                <TipsGoodTransaction />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,
    error: reducer.transactionRejected.error,
    loading: reducer.transactionRejected.loading,
    commentList: reducer.transactionRejected.list
  };
}
const mapDispatchToProps = dispatch => ({
  LoadTransactionRejected: (id, user_id, token) =>
    dispatch(actions.loadList({ id, user_id, token }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CommentTransactionRejected)
);
