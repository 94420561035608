import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaPhone, FaEnvelope, FaQuestion } from "react-icons/fa";
import "./styles/Footer.css";
import { Translate } from "react-redux-i18n";
import logo_blanco from "../assets/logo_blanco.png";
import { connect } from "react-redux";
class Footer extends React.Component {
  state = {
    isOpen: false,
  };
  onClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    //console.log(this.props.user);
    return (
      <footer className="footer">
        <div className="text-center text-Blue">
          <button
            onClick={() => {
              this.onClick();
            }}
            className="btn btn-link"
          >
            {" "}
            <Translate value="footer_more_information" />
          </button>
        </div>
        {this.state.isOpen && (
          <div className="container-fluid">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-sm-12 footer-navigation ">
                  <h3 className="">
                    <Link to="https://www.godiway.com">
                      <img src={logo_blanco} alt="Gw" />
                    </Link>
                  </h3>
                  <p className="links">
                    <Link to="/">
                      <Translate value="footer_home" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/home/privacy_politics">
                      <Translate value="footer_privacy" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/home/terms_condition">
                      <Translate value="footer_tos" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/register">
                      <Translate value="footer_register" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/login">
                      <Translate value="login" />
                    </Link>
                  </p>
                </div>

                <div className="col-md-4 col-sm-12 footer-contacts ">
                  <div className="row">
                    <FaPhone className="footer_icon" />
                    <p>
                      <Link className="footer_info" to="/contact_me">
                        <Translate value="footer_contacts" />
                      </Link>
                    </p>
                  </div>
                  <div className="row">
                    <FaEnvelope className="footer_icon" />
                    <p>
                      <a
                        className="footer_info"
                        href="mailto:team@godiway.com?subject=Godiwayer&body=Equipo%20GodiWay,"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Translate value="footer_email" />
                      </a>
                    </p>
                  </div>
                  <div className="row">
                    <FaQuestion className="footer_icon" />
                    <p>
                      <Link className="footer_info" to="/faq_me">
                        Faq
                      </Link>
                    </p>
                  </div>
                  <div className="social-links social-icons text-center">
                    <a
                      href="https://www.facebook.com/GodiWay-602264786813460"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaFacebook className="footer_icon" />
                    </a>

                    <a href="https://twitter.com/GodiWay" target="_blank" rel="noopener noreferrer">
                      <FaTwitter className="footer_icon"
                      />
                    </a>
                    <b>
                      <i className=""></i>
                    </b>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12 footer-navigation">
                  <p className="links">
                    <Link to="/what_is_donation">
                      {" "}
                      <Translate value="footer_what_donation" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/what_is_exchange">
                      {" "}
                      <Translate value="footer_what_exchange" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/why_donation_exchange">
                      <Translate value="footer_why" />
                    </Link>
                  </p>
                  <p className="links">
                    <Link to="/feedback">
                      {" "}
                      <Translate value="footer_inform" />
                    </Link>
                  </p>
                  {JSON.stringify(this.props.user) === "{}" && (
                    <p className="links">
                      <Link to="/pricing">
                        <Translate value="footer_sales" />
                      </Link>
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="footerBottom "></div>
          </div>
        )}
        <div className="col-12 ">
          <div className="footer_right">
            <Translate value="footer_copyright" />
          </div>
        </div>
      </footer>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user: reducer.session.auth,
  };
}

export default //withRouter(
  connect(mapStateToProps)(Footer);
