import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  MARKETSTORE_COMMENT_ATTEMPT,
  MARKETSTORE_COMMENT_SUCCESS,
  MARKETSTORE_COMMENT_FAILED,
  //////////////////////
  MARKETSTORE_ADD_COMMENT_SUCCESS,
  MARKETSTORE_ADD_COMMENT_FAILED,
  /////////////////////////

  MARKETSTORE_REMOVE_COMMENT_SUCCESS,
  MARKETSTORE_REMOVE_COMMENT_FAILED,
  ///////////////////////////
  MARKETSTORE_REPORT_COMMENT_SUCCESS,
  MARKETSTORE_REPORT_COMMENT_FAILED,
  ///////////////////////////
  MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS,
  MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED,
  //////////////////////////
  COMMENT_SET_ITEM,

} from "../types/marketstoreCommentTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: MARKETSTORE_COMMENT_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: MARKETSTORE_COMMENT_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: MARKETSTORE_COMMENT_FAILED,
    error: error,
  };
}
////////////////////////////////////////
export function successAddComment(item) {
  return {
    type: MARKETSTORE_ADD_COMMENT_SUCCESS,
    item: item,
  };
}

export function failedAddComment(error) {
  return {
    type: MARKETSTORE_ADD_COMMENT_FAILED,
    error: error,
  };
}
////////////////////////////////////////
export function successRemoveComment(item) {
  return {
    type: MARKETSTORE_REMOVE_COMMENT_SUCCESS,
    item: item,
  };
}

export function failedRemoveComment(error) {
  return {
    type: MARKETSTORE_REMOVE_COMMENT_FAILED,
    error: error,
  };
}
///////////////////////////////////////
export function successReportComment(item) {
  return {
    type: MARKETSTORE_REPORT_COMMENT_SUCCESS,
  };
}

export function failedReportComment(error) {
  return {
    type: MARKETSTORE_REPORT_COMMENT_FAILED,
    error: error,
  };
}
///////////////////////////////////////
export function updateCommentLikeSuccess(item) {
  return {
    type: MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS,
    item: item,
  };
}

export function updateCommentLikeFailed(error) {
  return {
    type: MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED,
    error: error,
  };
}
export function setItemComment(item) {
  return {
    type: COMMENT_SET_ITEM,
    item: item,
  };
}

export const setCommentItem = (data: Object) => async (dispatch) => {
  dispatch(setItemComment(data.item));
};
////////////////////////////////////////

export const loadMarketstoreComment = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "marketstore/comment", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const updateLike = (data: Object) => async (dispatch) => {
  //console.log(data);
  await axios
    .post(url_api + "comment/like", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateCommentLikeSuccess(response.data.body));
        else dispatch(updateCommentLikeFailed(response.data.body));
      } else {
        dispatch(updateCommentLikeFailed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(updateCommentLikeFailed("try_later"));
      } else {
        dispatch(updateCommentLikeFailed("try_later"));
      }
      return;
    });
};

export const removeLike = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment/like", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(updateCommentLikeSuccess(response.data.body));
        else dispatch(updateCommentLikeFailed(response.data.body));
      } else {
        dispatch(updateCommentLikeFailed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(updateCommentLikeFailed("try_later"));
      } else {
        dispatch(updateCommentLikeFailed("try_later"));
      }
      return;
    });
};

export const addComment2 = (data: Object) => async (dispatch) => {
  await axios
    .post(url_api + "comment/subcomment", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successAddComment(response.data.body));
        else dispatch(failedAddComment(response.data.body));
      } else {
        dispatch(failedAddComment("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedAddComment("try_later"));
      } else {
        dispatch(failedAddComment("try_later"));
      }
      return;
    });
};

export const addComment = (data: Object) => async (dispatch) => {
  try {
    //data.params.append("lang",translate("lang"));
    var request = new XMLHttpRequest();
    await request.open("POST", url_api + "comment/subcomment", true);
    request.setRequestHeader("Authorization", `Bearer ${data.token}`);
    request.onload = () => {
      if (request.status < 300) {
        const response = JSON.parse(request.response);
        //console.log("Image Loaded", data.item);
        dispatch(successAddComment(response.body));
      } else {
        //console.error(request.response);
        dispatch(failedAddComment("try_later"));
      }
    };
    await request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        //dispatch(loadingUpImage(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(failedAddComment("try_later"));
  }
};

export const removeComment = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successRemoveComment(response.data.body));
        else dispatch(failedRemoveComment(response.data.body));
      } else {
        dispatch(failedRemoveComment("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedRemoveComment("try_later"));
      } else {
        dispatch(failedRemoveComment("try_later"));
      }
      return;
    });
};

export const reportComment = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "comment/report", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successReportComment(response.data.body));
        else dispatch(failedReportComment(response.data.body));
      } else {
        dispatch(failedReportComment("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failedReportComment("try_later"));
      } else {
        dispatch(failedReportComment("try_later"));
      }
      return;
    });
};
