import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/MenuMobile.css";
import { Translate } from "react-redux-i18n";
export default class MenuMobile extends Component {
  render() {
    return (
      <nav className="menu-mobile">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/login" onClick={this.props.onClick}>
              <Translate value="login" />
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/register"
              onClick={this.props.onClick}
            >
              <Translate value="register" />
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/home" onClick={this.props.onClick}>
              <Translate value="Home" />
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}
