import {
  NOTIFICATION_LIST_ATTEMPT,
  NOTIFICATION_LIST_SUCCESS,
  NOTIFICATION_LIST_FAILED,
  /////////////////////////
  NOTIFICATION_LIST_ALL_ATTEMPT,
  NOTIFICATION_LIST_ALL_SUCCESS,
  NOTIFICATION_LIST_ALL_FAILED,
  ////////////////////////
  NOTIFICATION_UPDATE_SUCCESS,
  NOTIFICATION_UPDATE_FAILED,
  NOTIFICATION_UPDATE_ALL_SUCCESS,
  //////////////////
  RECEIVE_NOTIFICATION
} from "../types/notificationTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  listAll: [],
  error: "",
  ///////////////////////////
  page_count: 0,
  error_update: "",
  page_size: 0
};
function notification(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NOTIFICATION_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        page_size: action.data.size,
        page_count: state.page_count,
        list: action.data.list,
        error: "",
      };

    case NOTIFICATION_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    ///////////////////////////////////
    case RECEIVE_NOTIFICATION:
      return {
        ...state,
        list: [...state.list, action.data],
        page_count: state.page_count + 1
      };
    /////////////////////////////////
    case NOTIFICATION_UPDATE_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.Id !== action.item.Id;
      });
      return {
        ...state,
        list: newState,
        page_count: state.page_count - 1,
        error_update: "",
      };
    }

    case NOTIFICATION_UPDATE_ALL_SUCCESS: {
      return {
        ...state,
        list: [],
        error_update: "",
        page_count: 0,
        error: "",
      };
    }
    case NOTIFICATION_UPDATE_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    /////////////////////////////////////////////////////
    case NOTIFICATION_LIST_ALL_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case NOTIFICATION_LIST_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        listAll: action.data.list,
        error: "",
      };

    case NOTIFICATION_LIST_ALL_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        listAll: [],
      };
    default:
      return state;
  }
}
export default notification;
