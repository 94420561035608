import axios from "axios";

import {
  MEDIA_LIST_ATTEMPT,
  MEDIA_LIST_SUCCESS,
  MEDIA_LIST_FAILED,
  //////////////
  MEDIA_DELETE_SUCCESS,
  MEDIA_DELETE_FAILED,
  //////////////
  MEDIA_NEW_SUCCESS,
  MEDIA_NEW_LOADING,
  MEDIA_NEW_FAILED,
  //////////////
  MEDIA_RESET_LIST

} from "../types/mediaTypes";
import { config } from "../../config";

const url_api = config.api.godiway;

export function loading() {
  return {
    type: MEDIA_LIST_ATTEMPT,
  };
}

export function success(data) {
  return {
    type: MEDIA_LIST_SUCCESS,
    data: data,
  };
}

export function failed(error) {
  return {
    type: MEDIA_LIST_FAILED,
    error: error,
  };
}
///////////////////////////////////
export function successDelete(item) {
  return {
    type: MEDIA_DELETE_SUCCESS,
    item: item,
  };
}

export function failedDelete(error) {
  return {
    type: MEDIA_DELETE_FAILED,
    error: error,
  };
}
///////////////////////////////////
export function successNew(item) {
  return {
    type: MEDIA_NEW_SUCCESS,
    item: item,
  };
}
export function loadingNew(progress) {
  return {
    type: MEDIA_NEW_LOADING,
    progress: progress,
  };
}

export function failedNew(error) {
  return {
    type: MEDIA_NEW_FAILED,
    error: error,
  };
}
//////////////////////
export function clearMedia() {
  return {
    type: MEDIA_RESET_LIST
  };
}

export const clearMediaList = () => async (dispatch) => {
  dispatch(clearMedia());
}

export const loadList = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "media", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const deleteItem = (data: Object) => async (dispatch) => {
  await axios
    .put(url_api + "media", data.params, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successDelete(response.data.body));
        else dispatch(failedDelete(response.data.body));
      } else {
        dispatch(failedDelete("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedDelete("try_later"));
      } else {
        dispatch(failedDelete("try_later"));
      }
      return;
    });
};


export const newItem = (data: Object) => async (dispatch) => {
  try {
    dispatch(loadingNew(0));
    var request = new XMLHttpRequest();
    request.open("POST", url_api + "media", true);
    request.setRequestHeader("Authorization", `Bearer ${data.token}`);
    // request.setRequestHeader(
    //   'Content-Type', 'multipart/form-data'
    // );
    request.onload = () => {
      if (request.status < 300) {
        //request.response;
        const response = JSON.parse(request.response);
        if (response.status === "Success")
          dispatch(successNew(response.body));
        else dispatch(failedNew("try_later"));
      } else {
        console.error(request.response);
        dispatch(failedNew("try_later"));
      }
    };
    request.send(data.params);
    if (request.upload) {
      request.upload.onprogress = ({ total, loaded }) => {
        const uploadProgess = loaded / total;
        console.log(uploadProgess);
        dispatch(loadingNew(uploadProgess));
      };
    }
  } catch (error) {
    console.error(error);
    dispatch(failedNew("try_later"));
  }
};
