import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
  AD_REPORT_ATTEMPT,
  AD_REPORT_SUCCESS,
  AD_REPORT_FAILED,
  //////////////////
  ADD_REPORT_FAILED,
  ADD_REPORT_SUCCESS
} from "../types/adReportTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: AD_REPORT_ATTEMPT
  };
}

export function success(data) {
  return {
    type: AD_REPORT_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: AD_REPORT_FAILED,
    error: error
  };
}

export function successPost(success) {
  return {
    type: ADD_REPORT_SUCCESS,
    success: success
  };
}

export function failedPost(error) {
  return {
    type: ADD_REPORT_FAILED,
    error: error
  };
}

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "adreport", {
      params: {
        id: data.user_id,
        lang: I18n.t("locale")
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const newItem = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .post(url_api + "adreport", {
      message: data.message,
      reason_id: data.reason_id,
      goder_id: data.id,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successPost(response.data.body));
        else dispatch(failedPost(response.data.body));
      } else {
        dispatch(failedPost("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failedPost("try_later"));
      } else {
        dispatch(failedPost("try_later"));
      }
      return;
    });
};
