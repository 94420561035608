import React from "react";
import FaqListQuestion from "../components/FaqListQuestion";
import FaqListAnswer from "../components/FaqListAnswer";
import Tabs from "../components/Tabs";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import { connect } from "react-redux";
import * as actionsQuestion from "../redux/actions/faqQuestionActions";
import * as actionsAnswer from "../redux/actions/faqAnswerActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class Faq extends React.Component {
  /* 
    constructor(props) {
      super(props);
    }
  */

  componentDidMount() {
    this.fetchFaqList();
  }

  fetchFaqList = async () => {
    try {
      await this.props
        .LoadFaqQuestion(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });

      await this.props
        .LoadFaqAnswer(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            <Translate value="faq_title" />
          </h3>
          <Tabs>
            <div label={I18n.t("faq_question_did")} className="col-12">
              {this.props.loadingQuestion ? (
                <PageLoading />
              ) : (
                <FaqListQuestion faqList={this.props.faqListQuestion} />
              )}
            </div>
            <div label={I18n.t("faq_by_reply")} className="col-12">
              {this.props.loadingAnswer ? (
                <PageLoading />
              ) : (
                <FaqListAnswer faqList={this.props.faqListAnswer} />
              )}
            </div>
          </Tabs>
        </PanelLayout>
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    loadingQuestion: reducer.faqQuestion.loading,
    faqListQuestion: reducer.faqQuestion.list,
    errorQuestion: reducer.faqQuestion.error,

    loadingAnswer: reducer.faqAnswer.loading,
    faqListAnswer: reducer.faqAnswer.list,
    errorAnswer: reducer.faqAnswer.error,


    //lang: reducer.i18n.locale
  };
}
const mapDispatchToProps = dispatch => ({
  LoadFaqQuestion: (user_id, token) =>
    dispatch(actionsQuestion.loadList({ user_id, token })),
  LoadFaqAnswer: (user_id, token) =>
    dispatch(actionsAnswer.loadList({ user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
