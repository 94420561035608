import React from "react";
import "./styles/TransactionStart.css";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import * as actions from "../redux/actions/wishActions";
import { withRouter } from "react-router";
import Footer from "../components/Footer";
import ModalWhatSappQr from "../components/ModalWhatSappQr";
import {
  FaComments,
  //FaComment 
} from "react-icons/fa";
import { Translate } from "react-redux-i18n";

class TransactionInfo extends React.Component {
  state = {
    open_modal: false,
    number: "",
  };
  componentDidMount() { }

  seeModal = () => {
    const cell =
      this.props.ad.UserStore.CountryPhoneId +
      this.props.ad.UserStore.Phone.substring(
        this.props.ad.UserStore.Phone.length - 9,
        this.props.ad.UserStore.Phone.length
      );

    this.setState({ open_modal: true, number: cell });
  };

  onClose = () => {
    this.setState({ open_modal: false });
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-transaction-start">
            <section className="successfull">
              <div className="icon">
                <svg width="100%" height="100%">
                  <g
                    strokeWidth="5"
                    stroke="#3EA4EB"
                    fill="none"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      d="M24.7529297,41.4525637 34.2547154,50.9543494 55,30.2090647"
                      strokeDasharray="42.77581787109375"
                      strokeDashoffset="0"
                    ></path>
                  </g>
                </svg>
              </div>
              <span className="h1">
                <h1 className="initial">
                  <Translate value="transaction_congratulations" />
                </h1>
              </span>
              <span className="p">
                <h3 className="initial">
                  <Translate value="transaction_email_seller" />
                </h3>
              </span>
            </section>
            <section className="card medium">
              <div className="card-body row">
                <div className="col-6">
                  <div className="text-right">
                    <img
                      className="img-rounded"
                      src={this.props.ad.User.Avatar}
                      width="220px"
                      height="220px"
                      alt="GW"
                    />
                  </div>
                </div>
                <div className="col-6 text-left">
                  <div className="row-12">
                    <small>
                      <strong>Godiwayer:</strong>
                    </small>
                  </div>
                  <div className="row-12">
                    <small>{this.props.ad.User.Name}</small>
                  </div>
                  <div className="row-12">
                    <small>
                      <strong>
                        {" "}
                        <Translate value="profile_phone" />:
                      </strong>
                    </small>
                  </div>
                  <div className="row-12">
                    <small>
                      {"(+ "} {this.props.ad.Country.Lada}
                      {")"}
                      {this.props.ad.User.Phone}
                    </small>
                  </div>
                  <div className="row-12 ">
                    <small>
                      <strong>
                        {" "}
                        <Translate value="profile_email" />
                        {": "}
                      </strong>
                    </small>
                  </div>
                  <div className="row-12">
                    <small>
                      <a
                        href={`mailto:${this.props.ad.User.Email}?subject=Godiwayer&body=Equipo%20GodiWay,`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.props.ad.User.Email}
                      </a>
                    </small>
                  </div>

                  <div className="row-12 ">
                    <small>
                      <strong>
                        <Translate value="profile_city" />:
                      </strong>
                    </small>
                  </div>
                  <div className="row-12">
                    <small>{this.props.ad.User.City}</small>
                  </div>
                  {this.props.ad.User.Whatsapp === "on" && (
                    <div className="row-12">
                      <Link
                        to="#"
                        onClick={() => {
                          this.seeModal();
                        }}
                      >
                        <small>
                          <strong>
                            <Translate value="chat_whatsap" />
                          </strong>
                        </small>
                      </Link>
                    </div>
                  )}
                  <div className="row-12"></div>
                </div>
              </div>
              <div className="card-footer row">

                {this.props.seller !== undefined && JSON.stringify(this.props.seller) !== "{}" ? (
                  <React.Fragment>
                    <div className="col-6 text-right">
                      <Link className="btn btn-primary" to="/home">
                        <Translate value="ok" />
                      </Link>
                    </div>
                    <div className="col-6 text-left">
                      <Link
                        to={`/chat/${this.props.seller.ConversationId}`}
                        className="btn btn-secondary"
                      >
                        <FaComments />
                      </Link>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="col-12 text-center">
                    <Link className="btn btn-primary" to="/home">
                      <Translate value="ok" />
                    </Link>
                  </div>
                )
                }
              </div>
            </section>
          </div>
        </div>
        <ModalWhatSappQr
          isOpen={this.state.open_modal}
          onClose={() => {
            this.onClose();
          }}
          item={this.state.number}
        />
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    ad: reducer.ad.item,
    seller: reducer.transactionPending.seller,
  };
}
const mapDispatchToProps = (dispatch) => ({
  // UpdateWishAd: (id, user_id, token) =>
  //  dispatch(actions.updateWishAd({ id, user_id, token }))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionInfo)
);
