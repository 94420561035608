import React from "react";
//import ReactDOM from "react-dom";
import Rating from "./Rating";
import Modal from "./Modal";
import { connect } from "react-redux";
import * as actions from "../redux/actions/transactionRequestPendingActions";
import { Translate, I18n } from "react-redux-i18n";
import good from "../assets/good.png";
import bad from "../assets/bad.png";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

class ModalCalificationRequest extends React.Component {
  state = {
    description: "",
    rating: "'0'"
  };

  componentDidMount() {
    //   this.fetchaAdReport();
  }

  onChangeDescription = event => {
    this.setState({ description: event.target.value });
  };

  storeReport = async () => {
    try {
      await this.props
        .AddCalification(this.props.token, {
          message: this.state.description,
          rating: this.state.rating,
          status: this.props.isConfirmed ? "1" : "'0'",
          transaction_id: this.props.transaction.Id,
          id: this.props.user_id,
        }
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
      this.props.onClose();
    } catch (error) {
      console.log(error);
    }
  };
  onClickRating = val => {
    this.setState({ rating: val });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={<Translate value="calification_transaction" />}
      >
        {this.props.isConfirmed ? (
          <img src={good} className="img-responsive" alt="Gw" />
        ) : (
          <img src={bad} className="img-responsive" alt="Gw" />
        )}

        <Rating
          onClickRating={val => {
            this.onClickRating(val);
          }}
        />
        <div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-2">
          <div className="form-group col-md-12">
            <label>
              <Translate value="comments" />
            </label>
            <textarea
              className="form-control"
              name="comment"
              rows="3"
              cols="300"
              value={this.state.description}
              onChange={this.onChangeDescription}
            ></textarea>
          </div>
        </div>

        <div className="modal-footer text-center">
          <div className="col-sm-6 text-right">
            <button className="btn btn-secondary" onClick={this.props.onClose}>
              <i className="fa fa-close"></i>
              <Translate value="close" />
            </button>
          </div>
          <div className="col-sm-6 text-left">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.storeReport();
              }}
            >
              <Translate value="Enviar" />
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token
  };
}
const mapDispatchToProps = dispatch => ({
  AddCalification: (token, params) =>
    dispatch(
      actions.addCalification({ token, params })
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalCalificationRequest);
