import React from "react";
import "./styles/RegisterForm.css";
import { config } from "../config";
import Select from "./Select";
import { connect } from "react-redux";
import * as actions from "../redux/actions/countryActions";
import * as actions_session from "../redux/actions/sessionActions";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Translate, I18n } from "react-redux-i18n";
import { withRouter } from "react-router";
const API_KEY_FACEBOOK = config.api.facebook;
const API_KEY_GOOGLE = config.api.google;
class RegisterForm extends React.Component {
  state = {
    accept_tos: false,
    country: 0,
  };

  responseFacebook = async (response) => {
    if (response.status === "unknown") return;
    let params = new FormData();

    params.append("provider", "facebook");
    params.append("email", response.email);
    params.append("id", response.id);
    params.append("name", response.name);
    params.append("photo", response.picture.data.url);

    await this.props.LoginSocialRequest(params).then(() => {
      if (this.props.error_sesion) {
        alertify.warning(I18n.t(this.props.error_sesion));
      } else {
        this.props.history.push("/home");
      }
    });
  };

  responseGoogle = async (response) => {
    let params = new FormData();

    params.append("provider", "google");
    params.append("email", response.user.email);
    params.append("id", response.user.id);
    params.append("name", response.user.name);
    params.append("photo", response.user.photo);

    await this.props.LoginSocialRequest(params).then(() => {
      if (this.props.error_sesion) {
        alertify.warning(I18n.t(this.props.error_sesion));
      } else {
        this.props.history.push("/home");
      }
    });
  };

  responseGoogleFailure = async (response) => {
    alertify.warning(response.error);
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  onChangeTOS = () => {
    this.setState({
      accept_tos: !this.state.accept_tos,
    });
  };

  componentDidMount() {
    this.fetchCountry();
  }

  fetchCountry = async () => {
    try {
      await this.props.LoadCountry().then(() => {
        if (this.props.error_country) {
          alertify.warning(I18n.t(this.props.error_country));
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="register">
        <form className="form-signin" onSubmit={this.props.onClickRegister} autoComplete="new-password">
          <h1 className="h3 mb-3 text-center">
            <Translate value="register" />
          </h1>
          <div className="form-group">
            <input
              onChange={this.props.onChange}
              className={
                this.props.error.email
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="email"
              name="email"
              id="email"
              placeholder={I18n.t("email")}
              required
              autoFocus
              value={this.props.form.email}
              autoComplete={"new-password"}
            />
          </div>
          <div className="form-group">
            <input
              onChange={this.props.onChange}
              className={
                this.props.error.password
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="password"
              id="password"
              name="password"
              placeholder={I18n.t("password")}
              required
              value={this.props.form.password}
              autoComplete={"new-password"}
            />
          </div>
          <div className="form-group">
            <input
              onChange={this.props.onChange}
              className={
                this.props.error.password2
                  ? "form-control is-invalid"
                  : "form-control"
              }
              type="password"
              id="password"
              name="password2"
              placeholder={I18n.t("password2")}
              required
              value={this.props.form.password2}
              autoComplete={"new-password"}
            />
          </div>
          <div className="form-group">

            <select
              className={
                this.props.error.country
                  ? "form-control is-invalid"
                  : "form-control"
              }
              onChange={this.props.onChange}
              name="country_id"
              value={this.props.form.country_id}
            >

              <option key={"0"} value={""}>{I18n.t("choose_country")}</option>
              {
                this.props.countryList.map((item) => {
                  return (
                    <option key={item.prefix} value={item.prefix} >
                      {item.name}{" (+" + item.lada + ")"}
                    </option>
                  );
                })
              }
            </select>

          </div>

          <div className="form-group form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="accept_tos"
              value={this.props.accept_tos}
              onChange={this.props.onChangeAcceptTos}
            />
            <Translate value="register_tos" />{" "}
            <Link className="" to="/home/terms_condition" target="_blank">
              {<Translate value="footer_tos" />}
            </Link>{" "}
            <Translate value="and" />{" "}
            <Link className="" to="/home/privacy_politics" target="_blank">
              {<Translate value="footer_privacy" />}
            </Link>
          </div>

          <button
            onClick={this.props.onClickRegister}
            className="btn btn-primary mb-4"
          >
            <Translate value="sign_in" />
          </button>

          <FacebookLogin
            appId={API_KEY_FACEBOOK} //APP ID NOT CREATED YET
            fields="name,email,picture"
            autoLoad={false}
            size="small"
            textButton="Facebook"
            icon="fa-facebook"
            callback={(response) => {
              this.responseFacebook(response);
            }}
          />
          <br />

        </form>
      </div>
    );
  }
}
/**
 <Select value={this.props.form.country}
            default={I18n.t("choose_country")}
            onChange={this.props.onChange}
            options={this.props.countryList}
          />
 *<button
            onClick={this.onClickFacebook}
            className="btn btn-facebook mb-3"
          >
            Facebook
          </button>
           <button onClick={this.onClickGoogle} className="btn btn-danger">
            Google
          </button>

           <br />
          <GoogleLogin
            clientId={API_KEY_GOOGLE} //CLIENTID NOT CREATED YET
            buttonText="LOGIN WITH GOOGLE"
            onSuccess={(response) => {
              this.responseGoogle(response);
            }}
            onFailure={(response) => {
              this.responseGoogleFailure(response);
            }}
          />
 */
function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    countryList: reducer.country.list,
    error_country: reducer.country.error,
    loading: reducer.country.loading,
    error_update: reducer.user.error_update,
    lang: reducer.i18n.locale,
    user: reducer.session.auth,
    error_sesion: reducer.session.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCountry: () => dispatch(actions.loadList({})),
  //////////////////////////
  LoginSocialRequest: (params) =>
    dispatch(actions_session.loginSocialRequest({ params })),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
);
