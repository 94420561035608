import {
    BLOGCAROUSEL_LIST_ATTEMPT,
    BLOGCAROUSEL_LIST_SUCCESS,
    BLOGCAROUSEL_LIST_FAILED
} from "../types/blogCarouselTypes";

const INITIAL_STATE = {
    loading: false,
    list: [],
    error: ""
};

function blogCarousel(state = INITIAL_STATE, action) {
    switch (action.type) {
        case BLOGCAROUSEL_LIST_ATTEMPT:
            return {
                ...state,
                loading: true
            };

        case BLOGCAROUSEL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                error: ""
            };

        case BLOGCAROUSEL_LIST_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            };
        default:
            return state;
    }
}
export default blogCarousel;