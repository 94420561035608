import axios from "axios";

import {
  PRIVACY_ATTEMPT,
  PRIVACY_SUCCESS,
  PRIVACY_FAILED,
  //////////////////
  PRIVACY_UPDATE_SUCCESS,
  PRIVACY_UPDATE_FAILED
} from "../types/privacyTypes";
import { config } from "../../config";
const url_api = config.api.godiway;
export function loading() {
  return {
    type: PRIVACY_ATTEMPT
  };
}

export function success(data) {
  return {
    type: PRIVACY_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: PRIVACY_FAILED,
    error: error
  };
}
///////////////////////////////////
export function successUpdate(success) {
  return {
    type: PRIVACY_UPDATE_SUCCESS,
    success: success
  };
}

export function failedUpdate(error) {
  return {
    type: PRIVACY_UPDATE_FAILED,
    error: error
  };
}
export const loadItem = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "privacy", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const updateItem = (data: Object) => async dispatch => {
  //dispatch(Loading());
  await axios
    .post(url_api + "privacy", {
      key: data.field,
      value: data.value,
      id: data.user_id,
    }, {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successUpdate(response.data.body));
        else dispatch(failedUpdate(response.data.body));
      } else {
        dispatch(failedUpdate("try_later"));
      }
    })
    .catch(function (error) {
      console.log("Error", error.message);
      if (error.response) {
        dispatch(failedUpdate("try_later"));
      } else {
        // Something happened in setting up the request that triggered an Error
        dispatch(failedUpdate("try_later"));
      }
    });
};
