import React from "react";

import "./styles/Rating.css";

import { FaStar, FaRegStar } from "react-icons/fa";
//import appstore from "../assets/download_appstore.png";
//import playstore from "../assets/download_playstore.png";
class Rating extends React.Component {
  state = { rating: 0 };

  onClick = value => {
    this.setState({ rating: value });
  };

  render() {
    return (
      <div className="container-rating">
        <button
          className="btn btn-outline-light "
          onClick={() => {
            this.onClick(1);
            this.props.onClickRating(1);
          }}
        >
          {this.state.rating > 0 ? <FaStar /> : <FaRegStar />}
        </button>
        <button
          className="btn btn-outline-light "
          onClick={() => {
            this.onClick(2);
            this.props.onClickRating(2);
          }}
        >
          {this.state.rating > 1 ? <FaStar /> : <FaRegStar />}
        </button>
        <button
          className="btn btn-outline-light "
          onClick={() => {
            this.onClick(3);
            this.props.onClickRating(3);
          }}
        >
          {this.state.rating > 2 ? <FaStar /> : <FaRegStar />}
        </button>
        <button
          className="btn btn-outline-light "
          onClick={() => {
            this.onClick(4);
            this.props.onClickRating(4);
          }}
        >
          {this.state.rating > 3 ? <FaStar /> : <FaRegStar />}
        </button>
        <button
          className="btn btn-outline-light "
          onClick={() => {
            this.onClick(5);
            this.props.onClickRating(5);
          }}
        >
          {this.state.rating > 4 ? <FaStar /> : <FaRegStar />}
        </button>
      </div>
    );
  }
}

export default Rating;
