import React from "react";
import { Link } from "react-router-dom";
import { FaEdit, FaTrash, FaPause, FaEyeSlash, FaEye } from "react-icons/fa";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/AdPanelList.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions_ad from "../redux/actions/adActions";
import * as actions_ad_sales_active from "../redux/actions/adSalesActions";
import * as actions_ad_sales_inactive from "../redux/actions/adSalesInactiveActions";
import * as actions_ad_rents_active from "../redux/actions/adRentsActions";
import * as actions_ad_rents_inactive from "../redux/actions/adRentsInactiveActions";
import * as actions_ad_auction_active from "../redux/actions/adAuctionActions";
import * as actions_ad_auction_inactive from "../redux/actions/adAuctionInactiveActions";
import * as actions_ad_exchange from "../redux/actions/adExchangeActions";
import * as actions_ad_Donation from "../redux/actions/adDonationActions";

import MiniLoader from "./MiniLoader";


class AdListItem extends React.Component {
  render() {
    return (
      <div className="col-lg-4  mb-4">
        <div className="card  h-100 goder-shadow" style={{ padding: "0px" }}>
          <Link to={`/goder/show/${this.props.item.Id}`}>
            <img
              className="card-img-top goder-img-list-panel "
              src={this.props.item.Photo}
              alt="Image"
            />
          </Link>
          <div className="goder-body" style={{ padding: 0 }}>
            <div className="goder-title ">
              <Link to={`/goder/show/${this.props.item.Id}`}
                className="goder-link-list"
              >
                {this.props.item.Name}

              </Link>
            </div>

            <div className="container-btn-ad">
              <div className="btn-ad-left">
                <button
                  data-toggle="tooltip"
                  title={<Translate value="ad_edit" />}
                  onClick={this.props.onClickEdit}
                  className="btn btn-light btn-sm"
                >
                  <FaEdit />
                </button>
              </div>
              <div className="btn-ad-center">
                <button
                  data-toggle="tooltip"
                  title={<Translate value="ad_remove" />}
                  onClick={this.props.onClickTrash}
                  className="btn btn-light btn-sm"
                >
                  <FaTrash />
                </button>
              </div>
              <div className="btn-ad-rigth">
                <button
                  data-toggle="tooltip"
                  title={<Translate value="ad_pause" />}
                  onClick={this.props.onClickStatus}
                  className="btn btn-light btn-sm"
                >
                  <FaEyeSlash />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdPanelList extends React.Component {

  onClickEdit = (item) => {
    this.props.SetAd(item);
    var route = "";
    switch (item.KindCommerceId) {
      case 1:
        route = "donation";
        break;

      case 2:
        route = "sale";
        break;

      case 3:
        route = "exchage";
        break;

      case 4:
        route = "rent";
        break;

      case 5:
        route = "auction";
        break;

      default:
        route = "sale";
        break;
    }
    this.props.history.push("/panel/goder/edit/" + route);
  };

  onClickStatus = async (item) => {
    // var ads_active =
    //   this.props.summary.SalesActive + this.props.summary.RentsActive;
    // if (item.PostStatus === 1 && ads_active < this.props.summary.AdAvailable) {
    /*  if (item.PostStatus === 1 ){
        try {
          await this.props
            .AdPostStatus(item.Id, this.props.user_id, this.props.token)
            .then(() => {
              if (this.props.error) {
                alertify.warning(I18n.t(this.props.error));
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
  
      if (item.PostStatus === 2) {
        try {
          await this.props
            .AdPostStatus(item.Id, this.props.user_id, this.props.token)
            .then(() => {
              if (this.props.error) {
                alertify.warning(I18n.t(this.props.error));
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
      */
    switch (item.KindCommerceId) {
      case 2: {//sales
        if (item.PostStatus === 2) {
          try {
            await this.props
              .AdPostStatusSalesActive(item.Id, this.props.user_id, this.props.token)
              .then(() => {
                if (this.props.error) {
                  alertify.warning(I18n.t(this.props.error));
                }
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          try {
            await this.props
              .AdPostStatusSalesInactive(item.Id, this.props.user_id, this.props.token)
              .then(() => {
                if (this.props.error) {
                  alertify.warning(I18n.t(this.props.error));
                }
              });
          } catch (error) {
            console.log(error);
          }
        }

      } break;

      case 4:
        {
          if (item.PostStatus === 2) {
            try {
              await this.props
                .AdPostStatusRentsActive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              await this.props
                .AdPostStatusRentsInactive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }

        }
        break;


      case 5:
        {
          if (item.PostStatus === 2) {
            try {
              await this.props
                .AdPostStatusAuctionActive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              await this.props
                .AdPostStatusAuctionInactive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }

        }
        break;

      default:

        break;
    }
  };

  onClickTrash = async (item) => {
    switch (item.KindCommerceId) {
      case 1:
        {
          try {
            await this.props
              .AdDeleteDonation(item.Id, this.props.user_id, this.props.token)
              .then(() => {
                if (this.props.error) {
                  alertify.warning(I18n.t(this.props.error));
                }
              });
          } catch (error) {
            console.log(error);
          }
        }

        break;

      case 2:
        {
          if (item.PostStatus === 2) {
            try {
              await this.props
                .AdDeleteSalesActive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }
          else {
            try {
              await this.props
                .AdDeleteSalesInactive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }
        }

        break;

      case 3:
        {
          try {
            await this.props
              .AdDeleteExchange(item.Id, this.props.user_id, this.props.token)
              .then(() => {
                if (this.props.error) {
                  alertify.warning(I18n.t(this.props.error));
                }
              });
          } catch (error) {
            console.log(error);
          }
        }
        break;

      case 4:
        {
          if (item.PostStatus === 2) {
            try {
              await this.props
                .AdDeleteRentsActive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              await this.props
                .AdDeleteRentsInactive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }
        }
        break;

      case 5:
        {
          if (item.PostStatus === 2) {
            try {
              await this.props
                .AdDeleteAuctionActive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          } else {
            try {
              await this.props
                .AdDeleteAuctionInactive(item.Id, this.props.user_id, this.props.token)
                .then(() => {
                  if (this.props.error) {
                    alertify.warning(I18n.t(this.props.error));
                  }
                });
            } catch (error) {
              console.log(error);
            }
          }
        }
        break;

      default:
        break;
    }
  };

  render() {
    if (this.props.pageCount == 0)
      return (
        <h4>
          <Translate value="ad_not_list" />
        </h4>
      );

    return (
      <React.Fragment>
        <div className="row">
          {
            this.props.adPanelList.map((item) => {
              return (
                <AdListItem
                  item={item}
                  key={item.Id}
                  onClickEdit={() => {
                    this.onClickEdit(item);
                  }}
                  onClickStatus={() => {
                    this.onClickStatus(item);
                  }}
                  onClickTrash={() => {
                    this.onClickTrash(item);
                  }}
                />
              );
            })
          }
        </div>
        {
          this.props.pageSize > 5 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.props.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )
          )
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);
  return {

    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    summary: reducer.summaryByUser.item,
    ad: reducer.ad.item,

  };
}
const mapDispatchToProps = (dispatch) => ({
  SetAd: (item) => dispatch(actions_ad.setAd({ item })),
  ////////////////////////
  AdPostStatusSalesActive: (id, user_id, token) =>
    dispatch(actions_ad_sales_active.updateItem({ id, user_id, token })),
  AdDeleteSalesActive: (id, user_id, token) =>
    dispatch(actions_ad_sales_active.deleteItem({ id, user_id, token })),
  /////////////////////////
  AdPostStatusSalesInactive: (id, user_id, token) =>
    dispatch(actions_ad_sales_inactive.updateItem({ id, user_id, token })),
  AdDeleteSalesInactive: (id, user_id, token) =>
    dispatch(actions_ad_sales_inactive.deleteItem({ id, user_id, token })),
  /////////////////////////
  AdPostStatusRentsActive: (id, user_id, token) =>
    dispatch(actions_ad_rents_active.updateItem({ id, user_id, token })),
  AdDeleteRentsActive: (id, user_id, token) =>
    dispatch(actions_ad_rents_active.deleteItem({ id, user_id, token })),
  /////////////////////////
  AdPostStatusRentsInactive: (id, user_id, token) =>
    dispatch(actions_ad_rents_inactive.updateItem({ id, user_id, token })),
  AdDeleteRentsInactive: (id, user_id, token) =>
    dispatch(actions_ad_rents_inactive.deleteItem({ id, user_id, token })),
  ////////////////////////
  AdPostStatusAuctionActive: (id, user_id, token) =>
    dispatch(actions_ad_auction_active.updateItem({ id, user_id, token })),
  AdDeleteAuctionActive: (id, user_id, token) =>
    dispatch(actions_ad_auction_active.deleteItem({ id, user_id, token })),
  ////////////////////////
  AdPostStatusAuctionInactive: (id, user_id, token) =>
    dispatch(actions_ad_auction_inactive.updateItem({ id, user_id, token })),
  AdDeleteAuctionInactive: (id, user_id, token) =>
    dispatch(actions_ad_auction_inactive.deleteItem({ id, user_id, token })),
  ////////////////////////
  AdDeleteDonation: (id, user_id, token) =>
    dispatch(actions_ad_Donation.deleteItem({ id, user_id, token })),
  /////////
  AdDeleteExchange: (id, user_id, token) =>
    dispatch(actions_ad_exchange.deleteItem({ id, user_id, token })),
});


export default
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AdPanelList)
  );
