import React from "react";
import { Link } from "react-router-dom";
import "./styles/HomeSlider.css";
/*import slider01_es from "../assets/slider01_es.jpg";
import slider02_es from "../assets/slider02_es.jpg";
import slider03_es from "../assets/slider03_es.jpg";

import slider01_en from "../assets/slider01_en.jpg";
import slider02_en from "../assets/slider02_en.jpg";
import slider03_en from "../assets/slider03_en.jpg";
*/

import { connect } from "react-redux";

// const slider01_es = "https://www.godiway.com/themes/frontend/img/slider01_es.jpg";
// const slider02_es = "https://www.godiway.com/themes/frontend/img/slider02_es.jpg";
// const slider03_es = "https://www.godiway.com/themes/frontend/img/slider03_es.jpg";

// const slider01_en = "https://www.godiway.com/themes/frontend/img/slider01_en.jpg";
// const slider02_en = "https://www.godiway.com/themes/frontend/img/slider02_en.jpg";
// const slider03_en = "https://www.godiway.com/themes/frontend/img/slider03_en.jpg";

const slider01_es = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/donation_es.jpg";
const slider02_es = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/exchange_es.jpg";
const slider03_es = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/sales_es.jpg";

const slider01_en = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/donation_en.jpg";
const slider02_en = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/exchange_en.jpg";
const slider03_en = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/sales_en.jpg";

const slider01_he = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/donation_he.jpg";
const slider02_he = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/exchange_he.jpg";
const slider03_he = "https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/banner/sales_he.jpg";


//import Carousel from "../components/Carousel";

const images_es = [slider01_es, slider02_es, slider03_es];
const images_en = [slider01_en, slider02_en, slider03_en];
const images_he = [slider01_he, slider02_he, slider03_he];

class HomeSlider extends React.Component {
  state = {
    index: 0,
    timer: null,
    images: []
  };

  componentDidMount() {

    switch (this.props.lang) {
      case "es": {
        this.setState({
          images: images_es
        });
      } break;

      case "en": {
        this.setState({
          images: images_en
        });
      } break;

      case "he": {
        this.setState({
          images: images_he
        });
      } break;

      default: {
        this.setState({
          images: images_en
        });
      }
    }


    const timer = setInterval(() => {
      this.setState({
        index:
          this.state.index < this.state.images.length - 1
            ? this.state.index + 1
            : 0
      });
    }, 5000);
    this.setState({ timer: timer });
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  onClickPrev = () => {
    this.setState({
      index:
        this.state.index > 0
          ? this.state.index - 1
          : this.state.images.length - 1
    });
  };

  onClickNext = () => {
    this.setState({
      index:
        this.state.index < this.state.images.length - 1
          ? this.state.index + 1
          : 0
    });
  };

  render() {
    return (
      <div className="container-home-slider">
        <div id="" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            <li data-target="#" data-slide-to="0" className="active"></li>
            <li data-target="#" data-slide-to="1"></li>
            <li data-target="#" data-slide-to="2"></li>
          </ol>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={this.state.images[this.state.index]}
                className="d-block w-100"
                alt="..."
                style={{ minHeight: "200px" }}
              />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p></p>
              </div>
            </div>
          </div>
          <Link
            className="carousel-control-prev"
            to="#"
            role="button"
            data-slide="prev"
            onClick={() => {
              this.onClickPrev();
            }}
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </Link>
          <Link
            className="carousel-control-next"
            to="#"
            role="button"
            data-slide="next"
            onClick={() => {
              this.onClickNext();
            }}
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </Link>
        </div>
      </div>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(HomeSlider);

/**    <div className="carousel-item">
              <img src={slider02_es} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p></p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slider03_es} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p></p>
              </div>
            </div>   */
