import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
//import scriptLoader from "react-async-script-loader";
import ReactDOM from "react-dom";
import { PayPalButton } from "react-paypal-button-v2";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { connect } from "react-redux";
import { config } from "../config";
//import './styles/Home.css';
import { withRouter } from "react-router";

class PaypalOnePayMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showButton: false,
    };
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;
    const isLoadedButWasntLoadedBefore =
      !this.state.showButton && !this.props.isScriptLoaded && isScriptLoaded;
    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }
  componentWillUnmount() { }

  render() {
    return (
      <div className="text-center">
        <PayPalButton
          amount={this.props.value.toString()}
          currency="USD"
          shippingPreference={"NO_SHIPPING"}
          onSuccess={(details, data) => {
            this.props.history.push("/mobile/paypal/success");
            return fetch("/api/paypal/onepay-godiway", {
              method: "post",
              body: JSON.stringify({
                order_id: data.orderID,
                id: this.props.user_id,
                details: details,
              }),
              headers: { Authorization: `Bearer ${this.props.token}` },
            });
          }}
          options={{
            clientId: config.paypal.clientId,
            vault: true
          }}
          catchError={(err) => {
            alertify.warning(I18n.t("try_later"));
            this.props.history.push("/mobile/paypal/wrong");
          }}
          onError={(err) => {
            alertify.warning(I18n.t("try_later"));
            this.props.history.push("/mobile/paypal/wrong");
          }}
        />
      </div>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    value: reducer.suscriptionPlan.plan_value,
  };
}
export default withRouter(connect(mapStateToProps, null)(PaypalOnePayMobile));