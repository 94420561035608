import React from "react";
import TransactionRequestList from "../components/TransactionRequestList";
//import api from '../api';
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import Tabs from "../components/Tabs";
import { connect } from "react-redux";
import * as actions_pending from "../redux/actions/transactionRequestPendingActions";
import * as actions_finish from "../redux/actions/transactionRequestFinishActions";
import { Translate, I18n } from "react-redux-i18n";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class TransactionRequest extends React.Component {
  state = {
    data_pending: [],
    data_finish: [],
  };
  /*constructor(props) {
    super(props);
  }
*/

  componentDidMount() {
    this.fetchTransactionRequestList();
  }

  fetchTransactionRequestList = async () => {
    try {
      this.props
        .LoadTransactionRequest(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
      this.props
        .LoadTransactionFRequest(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.errorf) {
            alert(this.props.errorf);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadTransactionRequestMore(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  onClickLoadMoref = async () => {
    try {
      await this.props
        .LoadTransactionFRequestMore(this.props.pageCountf, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            <Translate value="transaction_request" />
          </h3>
          <Tabs>
            <div label="Pendientes" className="col-12">
              {this.props.loading ? (
                <PageLoading />
              ) : (
                <TransactionRequestList
                  transactionList={this.props.transactionList}
                  pageCount={this.props.pageCount}
                  pageSize={this.props.pageSize}
                  loadingMore={this.props.loadingMore}
                  onClickLoadMore={() => {
                    this.onClickLoadMore();
                  }}
                />
              )}
            </div>
            <div label="Finalizadas" className="col-12">
              {this.props.loadingf ? (
                <PageLoading />
              ) : (
                <TransactionRequestList
                  transactionList={this.props.transactionfList}
                  pageCount={this.props.pageCountf}
                  pageSize={this.props.pageSizef}
                  loadingMore={this.props.loadingMoref}
                  onClickLoadMore={() => {
                    this.onClickLoadMoref();
                  }}
                />
              )}
            </div>
          </Tabs>
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    transactionList: reducer.transactionRequestPending.list,
    error: reducer.transactionRequestPending.error,
    loading: reducer.transactionRequestPending.loading,

    pageCount: reducer.transactionRequestPending.page_count,
    pageSize: reducer.transactionRequestPending.page_size,
    loadingMore: reducer.transactionRequestPending.loadingMore,

    transactionfList: reducer.transactionRequestFinish.list,
    errorf: reducer.transactionRequestFinish.error,
    loadingf: reducer.transactionRequestFinish.loading,

    pageCountf: reducer.transactionRequestFinish.page_count,
    pageSizef: reducer.transactionRequestFinish.page_size,
    loadingMoref: reducer.transactionRequestFinish.loadingMore,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadTransactionRequest: (user_id, token) =>
    dispatch(actions_pending.loadList({ user_id, token })),
  LoadTransactionFRequest: (user_id, token) =>
    dispatch(actions_finish.loadList({ user_id, token })),
  LoadTransactionRequestMore: (page_count, user_id, token) =>
    dispatch(actions_pending.loadListScroll({ page_count, user_id, token })),
  LoadTransactionFRequestMore: (page_count, user_id, token) =>
    dispatch(actions_finish.loadListScroll({ page_count, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionRequest);
