import React from "react";
import { Link } from "react-router-dom";
import "./styles/AdList.css";
import MiniLoader from "../components/MiniLoader";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adActions";
import * as actions_store_ad from "../redux/actions/marketstoreAdListActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

function AdListItem(props) {

  return (
    <div className="col-lg-4  mb-4">
      <div className="card  h-100 goder-shadow" style={{ padding: "0px" }}>
        <Link to={`/goder/show/${props.item.Id}`} onClick={props.onClick}>
          <img
            className="card-img-top img-responsive"
            src={props.item.Photo}
            alt="Godiway_img"
          />
        </Link>
        <div className="goder-body">
          <div className="goder-title ">
            <Link
              to={`/goder/show/${props.item.Id}`}
              onClick={props.onClick}
              style={{
                textDecoration: "none",
                fontSize: "larger",
                fontWeight: "300",
                color: "#094683",
              }}
            >
              {props.item.Name}
            </Link>
          </div>

          <div className="goder-row">
            <div className="">
              <small>{props.kindCommerce}</small>
              <span>&nbsp;</span>
            </div>
            <div className="">
              <i><strong>
                {" "}
                {props.item.Price}&nbsp;
                <small>{props.item.Currency}</small>{" "}
              </strong>
              </i>
            </div>
          </div>
          <div className="goder-row">
            <div className="">
              <small>{props.item.Condition.Name}</small>
              <span>&nbsp;</span>
            </div>
            <div className="">
              <small>{props.item.CreatedAt}</small>
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

class StoreAdList extends React.Component {

  state = { marketstoreId: "" };
  onClick = (item) => {
    this.props.SetAd(item);
  };
  //componentDidUpdate
  //componentDidMount
  // componentWillReceiveProps
  //shouldComponentUpdate

  async componentDidMount() {
    await this.setState({ marketstoreId: this.props.marketstore.Id });
    this.fetchLoadAdList();
  }

  fetchLoadAdList = async () => {
    //console.log(this.state.marketstoreId);
    if (this.state.marketstoreId !== undefined)
      try {
        await this.props
          .LoadAdStore(
            this.state.marketstoreId,
            this.props.user_id,
            this.props.token
          )
          .then(() => {
            if (this.props.error) {
              alertify.warning(I18n.t(this.props.error));
            }
          });
      } catch (error) {
        console.log(error);
      }
  };

  componentWillUnmount() {
    this.props.ClearAdStore();
  }

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadAdStoreMore(this.props.pageCount, this.state.marketstoreId, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    if (!this.props.loading && this.props.pageCount === 0)
      return (
        <h3>
          {" "}
          <Translate value="ad_not_list" />
        </h3>
      );

    if (this.props.loading) return <MiniLoader />;

    return (
      <div className="row">
        {this.props.storeAdList.map((item) => {
          var KindCommerce = "";
          switch (item.KindCommerceId) {
            case 1:
              KindCommerce = <Translate value="donation" />;
              break;
            case 2:
              KindCommerce = <Translate value="sale" />;
              break;
            case 3:
              KindCommerce = <Translate value="exchange" />;
              break;
            case 4:
              KindCommerce = <Translate value="rent" />;
              break;
            default: break;
          }
          return (
            <AdListItem
              kindCommerce={KindCommerce}
              item={item}
              key={item.Id}
              onClick={() => {
                this.onClick(item);
              }}
            />
          );
        })}
        <div className="col-12">
          {
            this.props.pageSize > 5 && (
              this.props.loadingMore ? (
                <div className="text-center">
                  <MiniLoader />
                </div>
              ) :
                (
                  <div className="text-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        this.onClickLoadMore();
                      }}
                    >
                      <Translate value="load_more" />
                    </button>
                  </div>
                )
            )
          }
        </div>
      </div>
    );
  }
}

/**
 * 
 * @param {this.props.loading ? (
          <div className="col text-center">
          <MiniLoader />
        </div>
      ) : (} reducer 
 */
function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    marketstore: reducer.marketstore.item,
    marketstore_loading: reducer.marketstore.loading,

    storeAdList: reducer.marketstoreAdList.list,
    loading: reducer.marketstoreAdList.loading,
    error: reducer.marketstoreAdList.error,

    pageSize: reducer.marketstoreAdList.page_size,
    pageCount: reducer.marketstoreAdList.page_count,
    loadingMore: reducer.marketstoreAdList.loadingMore,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SetAd: (item) => dispatch(actions.setAd({ item })),
  LoadAdStore: (id, user_id, token) =>
    dispatch(actions_store_ad.loadList({ id, user_id, token })),
  LoadAdStoreMore: (page_count, id, user_id, token) =>
    dispatch(actions_store_ad.loadListScroll({ page_count, id, user_id, token })),
  ClearAdStore: () => dispatch(actions_store_ad.clearAdStore({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAdList);
