import React from "react";
import "./styles/Select.css";
import { Link } from "react-router-dom";

class Select extends React.Component {
    constructor() {
        super();

        this.state = {
            displaySelect: false
        };

        this.showSelect = this.showSelect.bind(this);
        this.hideSelect = this.hideSelect.bind(this);
    }

    showSelect(event) {
        event.preventDefault();
        this.setState({ displaySelect: true }, () => {
            document.addEventListener("click", this.hideSelect);
        });
    }

    hideSelect() {
        this.setState({ displaySelect: false }, () => {
            document.removeEventListener("click", this.hideSelect);
        });
    }

    render() {
        return (
            <div className="select  ">
                <div className="button" onClick={this.showSelect}>
                    {this.props.default}
                    <div className="widther" />
                    <div className="chevron-down" />
                </div>

                {
                    this.state.displaySelect ? (
                        <ul>
                            {this.props.options.map(item => {
                                return (

                                    <span className="" > {item.name}</span>

                                );
                            })}
                        </ul>
                    ) : null
                }
            </div>
        );
    }
}

export default Select;