import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/MenuMobile.css";
import { Translate } from "react-redux-i18n";

import { connect } from "react-redux";
class MenuMobileIn extends Component {
  render() {
    return (
      <nav className="menu-mobile">
        <ul className="navbar-nav">
          <li className="nav-item text-center">
            <Link
              className="nav-link"
              to="/profile/edit"//profile
              onClick={this.props.onClick}
            >{`@${this.props.auth.username}`}</Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/goder/menu"
              onClick={this.props.onClick}
            >
              <Translate value="ad" />
            </Link>
          </li>
          <small>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/wish"
                onClick={this.props.onClick}
              >
                <Translate value="wish_title" />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/faq" onClick={this.props.onClick}>
                <Translate value="faq_title" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/contacts"
                onClick={this.props.onClick}
              >
                <Translate value="contact_title" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/panel/sales"
                onClick={this.props.onClick}
              >
                <Translate value="sales" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/panel/rents"
                onClick={this.props.onClick}
              >
                <Translate value="rents" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/panel/exchanges"
                onClick={this.props.onClick}
              >
                <Translate value="exchanges" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/panel/donations"
                onClick={this.props.onClick}
              >
                <Translate value="donations" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/panel/marketstore"
                onClick={this.props.onClick}
              >
                <Translate value="marketstores_mine" />
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                to="/marketstores"
                onClick={this.props.onClick}
              >
                <Translate value="marketstores_title" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/transaction"
                onClick={this.props.onClick}
              >
                <Translate value="transaction_ad" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/transaction/request"
                onClick={this.props.onClick}
              >
                <Translate value="transaction_request" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/mysuscription"
                onClick={this.props.onClick}
              >
                <Translate value="suscription_mine" />
              </Link>
            </li>

            <li className="nav-item">
              <Link
                className="nav-link"
                to="/customer"
                onClick={this.props.onClick}
              >
                <Translate value="order_data" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/paylist"
                onClick={this.props.onClick}
              >
                <Translate value="pay_did" />
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/logout"
                onClick={this.props.onClick}
              >
                <Translate value="logout" />
              </Link>
            </li>
          </small>
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    auth: reducer.session.auth,
    wishCount: reducer.wish.page_count,
    lang: reducer.i18n.locale
  };
}

export default connect(mapStateToProps, null)(MenuMobileIn);

/**
 *   <li className="nav-item">
              <Link
                className="nav-link"
                to="/cardlist"
                onClick={this.props.onClick}
              >
                <Translate value="card_registered" />
              </Link>
            </li>
 */
