export const AD_ITEM_ATTEMPT = "AD_ITEM_ATTEMPT";
export const AD_ITEM_SUCCESS = "AD_ITEM_SUCCESS";
export const AD_ITEM_FAILED = "AD_ITEM_FAILED";

export const AD_UPDATE_WISH = "AD_UPDATE_WISH"; //Viene del Action de Wish

export const ADLIKE_UPDATE_SUCCESS = "ADLIKE_UPDATE_SUCCESS";
export const ADLIKE_UPDATE_FAILED = "ADLIKE_UPDATE_FAILED";

export const LOGOUT = "LOGOUT";



