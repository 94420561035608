import React from "react";
import { Link } from "react-router-dom";
import { FaCartPlus, FaComment, FaWhatsapp, FaPhone, FaMailBulk } from "react-icons/fa";//FaCartArrowDown, FaDollarSign, FaSlackHash, FaHashtag,
import { connect } from "react-redux";
import * as actions_wish from "../redux/actions/wishActions";
import * as actions_transaction from "../redux/actions/transactionRequestPendingActions";
import { withRouter } from "react-router";
import ModalReportAd from "./ModalReportAd";
import "./styles/AdDetailAction.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import { Translate, Localize, I18n } from "react-redux-i18n";
import {
  EmailShareButton,
  FacebookShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton
  /*
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,

  PocketShareButton,
  RedditShareButton,

  TumblrShareButton,

  ViberShareButton,
  VKShareButton,

  WorkplaceShareButton*/
} from "react-share";//npm install react-share --save
import {
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
  InstapaperIcon
  /*
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PocketIcon,
    RedditIcon,
    TumblrIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WorkplaceIcon*/
} from "react-share";
/**
 * <div className="col-md-8">
                        <div className="text-right">
                            <div className="fb-share-button fb_iframe_widget" data-to="https://www.godiway.com/productshow/51305d2776style6dce6d5b889343b5af9c82e" data-layout="button" data-size="small" data-mobile-iframe="false" fb-xfbml-state="rendered" fb-iframe-plugin-query="app_id=1796994160354148&amp;container_width=330&amp;to=https%3A%2F%2Fwww.godiway.com%2Fproductshow%2F51305d27766dce6d5b889343b5af9c82e&amp;layout=button&amp;locale=en_US&amp;mobile_iframe=false&amp;sdk=joey&amp;size=small">
                                <span >
                                    <iframe name="f30aed565b5556" title="fb:share_button Facebook Social Plugin" allowTransparency="true" allowfullscreen="true" scrolling="no" allow="encrypted-media"  src="https://www.facebook.com/v3.2/plugins/share_button.php?app_id=1796994160354148&amp;channel=https%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter.php%3Fversion%3D44%23cb%3Df2ae8e030054882%26domain%3Dwww.godiway.com%26origin%3Dhttps%253A%252F%252Fwww.godiway.com%252Ff6c80ef8b2bb18%26relation%3Dparent.parent&amp;container_width=330&amp;to=https%3A%2F%2Fwww.godiway.com%2Fproductshow%2F51305d27766dce6d5b889343b5af9c82e&amp;layout=button&amp;locale=en_US&amp;mobile_iframe=false&amp;sdk=joey&amp;size=small" className="" width="1000px" height="1000px" frameborder="0">
                                    </iframe>
                                </span>
                            </div>
                        </div>
                    </div>
 */

class AdDetailAction extends React.Component {
  state = {
    open_modal: false,
    quantity: 1,
  };

  componentDidMount() {
    // console.log(I18n.t("warning"));
    if (this.props.ad.KindCommerceId === 5)
      this.setState({
        quantity: this.props.ad.Price
      });
  }

  onOpenCloseModal = () => {
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }
    this.setState({ open_modal: !this.state.open_modal });
  };

  onClickBuy = async (event) => {
    event.preventDefault();
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }

    if (this.props.ad.KindCommerceId === 5 && this.props.ad.Price >= this.state.quantity) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_price"),
        function () { }
      );
      return;
    }
    let params = new FormData();
    params.append("goder_id", this.props.ad.Id);
    params.append("message", "");
    params.append("quantity", this.state.quantity);
    params.append("price", this.state.quantity);
    params.append("id", this.props.user_id);
    //params.append("token", this.props.token);

    await this.props
      .NewTransaction(this.props.token, params)
      .then(() => {
        if (this.props.error_transaction) {
          alertify.warning(I18n.t(this.props.error_transaction));
        }
      });

    if (this.props.ad.KindCommerceId === 5)
      this.props.history.push("/transaction/bid");
    else
      this.props.history.push("/transaction/info");
  };

  handleWish = async (event) => {
    //event.preventDefault();
    if (JSON.stringify(this.props.user) === "{}") {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_login"),
        function () { }
      );
      return;
    }

    await this.props
      .UpdateWishAd(this.props.token, {
        goder_id: this.props.ad.Id,
        id: this.props.user_id,
      })
      .then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        }
      });
  };

  onChangeQuantity = (event) => {
    this.setState({
      ...this.state,
      quantity: event.target.value
    });
  };

  onClickNumber = (event) => {
    alertify.success('(+' + this.props.ad.User.CountryPhoneId + ') ' + this.props.ad.User.Phone);
  };

  onClickStore = (event) => {
    event.preventDefault();
    var link_marketstore = "";
    if (JSON.stringify(this.props.user) !== "{}")
      link_marketstore = `/marketstore/show/${this.props.ad.Store.Id}`;
    else link_marketstore = "/login";
    this.props.history.replace(link_marketstore);
  };

  render() {
    var link_marketstore = "#";
    //  console.log(this.props.ad.MyInterest);
    if (JSON.stringify(this.props.user) !== "{}")
      link_marketstore = `/marketstore/show/${this.props.ad.Store.Id}`;
    else link_marketstore = "/login";
    var asq_ad = <Translate value="ad_buy" />,
      kindcommerce = "",
      condition = "";
    //this.props.ad.KindCommerceId = 5;
    switch (this.props.ad.KindCommerceId) {
      case 1:
        {
          asq_ad = <Translate value="ad_request_donation" />;
          kindcommerce = <Translate value="donation" />;
        }
        break;
      case 2:
        {
          if (this.props.ad.ConditionId === "SERVICE") {
            asq_ad = <Translate value="contact" />;
            kindcommerce = <Translate value="ad_service" />;
          } else {
            asq_ad = <Translate value="ad_buy" />;
            kindcommerce = <Translate value="sale" />;
          }
        }
        break;
      case 3:
        {
          asq_ad = <Translate value="ad_request_exchange" />;
          kindcommerce = <Translate value="exchange" />;
        }
        break;
      case 4:
        {
          asq_ad = <Translate value="ad_request_rent" />;
          kindcommerce = <Translate value="rent" />;
        }
        break;
      case 5:
        {
          asq_ad = <Translate value="ad_bid" />;
          kindcommerce = <Translate value="auction" />;
        }
        break;
      default: break;
    }
    if (this.props.ad.Condition.Id === "NEW")
      condition = <Translate value="ad_new" />;
    if (this.props.ad.Condition.Id === "USED")
      condition = <Translate value="ad_used" />;
    if (this.props.ad.Condition.Id === "SERVICE")
      condition = <Translate value="ad_service" />;

    /*  if (this.props.loading)
     return (
 
       <div className="background">
         <div className="refill">
           <div className="bar"></div>
           <div className="mask thick"></div>
           <div className="bar"></div>
           <div className="mask thin"></div>
           <div className="bar medium"></div>
           <div className="mask thick"></div>
           <div className="bar small"></div>
         </div>
       </div>
 
 
     )
 */
    // if (false)
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12 text-center">
            <h3 className="my-3">{this.props.ad.Name}</h3>
          </div>
          <div className="col-md-12 text-center">

            <button
              data-toggle="tooltip"
              title="Deseos/Wish"
              onClick={this.handleWish.bind(this)}
              className={this.props.ad.MyInterest ? "btn btn-danger" : "btn btn-light"}
            >
              <FaCartPlus />
            </button>

          </div>
        </div>

        <div className="text-center mt10">
          <label htmlFor="cantidad">
            {
              this.props.ad.KindCommerceId === 5 ?
                <Translate value="ad_you_bid" />
                : <Translate value="ad_you_need" />
            }
          </label>
        </div>

        <div className="col-md-12 form-group  ">
          <div className="row">
            <div className="col-md-4 text-center mt-15">
              <h3 className="italic">{kindcommerce}:</h3>
            </div>
            <div className="col-xs-12 col-md-4  mt10 text-center">
              <input
                className="form-control text-center"
                name="quantity"
                type="number"
                value={this.state.quantity}
                onChange={this.onChangeQuantity}
                max={this.props.ad.KindCommerceId === 5 ? "" : "100"}
                min={this.props.ad.KindCommerceId === 5 ? this.props.ad.Price : "1"}
                disabled={this.props.ad.KindCommerceId !== 2}
              />
            </div>
            <div className="col-md-4 col-xs-12 text-center goder-button">
              <button
                data-toggle="tooltip"
                title="Godiway"
                onClick={this.onClickBuy.bind(this)}
                className="btn btn-success"
              >
                <b>{asq_ad}</b>
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          {
            (this.props.ad.KindCommerceId === 2 || this.props.ad.KindCommerceId === 4) &&
            <h2 className="my-3">
              ${this.props.ad.Price}
              <small>{this.props.ad.Currency}</small>
            </h2>
          }

          {
            this.props.ad.KindCommerceId === 5 && (
              <h3 className="my-3">
                <Translate value="expiration_in" />{this.props.ad.Expiration}
              </h3>
            )
          }

        </div>
        <div className="col-12 row">
          <div className="col-4 text-center">
            <h6 className="">
              <Translate value="ad_more_detail" />{" "}
            </h6>
          </div>
          <div className="col-12 text-left">
            <ul>
              {this.props.ad.Quantity > 0 && (
                <li>
                  <span className="fa fa-bars"></span> Stock:
                  <b>{this.props.ad.Quantity}</b>
                </li>
              )}
              {JSON.stringify(this.props.user) !== "{}" ? (
                this.props.ad.State !== "" && (
                  <li>
                    <span className="fa fa-globe"></span>{" "}
                    <b>
                      {this.props.ad.State}
                      {" - "} {this.props.ad.City}
                    </b>
                  </li>
                )
              ) : (
                <li>
                  <span className="fa fa-globe"></span>{" "}
                  <b>{this.props.ad.Country.Name}</b>{" "}
                  {this.props.ad.Country.Flag !== undefined && (
                    <img
                      src={this.props.ad.Country.Flag}
                      className="profile-flag"
                      alt="Gw"
                    />
                  )}
                </li>
              )}
              { }

              <li>
                <span className="fa fa-lock"> </span>
                {" "} <b>{condition}</b>
              </li>
              {this.props.ad.Category && (
                <li>
                  <span className="fa fa-tags"></span>
                  <b>{this.props.ad.Category.name} </b>
                </li>
              )}
            </ul>
            {
              JSON.stringify(this.props.user) !== "{}" &&
              <React.Fragment>{
                this.props.ad.Store.Id &&
                <React.Fragment>
                  <div className="inline">
                    <img
                      src={this.props.ad.Store.Logo}
                      className="rounded-circle user_img "
                      alt="Gw"
                      style={{ height: "50px", width: "50px" }}
                    />
                    &nbsp;
                    <Link
                      to={link_marketstore}
                      style={{ textDecoration: "none" }}
                    >
                      <b className="" style={{
                        textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"

                      }}>
                        <Translate value="ad_marketstore" />{" "}
                        {this.props.ad.Store.Name}
                      </b>
                    </Link>
                  </div>
                </React.Fragment>
              }

                <div className="text-center">
                  {
                    (this.props.ad.User.Id).toString() !== this.props.user_id && (<React.Fragment><Link to={`/chat_conversation/${this.props.ad.Id}`} className="btn btn-info"><FaComment /> </Link> &nbsp;</React.Fragment>)
                  }

                  {this.props.ad.User.Whatsapp === "on" && (<React.Fragment><a href={`https://wa.me/${this.props.ad.User.CountryPhoneId}${this.props.ad.User.Phone}?text=Quisiera saber más`} className="btn btn-success" target="_blank"><FaWhatsapp /></a>&nbsp;</React.Fragment>)}

                  <a to="#" className="btn btn-secondary" onClick={() => {
                    this.onClickNumber();
                  }}><FaPhone /></a>&nbsp;

                  <a href={`mailto:${this.props.ad.User.Email}?subject=Godiwayer&body=Equipo%20GodiWay,`} className="btn btn-dark" target="_blank" rel="noopener noreferrer"><FaMailBulk /></a>
                </div>
              </React.Fragment>

            }
            <div className="text-center mt-4">
              <FacebookShareButton > <FacebookIcon size={32} round={true} /> </FacebookShareButton> &nbsp;
              <EmailShareButton subject={this.props.ad.Name} body={this.props.ad.Description} >
                <EmailIcon size={32} round={true} />
              </EmailShareButton>&nbsp;
              <TwitterShareButton title={this.props.ad.Name} description={this.props.ad.Description}><TwitterIcon size={32} round={true} /></TwitterShareButton>&nbsp;
              <PinterestShareButton title={this.props.ad.Name} description={this.props.ad.Description}><PinterestIcon size={32} round={true} />  </PinterestShareButton>&nbsp;
              <TelegramShareButton title={this.props.ad.Name} description={this.props.ad.Description}><TelegramIcon size={32} round={true} /> </TelegramShareButton>&nbsp;
              <WhatsappShareButton title={this.props.ad.Name} description={this.props.ad.Description}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>&nbsp;
            </div>
          </div>
        </div>

        <div className="report-goder-container">
          <small>
            <span style={{ color: "#929292" }}>{this.props.ad.CreatedAt}</span>
          </small>
        </div>
        <div className="report-goder-container">
          <small className="report-goder">
            <Link
              to="#"
              onClick={() => {
                this.onOpenCloseModal();
              }}
            >
              <Translate value="ad_report_title" />
            </Link>
            <span style={{ color: "#929292" }}> {"  #"} {this.props.ad.Id}</span>
          </small>

        </div>
        <hr className="dividerGray" />
        <ModalReportAd
          isOpen={this.state.open_modal}
          onClose={() => {
            this.onOpenCloseModal();
          }}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    ad: reducer.ad.item,
    error: reducer.ad.error,
    loading: reducer.ad.loading,

    user: reducer.session.auth,
  };
}
const mapDispatchToProps = (dispatch) => ({
  UpdateWishAd: (token, params) =>
    dispatch(actions_wish.updateItem({ token, params })),
  NewTransaction: (token, params) => dispatch(actions_transaction.newItem({ token, params }) //id, message, quantity, user_id, token
  ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdDetailAction)
);
/*
 < div className="text-center">
          <button className="btn btn-secondary">
          <FaSlackHash/><FaHashtag/><FaSlackHash/><FaHashtag/><FaSlackHash/>
          </button>
        </div >*/