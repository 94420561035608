import axios from "axios";
import { I18n } from "react-redux-i18n";
import formatMessage from '../../utils/message';
import {
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAILED,
  MESSAGE_LIST_ATTEMPT,
  /////////////////////
  MESSAGE_STORE_SUCCESS,
  MESSAGE_STORE_FAILED,
  /////////////////////
  MESSAGE_MORELIST_SUCCESS,
  MESSAGE_MORELIST_FAILED,
  MESSAGE_MORELIST_ATTEMPT,
  //////////////////////////
  UPDATE_CONTACT_MESSAGE,
  CLEAR_CONTACT_MESSAGE,
  /////////////////////
  CONFIRM_MESSAGE_READ,
  SEND_MESSAGE,
} from "../types/messageTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: MESSAGE_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: MESSAGE_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: MESSAGE_LIST_FAILED,
    error: error
  };
}
//////////////////////////////////
export function loadingMore() {
  return {
    type: MESSAGE_MORELIST_ATTEMPT
  };
}

export function successMore(data) {
  return {
    type: MESSAGE_MORELIST_SUCCESS,
    data: data
  };
}

export function failedMore(error) {
  return {
    type: MESSAGE_MORELIST_FAILED,
    error: error
  };
}
////////////////////////////////////
export function successStoreMessage(item, index) {
  return {
    type: MESSAGE_STORE_SUCCESS,
    item: item,
    index: index
  };
}

export function failedStoreMessage(error, index) {
  return {
    type: MESSAGE_STORE_FAILED,
    error: error,
    index: index
  };
}
////////////////////////////////////

export function setContactMessage(data) {
  return {
    type: UPDATE_CONTACT_MESSAGE,
    item: data
  };
}

export function removeContactMessage(error) {
  return {
    type: CLEAR_CONTACT_MESSAGE,
  };
}
/////////////////////////////
export function sendChat(item) {
  return {
    type: SEND_MESSAGE,
    item: item
  }
};
export function confirmMessageRead(data) {
  return {
    type: CONFIRM_MESSAGE_READ,
    data: data
  }
}

export const updateContactMessage = (data: Object) => async dispatch => {
  dispatch(setContactMessage(data));
}

export const clearContactMessage = (data: Object) => async dispatch => {
  dispatch(removeContactMessage());
}

export const addMessage = (data: Object) => async dispatch => {
  dispatch(sendChat(data.params));
}

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "message", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

export const loadListScroll = (data: Object) => async dispatch => {
  dispatch(loadingMore());
  await axios
    .get(url_api + "message", {
      params: data.params,
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(successMore(response.data.body));
        else dispatch(failedMore(response.data.body));
      } else {
        dispatch(failedMore("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedMore("try_later"));
      } else {
        dispatch(failedMore("try_later"));
      }
      return;
    });
};

export const newItem = (data: Object) => async dispatch => {

  await axios({
    method: "post",
    url: url_api + "message",
    data: data.params,
    headers: { Authorization: `Bearer ${data.token}` },
  }).then(function (response) {
    // console.log(response.data);
    if (response.status < 300) {
      if (response.data.status === "Success")
        dispatch(successStoreMessage(response.data.body, data.index));
      else dispatch(failedStoreMessage(response.data.body, data.index));
    } else {
      dispatch(failedStoreMessage("try_later"));
    }
  })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failedStoreMessage("try_later"));
      } else {
        dispatch(failedStoreMessage("try_later"));
      }
      return;
    });
};

export const updateMessageRead = (data: Object) => async (dispatch) => {
  axios.put(url_api + "message/read", data.params,
    {
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      //console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(confirmMessageRead(response.data.body));
        else console.log("error");//dispatch(failedStoreMessage(response.data.body));
      } else {
        console.log("error");
        //dispatch(failedStoreMessage("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      return;
    });
}


