import React from "react";
import { Translate } from "react-redux-i18n";
import { connect } from "react-redux";

import "./styles/Cards.css";

import CardList from "../components/CardList";
import PanelLayout from "../components/PanelLayout";
import PageLoading from "../components/PageLoading";
import * as actions from "../redux/actions/cardsActions";
import PayBankAccount from "../components/PayBankAccount";

//
//const ENV = "sandbox"; //process.env.NODE_ENV === "sandbox" ? "production" : "sandbox";
class Cards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: false,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    this.fetchCardsList();
  }

  fetchCardsList = async () => {
    try {
      await this.props
        .LoadCards(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  // fieldValidationErrors.email = emailValid ? '' : ' is invalid';
  ////<AdSectionLeft/>
  handleClick = e => {
    alert(this.state.form.email);
  };

  render() {
    //const onSuccess = payment => console.log("Successful payment!", payment);
    //const onError = error =>
    // console.log("Erroneous payment OR failed to load script!", error);
    //const onCancel = data => console.log("Cancelled payment!", data);
    return (
      <React.Fragment>
        <PanelLayout>
          <h3 className="text-center">
            <Translate value="kind_pay" />
          </h3>
          <div className="card-container">
            <h3>
              <Translate value="card_register" />
            </h3>
            <button className="btn btn-primary">
              <Translate value="card_add" />
            </button>
          </div>

          {this.props.loading ? <PageLoading /> : <CardList />}

          <PayBankAccount />
        </PanelLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //  console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    cardList: reducer.cards.cardList,
    error: reducer.cards.error,
    loading: reducer.cards.loading
  };
}
const mapDispatchToProps = dispatch => ({
  LoadCards: (user_id, token) => dispatch(actions.loadList({ user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cards);
