import {
  AD_EXCHANGE_ATTEMPT,
  AD_EXCHANGE_SUCCESS,
  AD_EXCHANGE_FAILED,
  //////////////////////////////
  AD_EXCHANGE_MORE_ATTEMPT,
  AD_EXCHANGE_MORE_SUCCESS,
  AD_EXCHANGE_MORE_FAILED,
  //////////////////////////////
  AD_EXCHANGE_DELETE_SUCCESS,
  AD_EXCHANGE_DELETE_FAILED,
  ///////////////////////////
  AD_EXCHANGE_NEW_SUCCESS,
  AD_EXCHANGE_NEW_FAILED
} from "../types/adExchangeTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  /////////
  loadingMore: false,
  page_count: 0,
  page_size: 0
};
function adExchange(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_EXCHANGE_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case AD_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_count: action.data.size,
        page_size: action.data.size,
        error: ""
      };

    case AD_EXCHANGE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    /////////////////////////////////////////////
    case AD_EXCHANGE_MORE_ATTEMPT:
      return {
        ...state,
        loadingMore: true
      };

    case AD_EXCHANGE_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_count: state.page_count + action.data.size,
        page_size: action.data.size,
        error: ""
      };

    case AD_EXCHANGE_MORE_FAILED:
      return {
        ...state,
        loadingMore: false,
      };

    /////////////////////////////////////////////
    case AD_EXCHANGE_DELETE_SUCCESS: {
      const newState = state.list.filter(item => {
        return item.Id !== action.item.Id;
      });
      return {
        ...state,
        list: newState,
        page_count: state.page_count - 1,
      };
    }

    case AD_EXCHANGE_DELETE_FAILED:
      return {
        ...state,
        error: action.error
      };
    ////////////////////////////////////////
    case AD_EXCHANGE_NEW_SUCCESS: {
      return {
        ...state,
        list: [action.item, ...state.list],
        page_count: state.page_count + 1,
        error_update: ""
      };
    }

    case AD_EXCHANGE_NEW_FAILED:
      return {
        ...state,
        error_update: action.error
      };

    default:
      return state;
  }
}
export default adExchange;
