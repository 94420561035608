export const ORDER_LIST_ATTEMPT = "ORDER_LIST_ATTEMPT";
export const ORDER_LIST_SUCCESS = "ORDER_LIST_SUCCESS";
export const ORDER_LIST_FAILED = "ORDER_LIST_FAILED";

export const ORDER_MORELIST_ATTEMPT = "ORDER_MORELIST_ATTEMPT";
export const ORDER_MORELIST_SUCCESS = "ORDER_MORELIST_SUCCESS";
export const ORDER_MORELIST_FAILED = "ORDER_MORELIST_FAILED";

export const ORDER_SET_ITEM = "ORDER_SET_ITEM";


export const LOGOUT = "LOGOUT";