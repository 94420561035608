import React from "react";
import { Link } from "react-router-dom";
import "./styles/AdList.css";
import MiniLoader from "../components/MiniLoader";
import { connect } from "react-redux";
import * as actions_store_extra from "../redux/actions/marketstoreExtraListActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

function ExtraItem(props) {
    return (
        <div className="col-lg-4  mb-4">
            <div className="card  h-100">

            </div>
        </div>
    );
}

class StoreAdList extends React.Component {

    state = { marketstoreId: "" };

    onClick = (item) => {
        this.props.SetAd(item);
    };
    //componentDidUpdate
    //componentDidMount
    // componentWillReceiveProps
    //shouldComponentUpdate

    async componentDidMount() {
        // this.setState(...this.state, { marketstoreId: this.props.marketstore.Id });
        this.fetchLoadExtraList();
    }

    fetchLoadExtraList = async () => {
        // console.log(this.props.marketstore.Id);
        // if (this.state.marketstoreId !== undefined)
        try {
            await this.props
                .LoadExtraStore(
                    this.props.token,
                    {
                        store_id: this.props.marketstore.Id,
                        id: this.props.user_id,
                        lang: I18n.t("locale"),
                    }
                )
                .then(() => {
                    if (this.props.error) {
                        alertify.warning(I18n.t(this.props.error));
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    componentWillUnmount() {
        this.props.ClearExtraStore();
    }

    onClickLoadMore = async () => {
        try {
            await this.props
                .LoadExtraStoreMore(this.props.token,
                    {
                        store_id: this.props.marketstore.Id,
                        id: this.props.user_id,
                        page_count: this.props.pageCount,
                        lang: I18n.t("locale"),
                    }
                )
                .then(() => {
                    if (this.props.error) {
                        alertify.warning(I18n.t(this.props.error));
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    render() {
        if (!this.props.loading && this.props.pageCount === 0)
            return (
                <h3>
                    {" "}
                    <Translate value="extra_not_list" />
                </h3>
            );

        if (this.props.loading) return <MiniLoader />;

        return (
            <div className="row">
                {this.props.storeExtraList.map((item) => {
                    return (
                        <ExtraItem


                        />
                    );
                })}
                <div className="col-12">
                    {
                        this.props.pageSize > 5 && (
                            this.props.loadingMore ? (
                                <div className="text-center">
                                    <MiniLoader />
                                </div>
                            ) :
                                (
                                    <div className="text-center">
                                        <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                this.onClickLoadMore();
                                            }}
                                        >
                                            <Translate value="load_more" />
                                        </button>
                                    </div>
                                )
                        )
                    }
                </div>
            </div>
        );
    }
}

/**
 * 
 * @param {this.props.loading ? (
          <div className="col text-center">
          <MiniLoader />
        </div>
      ) : (} reducer 
 */
function mapStateToProps(reducer) {
    //  console.log(reducer);
    return {
        user_id: reducer.session.auth.id,
        token: reducer.session.auth.access_token,

        marketstore: reducer.marketstore.item,
        marketstore_loading: reducer.marketstore.loading,

        storeExtraList: reducer.marketstoreExtraList.list,
        loading: reducer.marketstoreExtraList.loading,
        error: reducer.marketstoreExtraList.error,

        pageSize: reducer.marketstoreExtraList.page_size,
        pageCount: reducer.marketstoreExtraList.page_count,
        loadingMore: reducer.marketstoreExtraList.loadingMore,
    };
}
const mapDispatchToProps = (dispatch) => ({
    LoadExtraStore: (token, params) =>
        dispatch(actions_store_extra.loadList({ token, params })),
    LoadExtraStoreMore: (token, params) =>
        dispatch(actions_store_extra.loadListScroll({ token, params })),
    ClearExtraStore: () => dispatch(actions_store_extra.clearExtraStore({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreAdList);
