import React from "react";
import { FaTrash } from "react-icons/fa";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import "./styles/AdNew.css";

import MiniLoader from "../components/MiniLoader";
import AdTip from "../components/AdTip";
import * as actions from "../redux/actions/mediaActions";
// import Api from "../utils/api";

//import { Link } from "react-router-dom";
class GaleryItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-lg-4 mb-4">
          <div className="card ">
            <img src={this.props.item.Url} className="img-responsive" alt="goder" />
            <button className="btn btn-light" onClick={this.props.onClick}>
              <FaTrash />
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
class AdNewGalery extends React.Component {

  state = {
    image: "",
    file: ""
  };

  componentDidMount() {
    this.props.ClearMedia();
  }

  onClickRemove = async item => {
    try {
      await this.props
        .RemoveMedia(this.props.token, {
          media_id: item.Id,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeImageAd = async e => {
    if (this.props.mediaList.length >= 5) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_max_img"),
        function () { }
      );
      return;
    }
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        image: reader.result
      });
    };

    reader.readAsDataURL(file);
    let params = new FormData();

    params.append("goder_id", this.props.ad.Id);
    params.append("id", this.props.user_id);
    params.append("image", file);// Api.compressImage()


    await this.props.UpdateMedia(this.props.token, params).then(() => {
      if (this.props.error_update) {
        alertify.warning(I18n.t(this.props.error_update));
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-ad-new">
          <div className="row">
            <AdTip />
            <div className="col-lg-8  card bg-light">
              <div className="card-header text-center">
                <h2>
                  <Translate value="img_for_ad" />
                </h2>
              </div>
              <div className="card-body row">
                {this.props.mediaList.map(item => {
                  return (
                    <GaleryItem
                      item={item}
                      key={item.Id}
                      onClick={() => {
                        this.onClickRemove(item);
                      }}
                    />
                  );
                })}
              </div>
              {this.props.loading_image && <MiniLoader />}
              <hr className="dividerGray" />
              <div className="card-footer row">
                <div className="col-6 text-center">
                  <input
                    className="upload"
                    accept="image/*"
                    type="file"
                    name="image"
                    id="image"
                    onChange={e => {
                      this.onChangeImageAd(e);
                    }}
                  />
                  <label htmlFor="image">
                    <span className="btn btn-secondary">
                      <Translate value="load_img" />
                    </span>
                  </label>
                </div>
                <div className="col-6 text-center">
                  <Link to="/home" className="btn btn-danger">
                    <Translate value="step_finish" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    ad: reducer.publish.item,

    mediaList: reducer.media.list,

    error_update: reducer.media.error_update,
    loading_image: reducer.media.loadingFile
  };
}
const mapDispatchToProps = dispatch => ({
  RemoveMedia: (token, params) =>
    dispatch(actions.deleteItem({ token, params })),

  UpdateMedia: (token, params) => dispatch(actions.newItem({ token, params })),

  ClearMedia: () => dispatch(actions.clearMediaList({})),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdNewGalery);
