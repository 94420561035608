import React, { Component } from "react";
import { Link } from "react-router-dom";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreListActions";

//import { Translate, I18n } from "react-redux-i18n";
function getInitial(name) {
  return name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
}
class StoreItem extends React.Component {
  render() {
    return (

      <div className="col-md-12 media mb-1">
        <div className="media-left ">
          {
            this.props.item.Logo ? (<img
              src={this.props.item.Logo}
              className="img-circle"
              style={{ borderRadius: "50%" }}
              alt="img"
              width="50"
              height="50"
            />) : (
              <div style={{
                border: "2px solid #f4f4f4",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                fontSize: "x-large",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}> {getInitial(this.props.item.Name)}</div>)
          }


        </div>
        <div className="media-body ">
          <div className="text-left">
            <Link
              className=""
              style={{ textDecoration: "none", color: "black" }}
              to={`/marketstore/show/${this.props.item.Id}`}
            //onClick={this.props.onClick}
            >
              {this.props.item.Name}
            </Link>
          </div>
        </div>
        <div className="media-footer "></div>
      </div>

    );
  }
}

class WallStores extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchMarketStoreList();
  }
  fetchMarketStoreList = async () => {
    try {
      await this.props
        .LoadMarketStores(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  //componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <small>MarketStores</small>
          <div className="card-body">

            {this.props.marketstoreList.map((item) => {
              return <StoreItem item={item} key={item.Id} />;
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducers) {
  return {
    user_id: reducers.session.auth.id,
    token: reducers.session.auth.access_token,

    error: reducers.marketstoreList.error,
    loading: reducers.marketstoreList.loading,
    marketstoreList: reducers.marketstoreList.list,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadMarketStores: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(WallStores);
