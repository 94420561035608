export const ADFAQ_LIST_ATTEMPT = 'ADFAQ_LIST_ATTEMPT';
export const ADFAQ_LIST_SUCCESS = 'ADFAQ_LIST_SUCCESS';
export const ADFAQ_LIST_FAILED = 'ADFAQ_LIST_FAILED';

export const FAQ_NEW_SUCCESS = 'FAQ_NEW_SUCCESS';
export const FAQ_NEW_FAILED = 'FAQ_NEW_FAILED';

export const ADFAQ_MORE_ATTEMPT = 'ADFAQ_MORE_ATTEMPT';
export const ADFAQ_MORE_SUCCESS = 'ADFAQ_MORE_SUCCESS';
export const ADFAQ_MORE_FAILED = 'ADFAQ_MORE_FAILED';

export const LOGOUT = "LOGOUT";

