import React from "react";

class TransactionItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="card ">
          <div className="card-body">
            <ul className="">
              <li className="">{this.props.item.Comment}</li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class TransactionComments extends React.Component {
  render() {
    return (
      <div>
        {this.props.commentList.map(item => {
          return <TransactionItem item={item} key={item.Id} />;
        })}
      </div>
    );
  }
}

export default TransactionComments;
