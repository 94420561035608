import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";

import "./styles/Customer.css";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import PanelLayout from "../components/PanelLayout";
import * as actions from "../redux/actions/customerActions";


class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchCustomer();
  }

  fetchCustomer = async () => {
    try {
      await this.props
        .LoadCustomer(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onClickEdit = (e) => {
    this.props.history.push("/customer/edit");
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            {" "}
            <Translate value="billing" />
          </h3>
          <div className="row">
            <div className="col-md-6 ">
              <div className="card-title mb-20 mt-10 text-center">
                <b>
                  {" "}
                  <Translate value="billing_customer_data" />
                </b>
              </div>
              <div className="card-content col-sm-12">
                <small className="row">
                  <b>
                    <Translate value="profile_name" />:
                  </b>
                </small>
                <label className="row field">{this.props.customer.Name}</label>

                <small className="row">
                  <b>
                    <Translate value="profile_email" />:
                  </b>
                </small>
                <label className="row field">{this.props.customer.Email}</label>

                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_phone" />:
                  </b>
                </small>
                <label className="row field">{this.props.customer.Phone}</label>

                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_organization" />:
                  </b>
                </small>
                <label className="row field">
                  {this.props.customer.Organization}
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-title mb-20">
                <b>
                  <Translate value="billing_customer_address" />
                </b>
              </div>
              <div className="card-content col-sm-12">
                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_address1" />:
                  </b>
                </small>
                <label className="row field">
                  {this.props.customer.Address}
                </label>

                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_city" />:
                  </b>
                </small>
                <label className="row field">
                  {this.props.customer.City}
                </label>

                <small className="row">
                  <b>
                    <Translate value="profile_state" />:
                  </b>
                </small>
                <label className="row field">
                  {this.props.customer.State}
                </label>

                <small className="row">
                  <b>
                    <Translate value="profile_zipcode" />:
                  </b>
                </small>
                <label className="row field">
                  {this.props.customer.Zipcode}
                </label>
              </div>
            </div>
            <div className="col-12 text-center customer_btn">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickEdit();
                }}
              >
                {" "}
                <Translate value="profile_edit" />
              </button>
            </div>
          </div>
        </PanelLayout>
      </React.Fragment>
    );
  }
}
/**<a href="#">
                  <small>
                    <b>
                      {" "}
                      <Translate value="profile_edit" />
                    </b>
                  </small>
                </a> */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    customer: reducer.customer.item,
    error: reducer.customer.error,
    loading: reducer.customer.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCustomer: (user_id, token) =>
    dispatch(actions.loadItem({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Customer);
