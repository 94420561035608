import React from "react";
//import ReactDOM from 'react-dom';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import logo_g from "../assets/logo_celeste.png";
import posi from "../assets/bad.png";
class PaypalSuccess extends React.Component {
    render(){
        return (
            <React.Fragment>
              <div style={{display:"flex",justifyContent:"center",alignItems:"center", height:"100vh"}}>
              <div className="card">
                <div className="text-center" >
               
                  <img src={logo_g} alt="Godiway" />
                </div>  
            
              <div className="row justify-content-center">
               
                  <img src={posi} className="img-responsive" />
                
                <br />
                <br />
                <div className="row justify-content-center">
                  <div className="col-7 text-center">
                    <h5 className="purple-text text-center">
                      <Translate value="congratulations" />
                    </h5>
                  </div>
                </div>
              </div>
              </div>
          
              </div>
            </React.Fragment>
          );
    }
  
}
export default PaypalSuccess;

/**   <text
                    className=""
                    style={{
                      fontSize: "22px",
                      fontWeight: "bold ",
                      color: "gray",
                    }}
                  >Godiway</text> */