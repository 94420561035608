import React from "react";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/PanelSummary.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/summaryByUserActions";
//import { Link } from 'react-router-dom';



class PanelSummary extends React.Component {
  componentDidMount() {
    this.fecthLoadSummary();
  }

  fecthLoadSummary = async () => {
    try {
      await this.props
        .LoadSummary(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <div className="Summary">
        <div className="card text-center">
          <div className="card-header">
            <img
              className="avatarBig"
              src={this.props.auth.avatar}
              width="80px"
              alt="Gw"
            />

            <div className="row">
              <b className="col-12 text-center">
                @{this.props.auth.username}
              </b>
            </div>

            <div className="row">
              <div className="col-6 text-left">
                {" "}
                <Translate value="ads_available" />:
              </div>
              <div className="col-6 text-right">
                {this.props.summary.AdAvailable}
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-left">
                {" "}
                Plan:
              </div>
              <div className="col-6 text-right">
                {this.props.summary.PlanName}
              </div>
            </div>
          </div>
          <div className="card-body ">
            <div className="title_summary text-left mt-4">
              <Translate value="sales" />
            </div>
            <div className="container_summary" id="summary_sale">
              <div className="row">
                <div className="col-8 text-left">
                  {" "}
                  <Translate value="ads_active" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.SalesActive}
                </div>
                <div className="col-8 text-left">
                  <Translate value="ads_inactive" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.SalesInActive}
                </div>
              </div>
            </div>
            <div className="title_summary text-left mt-4">
              {" "}
              <Translate value="rents" />
            </div>
            <div className="container_summary" id="">
              <div className="row">
                <div className="col-8 text-left">
                  <Translate value="ads_active" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.RentsActive}
                </div>
                <div className="col-8 text-left">
                  <Translate value="ads_inactive" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.RentsInActive}
                </div>
              </div>
            </div>
            <div className="title_summary text-left mt-4">
              {" "}
              <Translate value="exchanges" />
            </div>
            <div className="container_summary">
              <div className="row">
                <div className="col-8 text-left">
                  <Translate value="ads_active" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.ExchangesActive}
                </div>
                <div className="col-8 text-left">
                  <Translate value="ads_inactive" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.ExchangesInActive}
                </div>
              </div>
            </div>
            <div className="title_summary text-left mt-4">
              {" "}
              <Translate value="donations" />
            </div>
            <div className="container_summary">
              <div className="row">
                <div className="col-8 text-left">
                  <Translate value="ads_active" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.DonationsActive}
                </div>
                <div className="col-8 text-left">
                  <Translate value="ads_inactive" />:
                </div>
                <div className="col-4 text-center">
                  {this.props.summary.DonationsInActive}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row">
              <div className="col-8 text-left">
                <Translate value="ads" /> <Translate value="total" />:
              </div>
              <div className="col-4 text-center">
                {" "}
                {this.props.summary.SalesActive +
                  this.props.summary.SalesInActive +
                  this.props.summary.RentsActive +
                  this.props.summary.RentsInActive +
                  this.props.summary.ExchangesActive +
                  this.props.summary.ExchangesInActive +
                  this.props.summary.DonationsActive +
                  this.props.summary.DonationsInActive}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// {this.props.summary.SalesActive + this.props.summary.SalesInActive + this.props.summary.RentsActive + this.props.summary.RentsInActive + this.props.summary.ExchangesActive + this.props.summary.ExchangesInActive + this.props.summary.DonationsActive + this.props.summary.DonationsInActive )}
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    auth: reducer.session.auth,

    summary: reducer.summaryByUser.item,
    error: reducer.summaryByUser.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadSummary: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});
export default connect(mapStateToProps, mapDispatchToProps)(PanelSummary);
