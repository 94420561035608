import React from "react";
import { Link } from "react-router-dom";
import "./styles/NotificationCenter.css";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/notificationActions";

class NotificationItem extends React.Component {
  render() {
    const kind = this.props.notification.Kind;
    var route = "";
    switch (kind) {
      case "FAQ":
        route = "/faq";
        break;
      case "AD":
        route = `/goder/show/${this.props.notification.TempId}`;
        break;
      case "MESSAGE":
        route = `/chat/${this.props.notification.TempId}`;
        break;
      case "TRANSACTION":
        route = "/transaction";
        break;
      case "TRANSACTION_REQUEST":
        route = "/transaction/request";
        break;
      case "MARKETSTORE":
        route = `/marketstore/show/${this.props.notification.TempId}`;
        break;
      case "COMMENT":
        route = "/panel";
        break;

      default:
        route = "/panel";
        break;
    }
    return (
      <React.Fragment>
        <tr>
          <td>
            <Link
              to={route}
              className=""
              onClick={this.props.onClick}
              style={{ textDecoration: "none" }}
            >
              <div className="col-md-12 media">
                <div className="media-left ">
                  <img
                    src={this.props.notification.Avatar}
                    className="rounded-circle user_img "
                    alt="img"
                    width="50"
                    height="50"
                  />
                </div>{" "}
                &nbsp; &nbsp;
                <div className="media-body ">
                  <div className="text-left ">
                    <small>  <Translate value={this.props.notification.Message} /></small>
                  </div>
                  <div className="text-left">
                    <small>{this.props.notification.Detail}</small>
                  </div>
                </div>
              </div>
            </Link>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

class NotificationCenter extends React.Component {
  state = {
    notiticationList: [],
  };

  componentDidMount() {
    this.setState({ notiticationList: this.props.notiticationList });
  }

  onClickAllRead = async () => {
    if (this.props.notificationCount > 0)
      await this.props.SetNotificationReadAll(this.props.user_id, this.props.token);


  };

  onClickMoreItem = () => {
    // alert("mas items");
  };

  onClickNotification = (item) => {
    this.props.SetNotificationRead(
      item.Id,
      this.props.user_id,
      this.props.token
    );
  };

  render() {
    if (this.props.notiticationList.length === 0)
      return (
        <h3>
          {" "}
          <Translate value="notification_not_list" />
        </h3>
      );

    return (
      <React.Fragment>
        <div className="notification-row">
          <h3>
            <Translate value="notifications" />
          </h3>
          <div className="">
            <button
              className="btn btn-primary"
              onClick={() => {
                this.onClickAllRead();
              }}
            >
              <Translate value="notification_mark_all" />
            </button>
          </div>
        </div>

        <table className="table table-striped">
          <tbody className="">
            {this.props.notiticationList.map((item) => {
              return (
                <NotificationItem
                  notification={item}
                  key={item.Id}
                  onClick={() => {
                    this.onClickNotification(item);
                  }}
                />
              );
            })}
          </tbody>
        </table>
        <div className="text-center">
          <button
            className="btn btn-secondary"
            onClick={() => {
              this.onClickMoreItem();
            }}
          >
            <Translate value="load_more" />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    notificationCount: reducer.notification.page_count,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SetNotificationRead: (id, user_id, token) =>
    dispatch(actions.setNotificationRead({ id, user_id, token })),
  SetNotificationReadAll: (user_id, token) =>
    dispatch(actions.setNotificationReadAll({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationCenter);
