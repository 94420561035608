import React from "react";

import { connect } from "react-redux";
import * as actions from "../redux/actions/sessionActions";
import * as event_actions from "../redux/actions/eventActions";
import { withRouter } from "react-router";
//import StoreAdList from "../components/StoreAdList";

class Logout extends React.Component {
  componentDidMount() {
    this.props.UserOffline(this.props.user);
    this.props.LogoutRequest();

    this.props.history.push("/");

  }

  render() {
    return <></>;
  }
}

const mapDispatchToProps = (dispatch) => ({
  UserOffline: (user) => dispatch(event_actions.userOffline(user)),
  LogoutRequest: () => dispatch(actions.logoutRequest({})),

});

function mapStateToProps(reducer) {
  return {
    user: reducer.session.auth,
  };
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Logout));
