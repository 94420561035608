import React from "react";
import { Link } from "react-router-dom";
import "./styles/AdList.css";
//import PageLoading from '../components/PageLoading';
import { connect } from "react-redux";
import * as actions from "../redux/actions/adActions";
import { Translate } from "react-redux-i18n";

class AdListItem extends React.Component {
  render() {
    var KindCommerce = "";
    switch (this.props.ad.KindCommerceId) {
      case 1:
        KindCommerce = <Translate value="donation" />;
        break;
      case 2:
        KindCommerce = <Translate value="sale" />;
        break;
      case 3:
        KindCommerce = <Translate value="exchange" />;
        break;
      case 4:
        KindCommerce = <Translate value="rent" />;
        break;
      case 5:
        KindCommerce = <Translate value="auction" />;
        break;
      default:
        KindCommerce = <Translate value="sale" />;
        break;
    }
    return (
      <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12  mb-4">
        <div className="card  h-100 goder-shadow" style={{ padding: "0px" }}>
          <Link
            to={`/goder/show/${this.props.ad.Id}`}
            onClick={this.props.onClick}
          >
            <img
              className="card-img-top  goder-img-list"
              src={this.props.ad.Photo}
              alt="Godiway_img"
            />
          </Link>
          <div className="goder-body">
            <div className="goder-title ">
              <Link
                to={`/goder/show/${this.props.ad.Id}`}
                onClick={this.props.onClick}
                className="goder-link-list"
              >
                {this.props.ad.Name}
              </Link>
            </div>

            <div className="goder-row">
              <div className="">
                <small>{KindCommerce}</small>
                <span>&nbsp;</span>
              </div>
              <div className="">
                <i>
                  <strong>
                    {" "}
                    {this.props.ad.Price}&nbsp;
                    <small>{this.props.ad.Currency}</small>{" "}
                  </strong>
                </i>
              </div>
            </div>
            <div className="goder-row">
              {
                this.props.ad.KindCommerceId === 4 || this.props.ad.KindCommerceId === 5 ?
                  (
                    <div className="">
                      <small>{this.props.ad.Frequency && this.props.ad.Frequency.Name}</small>
                      <span>&nbsp;</span>
                    </div>
                  )
                  :
                  (
                    <div className="">
                      <small>{this.props.ad.Condition && this.props.ad.Condition.Name}</small>
                      <span>&nbsp;</span>
                    </div>
                  )

              }

              <div className="">
                <small>{this.props.ad.CreatedAt}</small>
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdList extends React.Component {
  onClick = (item) => {
    this.props.SetAd(item);
  };

  render() {
    if (this.props.adList.length === 0)
      return (
        <h3>
          <Translate value="ad_not_list" />
        </h3>
      );

    return (
      <div className="row">
        {
          this.props.adList.map((ad) => {
            return (
              <AdListItem
                ad={ad}
                key={ad.Id}
                onClick={() => {
                  this.onClick(ad);
                }}
              />
            );
          })}
      </div>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    //adList: reducer.adList.list,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SetAd: (item) => dispatch(actions.setAd({ item })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdList);
