import React from "react";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./styles/FaqList.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/faqQuestionActions";
import MiniLoader from "./MiniLoader";



class FaqListItem extends React.Component {
  render() {
    var KindCommerce = "";
    switch (this.props.item.KindCommerceId) {
      case 1:
        KindCommerce = <Translate value="donation" />;
        break;
      case 2:
        KindCommerce = <Translate value="sale" />;
        break;
      case 3:
        KindCommerce = <Translate value="exchange" />;
        break;
      case 4:
        KindCommerce = <Translate value="rent" />;
        break;
      default: break;
    }
    return (
      <React.Fragment>
        <div className="col-12">
          <table className="table table-striped">
            <tbody>
              <tr>
                <td>
                  <div className="row">
                    <div className="col-4">
                      <Link
                        to={`/goder/show/${this.props.item.AdId}`}
                        onClick={this.props.onClick}
                      >
                        <img
                          className="card-img-top faq-img"
                          src={this.props.item.Photo}
                          alt="Gw"
                        />
                        {
                          this.props.item.Answer.length > 0 ? (
                            <label className="faq-status-closed">
                              {" "}
                              <Translate value="faq_close" />
                            </label>
                          ) : (
                            <label className="faq-status-pending">
                              <Translate value="faq_pending" />
                            </label>
                          )
                        }
                      </Link>
                    </div>
                    <div className="col-6">
                      <h5 className="row">
                        <Link
                          to={`/goder/show/${this.props.item.AdId}`}
                          onClick={this.props.onClick}
                          className="link-title"
                        >
                          {this.props.item.Name}
                        </Link>
                      </h5>
                      <div className="row">
                        <Translate value="faq_date" />:
                        {this.props.item.CreatedAt}
                      </div>
                      <div className="row">
                        <Translate value="faq_kindcommerce" />:{KindCommerce}
                      </div>
                      <div className="row">
                        <strong>
                          <Translate value="faq_question" />:&nbsp;
                        </strong>
                        {this.props.item.Question}
                      </div>
                      <div className="row">
                        <strong>
                          <Translate value="faq_answer" />:&nbsp;
                        </strong>
                        {this.props.item.Answer}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}
////style="background-color: #007bff !important;"    style="background-color: white !important; color: black !important; border: 1px none !important; height: 101px;"
//style="display:flex;flex-wrap: nowrap;"
class FaqListQuestion extends React.Component {
  state = {
    isLogin: true,
    nextLoad: 0,
    loading: false,
    error: null,
    data: []
  };

  onClickViewAd = item => {
    //this.props.SetAd(item);
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadMoreFaqQuestion(this.props.pageCount, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    if (this.props.pageCount === 0)
      return (
        <h3>
          <Translate value="faq_question_not_list" />{" "}
        </h3>
      );

    return (
      <React.Fragment>
        <div className="row">
          {
            this.props.faqList.map(item => {
              return (
                <FaqListItem
                  item={item}
                  key={item.Id}
                  onClick={() => {
                    this.onClickViewAd(item);
                  }}
                />
              );
            })
          }
        </div>
        {
          this.props.pageSize > 4 && (
            this.props.loadingMore ? (
              <div className="text-center">
                <MiniLoader />
              </div>
            ) :
              (
                <div className="text-center">
                  <button
                    className="btn btn-secondary"
                    onClick={() => {
                      this.onClickLoadMore();
                    }}
                  >
                    <Translate value="load_more" />
                  </button>
                </div>
              )

          )
        }
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,

    pageCount: reducer.faqQuestion.page_count,
    loadingMore: reducer.faqQuestion.loadingMore,
    pageSize: reducer.faqQuestion.page_size
  };
}


const mapDispatchToProps = dispatch => ({
  LoadMoreFaqQuestion: (page_count, user_id, token) =>
    dispatch(actions.loadListScroll({ page_count, user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaqListQuestion);