import React from "react";
//import api from '../api';
import PanelLayout from "../components/PanelLayout";
//import PageLoading from '../components/PageLoading';
import "./styles/Customer.css";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/customerActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class CustomerEdit extends React.Component {

  state = {
    name: "",
    email: "",
    phone: "",
    organization: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
  };

  componentDidMount() {
    this.setState({
      name: this.props.customer.Name,
      email: this.props.customer.Email,
      phone: this.props.customer.Phone,
      organization: this.props.customer.Organization,
      address: this.props.customer.Address,
      city: this.props.customer.City,
      state: this.props.customer.State,
      zipcode: this.props.customer.Zipcode,
      id: this.props.user_id,
    });
  }

  saveCustomer = async () => {
    try {
      await this.props.SaveCustomer(this.props.token, this.state).then(() => {
        if (this.props.error) {
          alertify.warning(this.props.error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  onClickSave = (e) => {
    this.saveCustomer();
    this.props.history.push("/customer");
  };

  onChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  render() {
    return (
      <React.Fragment>
        <PanelLayout>
          <h3>
            {" "}
            <Translate value="billing" />
          </h3>
          <div className="row">
            <div className="col-md-6 ">
              <div className="card-title mb-20 mt-10 text-center">
                <b>
                  {" "}
                  <Translate value="billing_customer_data" />
                </b>
              </div>
              <div className="card-content col-sm-12">
                <small className="row">
                  <b>
                    <Translate value="profile_name" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="name"
                  onChange={this.onChange}
                  value={this.state.name}
                  placeholder={I18n.t("profile_name")}
                  autoComplete={"off"}
                ></input>

                <small className="row">
                  <b>
                    <Translate value="profile_email" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="email"
                  value={this.state.email}
                ></input>

                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_phone" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="phone"
                  placeholder={I18n.t("profile_phone")}
                  autoComplete={"off"}
                  onChange={this.onChange}
                  value={this.state.phone}
                ></input>
                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_organization" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="organization"
                  onChange={this.onChange}
                  value={this.state.organization}
                ></input>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-title mb-20">
                <b>
                  <Translate value="billing_customer_address" />
                </b>
              </div>
              <div className="card-content col-sm-12">
                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_address1" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="address"
                  onChange={this.onChange}
                  placeholder={I18n.t("profile_address1")}
                  autoComplete={"off"}
                  value={this.state.address}
                ></input>

                <small className="row">
                  <b>
                    {" "}
                    <Translate value="profile_city" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="city"
                  onChange={this.onChange}
                  value={this.state.city}
                ></input>
                <small className="row">
                  <b>
                    <Translate value="profile_state" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="state"
                  onChange={this.onChange}
                  value={this.state.state}
                ></input>

                <small className="row">
                  <b>
                    <Translate value="profile_zipcode" />:
                  </b>
                </small>

                <input
                  className="form-control row customer_input"
                  type="text"
                  name="zipcode"
                  onChange={this.onChange}
                  value={this.state.zipcode}
                  placeholder={I18n.t("profile_zipcode")}
                  autoComplete={"off"}
                ></input>
              </div>
            </div>
            <div className="col-12 text-center customer_btn">
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.onClickSave();
                }}
              >
                {" "}
                <Translate value="save" />
              </button>
            </div>
          </div>
        </PanelLayout>
      </React.Fragment>
    );
  }
}
/**<a href="#">
                  <small>
                    <b>
                      {" "}
                      <Translate value="profile_edit" />
                    </b>
                  </small>
                </a> */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    customer: reducer.customer.item,
    error: reducer.customer.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SaveCustomer: (token, params) => dispatch(actions.saveCustomer({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
