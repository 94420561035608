import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import "./styles/PanelAddLinkYoutube.css";
import { connect } from "react-redux";
import * as actions from "../redux/actions/mediaActions";
//import MiniLoader from "../components/MiniLoader";
import { FaTrash, FaPlus, FaSave } from "react-icons/fa";

import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";

function LinkItem(props) {
  return (
    <React.Fragment>
      <div key={props.item.Id} className="panel-add-link">
        <input
          type="text"
          id="link"
          name="link"
          data-type="text"
          required={true}
          value={props.item.Url}
          maxLength="100"
          className="form-control mg-5"
          placeholder="https://www.youtube.com/watch?..."
          onChange={props.onChange}
        />
        <button className="btn btn-info mg-5" onClick={props.onClickSave}>
          <FaSave />
        </button>
        {"  "}
        <button className="btn btn-danger mg-5" onClick={props.onClickRemove}>
          <FaTrash />
        </button>
      </div>
    </React.Fragment>
  );
}

class PanelAddLinkYoutube extends Component {
  state = {
    // image: "",
    //   file: "",
    index: 1,
    values: [],
    links: [{ id: 0, link: "" }],
    //band: 0,
    media: [],
  };

  componentDidMount() {
    // this.fecthLink();
    //console.log(media);
    /*
    if (!this.props.loading && this.state.band == 0) {
      this.setState({ band: 1 });
      this.props.mediaList.map((item) => {
        if (item.Type === "video")
          this.setState((prevState) => ({
            values: [...prevState.values, item.Url],
          }));
      });
    }*/
    const newState = this.props.mediaList.filter((item) => {
      return item.Type === "video";
    });
    this.setState({ media: newState });
  }

  fecthLink = () => {
    /* try {
      await this.props
        .LoadLinkVideo(this.props.ad.Id, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
    */
    //  console.log(this.props.mediaList);
    // alert("prueba");
  };

  componentWillUpdate() { }

  onClickRemove = async (item) => {
    try {
      /* await this.props
        .RemoveMedia(item.Id, this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });*/
    } catch (error) {
      console.log(error);
    }
  };

  handleChange(i, event) {
    let media = [...this.state.media];
    media[i].Url = event.target.value;
    this.setState({ media });
  }

  onClickAddInput = (e) => {
    //console.log(this.props.mediaList);
    // this.fecthLink();
    console.log(this.state.media);
    if (this.state.media.length > 2) {
      alertify.alert(
        I18n.t("warning"),
        I18n.t("warning_max_link"),
        function () { }
      );
      return;
    }

    // this.setState((prevState) => ({ values: [...prevState.values, ""] }));

    this.setState((prevState) => ({
      ...this.state,
      media: [
        ...this.state.media,
        { Id: "", Name: "", Url: "", Type: "video" },
      ],
    }));
  };

  removeClick = async (item, i) => {
    /*  let values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
    */
    await this.props
      .RemoveMedia(item.Id, this.props.user_id, this.props.token)
      .then(() => {
        if (this.props.error) {
          alertify.warning(I18n.t(this.props.error));
        } else {
          let values = [...this.state.media];
          values.splice(i, 1);
          this.setState({ media: values });
        }
      });
  };

  saveClick = async (item, i) => {
    //let media = [...this.state.media];

    if (item.Url !== "") {
      let params = new FormData();

      params.append("goder_id", this.props.ad.Id);
      params.append("link", item.Url);
      // params.append("token", this.props.token);
      params.append("id", this.props.user_id);
      params.append("media_id", item.Id);

      await this.props.UpdateMedia(this.props.token, params).then(() => {
        if (this.props.error_update) {
          alertify.warning(I18n.t(this.props.error_update));
        } else {
          alertify.success(I18n.t("saved_succesfull"));
        }
      });
      /*
      await this.props.UpdateLinkVideo(values[i]).then(() => {
        if (this.props.error_update) {
          alertify.warning(I18n.t(this.props.error_update));
        }
      });*/
    }
  };

  createUI() {
    return this.state.media.map((item, i) => (
      <div key={item.Id} className="panel-add-link">
        <input
          type="text"
          value={item.Url || ""}
          data-type="text"
          required={true}
          maxLength="100"
          className="form-control mg-5"
          placeholder="https://www.youtube.com/watch?..."
          onChange={(e) => {
            this.handleChange(item.Id, e);
          }}
        />
        <button
          type="button"
          className="btn btn-info mg-5"
          value="remove"
          onClick={() => {
            this.saveClick(item.Id);
          }}
        >
          <FaSave />
        </button>
        <button
          className="btn btn-danger mg-5"
          onClick={() => {
            this.removeClick(item.Id);
          }}
        >
          <FaTrash />
        </button>
      </div>
    ));
  }
  render() {
    //console.log(this.state.values);
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-header text-center">
            <h2>
              {" "}
              <Translate value="add_link_youtube" />
            </h2>
          </div>
          <button className="btn btn-success" onClick={this.onClickAddInput}>
            <FaPlus />
          </button>

          {this.state.media.map((item, index) => {
            if (item.Type === "video") {
              return (
                <LinkItem
                  item={item}
                  key={index}
                  onClickRemove={() => {
                    this.removeClick(item, index);
                  }}
                  onClickSave={() => {
                    this.saveClick(item, index);
                  }}
                  onChange={(e) => {
                    this.handleChange(index, e);
                  }}
                />
              );
            }
          })}

          <div className="card-footer">
            <div className="text-center">
              {" "}
              <Translate value="warning_max_link" />
              ,YouTube
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

/**
 * <div className="card-body row">{this.createUI()}</div>
 *  {this.props.loading_image && <MiniLoader />}
{ {this.props.mediaList.map((item) => {
  if (item.Type === "video")
  return (
    <LinkItem
      item={item}
      key={item.Id}
      onClick={() => {
        this.onClickRemove(item);
      }}
    />
  );
})}
} reducer 
   error: reducer.video.error,
    loading: reducer.video.loading,
    videoList: reducer.video.videoList,

    error_update: reducer.video.error_update,
    success_update: reducer.video.success_update,
 */
function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,

    mediaList: reducer.media.list,

    //  error_update: reducer.media.error_update,
    // loading_image: reducer.media.loadingFile,
  };
}
const mapDispatchToProps = (dispatch) => ({
  RemoveMedia: (id, user_id, token) =>
    dispatch(actions.deleteItem({ id, user_id, token })),
  //LoadLinkVideo: (id, user_id, token) =>
  //s  dispatch(actions.loadLinkVideo({ id, user_id, token })),

  UpdateMedia: (token, params) => dispatch(actions.newItem({ token, params })),
  //UpdateLinkVideo: (params) => dispatch(actions.updateLinkVideo({ params })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PanelAddLinkYoutube);
