import {
  AD_SALES_ATTEMPT,
  AD_SALES_SUCCESS,
  AD_SALES_FAILED,
  //////////////////////////////
  AD_SALES_MORE_ATTEMPT,
  AD_SALES_MORE_SUCCESS,
  AD_SALES_MORE_FAILED,
  //////////////////////////
  AD_SALES_CHANGE_POSTSTATUS_SUCCESS,
  AD_SALES_CHANGE_POSTSTATUS_FAILED,
  ///////////////////////////
  AD_SALES_REMOVE_SUCCESS,
  AD_SALES_REMOVE_FAILED,
  ///////////////////////////
  AD_SALES_NEW_SUCCESS,
  AD_SALES_NEW_FAILED,
} from "../types/adSalesTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  error_update: "",

  loadingMore: false,
  page_size: 0,
  page_count: 0
};

function adSales(state = INITIAL_STATE, action) {
  switch (action.type) {
    case AD_SALES_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case AD_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: "",
      };

    case AD_SALES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    ///////////////////////////////////////
    case AD_SALES_MORE_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };

    case AD_SALES_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,

      };

    case AD_SALES_MORE_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    /////////////////////////////////////////////
    case AD_SALES_CHANGE_POSTSTATUS_SUCCESS: {
      if (action.item.PostStatus === 1) {
        const newState = state.list.filter((item) => {
          return item.Id !== action.item.Id;
        });
        return {
          ...state,
          list: newState,
          page_count: state.page_count - 1,
        };
      } else {

        return {
          ...state,
          page_count: state.page_count + 1,
          list: [action.item, ...state.list],
        };
      }
    }

    case AD_SALES_CHANGE_POSTSTATUS_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    ///////////////////////////////////////////////////
    case AD_SALES_REMOVE_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.Id !== action.item.Id;
      });
      return {
        ...state,
        list: newState,
        page_count: state.page_count - 1,
      };

    }

    case AD_SALES_REMOVE_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    /////////////////////////////////////////////////
    case AD_SALES_NEW_SUCCESS: {
      return {
        ...state,
        list: [action.item, ...state.list],
        page_count: state.page_count + 1,
        error_update: "",
      };
    }

    case AD_SALES_NEW_FAILED:
      return {
        ...state,
        error_update: action.error,
      };

    default:
      return state;
  }
}
export default adSales;
