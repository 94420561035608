import React from "react";
import { Link } from "react-router-dom";
import "./styles/AdList.css";
//import PageLoading from '../components/PageLoading';
import { connect } from "react-redux";
import * as actions from "../redux/actions/adActions";
import { Translate } from "react-redux-i18n";

class AdListItem extends React.Component {
  render() {
    var KindCommerce = "";
    var condition = "";
    switch (this.props.item.KindCommerceId) {
      case 1:
        KindCommerce = <Translate value="donation" />;
        break;
      case 2:
        KindCommerce = <Translate value="sale" />;
        break;
      case 3:
        KindCommerce = <Translate value="exchange" />;
        break;
      case 4:
        KindCommerce = <Translate value="rent" />;
        break;
      default:
        KindCommerce = <Translate value="sale" />;
        break;
    }
    if (this.props.item.Condition.Id === "NEW")
      condition = <Translate value="ad_new" />;
    if (this.props.item.Condition.Id === "USED")
      condition = <Translate value="ad_used" />;
    if (this.props.item.Condition.Id === "SERVICE")
      condition = <Translate value="ad_service" />;

    return (
      <div className="col-lg-3  mb-4">
        <div className="card  h-100">
          <Link
            to={`/goder/show/${this.props.item.Id}`}
            onClick={this.props.onClick}
          >
            <img
              className="card-img-top img-responsive"
              src={this.props.item.Photo}
              alt="Godiway_img"
            />
          </Link>
          <div className="goder-body">
            <div className="goder-title text-center">
              <Link
                to={`/goder/show/${this.props.item.Id}`}
                onClick={this.props.onClick}
                style={{ textDecoration: "none" }}
              >
                {this.props.item.Name}
              </Link>
            </div>

            <div className="goder-row">
              <div className="">
                <small>{KindCommerce}</small>
                <span>&nbsp;</span>
              </div>
              <div className="">
                <i>
                  <strong>
                    {" "}
                    {this.props.item.Price}&nbsp;
                    <small>{this.props.item.Currency}</small>{" "}
                  </strong>
                </i>
              </div>
            </div>
            <div className="goder-row">
              <div className="">
                <small>{condition}</small>
                <span>&nbsp;</span>
              </div>
              <div className="">
                <small>{this.props.item.CreatedAt}</small>
                <span>&nbsp;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class AdSearchList extends React.Component {
  onClick = (item) => {
    this.props.SetAd(item);
  };

  render() {
    if (this.props.pageCount === 0)
      return (
        <h3>
          <Translate value="ad_search_not_list" />
        </h3>
      );

    return (
      <div className="row">
        {this.props.adList.map((item) => {
          return (
            <AdListItem
              item={item}
              key={item.Id}
              onClick={() => {
                this.onClick(item);
              }}
            />
          );
        })}
      </div>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    adList: reducer.adSearch.list,
    pageCount: reducer.adSearch.page_count,
  };
}
const mapDispatchToProps = (dispatch) => ({
  SetAd: (item) => dispatch(actions.setAd({ item })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdSearchList);
