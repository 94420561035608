import React from "react";
//import ReactDOM from 'react-dom';
//import Modal from "./Modal";
import ReactDOM from "react-dom";
import { Translate } from "react-redux-i18n";
import "./styles/Modal.css";
//onClose={props.onClose}
function ModalLoading(props) {
  if (!props.loading) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="modal">
      <div className="modal-loading-content">
        <div className="modal-loading">Loading</div>
      </div>
    </div>,
    document.getElementById("modal")
  );
}

export default ModalLoading;
/**
 *  <div className="modal-footer">
        <div className="col-12 text-center">
          <button className="btn btn-primary" onClick={props.onClose}>
            <i className="fa fa-close"></i> <Translate value="ok" />
          </button>
        </div>
      </div>
       <div className="modal-body">
        <img className="modal-img" src={props.image} alt="modal-img" />
      </div>
      <div className="modal-header">
            <h4 className="modal-title">{props.title}</h4>
            <button
              type="button"
              onClick={props.onClose}
              className="modal_close-button"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-content">
          <div className="modal-body">{props.children}</div>
        </div>
 */
