import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import {
  FaLocationArrow,
  FaEllipsisH,
  FaComments,
  FaCamera,
  FaShareAlt,
  //FaComment,
  FaThumbsDown,
  FaThumbsUp,
  FaRegThumbsUp,
  FaRegThumbsDown
} from "react-icons/fa";

import "./styles/CommentCenter.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/adCommentActions";
import HeadComment from "../components/HeadComment";
import CommentBtnRight from "./CommentBtnRight";
import YoutubeEmbed from "./YoutubeEmbed";
import MiniLoader from "./MiniLoader";
import ModalGalery from "../components/ModalGalery";
import Api from "../utils/api";

function getEmbedId(url) {
  const array = url.split(' ');
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  for (let index = 0; index < array.length; index++) {
    var element = array[index];
    if (element.indexOf("https://") >= 0) {
      var match = element.match(regExp);
      if (match && match[7].length == 11) {
        return match[7];
      } else {
        return false;
      }
    }
  }
}

class ComentItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="cardbox shadow-lg bg-white">
          <div className="cardbox-heading">
            <CommentBtnRight
              item={this.props.comment}
              onClickRemove={this.props.onClickRemove}
              onClickReport={this.props.onClickReport}
            />
            <div className="media m-0">
              <div className="d-flex mr-3">
                <Link to="#">
                  <img
                    className="img-fluid rounded-circle"
                    src={this.props.comment.Avatar}
                    alt="User"
                  />
                </Link>
              </div>
              <div className="media-body">
                {this.props.comment.HasStore ? (
                  <Link to={`/marketstore/show/${this.props.comment.Store.Id}`}>
                    <p className="m-0">{this.props.comment.Name}</p>
                  </Link>
                ) : (
                  <Link to="#">
                    <p className="m-0">{this.props.comment.Name}</p>
                  </Link>
                )}

                {
                  Object.keys(this.props.comment.Ad).length !== 0 && this.props.comment.Ad.State !== "" && (
                    <small>
                      <span>
                        <i className="icon ion-md-pin">
                          {
                            (this.props.comment.Ad.State,
                              this.props.comment.Ad.City)
                          }
                          <i className="icon ion-md-time"></i>{" "}
                        </i>
                      </span>
                    </small>
                  )
                }
                <small>
                  <span>
                    <i className="icon ion-md-time"></i>{" "}
                    {this.props.comment.CreatedAt}
                  </span>
                </small>
              </div>
            </div>
            {
              getEmbedId(this.props.comment.Text) && (<YoutubeEmbed embedId={getEmbedId(this.props.comment.Text)} />)
            }
            {this.props.comment.Text !== "" && (
              <span className="" style={{ "whiteSpace": "pre-wrap" }}>
                {this.props.comment.Text} </span>

            )}

            {
              Object.keys(this.props.comment.Ad).length !== 0 && this.props.comment.Ad.Name !== "" && (
                <span className="">
                  {this.props.comment.Ad.Name} </span>
              )
            }
          </div>
          <div className="cardbox-item">
            {this.props.comment.HasMedia && (
              <div className="text-center">
                <Link to={`/galery`}>
                  <img
                    src={this.props.comment.Media}
                    className="img-fluid"
                    style={{ "width": "100%" }}
                    alt="image"
                  />
                </Link>
              </div>
            )}
            {this.props.comment.Kind === "PRODUCT" && (
              <React.Fragment>
                <small className="cardbox-comment">
                  <span> {this.props.comment.Ad.Name} </span>
                </small>
                <div className="text-center">
                  <Link to={`/goder/show/${this.props.comment.Ad.Id}`}>
                    <img
                      src={this.props.comment.Ad.Photo}
                      className="img-fluid"
                      alt="GW"
                    />
                  </Link>
                </div>
              </React.Fragment>
            )}
            {
              this.props.comment.Ad !== {} && this.props.comment.Ad.Id !== undefined && (
                <React.Fragment>
                  <div className="text-center">
                    <Link to={`/goder/show/${this.props.comment.Ad.Id}`}>
                      <img
                        src={this.props.comment.Ad.Photo}
                        className="img-fluid"
                        style={{ "width": "100%" }}
                        alt="image"
                      />
                    </Link>
                  </div>
                </React.Fragment>
              )}
          </div>
          <div className="cardbox-base">
            <ul className="float-right">
              <li>
                <a>
                  <em className="mr-3">
                    {" "}
                    {this.props.comment.CountSubComment}
                  </em>
                </a>
              </li>

              <li>
                <Link to="#">
                  <em className="mr-3">
                    {this.props.comment.CountShare}
                  </em>
                </Link>
              </li>
              <li>
                <Link to="#" data-toggle="modal" data-target="#product_share">
                  <i>
                    <FaShareAlt />
                  </i>
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link to="#">
                  <em className="mr-3">
                    <small>{this.props.comment.CountLike}</small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.props.onClickUp}
                >
                  <i>
                    {this.props.comment.Like === 1 ? (
                      <FaThumbsUp />
                    ) : (
                      <FaRegThumbsUp />
                    )}
                  </i>
                </button>
              </li>
              <li>
                <Link to="#">
                  <em className="mr-3">
                    <small>{this.props.comment.CountUnlike}</small>
                  </em>
                </Link>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={this.props.onClickDown}
                >
                  <i>
                    {this.props.comment.Like === 0 ? (
                      <FaThumbsDown />
                    ) : (
                      <FaRegThumbsDown />
                    )}
                  </i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class CommentCenter extends React.Component {
  state = {
    comment_text: "",
    disabled: true,
    file: "",
    hasFile: false,
    loading: false
  };

  componentDidMount() { }

  onClickSendComment = async () => {

    if (this.state.comment_text.length > 0) {
      let params = new FormData();
      if (this.state.hasFile) {
        params.append("image", Api.compressImage(this.state.file));
      }
      if (this.state.comment_text !== "") {
        params.append("comment", this.state.comment_text);
      }
      params.append("comment_id", this.props.commentItem.Id);
      //params.append("token", this.props.token);
      params.append("id", this.props.user_id);

      await this.props
        .AddComment(this.props.token, params)
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          } else this.setState({ comment_text: "" });
        });
    }
  };

  onChangeComment = event => {
    const comment = event.target.value;
    this.setState({ comment_text: comment });
    if (comment.lenght === 0) this.setState({ disabled: true });
    else this.setState({ disabled: false });
  };



  onClickUp = async (item) => {
    if (item.Like === 1)
      await this.props
        .RemoveLike(this.props.token, {
          comment_id: item.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    else
      await this.props
        .UpdateLike(this.props.token, {
          comment_id: item.Id,
          status: "1",
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
  };



  onClickDown = async (item) => {
    if (item.Like === 0)
      await this.props
        .RemoveLike(this.props.token,
          {
            comment_id: item.Id,
            id: this.props.user_id,
            lang: I18n.t("locale"),
          })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
    else
      await this.props
        .UpdateLike(this.props.token, {
          comment_id: item.Id,
          status: "'0'",
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error) {
            //alertify.warning(this.props.error);
          }
        });
  };

  onClickReportComment = async (item) => {

    await this.props
      .ReportComment(this.props.token, {
        comment_id: item.Id,
        id: this.props.user_id,
        lang: I18n.t("locale"),
      })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
        else
          alertify.success(I18n.t('success'));
      });
  };

  onClickRemoveComment = async (item) => {
    await this.props
      .RemoveComment(this.props.token,
        {
          comment_id: item.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
      .then(() => {
        if (this.props.error) {
          //alertify.warning(this.props.error);
        }
      });
  };

  onClickLoadMore = async () => {
    try {
      await this.props
        .LoadWallMore(this.props.token, {
          index: this.props.pageCount,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        })
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-comment">
          <HeadComment
          // onClickRemove={this.onClickRemoveComment}
          // onClickReport={this.onClickReportComment}
          />
          <div className="card-footer">
            <div className="input-group">
              <div className="input-group-append">
                <input
                  accept="image/*"
                  id="open_input_img"
                  type="file"
                  name="open_img"
                  className="attach_file"
                  onChange={(e) => {
                    this.onChangeImage(e);
                  }}
                />
                <label
                  htmlFor="open_input_img"
                  className="input-group-text attach_btn"
                >
                  <span>
                    <FaCamera />
                  </span>
                </label>

              </div>
              <textarea
                name="comment_msg"
                className="form-control type_msg"
                placeholder={I18n.t("comment_placeholder")}
                value={this.state.comment_text}
                onChange={this.onChangeComment}
              ></textarea>
              <div className="input-group-append">
                <button
                  className="input-group-text send_btn"
                  disabled={this.state.disabled}
                  onClick={() => {
                    this.onClickSendComment();
                  }}
                >
                  <FaLocationArrow />{" "}
                </button>
              </div>
            </div>
            {this.state.hasFile && (
              <div className="text-center">
                <img
                  src={this.state.image}
                  alt="img-coment"
                  className="img-responsive"
                />
              </div>
            )}

          </div>
          {this.state.loading && <MiniLoader />}
          {this.props.commentList.map(item => {
            return (
              <ComentItem
                comment={item}
                key={item.Id}
                onClickUp={() => {
                  this.onClickUp(item);
                }}
                onClickDown={() => {
                  this.onClickDown(item);
                }}

              // onClickRemove={() => {
              //this.onClickRemoveComment(item);
              // }}
              // onClickReport={() => {
              // this.onClickReportComment(item);
              //  }}
              />
            );
          })}

          {
            this.props.pageSize > 6 && (
              this.props.loadingMore ? (
                <div className="text-center">
                  <MiniLoader />
                </div>
              ) :
                (
                  <div className="text-center">
                    <button
                      className="btn btn-secondary"
                      onClick={() => {
                        this.onClickLoadMore();
                      }}
                    >
                      <Translate value="load_more" />
                    </button>
                  </div>
                )
            )
          }
          <ModalGalery />
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    commentList: reducer.adComment.list,
    error: reducer.adComment.error,
    loading: reducer.adComment.loading,

    commentItem: reducer.adComment.item,

    loadingMore: reducer.adComment.loadingMore,
    pageSize: reducer.adComment.page_size,
    pageCount: reducer.adComment.page_count,
  };
}
const mapDispatchToProps = dispatch => ({
  AddComment: (token, params) =>
    dispatch(actions.addComment({ token, params })),
  RemoveLike: (token, params) =>
    dispatch(actions.removeLike({ token, params })),
  UpdateLike: (token, params) =>
    dispatch(actions.updateLike({ token, params })),

  ReportComment: (token, params) =>
    dispatch(actions.reportComment({ token, params })),
  RemoveComment: (token, params) =>
    dispatch(actions.removeComment({ token, params })),

  LoadWallMore: (token, params) =>
    dispatch(actions.loadListScroll({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentCenter);
