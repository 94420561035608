import React from "react";
//import ReactDOM from 'react-dom';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";

function StepAddress(props) {
  return (
    <React.Fragment>
      <div className="">
        <div className="row">
          <div className="col-12 text-center">
            <span
              className=""
              style={{
                fontSize: "18px",
                fontWeight: "bold ",
                color: "gray",
              }}
            >
              <Translate value="order_data" />
            </span>
          </div>
        </div>

        <input
          type="text"
          name="name"
          className="form-control"
          placeholder={I18n.t("profile_name")} //"Name and Last Name"
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.email}
        />

        <input
          type="text"
          name="address"
          className="form-control"
          placeholder={I18n.t("profile_address1")}
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.email}
        />

        <input
          type="text"
          name="phone"
          className="form-control"
          placeholder={I18n.t("profile_phone")}
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.email}
        />
        <input
          className="form-control "
          type="text"
          name="city"
          placeholder={I18n.t("profile_city")}
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.city}
        />

        <input
          className="form-control "
          type="text"
          name="state"
          placeholder={I18n.t("profile_state")}
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.state}
        />

        <input
          type="text"
          name="zipcode"
          className="form-control"
          placeholder={I18n.t("profile_zipcode")}
          autoComplete={"off"}
          onChange={props.onChange}
          value={props.form.email}
        />
      </div>
    </React.Fragment>
  );
}

export default StepAddress;
