import {
    FAQ_LIST_ANSWER_ATTEMPT,
    FAQ_LIST_ANSWER_SUCCESS,
    FAQ_LIST_ANSWER_FAILED,
    //////////////////////
    FAQ_SET_ANSWER_SUCCESS,
    FAQ_SET_ANSWER_FAILED,
    ///////////////////////
    FAQ_MORELIST_ANSWER_ATTEMPT,
    FAQ_MORELIST_ANSWER_SUCCESS,
    FAQ_MORELIST_ANSWER_FAILED
} from "../types/faqAnswerTypes";

const INITIAL_STATE = {

    loading: false,
    list: [],
    error: "",
    ///////////

    page_size: 0,
    page_count: 0,
    loadingMore: false
};

function faqAnswer(state = INITIAL_STATE, action) {
    switch (action.type) {

        case FAQ_LIST_ANSWER_ATTEMPT:
            return {
                ...state,
                loading: true,
                list: []
            };

        case FAQ_LIST_ANSWER_SUCCESS:
            return {
                ...state,
                loading: false,
                list: action.data.list,
                page_size: action.data.size,
                page_count: action.data.size,
                error: ""
            };

        case FAQ_LIST_ANSWER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
                list: []
            };
        ///////////////////////////////////
        case FAQ_SET_ANSWER_SUCCESS: {
            const newState = state.list.map(item => {
                if (item.Id === action.item.Id) return action.item;
                else return item;
            });
            return {
                ...state,
                list: newState,
                error: ""
            };
        }

        case FAQ_SET_ANSWER_FAILED:
            return {
                ...state,
                error: action.error
            };
        ////////////////////////////////////
        case FAQ_MORELIST_ANSWER_ATTEMPT:
            return {
                ...state,
                loadingMore: true,
            };

        case FAQ_MORELIST_ANSWER_SUCCESS:
            return {
                ...state,
                loadingMore: false,
                list: state.list.concat(action.data.list),
                page_size: action.data.size,
                page_count: state.page_count + action.data.size,
                error: ""
            };
        case FAQ_MORELIST_ANSWER_FAILED:
            return {
                ...state,
                loadingMore: false,
            }
        default:
            return state;
    }
}
export default faqAnswer;
