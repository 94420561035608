import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/marketstoreCategoriesActions";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

class CategoryItem extends React.Component {
  render() {
    return (
      <p>
        <Link to="#">
          <small>{this.props.item.Name}</small>
        </Link>
      </p>
    );
  }
}
class StoreInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: [],
    };
  }

  componentDidMount() {
    this.fetchCategoriesMarketStore();
  }

  fetchCategoriesMarketStore = async () => {
    await this.props
      .LoadCategoriesMarketStore(
        this.props.token,
        {
          store_id: this.props.marketstore.Id,
          id: this.props.user_id,
          lang: I18n.t("locale"),
        }
      )
      .then(() => {
        if (this.props.error) {
          //alertify.warning(I18n.t(this.props.error));
        }
      });
  };

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="col-sm-12">
            <div className="text-center">
              <h4>
                <Translate value="summary" />
              </h4>
            </div>
            <input
              type="text"
              className="form-control"
              name="searcher"
              placeholder={I18n.t("search")}
              maxLength="80"
              //value={this.state.search_text}
              //onChange={this.onChange}
              autoComplete={"off"}
              style={{ "marginTop": "10px", "marginBottom": "10px" }}
            />
            <span>
              Total <Translate value="store_ads" /> :{"   "}
              {this.props.adList.length}
            </span>

            <h6 className="text-left  ">
              <Translate value="ad_category" />
            </h6>

            <div>
              {this.props.categories.map((item) => {
                return (
                  <CategoryItem
                    item={item}
                    key={item.Id}
                    onClick={() => {
                      this.onClick(item);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    marketstore: reducer.marketstore.item,

    adList: reducer.marketstoreAdList.list,

    loading: reducer.marketstoreCategories.loading,
    categories: reducer.marketstoreCategories.list,
    error: reducer.marketstoreCategories.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCategoriesMarketStore: (token, params) =>
    dispatch(actions.loadList({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreInformation);
