import {
  FAQ_LIST_QUESTION_ATTEMPT,
  FAQ_LIST_QUESTION_SUCCESS,
  FAQ_LIST_QUESTION_FAILED,
  ////////////////////////
  FAQ_MORELIST_QUESTION_SUCCESS,
  FAQ_MORELIST_QUESTION_ATTEMPT,
  ////////////////////////

} from "../types/faqQuestionTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  ///////////

  page_size: 0,
  page_count: 0,
  loadingMore: false,

};

function faqQuestion(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FAQ_LIST_QUESTION_ATTEMPT:
      return {
        ...state,
        loading: true,
        list: []
      };

    case FAQ_LIST_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: ""
      };

    case FAQ_LIST_QUESTION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    //////////////////////////////////
    case FAQ_MORELIST_QUESTION_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };

    case FAQ_MORELIST_QUESTION_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };


    default:
      return state;
  }
}
export default faqQuestion;
