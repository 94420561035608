export const MARKETSTORE_ITEM_ATTEMPT = "MARKETSTORE_ITEM_ATTEMPT";
export const MARKETSTORE_ITEM_SUCCESS = "MARKETSTORE_ITEM_SUCCESS";
export const MARKETSTORE_ITEM_FAILED = "MARKETSTORE_ITEM_FAILED";

export const MARKETSTORE_UPDATE_ATTEMPT = "MARKETSTORE_UPDATE_ATTEMPT";
export const MARKETSTORE_UPDATE_SUCCESS = "MARKETSTORE_UPDATE_SUCCESS";
export const MARKETSTORE_UPDATE_FAILED = "MARKETSTORE_UPDATE_FAILED";

export const SET_SELECTED_MARKETSTORE = "SET_SELECTED_MARKETSTORE";
export const CLEAR_MARKETSTORE = "CLEAR_MARKETSTORE";

export const LOGOUT = "LOGOUT";

