import React from "react";
import "./styles/RegisterSuccessfull.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Translate } from "react-redux-i18n";
//import api from "../api";

class PasswordResetSucessfull extends React.Component {
  componentDidMount() {
    //this.fetchActivateAccount();
  }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-sucessfull">
            <section className="successfull">
              <div className="icon">
                <svg width="100%" height="100%">
                  <g
                    stroke-width="5"
                    stroke="#3EA4EB"
                    fill="none"
                    fill-rule="evenodd"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M24.7529297,41.4525637 34.2547154,50.9543494 55,30.2090647"
                      stroke-dasharray="42.77581787109375"
                      stroke-dashoffset="0"
                    ></path>
                  </g>
                </svg>
              </div>
              <span className="h1">
                <h1 className="initial">
                  <Translate value="godiway_wellcome" />
                </h1>
              </span>
              <span className="p">
                <h3 className="initial">
                  <Translate value="godiway_activated_email" />
                </h3>
                <p className="initial">
                  <Link to="/login" className="btn btn-primary">
                    {" "}
                    <Translate value="login" />{" "}
                  </Link>
                </p>
              </span>
            </section>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PasswordResetSucessfull;
