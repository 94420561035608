export const MESSAGE_LIST_ATTEMPT = "MESSAGE_LIST_ATTEMPT";
export const MESSAGE_LIST_SUCCESS = "MESSAGE_LIST_SUCCESS";
export const MESSAGE_LIST_FAILED = "MESSAGE_LIST_FAILED";
//////////////////////////////////////////
export const MESSAGE_MORELIST_ATTEMPT = "MESSAGE_MORELIST_ATTEMPT";
export const MESSAGE_MORELIST_SUCCESS = "MESSAGE_MORELIST_SUCCESS";
export const MESSAGE_MORELIST_FAILED = "MESSAGE_MORELIST_FAILED";
//////////////////////////////////////////
export const MESSAGE_STORE_SUCCESS = "MESSAGE_STORE_SUCCESS";
export const MESSAGE_STORE_FAILED = "MESSAGE_STORE_FAILED";
//////////////////////////////////////////////
export const RECEIVE_MESSAGE = "RECEIVE_MESSAGE";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CONFIRM_MESSAGE_READ = "CONFIRM_MESSAGE_READ";
export const CONFIRM_MESSAGE_RECEIVE = "CONFIRM_MESSAGE_RECEIVE";
////////////////////////////////////////////
export const UPDATE_CONTACT_MESSAGE = "UPDATE_CONTACT_MESSAGE";
export const CLEAR_CONTACT_MESSAGE = "CLEAR_CONTACT_MESSAGE";

export const LOGOUT = "LOGOUT";
