import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/AboutGodiway.css";
import intercambios from "../assets/intercambios.svg";
import donaciones from "../assets/donaciones.svg";
import ventarenta from "../assets/venta-renta.svg";
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// const about_title_es = "Acerca de Godiway";
// const about_detail_es = "Somos una comunidad de usuarios alrededor del mundo que, con el favor de Dios, buscamos ayudar a personas que más lo necesitan.";
// const about_donation_es = "Donaciones";
// const about_donation_detail_es = "Publica lo que quieras donar y encuentra personas que lo necesitan.";
// const about_exchange_es = "Intercambio";
// const about_exchange_detail_es = "Anuncia los productos que desees intercambiar con otros personas.";
// const about_rent_es = "Renta";
// const about_rent_detail_es = "Puedes anunciar y encontrar usuarios que renten sus productos o servicios.";
// const about_sale_es = "Ventas";
// const about_sale_detail_es = "Anuncia tus productos y servicios de manera sencilla y rápida.";

// const about_title_en = "About Godiway";
// const about_detail_en = "We are a community of users around the world who, with God's favor, seek to help people who need it most.";
// const about_donation_en = "Donations";
// const about_donation_detail_en = "Post what you want to donate and find people who need it.";
// const about_exchange_en = "Exchange";
// const about_exchange_detail_en = "Advertise the products you want to exchange with other people.";
// const about_rent_en = "Rent";
// const about_rent_detail_en = "You can advertise and find users who rent your products or services.";
// const about_sale_en = "Sales";
// const about_sale_detail_en = "Advertise your products and services easily and quickly.";


// const about_title_he = "על גודיוויי";
// const about_detail_he = "אנחנו קהילה של משתמשים ברחבי העולם, שבעזרת אלוהים מבקשים לעזור לאנשים הזקוקים לכך ביותר.";
// const about_donation_he = "תרומות.";
// const about_donation_detail_he = "פרסם את מה שאתה רוצה לתרום ומצא אנשים שצריכים את זה.";
// const about_exchange_he = "לְהַחלִיף.";
// const about_exchange_detail_he = "פרסם את המוצרים שאתה רוצה להחליף עם אנשים אחרים.";
// const about_rent_he = "השכרה.";
// const about_rent_detail_he = "אתה יכול לפרסם ולמצוא משתמשים שמשכירים את המוצרים או השירותים שלך.";
// const about_sale_he = "מכירות";
// const about_sale_detail_he = "פרסם את המוצרים והשירותים שלך בקלות ובמהירות.";


// const text_es = {
//     title: title_es,
//     detail: detail_es,
//     donation: donation_es,
//     donation_detail: donation_detail_es,
//     exchange: exchange_es,
//     exchange_detail: exchange_detail_es,
//     rent: rent_es,
//     rent_detail: rent_detail_es,
//     sale: sale_es,
//     sale_detail: sale_detail_es
// };
// const text_en = {
//     title: title_en,
//     detail: detail_en,
//     donation: donation_en,
//     donation_detail: donation_detail_en,
//     exchange: exchange_en,
//     exchange_detail: exchange_detail_en,
//     rent: rent_en,
//     rent_detail: rent_detail_en,
//     sale: sale_en,
//     sale_detail: sale_detail_en
// };
// const text_he = {
//     title: title_he,
//     detail: detail_he,
//     donation: donation_he,
//     donation_detail: donation_detail_he,
//     exchange: exchange_he,
//     exchange_detail: exchange_detail_he,
//     rent: rent_he,
//     rent_detail: rent_detail_he,
//     sale: sale_he,
//     sale_detail: sale_detail_he
// };

class AboutGodiway extends React.Component {

    state = {
        text: []
    };
    componentDidMount() {

        // switch (this.props.lang) {
        //     case "es": {
        //         this.setState({
        //             text: text_es
        //         });
        //     } break;

        //     case "en": {
        //         this.setState({
        //             text: text_en
        //         });
        //     } break;

        //     case "he": {
        //         this.setState({
        //             text: text_he
        //         });
        //     } break;

        //     default: {
        //         this.setState({
        //             text: text_en
        //         });
        //     }
        // }
    }

    render() {
        return (
            <div className="container_about" style={{
                // paddingInline: "250px",
                // background: "white"
            }}>
                <div className="container_about_title" style={{
                    // textAlign: "center",
                    // paddingTop: "70px",
                    // paddingBottom: "40px",
                    // paddingInline: "250px"
                }}>
                    <h1 className="">
                        <Translate value="about_title" />
                    </h1>
                    <span style={{ fontSize: "larger" }}><Translate value="about_detail" />  </span>
                </div>
                <div className="" style={{
                    display: "flex",
                    justifyContent: "space-Evenly"
                }}>
                    <div >
                        <div style={{ display: "flex", marginBlock: "25px" }}>
                            <img src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/about_godiway/icono_donation.png" className="icon_about"
                                alt="front1"
                            // style={{ height: "70px", width: "70px", marginRight: "30px" }}
                            />
                            <div>
                                <h2 className="">

                                    <Translate value="about_donation" />
                                </h2>
                                <span style={{ fontSize: "larger" }}>   <Translate value="about_donation_detail" /></span>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginBlock: "25px" }}>
                            <img src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/about_godiway/icono_exchange.png" className="icon_about"
                                alt="front1"
                            // style={{ height: "70px", width: "70px", marginRight: "30px" }}
                            />
                            <div>
                                <h2 className="">
                                    <Translate value="about_exchange" />
                                </h2>
                                <span style={{ fontSize: "larger" }}>     <Translate value="about_exchange_detail" /></span>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginBlock: "25px" }}>
                            <img src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/about_godiway/icono_sale.png" className="icon_about"
                                alt="front1"
                            // style={{ height: "70px", width: "70px", marginRight: "30px" }}
                            />
                            <div>
                                <h2 className="">
                                    <Translate value="about_rent" />
                                </h2>
                                <span style={{ fontSize: "larger" }}>    <Translate value="about_rent_detail" /></span>
                            </div>
                        </div>
                        <div style={{ display: "flex", marginBlock: "25px" }}>
                            <img src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/about_godiway/icono_rent.png" className="icon_about"
                                alt="front1"
                            // style={{ height: "70px", width: "70px", marginRight: "30px" }}
                            />
                            <div>
                                <h2 className="">
                                    <Translate value="about_sale" />
                                </h2>
                                <span style={{ fontSize: "larger" }}>    <Translate value="about_sale_detail" /></span>
                            </div>
                        </div>
                    </div>

                    <div >
                        <img src="https://godiwayusev.s3.us-east-1.amazonaws.com/front_end/about_godiway/about_godiway.png" className="img_about"
                            alt="front1"
                        // style={{ minHeight: "200px", height: "600px", width: "700px", position: "relative", top: "-70px", left: "80px" }}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

//export default ;

function mapStateToProps(reducer) {
    return {
        lang: reducer.i18n.locale
    };
}
export default connect(mapStateToProps, null)(AboutGodiway);