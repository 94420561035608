import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
//import * as actions from '../redux/actions/eventActions';
import LayoutOut from "../components/LayoutOut";
import LayoutIn from "../components/LayoutIn";

import HomeOut from "../pages/HomeOut";
import HomeIn from "../pages/HomeIn";
import NotFound from "../pages/NotFound";
import Login from "../pages/Login";
import Register from "../pages/Register";
import PasswordForget from "../pages/PasswordForget";
import PasswordForgetSend from "../pages/PasswordForgetSend";
import Ad from "../pages/Ad";

import Panel from "../pages/Panel";
import Wall from "../pages/Wall";
import Profile from "../pages/Profile";
import ProfileEdit from "../pages/ProfileEdit";
import ProfilePassword from "../pages/ProfilePassword";
import ProfilePrivacy from "../pages/ProfilePrivacy";
import ProfileData from "../pages/ProfileData";
import Wish from "../pages/Wish";
import Contacts from "../pages/Contacts";
import Faq from "../pages/Faq";
import MarketStores from "../pages/MarketStores";
import MarketStore from "../pages/MarketStore";
import PanelMarketStore from "../pages/PanelMarketStore";
import PanelEditMarketStore from "../pages/PanelEditMarketStore";
import AdMenu from "../pages/AdMenu";
import AdNew from "../pages/AdNew";
import PanelEditAd from "../pages/PanelEditAd";
import Notification from "../pages/Notification";
import TermOfCondition from "../pages/TermOfCondition";
import PrivacyPolitics from "../pages/PrivacyPolitics";
import Chat from "../pages/Chat";

import Transaction from "../pages/Transaction";
import TransactionRequest from "../pages/TransactionRequest";

import PanelSales from "../pages/PanelSales";
import PanelRents from "../pages/PanelRents";
import PanelExchanges from "../pages/PanelExchanges";
import PanelDonations from "../pages/PanelDonations";
import PanelAuction from "../pages/PanelAuction";

import HomeSales from "../pages/HomeSales";
import HomeRents from "../pages/HomeRents";
import HomeExchanges from "../pages/HomeExchanges";
import HomeDonations from "../pages/HomeDonations";
import HomeAuction from "../pages/HomeAuction";

import AdSearchIn from "../pages/AdSearch";
import AdSearchOut from "../pages/AdSearch";
import Logout from "../pages/Logout";
import MySuscription from "../pages/MySuscription";
import Customer from "../pages/Customer";
import Cards from "../pages/Cards";
import PanelOrder from "../pages/PanelOrder";
import CommentTransactionAccepted from "../pages/CommentTransactionAccepted";
import CommentTransactionRejected from "../pages/CommentTransactionRejected";

import RegisteSuccessfull from "../pages/RegisteSuccessfull";

import Feedback from "../pages/Feedback";

import TransactionInfo from "../pages/TransactionInfo";
import TransactionBid from "../pages/TransactionBid";
import WhatIsDonation from "../pages/WhatIsDonation";
import WhatIsExchange from "../pages/WhatIsExchange";
import WhyDonationExchange from "../pages/WhyDonationExchange";
import GodiwayEmail from "../pages/GodiwayEmail";
import Pricing from "../pages/Pricing";
import OnePay from "../pages/OnePay";
import SuscriptionPayPanel from "../pages/SuscriptionPayPanel";
import SuscriptionPay from "../pages/SuscriptionPay";
import ContactMe from "../pages/ContactMe";

import Galery from "../pages/Galery";
import Comment from "../pages/Comment";

import AdNewGalery from "../pages/AdNewGalery";

import GodiwayActivate from "../pages/GodiwayActivate";

import Country from "../pages/Country";

import OnePayPanel from "../pages/OnePayPanel";

import PasswordReset from "../pages/PasswordReset";

import PasswordResetSuccessfull from "../pages/PasswordResetSuccessfull";

import CustomerEdit from "../pages/CustomerEdit";

import TempRedirectMarketStore from "../pages/TempRedirectMarketStore";
//import PdfOrder from "../pages/PdfOrder";
import TempRedirectMySuscription from "../pages/TempRedirectMySuscription";
import PayStepProgress from "../pages/PayStepProgress";

import PaypalMobile from "../pages/PaypalMobile";
import PaypalSuccess from "../pages/PaypalSuccess";
import PaypalWrong from "../pages/PaypalWrong";

import FaqMe from "../pages/FaqMe";

import TempRedirectChat from "../pages/TempRedirectChat";

import OrderPdf from "../pages/OrderPdf";

const hist = createBrowserHistory();

class App extends React.Component {
  componentDidMount() {

  }
  render() {
    //let url = window.location.href;

    return (
      <BrowserRouter history={hist} /*basename="/gw"*/>
        <Switch>
          <Route exact path="/mobile/paypal/success" component={PaypalSuccess} />
          <Route exact path="/mobile/paypal/wrong" component={PaypalWrong} />
          <Route exact path="/mobile/paypal/:key" component={PaypalMobile} />
          <Route
            exact
            path="/orderview/:order_id"
            component={OrderPdf}
          />

          {JSON.stringify(this.props.user) === "{}" ? (
            <LayoutOut>
              <Switch>
                <Route exact path="/" component={HomeOut} />
                <Route exact path="/home" component={HomeOut} />

                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/password_forget" component={PasswordForget} />
                <Route exact path="/faq_me" component={FaqMe} />
                <Route
                  exact
                  path="/password_reset_successfull"
                  component={PasswordResetSuccessfull}
                />
                <Route
                  exact
                  path="/register_successfull"
                  component={RegisteSuccessfull}
                />
                <Route
                  exact
                  path="/password_forget_send"
                  component={PasswordForgetSend}
                />

                <Route
                  exact
                  path="/home/terms_condition"
                  component={TermOfCondition}
                />
                <Route
                  exact
                  path="/home/privacy_politics"
                  component={PrivacyPolitics}
                />
                <Route exact path="/feedback" component={Feedback} />
                <Route
                  exact
                  path="/what_is_donation"
                  component={WhatIsDonation}
                />
                <Route
                  exact
                  path="/what_is_exchange"
                  component={WhatIsExchange}
                />
                <Route
                  exact
                  path="/why_donation_exchange"
                  component={WhyDonationExchange}
                />
                <Route
                  exact
                  path="/pay/stepprogress"
                  component={PayStepProgress}
                />
                <Route exact path="/goder/search" component={AdSearchOut} />
                <Route exact path="/email_information" component={GodiwayEmail} />
                <Route exact path="/pricing" component={Pricing} />

                <Route exact path="/contact_me" component={ContactMe} />
                <Route exact path="/goder/show/:goder_id" component={Ad} />
                <Route
                  exact
                  path="/password_reset/:token"
                  component={PasswordReset}
                />

                <Route
                  exact
                  path="/goder/search/:search_text"
                  component={AdSearchOut}
                />

                <Route
                  exact
                  path="/activate/account/:token"
                  component={GodiwayActivate}
                />

                <Route component={Login} />
              </Switch>
            </LayoutOut>
          ) : (
            <LayoutIn  >
              <Switch>

                <Route exact path="/" component={HomeIn} />
                <Route exact path="/home" component={HomeIn} />

                <Route exact path="/goder/search" component={AdSearchIn} />
                <Route exact path="/goder/menu" component={AdMenu} />

                <Route exact path="/panel" component={Panel} />
                <Route exact path="/wall" component={Wall} />
                <Route exact path="/wish" component={Wish} />
                <Route exact path="/contacts" component={Contacts} />

                <Route exact path="/profile" component={Profile} />
                <Route exact path="/profile/edit" component={ProfileEdit} />
                <Route exact path="/marketstores" component={MarketStores} />
                <Route exact path="/faq_me" component={FaqMe} />

                <Route
                  exact
                  path="/panel/marketstore"
                  component={PanelMarketStore}
                />
                <Route
                  exact
                  path="/pay/stepprogress"
                  component={PayStepProgress}
                />
                <Route
                  exact
                  path="/panel/marketstore/edit"
                  component={PanelEditMarketStore}
                />
                <Route exact path="/faq" component={Faq} />

                <Route exact path="/notification/all" component={Notification} />
                <Route
                  exact
                  path="/profile/password"
                  component={ProfilePassword}
                />
                <Route exact path="/profile/privacy" component={ProfilePrivacy} />
                <Route exact path="/profile/more_data" component={ProfileData} />

                <Route exact path="/transaction" component={Transaction} />

                <Route
                  exact
                  path="/transaction/request"
                  component={TransactionRequest}
                />

                <Route exact path="/panel/sales" component={PanelSales} />
                <Route exact path="/panel/rents" component={PanelRents} />
                <Route exact path="/panel/exchanges" component={PanelExchanges} />
                <Route exact path="/panel/donations" component={PanelDonations} />
                <Route exact path="/panel/auction" component={PanelAuction} />

                <Route exact path="/home/sales" component={HomeSales} />
                <Route exact path="/home/rents" component={HomeRents} />
                <Route exact path="/home/exchanges" component={HomeExchanges} />
                <Route exact path="/home/donations" component={HomeDonations} />
                <Route exact path="/home/auction" component={HomeAuction} />

                <Route
                  exact
                  path="/home/terms_condition"
                  component={TermOfCondition}
                />
                <Route
                  exact
                  path="/home/privacy_politics"
                  component={PrivacyPolitics}
                />
                <Route exact path="/logout" component={Logout} />
                <Route exact path="/mysuscription" component={MySuscription} />
                <Route exact path="/cardlist" component={Cards} />
                <Route exact path="/paylist" component={PanelOrder} />
                <Route exact path="/customer" component={Customer} />
                <Route
                  exact
                  path="/commenttransaction/accepted"
                  component={CommentTransactionAccepted}
                />
                <Route
                  exact
                  path="/commenttransaction/rejected"
                  component={CommentTransactionRejected}
                />
                <Route
                  exact
                  path="/transaction/info"
                  component={TransactionInfo}
                />
                <Route
                  exact
                  path="/transaction/bid"
                  component={TransactionBid}
                />
                <Route
                  exact
                  path="/what_is_donation"
                  component={WhatIsDonation}
                />
                <Route
                  exact
                  path="/what_is_exchange"
                  component={WhatIsExchange}
                />
                <Route
                  exact
                  path="/why_donation_exchange"
                  component={WhyDonationExchange}
                />


                <Route exact path="/email_information" component={GodiwayEmail} />
                <Route exact path="/pricing" component={Pricing} />
                <Route exact path="/onepay" component={OnePay} />
                <Route exact path="/suscriptionpay" component={SuscriptionPay} />
                <Route exact path="/contact_me" component={ContactMe} />
                <Route exact path="/galery" component={Galery} />
                <Route exact path="/goder_new_galery" component={AdNewGalery} />
                <Route exact path="/country" component={Country} />
                <Route exact path="/panel/onepay" component={OnePayPanel} />
                <Route
                  exact
                  path="/panel/suscriptionpay"
                  component={SuscriptionPayPanel}
                />
                <Route
                  exact
                  path="/panel/redirectmysuscription"
                  component={TempRedirectMySuscription}
                />

                <Route exact path="/customer/edit" component={CustomerEdit} />
                <Route exact path="/comment/:comment_id" component={Comment} />
                <Route exact path="/goder/show/:goder_id" component={Ad} />
                <Route exact path="/chat/:conversation_id" component={Chat} />
                <Route
                  exact
                  path="/panel/goder/edit/:kindCommerce_id"
                  component={PanelEditAd}
                />
                <Route
                  exact
                  path="/goder/search/:search_text"
                  component={AdSearchIn}
                />

                <Route exact path="/goder/new/:kindCommerce_id" component={AdNew} />
                <Route
                  exact
                  path="/marketstore/show/:store_id"
                  component={MarketStore}
                />
                <Route
                  exact
                  path="/temp/marketstore/:store_id"
                  component={TempRedirectMarketStore}
                />
                <Route exact path="/chat_conversation/:product_id" component={TempRedirectChat}></Route>

                <Route component={NotFound} />
              </Switch>
            </LayoutIn>
          )}
        </Switch>
      </BrowserRouter>
    );
  }
}
/**
 * 
 * @param {   <Route exact path="/passwordForget" component={PasswordForget} />} reducer 
 * @returns 
 */
const mapStateToProps = (reducer) => {
  return {
    // HasUser: reducer.session.hasUser,
    user: reducer.session.auth,
    lang: reducer.configuration.lang,
  };
};


export default connect(mapStateToProps, null)(App);

// //<Route exact path="/pdforder" component={PdfOrder} />
/**
 * <Route exact path="/transaction/rents" component={TransactionRents} />
                    <Route exact path="/transaction/exchanges" component={TransactionExchanges} />
                    <Route exact path="/transaction/donations" component={TransactionDonations} />
 <Route exact path='/auction' component={Auction} />


                    <Route exact path="/transaction/rents" component={TransactionRents} />
                    <Route exact path="/transaction/exchanges" component={TransactionExchanges} />
                    <Route exact path="/transaction/donations" component={TransactionDonations} />
 */
