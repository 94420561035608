import { combineReducers } from "redux";
import {
  DESTROY_SESSION,
} from "./types/sessionType";
import session from "./reducers/session";
import notification from "./reducers/notification";
import ad from "./reducers/ad";
import adFaq from "./reducers/adFaq";
import adComment from "./reducers/adComment";
import adList from "./reducers/adList";
import adRelation from "./reducers/adRelation";
import adSearch from "./reducers/adSearch";
import wish from "./reducers/wish";
import contacts from "./reducers/contacts";
import faqQuestion from "./reducers/faqQuestion";
import faqAnswer from "./reducers/faqAnswer";
import marketstore from "./reducers/marketstore";
import marketstoreList from "./reducers/marketstoreList";
import marketstoreAdList from "./reducers/marketstoreAdList";
import wall from "./reducers/wall";
import publish from "./reducers/publish";
import transactionPending from "./reducers/transactionPending";
import transactionFinish from "./reducers/transactionFinish";
import transactionRequestPending from "./reducers/transactionRequestPending";
import transactionRequestFinish from "./reducers/transactionRequestFinish";
import message from "./reducers/message";
import country from "./reducers/country";
import user from "./reducers/user";
import state from "./reducers/state";
import city from "./reducers/city";
import adSales from "./reducers/adSales";
import adSalesInactive from "./reducers/adSalesInactive";
import adRents from "./reducers/adRents";
import adRentsInactive from "./reducers/adRentsInactive";
import adExchange from "./reducers/adExchange";
import adDonation from "./reducers/adDonation";
import configuration from "./reducers/configuration";
import adReport from "./reducers/adReport";
import adCategory from "./reducers/adCategory";
import marketstoreFollowers from "./reducers/marketstoreFollowers";
import transactionAccepted from "./reducers/transactionAccepted";
import transactionRejected from "./reducers/transactionRejected";
import adSellerReputation from "./reducers/adSellerReputation";
import summaryByUser from "./reducers/summaryByUser";
import summaryByCountry from "./reducers/summaryByCountry";
import privacy from "./reducers/privacy";
import media from "./reducers/media";
import { i18nReducer } from "react-redux-i18n";
import adFilter from "./reducers/adFilter";
import customer from "./reducers/customer";
import orders from "./reducers/orders";
import cards from "./reducers/cards";
import suscriptionPlan from "./reducers/suscriptionPlan";
import video from "./reducers/video";
import marketstoreCategories from "./reducers/marketstoreCategories";
import marketstoreSearch from "./reducers/marketstoreSearch";
import marketstoreComment from "./reducers/marketstoreComment";
import adBidder from "./reducers/adBidder";
import blogCarousel from "./reducers/blogCarousel";
import event from "./reducers/event";
import chat from "./reducers/chat";
import pripol from "./reducers/pripol";
import tos from "./reducers/tos";
import marketstoreExtraList from "./reducers/marketstoreExtraList";
import adAuction from "./reducers/adAuction";
import adAuctionInactive from "./reducers/adAuctionInactive";
import adSlider from "./reducers/adSlider";

import { persistReducer } from 'redux-persist';
//import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

//const rootPersistConfig = { key: 'root', storage, whitelist: ['cart', 'user'] };
const authPersistConfig = { key: 'auth', storage: storageSession };

const appReducer = combineReducers({
  ad,
  adAuction,
  adAuctionInactive,
  adBidder,
  adCategory,
  adComment,
  adDonation,
  adExchange,
  adFaq,
  adFilter,
  adList,
  adRelation,
  adRents,
  adRentsInactive,
  adReport,
  adSearch,
  adSales,
  adSalesInactive,
  adSellerReputation,
  adSlider,
  blogCarousel,
  cards,
  chat,
  city,
  configuration,
  contacts,
  country,
  customer,
  event,
  faqAnswer,
  faqQuestion,
  i18n: i18nReducer,
  marketstore,
  marketstoreAdList,
  marketstoreCategories,
  marketstoreComment,
  marketstoreExtraList,
  marketstoreFollowers,
  marketstoreList,
  marketstoreSearch,
  media,
  message,
  notification,
  orders,
  pripol,
  privacy,
  publish,
  session: persistReducer(authPersistConfig, session),
  state,
  summaryByCountry,
  summaryByUser,
  suscriptionPlan,
  tos,
  transactionPending,
  transactionFinish,
  transactionAccepted,
  transactionRejected,
  transactionRequestFinish,
  transactionRequestPending,
  user,
  video,
  wall,
  wish,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) {
    //state = undefined;
    //storage.removeItem('persist:root');
    //state = {};
    //action = {};
    //
  }

  return appReducer(state, action);
};

export default rootReducer;
