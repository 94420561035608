import React from "react";
//import ReactDOM from 'react-dom';
import { Translate, I18n } from "react-redux-i18n";
import logo_g from "../assets/ops.png";
import { Link } from "react-router-dom";
class PaypalWrong extends React.Component {
    render(){
        return (
    
           <React.Fragment>
              <div style={{display:"flex",justifyContent:"center",alignItems:"center", height:"100vh"}}>
              <div className="card">
                <div className="text-center" >
               
                  <img src={logo_g} alt="Godiway" className="img-responsive" />
                </div>  
                <br />
              <br />
        
              <br />
              <div className="row justify-content-center">
                

                <div className="row justify-content-center">
                  <div className="col-7 text-center">
                    <h5 className=" text-center">
                      <Translate value="uncongratulations" />
                    </h5>
                  </div>
                </div>
              </div>
              </div>
          
              </div>

            </React.Fragment>
          );
    }
  
}
export default PaypalWrong;