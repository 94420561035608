import React from "react";
//import { Link } from 'react-router-dom';

import "./styles/AdSectionLeft.css";
import AdGalery from "./AdGalery";
import AdLike from "./AdLike";
import AdTab from "./AdTab";

class AdSectionLeft extends React.Component {
  render() {
    return (
      <section className="Timeline-ColLeft">
        <AdGalery />
        <AdLike />
        <div className="container-goder-tab">
          <AdTab />
        </div>
      </section>
    );
  }
}
export default AdSectionLeft;
