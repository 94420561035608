export const FAQ_LIST_QUESTION_ATTEMPT = "FAQ_LIST_QUESTION_ATTEMPT";
export const FAQ_LIST_QUESTION_SUCCESS = "FAQ_LIST_QUESTION_SUCCESS";
export const FAQ_LIST_QUESTION_FAILED = "FAQ_LIST_QUESTION_FAILED";
///////////////////////////

export const FAQ_MORELIST_QUESTION_SUCCESS = "FAQ_MORELIST_QUESTION_SUCCESS";
export const FAQ_MORELIST_QUESTION_ATTEMPT = "FAQ_MORELIST_QUESTION_ATTEMPT";
export const FAQ_MORELIST_QUESTION_FAILED = "FAQ_MORELIST_QUESTION_FAILED";


export const LOGOUT = "LOGOUT";