import {
  ORDER_LIST_ATTEMPT,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_FAILED,
  ////////////////////
  ORDER_MORELIST_ATTEMPT,
  ORDER_MORELIST_SUCCESS,
  ORDER_MORELIST_FAILED,
  ////////////////////
  ORDER_SET_ITEM
} from "../types/ordersTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  item: {},
  error: "",

  loadingMore: false,
  page_index: 0,
  page_size: 0,
  page_count: 0,
};

function orders(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ORDER_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case ORDER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_index: action.data.page,
        page_size: action.data.size,
        page_count: action.data.size,
        error: ""
      };

    case ORDER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };
    //////////////////////////
    case ORDER_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      }

    case ORDER_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        page_index: action.data.page,
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        list: state.list.concat(action.data.list)
      }

    case ORDER_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
        //errorMore: action.error,
      }
    /////////////////////////
    case ORDER_SET_ITEM: {
      return {
        ...state,
        item: action.item,
        //errorMore: action.error,
      }
    }
    default:
      return state;
  }
}
export default orders;
