import React from "react";
import "./styles/Pricing.css";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Translate } from "react-redux-i18n";
import * as actions from "../redux/actions/suscriptionPlanActions";
import { connect } from "react-redux";
class Pricing extends React.Component {
  componentDidMount() { }
  onClickPlan = (value) => {
    this.props.SetPlanValue(value);
    this.props.history.push("/pay/stepprogress");
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-pricing">
            <div className="pricing-header  ">
              <h1 className="">Godiway</h1>
              <p className="lead">
                <Translate value="pay_subtitle" />.
              </p>
            </div>
            <div className="">
              <div className="card-deck mb-3 text-center">
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">
                      {" "}
                      <Translate value="free" />
                    </h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">
                      $0{" "}
                      <small className="text-muted">
                        /<Translate value="ad_month" />
                      </small>
                    </h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <Translate value="one_pay6" />
                      </li>
                      <li>
                        <Translate value="one_pay7" />
                      </li>
                      <li>
                        <Translate value="one_pay3" />
                      </li>
                      <li>
                        <Translate value="one_pay5" />
                      </li>
                    </ul>
                    <Link
                      to="/register"
                      className="btn btn-lg btn-block btn-outline-primary"
                      disabled=""
                    >
                      <Translate value="register" />
                    </Link>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">
                      <Translate value="one_pay" />
                    </h4>
                  </div>
                  <div className="card-body">
                    <h1 className="card-title pricing-card-title">$15 USD</h1>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <Translate value="one_pay2" />
                      </li>
                      <li>
                        <Translate value="one_pay4" />
                      </li>
                      <li>
                        <Translate value="one_pay3" />
                      </li>
                      <li>
                        <Translate value="one_pay5" />
                      </li>
                    </ul>
                    <button
                      // to="/onepay"
                      //to="/mysuscription"
                      onClick={() => {
                        this.onClickPlan(15);
                      }}
                      className="btn btn-lg btn-block btn-primary"
                    >
                      <Translate value="ad_buy" />
                    </button>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">
                      <Translate value="pay_basic" />
                    </h4>{" "}
                    <small className="text-muted">
                      <Translate value="suscription_mine" />
                    </small>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title">
                      $10 USD{" "}
                      <small className="text-muted">
                        / <Translate value="ad_month" />
                      </small>
                    </h3>
                    <h3 className="card-title pricing-card-title">
                      $100 USD{" "}
                      <small className="text-muted">
                        / <Translate value="ad_year" />
                      </small>
                    </h3>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <Translate value="suscription2" />
                      </li>
                      <li>MarketStore</li>
                      <li>
                        <Translate value="suscription3" />
                      </li>
                      <li>
                        <Translate value="suscription4" />
                      </li>
                    </ul>
                    <button
                      // to="/suscriptionpay"
                      onClick={() => {
                        this.onClickPlan(10);
                      }}
                      className="btn btn-lg btn-block btn-primary"
                    >
                      <Translate value="suscription_subscribe_month" />
                    </button>
                    <button
                      // to="/suscriptionpay"
                      onClick={() => {
                        this.onClickPlan(100);
                      }}
                      className="btn btn-lg btn-block btn-primary"
                    >
                      <Translate value="suscription_subscribe_year" />
                    </button>
                  </div>
                </div>
                <div className="card mb-4 box-shadow">
                  <div className="card-header">
                    <h4 className="my-0 font-weight-normal">
                      <Translate value="pay_advance" />
                    </h4>
                    <small className="text-muted">
                      <Translate value="suscription_mine" />
                    </small>
                  </div>
                  <div className="card-body">
                    <h3 className="card-title pricing-card-title">
                      $20 USD{" "}
                      <small className="text-muted">
                        / <Translate value="ad_month" />
                      </small>
                    </h3>
                    <h3 className="card-title pricing-card-title">
                      $200 USD{" "}
                      <small className="text-muted">
                        /<Translate value="ad_year" />
                      </small>
                    </h3>
                    <ul className="list-unstyled mt-3 mb-4">
                      <li>
                        <Translate value="suscription7" />
                      </li>
                      <li>MarketStore</li>
                      <li>
                        <Translate value="suscription3" />
                      </li>
                      <li>
                        <Translate value="suscription4" />
                      </li>
                    </ul>
                    <button
                      //to="/suscriptionpay"
                      onClick={() => {
                        this.onClickPlan(20);
                      }}
                      className="btn btn-lg btn-block btn-primary"
                    >
                      <Translate value="suscription_subscribe_month" />
                    </button>
                    <button
                      //to="/suscriptionpay"
                      onClick={() => {
                        this.onClickPlan(200);
                      }}
                      className="btn btn-lg btn-block btn-primary"
                    >
                      <Translate value="suscription_subscribe_year" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h5 class="  text-center">
              <Translate value="note" />
              : <Translate value="pricing_note1" />
              <Translate value="pricing_note2" />
              <br />
              {/* <b>
                <Translate value="pricing_note3" />
              </b> */}
            </h5>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {};
}

const mapDispatchToProps = (dispatch) => ({
  SetPlanValue: (value) => dispatch(actions.setPlanValue({ value })),
});
export default //withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Pricing);
