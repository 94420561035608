import React, { Component } from "react";
//import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
//import './styles/Home.css';
import { connect } from "react-redux";

class StoreSuscription extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="col-sm-12">
            <div className="text-center">
              <h4>
                {" "}
                <Translate value="suscription_mine" />
              </h4>
            </div>
            {

              this.props.lang === "es" ? (
                <React.Fragment>
                  <p><strong>Conoce nuestras promociones, nuevos anuncios,rebajas y descuentos.</strong></p>
                </React.Fragment>
              )
                :
                (
                  <React.Fragment>
                    <strong>Know our promotions, new ads, Sales discounts.</strong>
                  </React.Fragment>
                )

            }

            <div>
              <div className="mc-field-group">

                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                ></input>
              </div>
              <div className="text-center mt-10" style={{ marginTop: "10px" }}>
                <button
                  name="subscribe"
                  className="btn btn-primary"
                  disabled=""
                >
                  <Translate value="suscription_subscribe" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    lang: reducer.i18n.locale
  };
}
export default connect(mapStateToProps, null)(StoreSuscription);
