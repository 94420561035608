import React, { Component } from "react";
//import { Link } from "react-router-dom";

//import './styles/Home.css';

import PublishVertical from "./PublishVertical";
import StoreSuscripction from "./StoreSuscripction";
//import api from '../api';

class NotificationRight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: [],
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    // if(this.state.isLogin) onClose={props.onCloseModal}
    return (
      <React.Fragment>
        <StoreSuscripction />
        <PublishVertical />
      </React.Fragment>
    );
  }
}

export default NotificationRight;
