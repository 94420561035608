import React from "react";

import "./styles/Profile.css";
import ProfileLayout from "../components/ProfileLayout";
import { connect } from "react-redux";
import * as actions_state from "../redux/actions/stateActions";
import * as actions_city from "../redux/actions/cityActions";
import * as actions_session from "../redux/actions/sessionActions";
import { FaSave } from "react-icons/fa";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { Translate, I18n } from "react-redux-i18n";

class ProfileData extends React.Component {
  state = {
    form: { state: "0" },
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  async componentDidMount() {
    await this.props.LoadAll(this.props.user_id, this.props.token);
    if (this.props.profile)
      this.setState({
        form: {
          state: this.props.profile.State && this.props.profile.State.Id, //address
          city: this.props.profile.City && this.props.profile.City.Id, //address
          address1: this.props.profile.Address && this.props.profile.Address.Address1, //address
          address2: this.props.profile.Address && this.props.profile.Address.Address2, //address
          address3: this.props.profile.Address && this.props.profile.Address.Address3, //address
          phone: this.props.profile.Address && this.props.profile.Address.PhoneHome, //address
          gender: this.props.profile.Gender,
          ocupation: this.props.profile.Ocupation,
          birthday: this.props.profile.Birthday || '',//new Date(),
        },
      });

    this.fetchLoadState();

  }

  fetchLoadCity = async () => {


    await this.props
      .LoadCities(this.state.state, this.props.user_id,)
      .then(() => {
        if (this.props.error_city) {
          // alert(this.props.error_city);
          alertify.warning(I18n.t(this.props.error_city));
        }
      });
  };

  onChangeCity = async (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeValue = async (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  onClickSave = async (field_name, field_value) => {
    try {
      await this.props
        .EditUser(this.props.token, {
          field_name: field_name,
          field_value: field_value,
          id: this.props.user_id,
        })
        .then(() => {
          if (this.props.error_update) {
            alertify.warning(this.props.error_update);
          } else {
            alertify.success(I18n.t("saved_succesfull"));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  fetchLoadState = async () => {
    try {
      await this.props
        .LoadStates(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error_state) {
            // alert(this.props.error_state);
            alertify.warning(I18n.t(this.props.error_state));
          }
          else {
            if (this.state.state && this.state.state.Id) this.fetchLoadCity();
          }

        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeState = async (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });

    await this.props
      .LoadCities(e.target.value, this.props.user_id, this.props.token)
      .then(() => {
        if (this.props.error_city) {
          //alert(this.props.error_city);
          alertify.warning(I18n.t(this.props.error_city));
        }
      });
  };

  render() {
    return (
      <React.Fragment>
        <ProfileLayout>
          <div className="col-sm-12 col-md-12 col-lg-12  card bg-light">
            <div className="row">
              <div className="col-md-12 text-center mt10">
                <h2>
                  <Translate value="profile_data" />
                </h2>
                <hr className="dividerGray" />
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-10">
                      <Translate value="profile_state" />
                      <select
                        className="form-control"
                        onChange={this.onChangeState}
                        name="state"
                        value={this.state.form.state}
                      >
                        <option value="0">{I18n.t("profile_select")}</option>
                        {this.props.stateList.map((state) => {
                          return (
                            <option key={state.key} value={state.key}>
                              {state.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave("state_id", this.state.form.state);
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <Translate value="profile_city" />
                        <img id="img_loading" src="" />
                        <div>
                          <select
                            className="form-control chosen"
                            name="city"
                            value={this.state.form.city}
                            onChange={this.onChangeCity}
                          >
                            <option value="">{I18n.t("profile_select")}</option>
                            {this.props.cityList.map((city) => {
                              return (
                                <option key={city.key} value={city.key}>
                                  {city.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave("city_id", this.state.form.city);
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <Translate value="profile_address1" />
                        <input
                          name="address1"
                          type="text"
                          className="form-control"
                          placeholder={I18n.t("profile_address1")}
                          value={this.state.form.address1}
                          onChange={this.onChangeValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave(
                            "address1",
                            this.state.form.address1
                          );
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <Translate value="profile_address2" />
                        <div className="input-group">
                          <input
                            className="form-control"
                            name="address2"
                            type="text"
                            placeholder={I18n.t("profile_address2")}
                            value={this.state.form.address2}
                            onChange={this.onChangeValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave(
                            "address2",
                            this.state.form.address2
                          );
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                    <div className="col-md-10">
                      <div className="form-group">
                        <Translate value="profile_address3" />
                        <div className="input-group">
                          <input
                            className="form-control"
                            name="address3"
                            type="text"
                            placeholder={I18n.t("profile_address3")}
                            value={this.state.form.address3}
                            onChange={this.onChangeValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave(
                            "address3",
                            this.state.form.address3
                          );
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-10">
                      <div className="form-group">
                        <Translate value="profile_phone_home" />
                        <input
                          type="text"
                          className="form-control"
                          value={this.state.form.phone}
                          placeholder={I18n.t("profile_phone_home")}
                          name="phone"
                          onChange={this.onChangeValue}
                        />
                      </div>
                    </div>
                    <div className="col-md-1 text-center">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave("phone", this.state.form.phone);
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                    <div className="col-md-10">
                      <div className="form-group">
                        <label className="control-label">
                          {" "}
                          <Translate value="profile_ocupation" />{" "}
                        </label>
                        <div className="input-group">
                          <input
                            name="ocupation"
                            className="form-control"
                            placeholder={I18n.t("profile_ocupation")}
                            value={this.state.form.ocupation}
                            onChange={this.onChangeValue}
                          />
                        </div>
                        <span className="help-block"></span>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave(
                            "ocupation",
                            this.state.form.ocupation
                          );
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <Translate value="profile_birthday" />
                      <input
                        type="date"
                        className="form-control"
                        name="birthday"
                        placeholder={I18n.t("profile_birthday")}
                        value={this.state.form.birthday}
                        onChange={this.onChangeValue}
                        min="1900-01-01"
                        max="2022-12-31"
                      />
                      <span className="help-block"></span>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave(
                            "birthday",
                            this.state.form.birthday
                          );
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-10">
                      <Translate value="profile_gender" />
                      <select
                        className="form-control chosen"
                        name="gender"
                        onChange={this.onChangeValue}
                        value={this.state.form.gender}
                      >
                        <option selected="selected" value="">
                          {I18n.t("profile_select")}
                        </option>
                        <option value="MALE">{I18n.t("profile_male")}</option>
                        <option value="FEMALE">
                          {I18n.t("profile_female")}
                        </option>
                      </select>
                      <span className="help-block"></span>
                    </div>
                    <div className="col-md-2">
                      <br />
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          this.onClickSave("gender", this.state.form.gender);
                        }}
                      >
                        <FaSave />
                      </button>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </ProfileLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer.user.item);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    stateList: reducer.state.list,
    error_state: reducer.state.error,
    loading_state: reducer.state.loading,

    cityList: reducer.city.list,
    error_city: reducer.city.error,
    loading_city: reducer.city.loading,

    profile: reducer.session.auth,
    error: reducer.session.error,
  };
}

const mapDispatchToProps = (dispatch) => ({
  LoadStates: (user_id, token) =>
    dispatch(actions_state.loadList({ user_id, token })),
  LoadCities: (state_id, user_id, token) =>
    dispatch(actions_city.loadList({ state_id, user_id, token })),
  ///////////////
  EditUser: (token, params) =>
    dispatch(
      actions_session.updatefield({ token, params })//field_name, field_value, user_id,
    ),
  LoadAll: (user_id, token) =>
    dispatch(
      actions_session.loadItemAll({ user_id, token })//field_name, field_value, user_id,
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileData);
