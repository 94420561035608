export const MARKETSTORE_COMMENT_ATTEMPT = "MARKETSTORE_COMMENT_ATTEMPT";
export const MARKETSTORE_COMMENT_SUCCESS = "MARKETSTORE_COMMENT_SUCCESS";
export const MARKETSTORE_COMMENT_FAILED = "MARKETSTORE_COMMENT_FAILED";

export const MARKETSTORE_ADD_COMMENT_SUCCESS = "MARKETSTORE_ADD_COMMENT_SUCCESS";
export const MARKETSTORE_ADD_COMMENT_FAILED = "MARKETSTORE_ADD_COMMENT_FAILED";

export const MARKETSTORE_REMOVE_COMMENT_SUCCESS = "MARKETSTORE_REMOVE_COMMENT_SUCCESS";
export const MARKETSTORE_REMOVE_COMMENT_FAILED = "MARKETSTORE_REMOVE_COMMENT_FAILED";

export const MARKETSTORE_REPORT_COMMENT_SUCCESS = "MARKETSTORE_REPORT_COMMENT_SUCCESS";
export const MARKETSTORE_REPORT_COMMENT_FAILED = "MARKETSTORE_REPORT_COMMENT_FAILED";

export const MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS = "MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS";
export const MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED = "MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED";

export const COMMENT_SET_ITEM = "COMMENT_SET_ITEM";


export const LOGOUT = "LOGOUT";