import axios from "axios";

import {
  COUNTRY_LIST_ATTEMPT,
  COUNTRY_LIST_SUCCESS,
  COUNTRY_LIST_FAILED,
  //////////////////
  COUNTRY_UPDATE_SUCCESS,
  COUNTRY_UPDATE_FAILED,
  ///////////
} from "../types/countryTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: COUNTRY_LIST_ATTEMPT
  };
}

export function success(data) {
  return {
    type: COUNTRY_LIST_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: COUNTRY_LIST_FAILED,
    error: error
  };
}
//////////////////////////////
export function successUpdate(success) {
  return {
    type: COUNTRY_UPDATE_SUCCESS,
    success: success,
  };
}

export function failedUpdate(error) {
  return {
    type: COUNTRY_UPDATE_FAILED,
    error: error,
  };
}


export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "country", {
      params: {}
    })
    .then(function (response) {
      //console.log(response.data.body);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      //console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};

// export const updateCountry = (data: Object) => async (dispatch) => {

//   await axios
//     .put(url_api + "country", data.params, {
//       headers: { Authorization: `Bearer ${data.token}` },
//     })
//     .then(function (response) {
//       //console.log(response);
//       if (response.status < 300) {
//         if (response.data.status === "Success")
//           dispatch(successUpdate(""));
//         else dispatch(failedUpdate("try_later"));
//       } else {
//         dispatch(failedUpdate("try_later"));
//       }
//     })
//     .catch(function (error) {
//       console.log(error.message);
//       if (error.response) {
//         dispatch(failedUpdate("try_later"));
//       } else {
//         dispatch(failedUpdate("try_later"));
//       }
//     });
//   return;
// };