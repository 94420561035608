

export const TRANSACTIONF_LIST_ATTEMPT = "TRANSACTIONF_LIST_ATTEMPT";
export const TRANSACTIONF_LIST_SUCCESS = "TRANSACTIONF_LIST_SUCCESS";
export const TRANSACTIONF_LIST_FAILED = "TRANSACTIONF_LIST_FAILED";

export const TRANSACTIONF_MORELIST_ATTEMPT = "TRANSACTIONF_MORELIST_ATTEMPT";
export const TRANSACTIONF_MORELIST_SUCCESS = "TRANSACTIONF_MORELIST_SUCCESS";
export const TRANSACTIONF_MORELIST_FAILED = "TRANSACTIONF_MORELIST_FAILED";

export const LOGOUT = "LOGOUT";