import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { FaComments, FaCartArrowDown } from "react-icons/fa";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/DropDownNotification.css";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

import * as actions from "../redux/actions/chatActions";
import * as actions_contacts from "../redux/actions/contactsActions";
import * as actions_message from "../redux/actions/messageActions";
import * as actions_event from "../redux/actions/eventActions";

import MiniLoader from "./MiniLoader";


function DropDownChatItem(props) {

  // route = `/chat/${this.props.item.TempId}`;
  return (
    <Link className="nav-link" to={`/chat/${props.item.ConversationId}`} onClick={props.onClick}>
      <li className="nav-item " >
        <div className="media">
          <div className="media-left">
            <div className="media-object">
              <img
                src={props.item.Avatar}
                className="notification-img-circle"
                alt="50x50"
              />
            </div>
          </div>
          <div className="notification-media-body">
            <div className="notification-title">
              <span>{props.item.Name}{" "}{props.item.LastN}</span>
              {
                props.item.MessageCount > 0 && <div className="notification-list-badget ">{props.item.MessageCount}</div>
              }

            </div>
            <div className="notification-meta">
              <small className="notification-detail">
                {props.item.Message.Text}
              </small>
              <small className="timestamp">
                {props.item.Message.CreatedAt}
              </small>

            </div>
          </div>
        </div>
      </li>
    </Link>
  );
}


class DropDownChat extends React.Component {

  state = {
    displayMenu: false
  };

  // constructor() {
  //   super();

  //   // this.showDropdownMenu = this.showDropdownMenu.bind(this);
  //   // this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
  //   this.changeDropdownMenu = this.changeDropdownMenu.bind(this);
  // }

  componentDidMount() {
    // this.fetchChatList();
  }

  fetchChatList = async () => {
    try {
      await this.props
        .LoadChat(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  changeDropdownMenu(event) {
    event.preventDefault();
    clearInterval(this.timer);
    this.setState({ displayMenu: !this.state.displayMenu });
    if (!this.state.displayMenu) {
      this.fetchChatList();
    }
  }

  // showDropdownMenu(event) {
  //   event.preventDefault();
  //   this.setState({ displayMenu: true }, () => {
  //     document.addEventListener("click", this.hideDropdownMenu);
  //     this.fetchChatList();
  //   });
  // }

  // hideDropdownMenu() {
  //   this.setState({ displayMenu: false }, () => {
  //     document.removeEventListener("click", this.hideDropdownMenu);
  //   });
  // }

  onClickReadAll = () => {
    this.setState({ displayMenu: false });
    if (this.props.notificationCount > 0)
      this.props.SetNotificationReadAll(this.props.user_id, this.props.token);
  };

  onClickViewChat = item => {
    this.setState({ displayMenu: false });
    this.props.SetContact(item);
    this.props.SetContactMessage(item);
    this.props.SetContactEvent(item);
    this.props.ClearBadgetContact(item);
  };

  lostBlur = (event) => {
    // event.preventDefault();
    this.timer = setInterval(() => {
      this.setState({ displayMenu: false });
    }, 200);
  }

  render() {
    return (
      <React.Fragment>
        <div className="dropdown_notification">
          <div className="button" tabIndex={0} id={"dropdown_chat"} onClick={this.changeDropdownMenu.bind(this)} onBlur={this.lostBlur} >
            <FaComments />
            {this.props.chatCount > 0 && (
              <span className="badge">{this.props.chatCount}</span>
            )}
          </div>

          {this.state.displayMenu ? (
            <ul>
              <div className="dropdown-container">
                <div className="dropdown-head">
                  <div className="dropdown-title">
                    <span>Chat</span>
                  </div>
                </div>

                <div className="dropdown-divider"></div>
                {
                  this.props.loading ?
                    (<MiniLoader />)
                    : (
                      this.props.chatList.map(item => {
                        return (
                          <DropDownChatItem
                            key={item.Id}
                            item={item}
                            onClick={() => {
                              this.onClickViewChat(item);
                            }}
                          />
                        )
                      })
                    )
                }
                <div className="dropdown-divider"></div>
                <div className="dropdown-footer text-center">
                  <Link className="dropdown-item" to="/contacts/" onClick={this.lostBlur}>
                    <Translate value="notification_see_all" />
                  </Link>
                </div>
              </div>
            </ul>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
//<hr className="dividerGray" />
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    user: reducer.session.auth,

    chatCount: reducer.contacts.tab_badget_chat,

    chatList: reducer.chat.list,
    error: reducer.chat.error,
    loading: reducer.chat.loading
  };
}

const mapDispatchToProps = dispatch => ({
  LoadChat: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
  ////////////
  SetContact: (item) => dispatch(actions_contacts.setContact(item)),
  ClearBadgetContact: item => dispatch(actions_contacts.clearBadgetContact({ item })),
  ///////////////
  SetContactMessage: (item) =>
    dispatch(actions_message.setContactMessage(item)),
  //////////////
  SetContactEvent: (item) =>
    dispatch(actions_event.setContactEvent(item)),
});



export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownChat);


/**
  <small>
  <Translate value="notification_mark_all" />{" "}
  </small>
    onClick={() => {
    this.onClickReadAll();
  }}
  <button
    className="btn btn-link"
  >
  </button>

  <div className="notification-count">
                    <small>
                      <Translate value="conversations" /> (
                      {this.props.chatCount})
                    </small>
                  </div>
 */