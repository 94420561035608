import axios from "axios";

import {
  USER_ITEM_ATTEMP,
  USER_ITEM_SUCCESS,
  USER_ITEM_FAILED,
  ///////////////
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILED,
  ////////////////////////
  USER_AVATAR_SUCCESS,
  USER_AVATAR_FAILED,
  USER_AVATAR_ATTEMP,
  /////////////////
  ///////////////////////
} from "../types/userTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: USER_ITEM_ATTEMP,
  };
}

export function success(item) {
  return {
    type: USER_ITEM_SUCCESS,
    item: item,
  };
}

export function failed(error) {
  return {
    type: USER_ITEM_FAILED,
    error: error,
  };
}

///////////////////////////////////
export function successUpdate(item) {
  return {
    type: USER_UPDATE_SUCCESS,
    item: item,
  };
}
export function failedUpdate(error) {
  return {
    type: USER_UPDATE_FAILED,
    error: error,
  };
}
///////////////////////////////////

///////////////////////////////////
export function loadingAvatar(progress) {
  return {
    type: USER_AVATAR_ATTEMP,
    progress: progress
  };
}
export function successAvatar(item) {
  return {
    type: USER_AVATAR_SUCCESS,
    item: item,
  };
}

export function failedAvatar(error) {
  return {
    type: USER_AVATAR_FAILED,
    error: error,
  };
}
///////////////////////////////////


//////////////////SET////////////////

export const loadItem = (data: Object) => async (dispatch) => {
  dispatch(loading());
  await axios
    .get(url_api + "user", {
      params: {
        id: data.user_id,
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      // console.log(response);
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      console.log(error);
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};



