import React, { Component } from "react";
import "./styles/Home.css";
import HomeSlider from "../components/HomeSlider";
import AdSlider from "../components/AdSlider";
import MarketStoreBanner from "../components/MarketStoreBanner";
import HowToWork from "../components/HowToWork";
import Footer from "../components/Footer";
import JoinWinAll from "../components/JoinWinAll";
import Modal from "../components/Modal";
import { connect } from "react-redux";
import * as actions_adList from "../redux/actions/adListActions";
import * as actions_Slider from "../redux/actions/adSliderActions";
import HomeKindCommerce from "../components/HomeKindCommerce";
import DonwloadApp from "../components/DownloadApp";
import BlogCarousel from "../components/BlogCarousel";
import AboutGodiway from "../components/AboutGodiway";
//import api from '../api';
//import { Translate } from "react-redux-i18n";
//import PublishVertical from "../components/PublishVertical";

class HomeOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: true,
      nextLoad: 0,
      loading: true,
      error: null,
      data: []
    };
  }

  componentDidMount() {
    this.fetchAdList();
  }

  fetchAdList = async () => {
    try {
      if (this.props.auth !== undefined && Object.keys(this.props.auth).length !== 0)
        await this.props.LoadAdHomeIn(this.props.user_id, this.props.token);
      else await this.props.LoadAdHomeOut();
    } catch (error) {
      console.log(error);
    }
  };
  /*
  componentDidUpdate(prevProps, prevState) {
    console.log('5. componentDidUpdate()');
    console.log({
      prevProps: prevProps,
      prevState: prevState,
    });

    console.log({
      props: this.props,
      state: this.state,
    });
  }

  <div className="col-lg-3">
            {translate("locale")}
            <PublishVertical />
          </div>

          container-fluid
          Home-out
          col-lg-12
*/
  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <HomeSlider />
        <div className="">
          <div className="">
            <div className=" ">
              <AdSlider />
              <AboutGodiway />
            </div>
          </div>
          <HowToWork />
          <MarketStoreBanner />
          <BlogCarousel />
        </div>
        <DonwloadApp />
        <Footer />
        <Modal isOpen={false} />
      </React.Fragment>
    );
  }
}
// <JoinWinAll />
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    auth: reducer.session.auth,

    adList: reducer.ad.list,
    error: reducer.ad.error,
    loading: reducer.ad.loading,
    // hasUser: reducer.session.hasUser
  };
}
const mapDispatchToProps = dispatch => ({
  LoadAdHomeIn: (user_id, token) =>
    dispatch(actions_adList.loadList({ user_id, token })),
  LoadAdHomeOut: () => dispatch(actions_Slider.loadList({}))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOut);
