import {
  ADCOMMENT_LIST_ATTEMPT,
  ADCOMMENT_LIST_SUCCESS,
  ADCOMMENT_LIST_FAILED,
  ///////////////////////
  ADCOMMENT_MORE_ATTEMPT,
  ADCOMMENT_MORE_SUCCESS,
  ADCOMMENT_MORE_FAILED,
  ////////////////////
  ADCOMMENT_NEW_ATTEMPT,
  ADCOMMENT_NEW_SUCCESS,
  ADCOMMENT_NEW_FAILED,
  ///////////////////////////
  ADCOMMENT_LIKE_SUCCESS,
  ADCOMMENT_LIKE_FAILED,
  //////////////////////////
  COMMENT_SET_ITEM,
  /////////////////////////
  ADCOMMENT_DELETE_SUCCESS,
  ADCOMMENT_DELETE_FAILED,
  ///////////////////////////
  REPORT_COMMENT_SUCCESS,
  REPORT_COMMENT_FAILED,
} from "../types/adCommentTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  error_update: "",
  item: {},
  error_remove: "",
  error_report: "",

  loadingMore: false,
  page_count: 0,
  page_size: 0
};

function adComment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADCOMMENT_LIST_ATTEMPT:
      return {
        ...state,
        loading: true,
      };

    case ADCOMMENT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: "",
      };

    case ADCOMMENT_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    ////////////////////////////////////
    case ADCOMMENT_MORE_ATTEMPT:
      return {
        ...state,
        loadingMore: true,
      };

    case ADCOMMENT_MORE_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: "",
      };

    case ADCOMMENT_MORE_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    ////////////////////////////////////
    case ADCOMMENT_NEW_SUCCESS:
      return {
        ...state,
        list: [action.item, ...state.list],
      };

    case ADCOMMENT_NEW_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    //////////////////////////////////
    case ADCOMMENT_LIKE_SUCCESS: {
      const newState = state.list.map((item) => {
        if (item.Id === action.item.Id)
          return action.item;
        else return item;
      });
      return {
        ...state,
        list: newState,
        item: action.item,
        error_update: "",
      };
    }

    case ADCOMMENT_LIKE_FAILED:
      return {
        ...state,
        error_update: action.error,
      };

    case COMMENT_SET_ITEM:
      return {
        ...state,
        item: action.item,
      };
    //////////////////////////
    case ADCOMMENT_DELETE_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.Id !== action.item.Id;
      });
      return {
        ...state,
        list: newState,
      };
    }

    case ADCOMMENT_DELETE_FAILED:
      return {
        ...state,
        error_remove: action.error,
      };
    //////////////////////////
    case REPORT_COMMENT_SUCCESS:
      return {
        ...state,
      };

    case REPORT_COMMENT_FAILED:
      return {
        ...state,
        error_report: action.error,
      };
    default:
      return state;
  }
}
export default adComment;
