import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
    ADSLIDER_LIST_ATTEMPT,
    ADSLIDER_LIST_SUCCESS,
    ADSLIDER_LIST_FAILED,
} from "../types/adSliderTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
    return {
        type: ADSLIDER_LIST_ATTEMPT
    };
}

export function success(data) {
    return {
        type: ADSLIDER_LIST_SUCCESS,
        data: data
    };
}

export function failed(error) {
    return {
        type: ADSLIDER_LIST_FAILED,
        error: error
    };
}

///////////////////////////////////////////



export const loadList = (data: Object) => async dispatch => {
    dispatch(loading());
    await axios
        .get(url_api + "goder/slider", {
            params: {
                id: data.user_id,
                lang: I18n.t("locale")
            },
            //headers: { Authorization: `Bearer ${data.token}` },
            // timeout: 5000,
        })
        .then(function (response) {
            //console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else dispatch(failed(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            console.log(error);
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};
