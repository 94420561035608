import React from "react";
//import ReactDOM from 'react-dom';
import Modal from "./Modal";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adReportActions";
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

class AdReportItem extends React.Component {
  state = {
    checked: false
  };
  onChangeRadio = event => {
    this.setState({ checked: !this.state.checked });
  };
  render() {
    return (
      <div className="col">
        <input type="radio" name="reason_id" value={this.props.item.key} />{" "}
        <small>{this.props.item.label}</small>
      </div>
    );
  }
}

class ModalReportAd extends React.Component {
  state = {
    description: "",
    radio: 0
  };

  componentDidMount() {
    this.fetchaAdReport();
  }

  fetchaAdReport = async () => {
    try {
      await this.props
        .LoadReportOption(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  onChangeDescription = event => {
    this.setState({ description: event.target.value });
  };

  storeReport = async item => {
    try {
      await this.props
        .AddReportAd(
          this.state.description,
          this.state.radio,
          this.props.ad.Id,
          this.props.user_id,
          this.props.token
        )
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
          else {
            alertify.success(I18n.t("saved_succesfull"));
          }
        });
      this.props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  setOption(event) {
    //console.log(event.target.value);
    this.setState({ radio: event.target.value });
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onClose={this.props.onClose}
        title={<Translate value="ad_report_title" />}
      >
        <div className="col-12 text-center">
          <Translate value="ad_report_kind" />
        </div>
        <div className="col-xs-10 col-xs-offset-1 col-sm-12 col-sm-offset-2">
          <div onChange={this.setOption.bind(this)}>
            {this.props.adReportOptionsList.map(item => {
              return <AdReportItem item={item} key={item.key} />;
            })}
          </div>
          <div className="form-group col-md-12">
            <label>
              <Translate value="comments" />
            </label>
            <textarea
              className="form-control"
              name="comment"
              rows="3"
              cols="300"
              onChange={this.onChangeDescription}
            ></textarea>
          </div>
        </div>

        <div className="modal-footer text-center">
          <div className="col-sm-6 text-right">
            <button className="btn btn-primary" onClick={this.props.onClose}>
              <i className="fa fa-close"></i>
              <Translate value="close" />
            </button>
          </div>
          <div className="col-sm-6 text-left">
            <button
              className="btn btn-danger"
              onClick={() => {
                this.storeReport();
              }}
            >
              <Translate value="ad_report_button" />
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}
function mapStateToProps(reducer) {
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,
    adReportOptionsList: reducer.adReport.list
  };
}
const mapDispatchToProps = dispatch => ({
  AddReportAd: (message, reason_id, id, user_id, token) =>
    dispatch(actions.newItem({ message, reason_id, id, user_id, token })),
  LoadReportOption: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalReportAd);
