import React from "react";
import "./styles/OnePay.css";
//import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Translate, I18n } from "react-redux-i18n";
import { connect } from "react-redux";
import * as actions from "../redux/actions/customerActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import PaypalOnePay from "../components/PaypalOnePay";

class OnePay extends React.Component {
  componentDidMount() {
    this.fetchCustomer();
  }

  fetchCustomer = async () => {
    try {
      await this.props
        .LoadCustomer(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-onepay">
            <div className="col-4">
              <section className="section-information-customer">
                <h4>
                  <span className="text-muted">
                    {" "}
                    <Translate value="billing" />
                  </span>
                </h4>
                <hr />
                <div className="">
                  <div className="col-12">
                    <div className="text-left">
                      <b>
                        <Translate value="billing_customer_data" />
                      </b>{" "}
                      <a href="#">
                        <small>
                          <b>
                            <Translate value="profile_edit" />
                          </b>
                        </small>
                      </a>
                    </div>
                    <div className="card ">
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_name" />:
                        </span>
                      </small>
                      <label>
                        {" "}
                        {this.props.customer.Name} {this.props.customer.LastN}
                      </label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_email" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Email}</label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_phone" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Phone}</label>
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_organization" />:
                        </span>
                      </small>
                      <label>{this.props.customer.Organization}</label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="text-left">
                      <b>
                        <Translate value="billing_customer_address" />
                      </b>{" "}
                      <a href="#">
                        <small>
                          <b>
                            {" "}
                            <Translate value="profile_edit" />
                          </b>
                        </small>
                      </a>
                    </div>
                    <div className="card">
                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_address1" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressAddress}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_city" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressCity}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_state" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressState}</label>

                      <small className="">
                        <span className="text-muted">
                          <Translate value="profile_zipcode" />:
                        </span>
                      </small>
                      <label>{this.props.customer.AddressZipcode}</label>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="col-4  ">
              <h4 className="d-flex justify-content-between align-items-center">
                <span className="text-muted">Your cart</span>
                <span className="badge badge-secondary badge-pill">1</span>
              </h4>
              <ul className="list-group mb-3">
                <li className="list-group-item d-flex justify-content-between lh-condensed">
                  <div>
                    <h6 className="my-0">
                      {" "}
                      <Translate value="one_pay" />
                    </h6>
                    <small className="text-muted">
                      <Translate value="one_pay2" />
                      <br />
                      <Translate value="one_pay4" />
                      <br /> <Translate value="one_pay4" /> <br />
                      <Translate value="one_pay5" />
                    </small>
                  </div>
                  <span className="text-muted">$15</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  <span>Total (USD)</span>
                  <strong>$15</strong>
                </li>
              </ul>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Codigo Promocional"
                />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-secondary">
                    <Translate value="password_forget_send" />
                  </button>
                </div>
              </div>
              <hr className="" />
              <PaypalOnePay />
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    customer: reducer.customer.customerData,
    error: reducer.customer.error,
    loading: reducer.customer.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCustomer: (user_id, token) =>
    dispatch(actions.loadItem({ user_id, token })),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnePay);
/**
 * order-md-2
 *  <div className="modal fade" id="modal_customer_edit">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="col-sm-12 ">
                        <div className="text-right">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="text-center">
                          <h4>Datos Cliente</h4>
                        </div>
                      </div>
                    </div>
                    <form
                      className="needs-validation"
                      action="https://www.godiway.com/updatecustomer"
                      method="post"
                    >
                      <div className="modal-body">
                        <div className="">
                          <div className="col-md-6 mb-3">
                            <small for="firstName">Nombres</small>
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              name="name"
                              placeholder=""
                              value="John "
                              required=""
                            />
                            <div className="invalid-feedback">
                              Valid first name is required.
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <small for="lastName">Apellido</small>
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              name="lastN"
                              placeholder=""
                              value="Guzman Isbes"
                              required=""
                            />
                            <div className="invalid-feedback">
                              Valid last name is required.
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <small for="phone">
                            Teléfono{" "}
                            <span className="text-muted">(Optional)</span>
                          </small>
                          <input
                            type="phone"
                            className="form-control"
                            id="phone"
                            name="phone"
                            placeholder="Teléfono"
                            value="0992601528"
                          />
                          <div className="invalid-feedback">
                            Please enter a valid email address for shipping
                            updates.
                          </div>
                        </div>
                        <div className="mb-3">
                          <small for="email">Email </small>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            placeholder="you@example.com"
                            value="johnguzman@elecsotech.com"
                          />
                          <div className="invalid-feedback">
                            Please enter a valid email address for shipping
                            updates.
                          </div>
                        </div>
                        <div className="mb-3">
                          <small for="organization">
                            Organización{" "}
                            <span className="text-muted">(Optional)</span>
                          </small>
                          <input
                            type="text"
                            className="form-control"
                            id="organization"
                            name="organization"
                            placeholder="Organización"
                            value="Elecsotech"
                          />
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="col-sm-12 text-center">
                          <button type="submit" className="btn btn-primary">
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="modal_address_edit">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="col-sm-12 ">
                        <div className="text-right">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="text-center">
                          <h4>Datos Dirección</h4>
                        </div>
                      </div>
                    </div>
                    <form
                      className="needs-validation"
                      action="https://www.godiway.com/updateaddress"
                      method="post"
                    >
                      <div className="modal-body">
                        <div className="mb-3">
                          <small for="address">Dirección</small>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            name="address"
                            placeholder="1234 Main St"
                            required=""
                            value="Calle Amazonas y Pompeya"
                          />
                          <div className="invalid-feedback">
                            Please enter your shipping address.
                          </div>
                        </div>
                        <div className="mb-3">
                          <small for="city">
                            Ciudad<span className="text-muted">(Optional)</span>
                          </small>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            placeholder="Apartment or suite"
                            value="Orellana"
                          />
                        </div>
                        <div className="">
                          <div className="col-md-5 mb-3">
                            <small for="country">País</small>
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              placeholder="País"
                              value="Ecuador"
                              disabled=""
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <small for="state">Estado</small>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              placeholder="Estado"
                              value="Orellana"
                              required=""
                            />
                          </div>
                          <div className="col-md-3 mb-3">
                            <small for="zip">Código Postal</small>
                            <input
                              type="text"
                              className="form-control"
                              id="zip"
                              name="postcode"
                              placeholder=""
                              value="220150"
                            />
                            <div className="invalid-feedback">
                              Zip code required.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer">
                        <div className="col-sm-12 text-center">
                          <button type="submit" className="btn btn-primary">
                            Guardar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

//////////////////////////////////////////////////////////////////////////////////

              <div className="information-aditional">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  save-info="same-address"
                  id="same-address"
                  disabled={true}
                />
                <label className="custom-control-label" htmlFor="same-address">
                  Direccion de envio es la misma que facturacion
                </label>
                <input
                  type="checkbox"
                  className="custom-control-input"
                  name="save_card"
                  id="save-info"
                  value=""
                  disabled={false}
                />
                <label className="custom-control-label" htmlFor="save-info">
                  Guardar informacion para compras futuras
                </label>
              </div>
              <hr className="" />
              ///////////////////////////////////////////////////////////////////
                <section className="section-information-credicart">
                <h5 className="mb-3">Tarjeta de Crédito</h5>

                <form action="https://www.godiway.com/charge" method="post">
                  <input type="hidden" name="plan_id" value="2" />
                  <input type="hidden" name="customer_id" value="1" />
                  <div className="form-">
                    <div className="form-">
                      <div className="col-12">
                        <label for="name">Nombre Títular de la tarjeta:</label>
                      </div>
                      <div className="col-12">
                        <input
                          id="card_name"
                          placeholder="Jenny Rosen"
                          required=""
                          className="form-control"
                        />
                      </div>
                      <div className="col-12">
                        <label for="name">Numero de Tarjeta:</label>
                      </div>
                      <div className="col-12">
                        <input
                          id="card_name"
                          placeholder="1111-1111-1111-1111"
                          required=""
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div id="card-element" className="col-md-12"></div>

                    <div
                      id="card-errors"
                      role="alert"
                      className="col-md-12"
                    ></div>
                  </div>
                  <div className="section-btn-pay">
                    <button className="btn btn-primary">
                      {" "}
                      <b>
                        {" "}
                        <Translate value="one_pay_pay" />
                      </b>{" "}
                    </button>
                  </div>
                </form>
              </section>
 */
