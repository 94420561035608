import React from "react";
import "./styles/AdSectionRight.css";
//import { Link } from 'react-router-dom';
import AdRelation from "./AdRelation";
import SellerCalification from "./SellerCalification";
import AdDetailAction from "./AdDetailAction";

import AdBider from "./AdBider";
import { connect } from "react-redux";
import { Translate } from "react-redux-i18n";
/*
import ModalDescription from "./Modal";
import ModalFaq from "./Modal";
import ModalComment from "./Modal";
import AdDescription from "./AdDescription";
import AdFaq from "./AdFaq";
import AdComment from "./AdComment";*/
class AdSectionRight extends React.Component {
  state = {
    open_modal_description: false,
    open_modal_faq: false,
    open_modal_comment: false,
  };

  onOpenCloseModalDescription = () => {
    this.setState({
      open_modal_description: !this.state.open_modal_description,
    });
  };

  onOpenCloseModalFaq = () => {
    this.setState({ open_modal_faq: !this.state.open_modal_faq });
  };

  onOpenCloseModalComment = () => {
    this.setState({ open_modal_comment: !this.state.open_modal_comment });
  };

  render() {
    return (
      <React.Fragment>
        <section className="Timeline-ColRight">
          <AdDetailAction />
          <div className="container-goder-button">
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.onOpenCloseModalDescription();
              }}
            >
              <small>
                <Translate value="ad_description" />
              </small>
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.onOpenCloseModalFaq();
              }}
            >
              <small>
                <Translate value="faq_questions" />
              </small>
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.onOpenCloseModalComment();
              }}
            >
              <small>
                <Translate value="comments" />
              </small>
            </button>
          </div>
          <SellerCalification />
          {
            this.props.ad.KindCommerceId === 5 && (<AdBider />)
          }
          <AdRelation />
        </section>
      </React.Fragment>
    );
  }
};

function mapStateToProps(reducer) {
  return {
    ad: reducer.ad.item,
  };
}

export default connect(mapStateToProps, null)(AdSectionRight);
/**
 * <ModalDescription
          isOpen={this.state.open_modal_description}
          onClose={() => {
            this.onOpenCloseModalDescription();
          }}
          title={<Translate value="ad_description" />}
        >
          <div className="modal-scroll ">
            <AdDescription />
          </div>
        </ModalDescription>
        <ModalFaq
          isOpen={this.state.open_modal_faq}
          onClose={() => {
            this.onOpenCloseModalFaq();
          }}
          title={<Translate value="faq_questions" />}
        >
          <div className="modal-scroll ">
            <AdFaq />
          </div>
        </ModalFaq>
        <ModalComment
          isOpen={this.state.open_modal_comment}
          onClose={() => {
            this.onOpenCloseModalComment();
          }}
          title={<Translate value="comments" />}
        >
          <div className="modal-scroll ">
            <AdComment />
          </div>
        </ModalComment>
 */
