import {
  TRANSACTION_REQUEST_LIST_ATTEMPT,
  TRANSACTION_REQUEST_LIST_SUCCESS,
  TRANSACTION_REQUEST_LIST_FAILED,
  ///////////////
  TRANSACTION_REQUEST_MORELIST_ATTEMPT,
  TRANSACTION_REQUEST_MORELIST_SUCCESS,
  TRANSACTION_REQUEST_MORELIST_FAILED,
  ////////////////
  TRANSACTION_REQUEST_NEW_SUCCESS,
  TRANSACTION_REQUEST_NEW_FAILED,
  ////////////////////////////////
  TRANSACTION_REQUEST_CALIFICATION_SUCCESS,
  TRANSACTION_REQUEST_CALIFICATION_FAILED
} from "../types/transactionRequestPendingTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",

  error_new: "",
  page_size: 0,
  page_count: 0,
  loadingMore: false
};

function transactionRequestPending(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRANSACTION_REQUEST_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case TRANSACTION_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        page_size: action.data.size,
        page_count: action.data.size,
        error: ""
      };

    case TRANSACTION_REQUEST_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: []
      };

    /////////////////////////////
    case TRANSACTION_REQUEST_MORELIST_ATTEMPT:
      return {
        ...state,
        loadingMore: true
      };

    case TRANSACTION_REQUEST_MORELIST_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        list: state.list.concat(action.data.list),
        page_size: action.data.size,
        page_count: state.page_count + action.data.size,
        error: ""
      };

    case TRANSACTION_REQUEST_MORELIST_FAILED:
      return {
        ...state,
        loadingMore: false,
      };
    /////////////////////////////
    case TRANSACTION_REQUEST_NEW_SUCCESS:
      return {
        ...state,
        error_new: ""
      };

    case TRANSACTION_REQUEST_NEW_FAILED:
      return {
        ...state,
        error_new: action.error
      };
    /////////////////////////  ,

    case TRANSACTION_REQUEST_CALIFICATION_SUCCESS: {
      if (
        action.item.Status === "FINISHED"
      ) {
        const newState = state.list.filter(item => {
          return item.Id !== action.item.Id;
        });

        return {
          ...state,
          listClosed: [action.item, ...state.listClosed],
          list: newState,
          error_new: ""
        };
      } else {
        const newState = state.list.map(item => {
          if (item.Id === action.item.Id) return action.item;
          return item;
        });

        return {
          ...state,
          list: newState,
          error_new: ""
        };
      }
    }

    case TRANSACTION_REQUEST_CALIFICATION_FAILED:
      return {
        ...state,
        error_new: action.error
      };
    default:
      return state;
  }
}
export default transactionRequestPending;
