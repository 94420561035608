import {
  FILTER_LIST_ATTEMPT,
  FILTER_LIST_SUCCESS,
  FILTER_LIST_FAILED
} from "../types/adFilterTypes";

const INITIAL_STATE = {
  loading: true,
  adFilter: [],
  error: ""
};
function adFilter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FILTER_LIST_ATTEMPT:
      return {
        ...state,
        loading: true
      };

    case FILTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        adFilter: action.adFilter,
        error: ""
      };

    case FILTER_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        adList: []
      };

    default:
      return state;
  }
}
export default adFilter;
