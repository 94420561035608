import React from "react";
//import api from "../api";
import PanelLayout from "../components/PanelLayout";
import "./styles/MarketStore.css";
//immport { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../redux/actions/adCategoryActions";
import {
  FaSave,
  //FaPlus 
} from "react-icons/fa";
import PanelEditGalery from "../components/PanelEditGalery";
import * as actions_sales from "../redux/actions/adSalesActions";
import * as actions_rent from "../redux/actions/adRentsActions";
import * as actions_exchange from "../redux/actions/adExchangeActions";
import * as actions_donation from "../redux/actions/adDonationActions";
import * as actions_auction from "../redux/actions/adAuctionActions";
import * as actions_publish from "../redux/actions/publishActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
//import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
//import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
//import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
//import CKEditor from 'ckeditor4-react';

import { Translate, I18n } from "react-redux-i18n";
import MiniLoader from "../components/MiniLoader";
import PanelAddLinkYoutube from "../components/PanelAddLinkYoutube";
//CKEditor.editorbgcolor = "blue";
import moment from "moment";


class AdCategoryItem extends React.Component {
  render() {
    return <option value={this.props.item.key}>{this.props.item.label}</option>;
  }
}
/*
const editorConfiguration = {
    plugins: [ Essentials, Bold, Italic, Paragraph ],
    toolbar: [ 'bold', 'italic' ]
};*/
class PanelEditAd extends React.Component {
  state = {
    form: {
      price: "1.0",
      name: "",
      description: "",
      category_id: "0",
      condition: "NEW",
      frequency: "H",
      request: "",
      store_id: "",
      quantity: "",
      expiration: ""
    },
    kindComerce: "",
  };


  componentDidMount() {
    this.setState({ kindComerce: this.props.match.params.kindCommerce_id });

    this.setState({
      form: {
        name: this.props.ad.Name,
        quantity: this.props.ad.Quantity,
        price: this.props.ad.Price,
        frequency: this.props.ad.Frequency.Id,
        condition: this.props.ad.Condition.Id,
        category_id: this.props.ad.Category.Id,
        store_id: this.props.ad.Store.Id,
        description: this.props.ad.Description,
        expiration: this.props.ad.Expiration
      },
    });
    this.fetchAdCategoryList();
  }

  fetchAdCategoryList = async () => {
    try {
      await this.props
        .LoadCategories(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alert(this.props.error);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleChangeDescription = (evt, editor) => {
    this.setState({
      form: {
        ...this.state.form,
        description: editor.getData(),
      },
    });
  };

  onClickSave = async () => {
    var KindCommerceId = "0";
    switch (this.state.kindComerce) {
      case "donation":
        KindCommerceId = "1";
        break;

      case "sale":
        KindCommerceId = "2";
        break;

      case "exchange":
        KindCommerceId = "3";
        break;

      case "rent":
        KindCommerceId = "4";
        break;

      case "auction":
        KindCommerceId = "5";
        break;

      default:
        KindCommerceId = "2";
        break;
    }
    let params = new FormData();
    params.append("goder_id", this.props.ad.Id);
    params.append("name", this.state.form.name);
    params.append("quantity", this.state.form.quantity || "0");
    params.append("price", this.state.form.price || "1");
    params.append("description", this.state.form.description);
    params.append("category_id", this.state.form.category_id);
    params.append("kindcommerce_id", KindCommerceId);
    params.append("condition_id", this.state.form.condition || "");
    params.append("frequency_id", this.state.form.frequency || "");
    params.append("numRequest", this.state.form.request || "0");
    params.append("store_id", this.state.form.store_id || "");
    params.append("expiration", this.state.form.expiration);

    params.append("id", this.props.user_id);
    params.append("lang", I18n.t("locale"));

    try {
      if (KindCommerceId === "1")
        await this.props.AdNewDonation(this.props.token, params).then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
      else if (KindCommerceId === "2")
        await this.props.AdNewSales(this.props.token, params).then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
      else if (KindCommerceId === "3")
        await this.props.AdNewExchange(this.props.token, params).then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
      else if (KindCommerceId === "4")
        await this.props.AdNewRent(this.props.token, params).then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
      else if (KindCommerceId === "5")
        await this.props.AdNewAuction(this.props.token, params).then(() => {
          if (this.props.error) {
            alert(this.props.error);
          } else alertify.success(I18n.t("saved_succesfull"));
        });
    } catch (error) {
      console.log(error);
    }
  };

  //
  render() {
    var texto = this.state.form.description;
    return (
      <React.Fragment>
        <PanelLayout>
          <div className="card">
            <div className="text-center ">
              <h2>
                <Translate value="ad_new_title" />
              </h2>
            </div>
            <hr className="dividerGray" />

            <div className="col-12">
              <div className="form-group ">
                <span className="required-field">*</span>
                <label htmlFor="name" className="control-label">
                  <Translate value="ad_name" />
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  data-type="product_detail"
                  required=""
                  maxLength="100"
                  className="form-control"
                  onChange={this.handleChange}
                  value={this.state.form.name}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="form-group row ">
                <div className="col-lg-4">
                  <div className="form-group">
                    <span className="required-field">*</span>
                    <label htmlFor="quantity" className="control-label">
                      <Translate value="ad_quantity" />
                    </label>
                    <div className="input-group">
                      <span className="input-group-addon">#</span>
                      <input
                        type="number"
                        min="0"
                        id="quantity"
                        data-type="product"
                        name="quantity"
                        className="form-control efectivity value_tmp"
                        value={this.state.form.quantity}
                        onChange={this.handleChange}
                        disabled={false}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group ">
                    <span className="required-field">*</span>
                    <label htmlFor="price" className="control-label">
                      <Translate value="ad_price" />
                    </label>
                    <div className="input-group">
                      <span className="input-group-addon">$</span>
                      <input
                        type="number"
                        step="0.01"
                        required=""
                        min="0"
                        id="price"
                        data-type="product"
                        name="price"
                        className="form-control "
                        value={this.state.form.price}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
                {this.state.kindComerce === "auction" && (
                  <div className="col-lg-4">
                    <div className="form-group ">
                      <div className="form-group">
                        <span className="required-field">*</span>
                        <label htmlFor="condition">
                          <Translate value="expire" />
                          <small>{" 3 month max"}</small>
                        </label>
                        <input
                          type="date"
                          required={true}
                          id="expiration"
                          min={moment().format('YYYY-MM-DD')}
                          max={moment().add(3, 'M').format('YYYY-MM-DD')}
                          data-date-format={'YYYY-MM-DD'}
                          name="expiration"
                          /* className={
                             this.shouldMarkError("expiration")
                               ? "form-control is-invalid"
                               : "form-control"
                           }*/
                          className="form-control"
                          //onBlur={this.handleBlur("expiration")}
                          value={this.state.form.expiration}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.kindComerce === "rent" ? (
                  <div className="col-lg-4">
                    <div className="form-group ">
                      <div className="form-group">
                        <label htmlFor="frequency">
                          <Translate value="ad_frecuency" />{" "}
                        </label>
                        <select
                          className="form-control "
                          data-type="product"
                          name="frequency"
                          value={this.state.form.frequency}
                          onChange={this.handleChange}
                          defaultValue={"H"}
                        >

                          <option value="H">{I18n.t("ad_hour")}</option>
                          <option value="D">{I18n.t("ad_day")}</option>
                          <option value="M">{I18n.t("ad_month")}</option>
                          <option value="Y">{I18n.t("ad_year")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-4">
                    <div className="form-group ">
                      <div className="form-group">
                        <label htmlFor="condition">
                          {" "}
                          <Translate value="ad_condition" />
                        </label>
                        <select
                          className="form-control "
                          data-type="product"
                          name="condition"
                          value={this.state.form.condition}
                          onChange={this.handleChange}
                          defaultValue={"NEW"}
                        >
                          <option value="NEW">{I18n.t("ad_new")}</option>
                          <option value="USED">{I18n.t("ad_used")}</option>
                          <option value="SERVICE">
                            {I18n.t("ad_service")}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12">
              <div className="form-group row ">
                <div className="col-lg-6">
                  <div className="form-group ">
                    <label htmlFor="conditions">
                      {" "}
                      <Translate value="ad_category" />
                    </label>
                    <select
                      className="form-control"
                      required=""
                      data-type="category"
                      rows="4"
                      name="category_id"
                      value={this.state.form.category_id}
                      onChange={this.handleChange}
                    //defaultValue={0}
                    >
                      <option value={0}>{I18n.t("ad_category_select")}</option>
                      {this.props.adCategoryList.map((item) => {
                        return <AdCategoryItem item={item} key={item.key} />;
                      })}
                    </select>
                  </div>
                </div>
                {this.state.kindComerce === "donation" ? (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <span className="required-field">*</span>
                      <label htmlFor="request" className="control-label">
                        <Translate value="ad_request" />
                      </label>
                      <div className="input-group">
                        <span className="input-group-addon">#</span>
                        <input
                          type="number"
                          min="0"
                          id="request"
                          data-type="product"
                          name="request"
                          className="form-control efectivity value_tmp"
                          value={this.state.form.request}
                          onChange={this.handleChange}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <div className="form-group ">
                      <label htmlFor="storeId">
                        {" "}
                        <Translate value="ad_marketstore" />{" "}
                      </label>
                      <a
                        data-container="body"
                        title="Marketstore"
                        data-content="???"
                      >
                        <span className="glyphicon glyphicon-info-sign"></span>
                      </a>
                      <div className="input-group">
                        <div className="input-group-btn"></div>
                        <select
                          className="form-control efectivity"
                          data-type="product"
                          name="store_id"
                          value={this.state.form.store_id}
                          defaultValue={"0"}
                          onChange={this.handleChange}
                        >
                          {this.props.ad.Store.Id ? (
                            <option value={this.props.ad.Store.Id}>
                              {this.props.ad.Store.Name}
                            </option>
                          ) : (
                            <option value="0">
                              {I18n.t("ad_not_marketstore")}
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-12">
              <div className="form-group ">
                <label className="control-label">
                  <Translate value="ad_description" />
                </label>

                <CKEditor
                  //		 config={ editorConfiguration }
                  data={texto == null ? "" : texto}
                  editor={ClassicEditor}
                  //   type="basic"
                  //		 config={{toolbar:[['Bold', 'Italic', 'Underline'],
                  //['Cut','Copy','Paste', 'Undo','Redo'],
                  //]}}
                  onChange={(e, editor) =>
                    this.handleChangeDescription(e, editor)
                  }
                  name="description"
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.setData(texto);
                  }}
                />
              </div>
            </div>

          </div>
          <PanelEditGalery />

          <hr className="dividerGray" />
          <div className="card">
            <button
              className="btn btn-success"
              onClick={() => {
                this.onClickSave();
              }}
            >
              <b> {I18n.t("save")}</b>
            </button>
          </div>

          {this.props.loadingMedia ? <MiniLoader /> : <PanelAddLinkYoutube />}
        </PanelLayout>
      </React.Fragment>
    );
  }
}

/**
 * 
 * <FaSave />
 * 
 *                    <option value="H">
                            <Translate value="ad_hour" />
                          </option>
                          <option value="D">
                            <Translate value="ad_day" />
                          </option>
                          <option value="M">
                            <Translate value="ad_month" />
                          </option>
                          <option value="Y">
                            <Translate value="ad_year" />
                          </option>

  <CKEditor
                  data={this.state.form.description}
                  type="classic"
                  onChange={(e) => this.handleChangeDescription(e)}
                  name="description"
                  config={{
                    toolbar: [
                      ["Bold", "Italic", "Underline"],
                      [
                        "NumberedList",
                        "BulletedList", 
                        "list",
                        "indent",
                        "blocks",
                        "Paragraph",

                      ],
                      ["Cut", "Copy", "Paste", "Undo", "Redo"],
                    ],
                  }}
                />
 * @param {*} reducer 
 */
function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,
    ad: reducer.ad.item,

    adCategoryList: reducer.adCategory.list,
    error: reducer.adCategory.error,
    loading: reducer.adCategory.loading,

    error_update: reducer.adSales.error_update,

    loadingMedia: reducer.media.loading,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadCategories: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
  AdNewSales: (token, params) => dispatch(actions_publish.updateItem({ token, params })),
  AdNewRent: (token, params) => dispatch(actions_publish.updateItem({ token, params })),
  AdNewExchange: (token, params) => dispatch(actions_publish.updateItem({ token, params })),
  AdNewDonation: (token, params) => dispatch(actions_publish.updateItem({ token, params })),
  AdNewAuction: (token, params) => dispatch(actions_publish.updateItem({ token, params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PanelEditAd);

/**
 * <textarea
                  id="description"
                  data-type="product_detail"
                  name="description"
                  className="form-control ckeditor "
                  cols="10"
                  rows="5"
                  value={this.state.form.description}
                  onChange={this.handleChange}
                ></textarea>
 */
