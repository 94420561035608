import React from "react";
import { Translate, I18n } from "react-redux-i18n";

import "./styles/ContactMe.css";

import Footer from "../components/Footer";


class ContactMe extends React.Component {
  componentDidMount() { }

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-contactme">
            <div className="card">
              <div className="card-header text-center">
                <img
                  src="https://www.godiway.com/themes/frontend/img/logo.png"
                  alt="img"
                  height="50"
                />
                <h3>
                  {" "}
                  <Translate value="footer_contacts" />
                </h3>
              </div>
              <div className="card-body">
                <ul className="text-center">
                  <p>
                    <Translate value="contact1" />
                  </p>
                  <p>
                    <Translate value="contact2" />.
                  </p>
                  <p>
                    <Translate value="contact3" /> team@godiway.com
                  </p>
                  <p>
                    <Translate value="contact4" />
                    :.
                  </p>
                  <p>
                    <a href="https://www.facebook.com/GodiWay-602264786813460" target="_blank">
                      {" "}
                      Facebook GodiWay
                    </a>
                  </p>

                  <p>
                    <a href="https://twitter.com/GodiWay" target="_blank">Twitter GodiWay</a>
                  </p>
                </ul>
              </div>
              <div className="panel-footer"></div>
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default ContactMe;

/**
 *  <p>
                    <a href="https://www.linkedin.com/in/godi-way-b80193168/">
                      {" "}
                      Linkedin GodiWay
                    </a>
                  </p>
 */
