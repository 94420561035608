import axios from "axios";
import { I18n } from "react-redux-i18n";
import {
    FAQ_LIST_ANSWER_ATTEMPT,
    FAQ_LIST_ANSWER_SUCCESS,
    FAQ_LIST_ANSWER_FAILED,
    ///////////////////////
    FAQ_SET_ANSWER_ATTEMPT,
    FAQ_SET_ANSWER_SUCCESS,
    FAQ_SET_ANSWER_FAILED,
    //////////////////////
    FAQ_MORELIST_ANSWER_ATTEMPT,
    FAQ_MORELIST_ANSWER_SUCCESS,
    FAQ_MORELIST_ANSWER_FAILED
} from "../types/faqAnswerTypes";

import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
    return {
        type: FAQ_LIST_ANSWER_ATTEMPT
    };
}

export function success(data) {
    return {
        type: FAQ_LIST_ANSWER_SUCCESS,
        data: data
    };
}

export function failed(error) {
    return {
        type: FAQ_LIST_ANSWER_FAILED,
        error: error
    };
}
//////////////////////////////////////////
export function setAttemptAnswer() {
    return {
        type: FAQ_SET_ANSWER_ATTEMPT
    };
}

export function successSetAnswer(item) {
    return {
        type: FAQ_SET_ANSWER_SUCCESS,
        item: item
    };
}

export function failedSetAnswer(error) {
    return {
        type: FAQ_SET_ANSWER_FAILED,
        error: error
    };
}
/////////////////////////////////////////////////////////
export function loadingMore() {
    return {
        type: FAQ_MORELIST_ANSWER_ATTEMPT
    };
}
export function successMore(data) {
    return {
        type: FAQ_MORELIST_ANSWER_SUCCESS,
        data: data
    };
}
export function failedMore(error) {
    return {
        type: FAQ_MORELIST_ANSWER_FAILED,

    };
}
/////////////////////////////////////////////////////////


export const loadList = (data: Object) => async dispatch => {
    dispatch(loading());
    await axios
        .get(url_api + "faq/answer", {
            params: {
                id: data.user_id,
                lang: I18n.t("locale")
            },
            headers: { authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            //   console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(success(response.data.body));
                else dispatch(failed(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            //console.log(error);
            if (error.Error) {
                dispatch(failed("try_later"));
            } else {
                dispatch(failed("try_later"));
            }
            return;
        });
};

export const loadListScroll = (data: Object) => async dispatch => {
    dispatch(loadingMore());
    await axios
        .get(url_api + "faq/answer", {
            params: {
                index: data.page_count,
                id: data.user_id,
                lang: I18n.t("locale")
            },
            headers: { authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            //   console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(successMore(response.data.body));
                else dispatch(failedMore(response.data.body));
            } else {
                dispatch(failed("try_later"));
            }
        })
        .catch(function (error) {
            //console.log(error);
            if (error.Error) {
                dispatch(failedMore("try_later"));
            } else {
                dispatch(failedMore("try_later"));
            }
            return;
        });
};



export const updateItem = (data: Object) => async dispatch => {
    dispatch(setAttemptAnswer());
    await axios
        .put(url_api + "faq", {
            faq_id: data.id,
            answer: data.answer,
            id: data.user_id,
            lang: I18n.t("locale")
        }, {
            headers: { Authorization: `Bearer ${data.token}` },
        })
        .then(function (response) {
            //console.log(response);
            if (response.status < 300) {
                if (response.data.status === "Success")
                    dispatch(successSetAnswer(response.data.body));
                else dispatch(failedSetAnswer(response.data.body));
            } else {
                dispatch(failedSetAnswer("try_later"));
            }
        })
        .catch(function (error) {
            //console.log(error);
            if (error.Error) {
                dispatch(failedSetAnswer("try_later"));
            } else {
                dispatch(failedSetAnswer("try_later"));
            }
            return;
        });
};
