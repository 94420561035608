import React, { Component } from "react";

import "./styles/Home.css";
import PanelSummary from "../components/PanelSummary";

export default class Panel extends Component {
  constructor(props) {
    super(props);

  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block"></div>
          <div className="col-lg-6"></div>
          <div className="col-lg-3 d-none d-lg-block">
            <PanelSummary />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

