export const WISH_LIST_ATTEMPT = "WISH_LIST_ATTEMPT";
export const WISH_LIST_SUCCESS = "WISH_LIST_SUCCESS";
export const WISH_LIST_FAILED = "WISH_LIST_FAILED";

export const WISH_UPDATE_SUCCESS = "WISH_UPDATE_SUCCESS";
export const WISH_UPDATE_FAILED = "WISH_UPDATE_FAILED";

export const WISH_SET_COUNT = "WISH_SET_COUNT";

export const LOGOUT = "LOGOUT";

