import React from "react";

import "./styles/Home.css";
import ProfileLayout from "../components/ProfileLayout";
//import ProfileMenu from '../components/ProfileMenu';
//import ProfileSummary from '../components/ProfileSummary';
//import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import * as actions from "../redux/actions/privacyActions";
//checked={this.props.item.Value}
import { Translate } from "react-redux-i18n";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";

class PrivacyItem extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row ">
          <div className="col-10">
            {" "}
            <Translate value={this.props.item.field} />
          </div>
          <div className="col-2">
            <label className="switch floatLeft">
              <input
                type="checkbox"
                className="configuration"
                name={this.props.item.field}
                value={this.props.item.value === 'on' ? true : false}
                checked={this.props.item.value === 'on' ? true : false}
                onChange={this.props.onChange}
              />
              <div className="slider round"></div>
            </label>
          </div>
        </div>
        <hr className="dividerGray" />
      </React.Fragment>
    );
  }
}

class ProfilePrivacy extends React.Component {
  state = {
    form: []
  };

  onChange = async item => {
    item.value = item.value === "on" ? "off" : "on";
    const newState = this.state.form.map(item_ => {
      if (item_.field === item.field) return item;
      else return item_;
    });
    this.setState({
      form: newState
    });
    await this.props
      .UpdatePrivacy(
        this.props.token,
        item.field,
        item.value,
        this.props.user_id,
      )
      .then(() => {
        if (this.props.error_update) {
          alertify.warning(this.props.error_update);
        }
      });
  };

  componentDidMount() {
    this.fecthLoadPrivacy();
  }

  fecthLoadPrivacy = async () => {
    await this.props
      .LoadPrivacy(this.props.user_id, this.props.token)
      .then(() => {
        if (this.props.error) {
          alertify.warning(this.props.error);
        } else
          this.setState({
            form: this.props.privacy
          });
      });
  };

  render() {
    return (
      <React.Fragment>
        <ProfileLayout>
          <div className="col-12">
            <dl className="text-center">
              <h3>
                <b>
                  <Translate value="profile_privacy" />
                </b>
              </h3>
            </dl>
            <div className="col-12">
              <hr className="dividerGray" />

              {this.state.form.map(item => {
                return (
                  <PrivacyItem
                    item={item}
                    key={item.Id}
                    onChange={() => {
                      this.onChange(item);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </ProfileLayout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  //console.log(reducer);
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    privacy: reducer.privacy.list,
    error: reducer.privacy.error,
    success: reducer.privacy.success,

    error_update: reducer.privacy.error_update
  };
}
const mapDispatchToProps = dispatch => ({
  LoadPrivacy: (user_id, token) =>
    dispatch(actions.loadItem({ user_id, token })),
  UpdatePrivacy: (token, field, value, user_id) =>
    dispatch(actions.updateItem({ token, field, value, user_id }))
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePrivacy);
