import React from "react";
import { Translate } from "react-redux-i18n";

import "./styles/AdNew.css";

import PlanAdCallAction from "../components/PlanAdCallAction";
import AdTip from "../components/AdTip";
import AdForm from "../components/AdForm";



class AdNew extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-ad-new">
            <div className="row">
              <div className=" col-4 d-none d-lg-block ">
                <AdTip />
                <PlanAdCallAction />
              </div>
              <div className="col-lg-8 card bg-light">
                <div className="card-header text-center ">
                  <h2>
                    <Translate value="ad_new_title" />
                  </h2>
                </div>
                <hr className="dividerGray" />
                <AdForm />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AdNew;
