function formatMessage(props) {
    return {
        id: props.index,
        text: props.message,
        createdAt: new Date(),
        user: {
            id: props.id,
            name: props.name,
            avatar: props.avatar,
        },
        socket: props.socket,
        is_seen: props.is_seen,
        is_server: props.is_server,
        to_id: props.to_id
    };
}

export default formatMessage;

/**
 *  _id: props.id,
        text: props.message,
         createdAt: new Date(),
        user: {
            _id: props.user_id,
            name: 'John Guzman',
        },
 */