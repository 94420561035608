import React from "react";
//import { Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./styles/SummaryByCountry.css";
import { connect } from "react-redux";
import * as actions from "../redux/actions/summaryByCountryActions";
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import alertify from "alertifyjs";
import "./styles/Alertify.css";
import "./styles/AlertifyTheme.css";
import "./styles/Alertify.min.css";
import "./styles/AlertifyTheme.min.css";

class SummaryByCountry extends React.Component {
  componentDidMount() {
    this.fecthLoadSummary();
  }

  fecthLoadSummary = async () => {
    try {
      await this.props
        .LoadSummaryByCountry(this.props.user_id, this.props.token)
        .then(() => {
          if (this.props.error) {
            alertify.warning(I18n.t(this.props.error));
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <div className="card ">
        <div className="card-header">
          <h4 className="text-center">
            <Translate value="summary" />
          </h4>
        </div>
        <div className="card-body ">
          <nav className="nav flex-column">
            <Link to="/home/sales" className="nav-link">
              <li className="summarycountry_inline">
                <div className=" ">
                  <Translate value="sales" />
                </div>
                <div className="">
                  {this.props.summary.Sales}
                </div>
              </li>
            </Link>
            <Link to="/home/rents" className="nav-link">
              <li className="summarycountry_inline">
                <div className="">
                  <Translate value="rents" />
                </div>
                <div className="">
                  {this.props.summary.Rents}
                </div>
              </li>
            </Link>

            <Link to="/home/exchanges" className="nav-link">
              <li className="summarycountry_inline">
                <div className="">
                  <Translate value="exchanges" />
                </div>
                <div className="">
                  {this.props.summary.Exchanges}
                </div>
              </li>
            </Link>

            <Link to="/home/donations" className="nav-link">
              <li className="summarycountry_inline">
                <div className="">
                  <Translate value="donations" />
                </div>
                <div className="">
                  {this.props.summary.Donations}
                </div>
              </li>
            </Link>

          </nav>
        </div>
      </div>
    );
  }
}

function mapStateToProps(reducer) {
  // console.log(reducer);<div className="container_summary">
  return {
    user_id: reducer.session.auth.id,
    token: reducer.session.auth.access_token,

    loading: reducer.summaryByCountry.loading,
    summary: reducer.summaryByCountry.item,
    error: reducer.summaryByCountry.error,
  };
}
const mapDispatchToProps = (dispatch) => ({
  LoadSummaryByCountry: (user_id, token) =>
    dispatch(actions.loadList({ user_id, token })),
});
export default connect(mapStateToProps, mapDispatchToProps)(SummaryByCountry);
/**
 *  <Link to="/home/auction" className="nav-link">
              <li className="summarycountry_inline">
                <div className="">
                  <Translate value="auction" />
                </div>
                <div className="">
                  {this.props.summary.Auctions}
                </div>
              </li>
            </Link>
 */