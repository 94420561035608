import {
  MARKETSTORE_COMMENT_ATTEMPT,
  MARKETSTORE_COMMENT_SUCCESS,
  MARKETSTORE_COMMENT_FAILED,
  //////////////
  MARKETSTORE_ADD_COMMENT_SUCCESS,
  MARKETSTORE_ADD_COMMENT_FAILED,
  ////////////////////////////////
  MARKETSTORE_REMOVE_COMMENT_SUCCESS,
  MARKETSTORE_REMOVE_COMMENT_FAILED,
  ///////////////////////////
  MARKETSTORE_REPORT_COMMENT_SUCCESS,
  MARKETSTORE_REPORT_COMMENT_FAILED,
  ///////////////////////////
  MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS,
  MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED,
} from "../types/marketstoreCommentTypes";

const INITIAL_STATE = {
  loading: false,
  list: [],
  error: "",
  error_report: "",
  error_remove: "",
  error_update: "",
};

function marketStoreComment(state = INITIAL_STATE, action) {
  switch (action.type) {
    case MARKETSTORE_COMMENT_ATTEMPT:
      return {
        ...state,
        loading: true,
      };
    case MARKETSTORE_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.data.list,
        error: "",
      };
    case MARKETSTORE_COMMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
        list: [],
      };
    //////////////////////////
    case MARKETSTORE_ADD_COMMENT_SUCCESS:
      return {
        ...state,
        list: [action.item, ...state.list],
        error_update: "",
      };

    case MARKETSTORE_ADD_COMMENT_FAILED:
      return {
        ...state,
        error_update: action.error,
      };
    //////////////////////////
    case MARKETSTORE_REMOVE_COMMENT_SUCCESS: {
      const newState = state.list.filter((item) => {
        return item.CommentId !== action.item.CommentId;
      });
      return {
        ...state,
        list: newState,
      };
    }
    case MARKETSTORE_REMOVE_COMMENT_FAILED:
      return {
        ...state,
        error_remove: action.error,
      };
    //////////////////////////
    case MARKETSTORE_REPORT_COMMENT_SUCCESS:
      return {
        ...state,
      };

    case MARKETSTORE_REPORT_COMMENT_FAILED:
      return {
        ...state,
        error_report: action.error,
      };

    case MARKETSTORE_COMMENT_UPDATE_LIKE_SUCCESS: {
      const newState = state.list.map((item) => {
        if (item.CommentId === action.item.CommentId)
          return action.item;
        else return item;
      });
      return {
        ...state,
        list: newState,
        error_update: "",
      };
    }

    case MARKETSTORE_COMMENT_UPDATE_LIKE_FAILED:
      return {
        ...state,
        error_update: action.error,
      };

    default:
      return state;
  }
}
export default marketStoreComment;
