import React from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/sessionActions";
import alertify from "alertifyjs";
import "../components/styles/Alertify.css";
import "../components/styles/AlertifyTheme.css";
import "../components/styles/Alertify.min.css";
import "../components/styles/AlertifyTheme.min.css";
import { I18n, Translate } from "react-redux-i18n";
//
class PasswordReset extends React.Component {
  state = {
    form: {
      password: "",
      password1: ""
    },
    enable: true,
    token: ""
  };

  componentDidMount() {
    this.setState({
      token: this.props.match.params.token
    });
  }

  onClick = async e => {
    if (this.state.password === this.state.password1) {
      try {
        await this.props
          .ChangePassword(this.state.token, this.state.password)
          .then(() => {
            if (this.props.error_update) {
              alertify.warning(I18n.t(this.props.error_update));
            } else {
              this.props.history.push("/password/reset/successfull");
            }
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      alertify.warning(I18n.t("warning_password_similar"));
    }
    /*
     */
  };

  handleChange = e => {
    this.setState({
      password: e.target.value
    });
  };
  handleChange1 = e => {
    this.setState({
      password1: e.target.value
    });
    if (e.target.value.length > 0) this.setState({ enable: false });
    else this.setState({ enable: true });
  };

  handleSubmit = e => {
    e.preventDefault();
  };
  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="container-recovery-password">
            <form className="form-signin" onSubmit={this.handleSubmit}>
              <h3 className="title-recovery">
                {" "}
                <Translate value="password_forget_title" />
              </h3>

              <div className="form-group">
                <input
                  onChange={this.handleChange}
                  className="form-control"
                  type="password"
                  name="password"
                  placeholder={I18n.t("password_new")}
                  required
                  autoFocus
                  value={this.state.password}
                />
              </div>
              <div className="form-group">
                <input
                  onChange={this.handleChange1}
                  className="form-control"
                  type="password"
                  name="password1"
                  placeholder={I18n.t("password_confirm")}
                  required
                  autoFocus
                  value={this.state.password1}
                />
              </div>

              <button
                onClick={() => {
                  this.onClick();
                }}
                className="btn btn-primary"
                disabled={this.state.enable}
              >
                <Translate value="password_forget_send" />
              </button>
              <div className="addition-recovery">
                <small>
                  <Translate value="password_forget_email" />

                  <a
                    href="mailto:team@godiway.com?subject=Godiwayer&body=Equipo%20GodiWay,"
                    target="_blank" rel="noopener noreferrer"
                  >
                    {" "}
                    team@godiway.com
                  </a>
                </small>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(reducer) {
  return {
    error_update: reducer.user.error_update,
    success_update: reducer.user.success_update
  };
}

const mapDispatchToProps = dispatch => ({
  ChangePassword: (token, password) =>
    dispatch(actions.changePassword({ token, password }))
});
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
