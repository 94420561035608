import axios from "axios";

import {
  TRANSACTION_REJECTED_ATTEMPT,
  TRANSACTION_REJECTED_SUCCESS,
  TRANSACTION_REJECTED_FAILED
} from "../types/transactionRejectedTypes";
import { config } from "../../config";
const url_api = config.api.godiway;

export function loading() {
  return {
    type: TRANSACTION_REJECTED_ATTEMPT
  };
}

export function success(data) {
  return {
    type: TRANSACTION_REJECTED_SUCCESS,
    data: data
  };
}

export function failed(error) {
  return {
    type: TRANSACTION_REJECTED_FAILED,
    error: error
  };
}

export const loadList = (data: Object) => async dispatch => {
  dispatch(loading());
  await axios
    .get(url_api + "transaction/rejected", {
      params: {
        id: data.user_id,
        transaction_id: data.id
      },
      headers: { Authorization: `Bearer ${data.token}` },
    })
    .then(function (response) {
      if (response.status < 300) {
        if (response.data.status === "Success")
          dispatch(success(response.data.body));
        else dispatch(failed(response.data.body));
      } else {
        dispatch(failed("try_later"));
      }
    })
    .catch(function (error) {
      if (error.Error) {
        dispatch(failed("try_later"));
      } else {
        dispatch(failed("try_later"));
      }
      return;
    });
};
